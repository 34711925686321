import PT from 'prop-types';
import React from 'react';

import { FlexLayout, styles, Text } from '~/ui';

function PageLayout({ children, rightTitleContent, title }) {
  return (
    <FlexLayout flexDirection="column" flexGrow="1" p={6} space={6} sx={{ ...styles.typography.yScrollable }}>
      <FlexLayout alignItems="center">
        <FlexLayout flexGrow="1">
          {title && (
            <Text color="deep-sapphire" variant="2xl-spaced-bold">
              {title}
            </Text>
          )}
        </FlexLayout>
        {rightTitleContent && rightTitleContent}
      </FlexLayout>
      {children}
    </FlexLayout>
  );
}

PageLayout.propTypes = {
  children: PT.node,
  rightTitleContent: PT.node,
  title: PT.oneOfType([PT.string, PT.node]),
};

export default PageLayout;
