import { riskAnalysisAssessments } from 'enums';
import { FlexLayout, Text } from 'ui';

type RiskAnalysisGraphPropsType = {
  assessment: 'Low' | 'Medium' | 'High';
};

const RiskAnalysisGraph = ({ assessment }: RiskAnalysisGraphPropsType) => {
  return (
    <FlexLayout
      id="graph"
      alignItems="center"
      space={4}
      p={4}
      bg="alabaster"
      sx={{ border: 'border', borderRadius: 'm' }}
    >
      <FlexLayout flexDirection="column" space={2} sx={{ width: '100%' }}>
        <FlexLayout alignItems="center" justifyContent="space-between" space={10}>
          {riskAnalysisAssessments.map((rating) => {
            const isActive = rating === assessment;
            return (
              <FlexLayout key={rating} px={2} sx={{ position: 'relative' }}>
                <Text color={isActive ? 'shakespeare' : 'bali-hai'} sx={{ textAlign: 'center' }} variant="s-spaced">
                  {rating}
                </Text>
                {isActive && (
                  <FlexLayout
                    bg="shakespeare"
                    sx={{ borderRadius: 'm', bottom: '-11px', height: '4px', left: 0, position: 'absolute', right: 0 }}
                  />
                )}
              </FlexLayout>
            );
          })}
        </FlexLayout>
        <FlexLayout bg="link-water" sx={{ borderRadius: 's', height: '2px' }} />
      </FlexLayout>
    </FlexLayout>
  );
};

export default RiskAnalysisGraph;
