import { theme } from '~/ui';

export default {
  brake: {
    pointerEvents: 'none',
    letterSpacing: '2px',
  },
  container: {
    alignItems: 'center',
    alignSelf: 'center',
    display: 'flex',
    listStyleType: 'none',
    padding: 0,
  },
  disabled: {
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'none',
    },
  },
  link: {
    cursor: 'pointer',
    outline: 'none',
  },
  page: {
    alignItems: 'center',
    backgroundColor: theme.colors['link-water'],
    borderRadius: '4.5px',
    color: theme.colors['deep-sapphire'],
    cursor: 'pointer',
    display: 'flex',
    fontSize: '12px',
    fontWeight: 'bold',
    height: '18px',
    justifyContent: 'center',
    margin: '0 4px',
    textAlign: 'center',
  },
  pageLink: {
    padding: '0 5px',
    outline: 'none',
  },
  pageActive: {
    backgroundColor: theme.colors['deep-sapphire'],
    color: theme.colors.white,
  },
  pageActiveLink: {
    pointerEvents: 'none',
  },
};
