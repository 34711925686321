const fixedTexts = {
  cir: 'Input the annualized credit interest rate of the cash pool with the bank. This rate must be positive.',
  cirLabel: 'Cash Pool Credit Interest Rate',
  dir: 'Input the annualized debit interest rate of the cash pool with the bank. This rate must be positive.',
  dirLabel: 'Cash Pool Debit Interest Rate',
  unit: '%',
};

const floatTexts = {
  cir: 'Input the annualized credit interest spread of the cash pool with the bank.<br /> A negative value will be treated as a discount from the overnight rate,<br /> while a positive value will be treated as a premium to the overnight rate.',
  cirLabel: 'Cash Pool Credit Interest Spread',
  dir: 'Input the annualized debit interest spread of the cash pool with the bank.<br /> A negative value will be treated as a discount from the overnight rate,<br /> while a positive value will be treated as a premium to the overnight rate.',
  dirLabel: 'Cash Pool Debit Interest Spread',
  unit: 'basis points',
};

export const cashPoolTexts = {
  fixedTexts,
  floatTexts,
  tooltips: {
    operatingExpenses: `
      Input the expenses related to routine and administrative functions of operating the cash pool.<br />
      <br />
      These should be in the cash pool's currency as selected above.<br />
      <br />
      Operating expenses are not considered directly in the model and are assumed to be remunerated separately.
    `,
  },
};
