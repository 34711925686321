import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { logout } from 'api';
import { getAuthMethod, removeAccessToken, removeAuthMethod } from 'auth';
import { azureUtils } from 'auth/azure';
import NotificationBadge from 'components/NotificationsPage/NotificationBadge';
import { UserAvatar } from 'components/Shared';
import { UnhandledNotificationsContext } from 'context/notification';
import { UserInfoContext } from 'context/user';
import { isAdmin, rolesEnum } from 'enums';
import { routesEnum } from 'routes';
import { FlexLayout, Icon, Text } from 'ui';
import { useOnClickOutside } from 'ui/hooks';

function UserMenu() {
  const history = useHistory();
  const authMethod = getAuthMethod();
  const { userInfo } = useContext(UserInfoContext);
  const { unhandledNotifications } = useContext(UnhandledNotificationsContext);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const userMenuRef = useRef<HTMLElement>(null);
  useOnClickOutside(userMenuRef, () => setIsCollapsed(true));

  function logoutCleanup() {
    removeAccessToken();
    removeAuthMethod();
    window.location.href = routesEnum.LOGIN;
  }

  function handleOnLogoutClick() {
    logout().then(logoutCleanup);
  }

  function handleOnAzureLogoutClick() {
    window.open(azureUtils.AZURE_LOGOUT_URL);
    handleOnLogoutClick();
  }

  const getBackgroundColor = (path: string) => () => {
    if (
      !history.location.pathname.startsWith(routesEnum.NOTIFICATIONS) &&
      path.startsWith(routesEnum.NOTIFICATIONS) &&
      unhandledNotifications?.length > 0
    ) {
      return 'white-02';
    }
    return history.location.pathname.startsWith(path) ? 'shakespeare' : null;
  };

  useEffect(() => {
    setIsCollapsed(true);
  }, [history.location.pathname]);

  if (!userInfo) return null;

  return (
    <FlexLayout ref={userMenuRef} flexDirection="column" p={4}>
      {!isCollapsed && (
        <FlexLayout
          p={4}
          bg="deep-sapphire"
          flexDirection="column"
          sx={{
            position: 'absolute',
            width: '240px',
            left: '241px',
            bottom: '82px',
            borderRadius: 'm',
            zIndex: 100,
          }}
        >
          <>
            <FlexLayout flexDirection="column" space={3}>
              <FlexLayout
                alignItems="center"
                sx={{ backgroundColor: getBackgroundColor(routesEnum.TRASH), borderRadius: 'm' }}
                space={2}
                p={2}
                onClick={() => history.push(routesEnum.TRASH)}
              >
                <Icon color="white" icon="delete" size="m" />
                <Text color="white" variant="s-spaced-medium">
                  Trash
                </Text>
              </FlexLayout>
            </FlexLayout>

            {isAdmin(userInfo.role) && (
              <FlexLayout flexDirection="column" pt={2} space={3}>
                <FlexLayout
                  alignItems="center"
                  sx={{ backgroundColor: getBackgroundColor(routesEnum.NOTIFICATIONS), borderRadius: 'm' }}
                  space={2}
                  p={2}
                  onClick={() => history.push(routesEnum.NOTIFICATIONS)}
                >
                  <Icon color="white" icon="bell" size="m" />
                  <Text color="white" variant="s-spaced-medium">
                    Notifications
                  </Text>
                  <NotificationBadge notificationNumber={unhandledNotifications?.length} />
                </FlexLayout>
              </FlexLayout>
            )}
            {userInfo.role === rolesEnum.SUPERADMIN && (
              <FlexLayout flexDirection="column" pt={2} space={3}>
                <FlexLayout
                  alignItems="center"
                  space={2}
                  p={2}
                  sx={{ backgroundColor: getBackgroundColor(routesEnum.CLIENTS), borderRadius: 'm' }}
                  onClick={() => history.push(routesEnum.CLIENTS)}
                >
                  <Icon color="white" icon="clients" size="m" />
                  <Text color="white" variant="s-spaced-medium">
                    Clients
                  </Text>
                </FlexLayout>
              </FlexLayout>
            )}
            {userInfo.role === rolesEnum.SUPERADMIN && (
              <FlexLayout flexDirection="column" pt={2} space={3}>
                <FlexLayout
                  alignItems="center"
                  space={2}
                  p={2}
                  sx={{ backgroundColor: getBackgroundColor(routesEnum.CUFT_DATA_UPLOAD), borderRadius: 'm' }}
                  onClick={() => history.push(routesEnum.CUFT_DATA_UPLOAD)}
                >
                  <Icon color="white" icon="paper" size="m" />
                  <Text color="white" variant="s-spaced-medium">
                    CUFT Upload
                  </Text>
                </FlexLayout>
              </FlexLayout>
            )}
            {isAdmin(userInfo.role) && (
              <FlexLayout flexDirection="column" pt={2} space={3}>
                <FlexLayout
                  alignItems="center"
                  space={2}
                  p={2}
                  sx={{ backgroundColor: getBackgroundColor(routesEnum.USERS), borderRadius: 'm' }}
                  onClick={() => history.push(routesEnum.USERS)}
                >
                  <Icon color="white" icon="users" size="m" />
                  <Text color="white" variant="s-spaced-medium">
                    Users
                  </Text>
                </FlexLayout>
              </FlexLayout>
            )}
            <FlexLayout flexDirection="column" pt={2} space={3}>
              <FlexLayout
                alignItems="center"
                space={2}
                p={2}
                sx={{ backgroundColor: getBackgroundColor(routesEnum.SETTINGS), borderRadius: 'm' }}
                onClick={() => history.push(routesEnum.SETTINGS)}
              >
                <Icon color="white" icon="gear" size="m" />
                <Text color="white" variant="s-spaced-medium">
                  Settings
                </Text>
              </FlexLayout>
            </FlexLayout>
            <FlexLayout flexDirection="column" pt={2} space={3}>
              <FlexLayout
                alignItems="center"
                space={2}
                p={2}
                pb={3}
                onClick={authMethod === 'azure' ? handleOnAzureLogoutClick : handleOnLogoutClick}
              >
                <Icon color="white" icon="logout" size="m" />
                <Text color="white" variant="s-spaced-medium">
                  Logout
                </Text>
              </FlexLayout>
            </FlexLayout>
          </>
        </FlexLayout>
      )}
      <FlexLayout sx={{ borderTop: 'border', borderColor: '#ffffff33' }}></FlexLayout>
      <FlexLayout
        dataTestId="userMenuButton"
        alignItems="center"
        space={3}
        p={2}
        mt={5}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <UserAvatar fullName={userInfo.fullName} />
        <Text color="white" variant="m-spaced-medium" sx={{ flexGrow: '1' }}>
          {userInfo.fullName || 'Account'}
        </Text>
        <Icon color="white" icon={isCollapsed ? 'chevronUp' : 'chevronDown'} />
      </FlexLayout>
    </FlexLayout>
  );
}

export default UserMenu;
