import React from 'react';
import ReactTooltip from 'react-tooltip';

import { Icon } from '~/ui';

const IconWithTooltip = ({ tooltip, icon = 'helpTooltip', size = 'xs' }) => {
  return (
    <>
      <Icon data-for={tooltip} data-tip="" icon={icon} size={size} />
      <ReactTooltip html={true} id={tooltip}>
        {tooltip}
      </ReactTooltip>
    </>
  );
};

export default IconWithTooltip;
