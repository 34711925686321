import React, { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getAdmins, getMyUserInfo } from 'api';
import { getAccessToken } from 'auth';
import { routesEnum } from 'routes';
import { UserType, UserInfoType } from 'types';

type UserInfoProps = {
  children: React.ReactNode;
};

type ProviderDataType = {
  userInfo: UserInfoType;
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfoType | undefined>>;
  admins: UserType[];
};

export const UserInfoContext = createContext<ProviderDataType>({} as ProviderDataType);

const UserInfo = ({ children }: UserInfoProps) => {
  const history = useHistory();
  const [userInfo, setUserInfo] = useState<UserInfoType>();
  const [admins, setAdmins] = useState<UserType[]>();

  /**
   * If the user is on /login or /reset-password there is no need to fetch this data.
   * Without this check azure login would fail because history.replace would overwrite the code returned from oauth.
   * This is going to rerun after login because we redirect by changing window.location.href.
   */
  useEffect(() => {
    const pathname = history.location.pathname;
    if (![routesEnum.LOGIN, routesEnum.RESET_PASSWORD].includes(pathname)) {
      if (getAccessToken()) {
        getMyUserInfo().then(setUserInfo);
        getAdmins().then(setAdmins);
      } else {
        history.replace(routesEnum.LOGIN);
      }
    }
  }, [history]);

  return (
    <UserInfoContext.Provider value={{ userInfo, setUserInfo, admins } as ProviderDataType}>
      {children}
    </UserInfoContext.Provider>
  );
};

export default UserInfo;
