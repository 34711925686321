import { default as cashPool } from './cashPool.slice';
import { default as cashPoolData } from './cashPoolData.slice';
import { default as cashPoolDataColumns } from './tableColumns/cashPoolDataColumns.slice';
import { default as cashPoolPaymentColumns } from './tableColumns/cashPoolPaymentColumns.slice';
import { default as cashPoolStructuralPositions } from './cashPoolStructuralPositions.slice';
import { default as cashPoolTopCurrencyAccount } from './cashPoolTopCurrencyAccount.slice';
import { default as clientFeature } from './clientFeature.slice';
import { default as companies } from './companies.slice';
import { default as company } from './company.slice';
import { default as creditRating } from './creditRating.slice';
import { default as cuftDataFilters } from './cuftDataFilters.slice';
import { default as cuftDataColumns } from './tableColumns/cuftDataColumns.slice';
import { default as creditRatingColumns } from './tableColumns/creditRatingColumns.slice';
import { default as floatBalloonPaymentColumns } from './tableColumns/floatBalloonPaymentColumns.slice';
import { default as guaranteeColumns } from './tableColumns/guaranteeColumns.slice';
import { default as guaranteePaymentColumns } from './tableColumns/guaranteePaymentColumns.slice';
import { default as importedReportForm } from './importedReportForm.slice';
import { default as loanColumns } from './tableColumns/loanColumns.slice';
import { default as backToBackLoanColumns } from './tableColumns/backToBackLoanColumns.slice';
import { default as loanPaymentColumns } from './tableColumns/loanPaymentColumns.slice';
import { default as withholdingTaxPaymentColumns } from './tableColumns/withholdingTaxPaymentsColumns.slice';
import { default as notifications } from './notifications.slice';
import { default as payments } from './payment.slice';
import { default as report } from './report.slice';
import { default as reportFile } from './reportFile.slice';
import { default as settings } from './settings.slice';
import { default as statementDataFilters } from './statementDataFilters.slice';

export default {
  companies,
  company,
  creditRating,
  guaranteeColumns,
  loanColumns,
  backToBackLoanColumns,
  creditRatingColumns,
  report,
  reportFile,
  importedReportForm,
  notifications,
  guaranteePaymentColumns,
  loanPaymentColumns,
  withholdingTaxPaymentColumns,
  floatBalloonPaymentColumns,
  payments,
  cashPool,
  cashPoolData,
  cuftDataFilters,
  cuftDataColumns,
  cashPoolStructuralPositions,
  cashPoolPaymentColumns,
  cashPoolDataColumns,
  cashPoolTopCurrencyAccount,
  clientFeature,
  settings,
  statementDataFilters,
};
