import React from 'react';

import { SingleSelect } from '~/ui';
import { getOptionsFromArray } from '~/utils/arrays';

const options = getOptionsFromArray(['Bullet', 'Balloon']);

function LoanTypeSingleSelect({ label = 'Type', tooltip, dataTestId, value, width = 'm', onChange }) {
  return (
    <SingleSelect
      label={label}
      options={options}
      tooltip={tooltip}
      dataTestId={dataTestId}
      value={value}
      width={width}
      onChange={onChange}
    />
  );
}

export default LoanTypeSingleSelect;
