import { backend } from 'services';
import { toFile } from 'utils/documents';
import { TemplateFileLabelsEnum } from 'enums/templateFiles';
import { TemplateFileType, TemplateFileTypeType } from 'types';

import { apiPaths } from './routes';

export async function getStandardTemplateFile(label: string): Promise<File> {
  const response = await backend.get(`${apiPaths.clientTemplate}/standard-template/${label}`, {
    fullResponse: true,
    responseType: 'blob',
  });
  return toFile(response);
}

export async function getClientTemplateFile(templateId: number): Promise<File> {
  const response = await backend.get(`${apiPaths.clientTemplate}/template/${templateId}`, {
    fullResponse: true,
    responseType: 'blob',
  });
  return toFile(response);
}

export function getAllClientTemplateFiles(): Promise<TemplateFileType[]> {
  return backend.get(`${apiPaths.clientTemplate}/template`);
}

export function deleteClientTemplateFile(templateId: number): Promise<void> {
  return backend.delete(`${apiPaths.clientTemplate}/template/${templateId}`);
}

export function uploadClientTemplateFile(
  file: File,
  label: TemplateFileLabelsEnum,
  type: TemplateFileTypeType,
  country: string | null,
  companyId: number | null
): Promise<void> {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('label', label);
  formData.append('type', type);
  if (country) formData.append('country', String(country));
  if (companyId) formData.append('companyId', String(companyId));

  return backend.post(`${apiPaths.clientTemplate}/template`, formData);
}
