/**
 * Creates a cancellable promise object containing the wrapped promise and cancel method.  When cancelled, the promise will always reject with an object that identifies that 'cancelled === true'
 * @param {Promise} promise any JS promise
 * @return {promise: Promise, cancel: function} cancellable promise object
 */
export const cancellable = <PromiseType>(
  promise: Promise<PromiseType>
): { promise: Promise<PromiseType>; cancel: Function } => {
  let _cancelled = false;

  const wrappedPromise: Promise<PromiseType> = new Promise((resolve, reject) => {
    promise
      .then((val) => {
        if (_cancelled) {
          reject({ cancelled: true });
        } else {
          resolve(val);
        }
      })
      .catch((error) => {
        if (_cancelled) {
          reject({ cancelled: true });
        } else {
          reject(error);
        }
      });
  });

  return {
    promise: wrappedPromise,
    cancel() {
      _cancelled = true;
    },
  };
};
