import _ from 'lodash';
import { isValid, parse } from 'date-fns';

import { DATE_FNS_FORMATS } from 'enums/dates';

export const getVisibleColumns = ({ columns, visibleColumns }: any) => {
  const result = [];
  for (let i = 0, length = columns.length; i < length; i++) {
    if (visibleColumns[columns[i].label]) {
      result.push(columns[i]);
    }
  }

  return result;
};

export const dateSortingFunction = (rowA: any, rowB: any, id: string, desc: boolean) => {
  const parseDate = (date: string) => {
    for (const format of Object.values(DATE_FNS_FORMATS)) {
      const parsedDate = parse(date, format, new Date());
      if (isValid(parsedDate)) return parsedDate;
    }
    return new Date();
  };

  const valueA = parseDate(_.get(rowA.original, id));
  const valueB = parseDate(_.get(rowB.original, id));

  if (desc) return valueA > valueB ? 1 : -1;

  return valueA < valueB ? -1 : 1;
};

export const percentageSortingFunction = (rowA: any, rowB: any, id: string, desc: boolean) => {
  const parsePercentage = (percentage: string) => parseFloat(percentage.replace('%', ''));

  const valueA = parsePercentage(_.get(rowA.original, id));
  const valueB = parsePercentage(_.get(rowB.original, id));

  if (desc) return valueA > valueB ? 1 : -1;

  return valueA < valueB ? -1 : 1;
};
