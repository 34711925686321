import React from 'react';
import { components } from 'react-select';

import { Icon } from '~/ui';

function DropdownIndicator(props) {
  const {
    selectProps: { menuIsOpen },
  } = props;

  return (
    <components.DropdownIndicator {...props}>
      <Icon color="bali-hai" icon={menuIsOpen ? 'triangleUp' : 'triangleDown'} size="2xs" />
    </components.DropdownIndicator>
  );
}

function IndicatorSeparator() {
  return null;
}

export default { DropdownIndicator, IndicatorSeparator };
