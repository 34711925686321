import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { QuestionGroup } from 'components/Shared';
import { NORDIC, PHYSICAL } from 'enums';
import { cashPoolSelector, updateField, updateNordicPhysicalRiskAnalysisAnswers } from 'reducers/cashPool.slice';
import { Box, Button, CollapsibleCard, FlexLayout } from 'ui';

import RiskAnalysisGraph from '../RiskAnalysisGraph';
import RiskAssessmentInput from './RiskAssessmentInput';
import { updateAssessment, getAssessmentAnswersValues } from './FunctionalAnalysisUtils';

const defaultAssessmentAnswers = Object.freeze({
  guarantee: false,
  liquidityRisk1: false,
  liquidityRisk2: false,
  creditRisk1: false,
  creditRisk2: false,
  functions1: false,
  functions2: false,
  functions3: false,
  functions4: false,
  functions5: false,
  functions6: false,
});

type AssessmentAnswersType = keyof typeof defaultAssessmentAnswers;

const options = {
  guaranteeOptions: [
    {
      label:
        'The cash pool leader has received a guarantee from the company group, covering any shortfall resulting from participants being unable to repay their cash withdrawals.',
      value: 'guarantee',
    },
  ],
  liquidityOptions: [
    {
      label:
        'The cash pool leader contractually bears the losses resulting from mismatches between the maturity of the credit and debit balances of participants.',
      value: 'liquidityRisk1',
    },
    {
      label:
        'The cash pool leader has the financial capacity to bear losses resulting from mismatches between the maturity of the credit and debit balances of participants.',
      value: 'liquidityRisk2',
    },
  ],
  creditRiskOptions: [
    {
      label:
        'The cash pool leader contractually bears the losses resulting from the inability of participants to repay their cash withdrawals.',
      value: 'creditRisk1',
    },
    {
      label:
        'The cash pool leader has the financial capacity to bear losses resulting from the inability of participants to repay their cash withdrawals.',
      value: 'creditRisk2',
    },
  ],
  functionsOptions: [
    {
      label: 'The cash pool leader monitors and forecasts cash positions across participants.',
      value: 'functions1',
    },
    {
      label: 'The cash pool leader secures financing where needed to keep the cash pool sufficiently funded.',
      value: 'functions2',
    },
    {
      label:
        'The cash pool leader determines the contractual terms, including financial risk policy, of deposits and withdrawals within the cash pool.',
      value: 'functions3',
    },
    {
      label:
        'The cash pool leader assesses the debit positions of participants against their respective credit quality.',
      value: 'functions4',
    },
    {
      label: 'The cash pool leader sets and enforces withdrawal limits of participants.',
      value: 'functions5',
    },
    {
      label:
        "The cash pool leader monitors changes in economic circumstances and changes in participants' conditions and its potential credit risk impact.",
      value: 'functions6',
    },
  ],
};

const functionalAnalysisTooltip = `
  The answers determine how much of the benefit generated by the cash pool<br />
  should be allocated to the cash pool leader. To bear the risks, the cash pool leader<br />
  must have the financial capacity to bear them and exercise control functions over them.
  `;

const NordicPhysicalFunctionalAnalysisCard = ({ isAuditTrail = false }) => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(true);
  const [isCalculationDone, setIsCalculationDone] = useState(false);
  const { assessment, nordicPhysicalRiskAnalysisAnswers, type, totalRisk } = useSelector(cashPoolSelector);
  const isShowing = [PHYSICAL, NORDIC].includes(type);

  const updateAssessmentField = (values: Array<AssessmentAnswersType>) => {
    const updatedAssessmentAnswers: Record<AssessmentAnswersType, boolean> = nordicPhysicalRiskAnalysisAnswers
      ? { ...nordicPhysicalRiskAnalysisAnswers }
      : { ...defaultAssessmentAnswers };

    Object.entries(updatedAssessmentAnswers).forEach(
      ([key]) =>
        (updatedAssessmentAnswers[key as AssessmentAnswersType] = values.includes(key as AssessmentAnswersType))
    );

    dispatch(updateNordicPhysicalRiskAnalysisAnswers(updatedAssessmentAnswers));
  };

  const onResetClick = () => {
    dispatch(updateNordicPhysicalRiskAnalysisAnswers(defaultAssessmentAnswers));
    dispatch(updateField({ totalRisk: null, assessment: null }));
  };

  const onCalculate = () => {
    const {
      guarantee,
      liquidityRisk2,
      creditRisk2,
      functions1,
      functions2,
      functions3,
      functions4,
      functions5,
      functions6,
    } = nordicPhysicalRiskAnalysisAnswers;
    let totalRiskCalculation = 0;

    if (liquidityRisk2) {
      if (functions1) totalRiskCalculation += 0.05;
      if (functions2) totalRiskCalculation += 0.35;
      if (functions3) totalRiskCalculation += 0.05;
      if (functions4) totalRiskCalculation += 0;
      if (functions5) totalRiskCalculation += 0.15;
      if (functions6) totalRiskCalculation += 0;
    }
    if (creditRisk2) {
      if (functions1) totalRiskCalculation += 0.05;
      if (functions2) totalRiskCalculation += 0;
      if (functions3) totalRiskCalculation += 0.05;
      if (functions4) totalRiskCalculation += 0.1;
      if (functions5) totalRiskCalculation += 0.15;
      if (functions6) totalRiskCalculation += 0.05;
    }

    if (guarantee) totalRiskCalculation /= 2;

    setCollapsed(true);
    if (totalRisk) setIsCalculationDone(true);
    dispatch(updateField({ totalRisk: totalRiskCalculation * 100 }));
    updateAssessment(totalRiskCalculation, dispatch);
  };

  if (!isShowing) return null;

  return (
    <CollapsibleCard
      collapsed={collapsed}
      title="Functional Analysis"
      dataTestId="functionalAnalysisCollapsible"
      description="Select the risks borne by the cash pool leader."
      onCollapseChange={() => setCollapsed(!collapsed)}
      subtitle={
        assessment && (
          <Box sx={{ display: 'grid', gridGap: 8, gridTemplateColumns: '400px 227px minmax(200px, max-content)' }}>
            <RiskAnalysisGraph assessment={assessment} />
            <RiskAssessmentInput isCalculationDone={isCalculationDone} setIsCalculationDone={setIsCalculationDone} />
          </Box>
        )
      }
      tooltip={functionalAnalysisTooltip}
    >
      <FlexLayout disabled={isAuditTrail} flexDirection="column" space={8}>
        <FlexLayout alignItems="flex-start" space={6}>
          <FlexLayout flexDirection="column" flexGrow="1" space={8} sx={{ flexBasis: '0' }}>
            <QuestionGroup
              title="Guarantee"
              options={options.guaranteeOptions}
              values={getAssessmentAnswersValues(nordicPhysicalRiskAnalysisAnswers)}
              updateFields={updateAssessmentField}
            />
            <QuestionGroup
              title="Liquidity Risk"
              options={options.liquidityOptions}
              values={getAssessmentAnswersValues(nordicPhysicalRiskAnalysisAnswers)}
              updateFields={updateAssessmentField}
            />
            <QuestionGroup
              title="Credit Risk"
              options={options.creditRiskOptions}
              values={getAssessmentAnswersValues(nordicPhysicalRiskAnalysisAnswers)}
              updateFields={updateAssessmentField}
            />
          </FlexLayout>
          <FlexLayout flexDirection="column" flexGrow="1" space={8} sx={{ flexBasis: '0' }}>
            <QuestionGroup
              title="Functions"
              options={options.functionsOptions}
              values={getAssessmentAnswersValues(nordicPhysicalRiskAnalysisAnswers)}
              updateFields={updateAssessmentField}
            />
          </FlexLayout>
        </FlexLayout>
        <FlexLayout alignItems="flex-end" flexGrow="1" justifyContent="space-between">
          <Button text="Reset" variant="gray" onClick={onResetClick} />
          <Button
            text="Calculate"
            variant="secondary"
            onClick={onCalculate}
            dataTestId="functionalAnalysisCalculateButton"
          />
        </FlexLayout>
      </FlexLayout>
    </CollapsibleCard>
  );
};

export default NordicPhysicalFunctionalAnalysisCard;
