export enum CreditRatingValueEnum {
  'AAA/Aaa' = 'AAA/Aaa',
  'AA+/Aa1' = 'AA+/Aa1',
  'AA/Aa2' = 'AA/Aa2',
  'AA-/Aa3' = 'AA-/Aa3',
  'A+/A1' = 'A+/A1',
  'A/A2' = 'A/A2',
  'A-/A3' = 'A-/A3',
  'BBB+/Baa1' = 'BBB+/Baa1',
  'BBB/Baa2' = 'BBB/Baa2',
  'BBB-/Baa3' = 'BBB-/Baa3',
  'BB+/Ba1' = 'BB+/Ba1',
  'BB/Ba2' = 'BB/Ba2',
  'BB-/Ba3' = 'BB-/Ba3',
  'B+/B1' = 'B+/B1',
  'B/B2' = 'B/B2',
  'B-/B3' = 'B-/B3',
  'CCC+/Caa1' = 'CCC+/Caa1',
  'CCC/Caa2' = 'CCC/Caa2',
  'CCC-/Caa3' = 'CCC-/Caa3',
  'CC/Ca' = 'CC/Ca',
  'C/Ca' = 'C/Ca',
}
