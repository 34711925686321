import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';

function useUnsavedChangesWarning({ isDirty = false, message = 'Are you sure you want to discard changes?' }) {
  useEffect(() => {
    window.onbeforeunload = isDirty && (() => message);

    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty, message]);

  return [<Prompt message={message} when={isDirty} />];
}

export default useUnsavedChangesWarning;
