import PT from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { Button, FlexLayout, Textarea } from '~/ui';
import { useEnter } from '~/ui/hooks';

function EditableText({ showButton = true, isFocused = false, placeholder = '...', value, onChange, onSave }) {
  const [isEditing, setIsEditing] = useState(!value?.length && showButton);
  const ref = useRef();

  useEffect(() => {
    if (isEditing && isFocused) {
      ref.current.focus();
    }
  }, [isEditing, isFocused]);

  useEnter(() => setIsEditing(false));

  function handleOnSaveClick() {
    if (onSave) {
      onSave();
    }

    setIsEditing(false);
  }

  return (
    <FlexLayout alignItems="center" flexGrow="1" space={8}>
      <Textarea
        disabled={!isEditing}
        placeholder={placeholder}
        ref={ref}
        value={value || ''}
        onChange={onChange}
        sx={{ flexGrow: '1' }}
      />
      {showButton ? (
        isEditing ? (
          <Button iconLeft="checkmark" text="Save" variant="secondary" onClick={handleOnSaveClick} />
        ) : (
          <Button iconLeft="edit" text="Edit" variant="gray" onClick={() => setIsEditing(true)} />
        )
      ) : null}
    </FlexLayout>
  );
}

EditableText.propTypes = {
  placeholder: PT.string,
  value: PT.string,
  onChange: PT.func,
  onSave: PT.func,
};

export default EditableText;
