import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateAccounts, cashPoolTopCurrencyAccountSelector } from '~/reducers/cashPoolTopCurrencyAccount.slice';
import { Box, Button, Modal, NumberInput } from '~/ui';
import { showToast } from '~/ui/components/Toast';

const UpdateCirDirModal = ({
  buttonText = 'Submit',
  onHide,
  title = 'Edit Credit and Debit Interest Rate',
  modalWidth = 's',
  participant,
  interestType,
}) => {
  const dispatch = useDispatch();
  const topCurrencyAccount = useSelector(cashPoolTopCurrencyAccountSelector);
  const [creditInterestRate, setCreditInterestRate] = useState();
  const [debitInterestRate, setDebitInterestRate] = useState();
  const isFixed = interestType === 'fixed';

  const onSubmit = async () => {
    const account = topCurrencyAccount.accounts.find((a) => a.companyId === participant.id);
    const data = {
      ...account,
      creditInterestRate,
      debitInterestRate,
      companyId: participant.id,
      name: participant.name,
      currency: topCurrencyAccount.currency,
    };
    dispatch(updateAccounts({ ...data, value: true }));

    showToast('Participant successfully updated.');
    setCreditInterestRate();
    setDebitInterestRate();
    onHide();
  };

  const getCreditRateTooltip = (isFixed) => {
    if (isFixed) {
      return `Input the annualized standalone credit interest rate of the participant.
              <br />This rate must be positive and less than the Cash Pool Credit Rate.`;
    }

    return `Input the annualized standalone credit interest spread of the participant.<br />
            A negative value will be treated as a discount from the overnight rate,<br />
            while a positive value will be treated as a premium to the overnight rate.<br />
            This spread must be less than the Cash Pool Credit Spread.`;
  };

  const getDebitRateTooltip = (isFixed) => {
    if (isFixed) {
      return `Input the annualized standalone debit interest rate of the participant.<br />
              This rate must be positive and greater than the Cash Pool Debit Rate.`;
    }

    return `Input the annualized standalone debit interest spread of the participant.<br />
            A negative value will be treated as a discount from the overnight rate,<br />
            while a positive value will be treated as a premium to the overnight rate.<br />
            This rate must be greater than the Cash Pool Debit Spread.`;
  };

  useEffect(() => {
    setCreditInterestRate(participant?.creditInterestRate ?? NaN);
    setDebitInterestRate(participant?.debitInterestRate ?? NaN);
  }, [participant]);

  if (!participant) return null;

  return (
    <Modal
      actionButtons={
        <Button
          disabled={Number.isNaN(creditInterestRate) || Number.isNaN(debitInterestRate)}
          text={buttonText}
          dataTestId="cirDirEditSubmitButton"
          onClick={onSubmit}
        />
      }
      title={title}
      width={modalWidth}
      onHide={onHide}
      onSubmit={onSubmit}
    >
      <Box sx={{ display: 'grid', gridGap: 8, gridTemplateColumns: 'repeat(2, 1fr)' }}>
        <NumberInput
          label={isFixed ? 'Standalone Credit Rate' : 'Standalone Credit Spread'}
          tooltip={getCreditRateTooltip(isFixed)}
          inputType="float"
          allowNegatives={!isFixed}
          width="fullWidth"
          unit={isFixed ? '%' : 'basis points'}
          dataTestId="standaloneCirInput"
          value={creditInterestRate}
          onChange={setCreditInterestRate}
        />
        <NumberInput
          label={isFixed ? 'Standalone Debit Rate' : 'Standalone Debit Spread'}
          tooltip={getDebitRateTooltip(isFixed)}
          inputType="float"
          allowNegatives={!isFixed}
          width="fullWidth"
          unit={isFixed ? '%' : 'basis points'}
          dataTestId="standaloneDirInput"
          value={debitInterestRate}
          onChange={setDebitInterestRate}
        />
      </Box>
    </Modal>
  );
};

export default UpdateCirDirModal;
