import React from 'react';
import { Box as ThemeBox } from 'theme-ui';

import { styles } from 'ui';

interface BoxProps {
  id?: string;
  as?: string;
  htmlFor?: string;
  accept?: string;
  type?: string;
  multiple?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
  sx?: any;
  bg?: string;
  px?: number;
  py?: number;
  placeholder?: string;
  maxLength?: number;
  className?: string;
  dataTestId?: string;
  value?: string | number;
  ref?: React.MutableRefObject<HTMLElement>;
}

const Box = React.forwardRef<HTMLElement, BoxProps>(({ sx, ...rest }, ref) => {
  const clickableStyle = rest.disabled || rest.onClick || rest.onMouseEnter ? styles.interactions.clickable : {};

  return <ThemeBox ref={ref} sx={{ ...clickableStyle, ...sx }} {...rest} data-test-id={rest.dataTestId} />;
});

export default Box;
