import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { fetchCompanies } from 'reducers/companies.slice';
import { useAppSelector } from './redux';
import { Company } from 'types/database/Company.type';

function useCompanies(update = true): Array<Company> {
  const [shouldUpdate, setShouldUpdate] = useState(update);
  const dispatch = useDispatch();
  const companies = useAppSelector((state) => state.companies);

  useEffect(() => {
    if (shouldUpdate) {
      dispatch(fetchCompanies());
    }

    setShouldUpdate(false);
  }, [companies, dispatch, shouldUpdate]);

  return companies;
}

export default useCompanies;
