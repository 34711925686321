import { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { isUserAuthenticated, Login, ResetPasswordPage } from '~/auth';
import AzureRedirect from '~/auth/azure';
import { NotifyAdminModal } from '~/components/Modals';
import { UserInfoContext } from '~/context/user';
import { getRoutes, routesEnum } from '~/routes';
import { FlexLayout, LoadingSpinner, useDocumentTitle } from '~/ui';

function PrivateRoute({ component: Component, roles, ...restProps }) {
  const { userInfo } = useContext(UserInfoContext);

  if (!userInfo) {
    return <LoadingSpinner />;
  }

  if (roles && !roles.includes(userInfo.role)) {
    return <Redirect to={routesEnum.DASHBOARD} />;
  }

  return (
    <Route
      {...restProps}
      render={(props) => {
        return isUserAuthenticated ? <Component {...props} /> : <Redirect to={routesEnum.LOGIN} />;
      }}
    />
  );
}

function ContentWindow() {
  useDocumentTitle();
  const { userInfo } = useContext(UserInfoContext);

  const clientModules = userInfo?.client?.clientFeatures.filter((cl) => cl.feature.isModule);
  const clientRoutes = getRoutes(clientModules);

  return (
    <FlexLayout flexGrow="1">
      <Switch>
        <Route path={routesEnum.LOGIN} component={Login} />
        <Route path={routesEnum.RESET_PASSWORD} component={ResetPasswordPage} />
        <Route path={routesEnum.AZURE_REDIRECT} component={AzureRedirect} />

        {Object.values(clientRoutes).map(({ component, path, roles }) => (
          <PrivateRoute component={component} key={path} path={path} roles={roles} />
        ))}
        <Route path="*">
          <Redirect to={routesEnum.DASHBOARD} />
        </Route>
      </Switch>
      <NotifyAdminModal />
    </FlexLayout>
  );
}

export default ContentWindow;
