import ReactTooltip from 'react-tooltip';

import { Box } from 'ui';

type WithToolTipProps = {
  sx?: Record<string, number | string>;
  children: React.ReactNode;
  label: string;
  tooltip: string;
  disabled: boolean;
};

/**
 * Wrap component with this component. It shows the tooltip when button is disabled.
 * The same function for determining if component is disabled should be passed to both the nested component and this component.
 */
const WithTooltip = ({ children, label, tooltip, disabled, sx = {} }: WithToolTipProps) => {
  return (
    <>
      {disabled && (
        <ReactTooltip id={label} html={true}>
          {tooltip}
        </ReactTooltip>
      )}
      <Box data-tip data-for={label} sx={sx}>
        {children}
      </Box>
    </>
  );
};

export default WithTooltip;
