import PT from 'prop-types';
import React from 'react';

import { TextWithTooltip } from 'components/Shared';
import { Box, FlexLayout, Icon, Text } from 'ui';

type CollapsibleCardProps = {
  children: React.ReactNode;
  collapsed: boolean;
  description: string;
  subtitle: string;
  title: string;
  tooltip: string;
  dataTestId: string;
  onCollapseChange: React.MouseEventHandler<HTMLButtonElement>;
};

function CollapsibleCard({
  children,
  collapsed = true,
  description,
  subtitle,
  title,
  tooltip,
  dataTestId,
  onCollapseChange,
}: CollapsibleCardProps) {
  return (
    <FlexLayout
      bg="white"
      flexDirection="column"
      flexShrink="0"
      px={6}
      sx={{ border: 'border-dashed', borderRadius: 'm', boxShadow: 'depth-1', height: 'fit-content' }}
    >
      <FlexLayout flexDirection="column" py={6} space={4} sx={{ borderBottom: collapsed ? undefined : 'border' }}>
        <FlexLayout dataTestId={dataTestId} alignItems="center" space={2} onClick={onCollapseChange}>
          <Icon color="deep-sapphire" icon={collapsed ? 'chevronDown' : 'chevronUp'} size="m" />
          <TextWithTooltip color="deep-sapphire" id={title} label={title} tooltip={tooltip} variant="xl-spaced-bold" />
        </FlexLayout>
        <FlexLayout flexDirection="column" space={6}>
          {description && (
            <Text color="bali-hai" variant="m-spaced">
              {description}
            </Text>
          )}
          {subtitle && subtitle}
        </FlexLayout>
      </FlexLayout>
      {!collapsed && <Box py={6}>{children}</Box>}
    </FlexLayout>
  );
}

CollapsibleCard.propTypes = {
  children: PT.node,
  collapsed: PT.bool.isRequired,
  description: PT.string,
  subtitle: PT.node,
  title: PT.string.isRequired,
  tooltip: PT.string,
  onCollapseChange: PT.func.isRequired,
};

export default CollapsibleCard;
