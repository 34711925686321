const alphas = {
  'deep-sapphire-02': 'rgba(18, 36, 108, 0.2)',
  'deep-sapphire-03': 'rgba(18, 36, 108, 0.3)',
  'deep-sapphire-06': 'rgba(18, 36, 108, 0.6)',
  'white-02': 'rgba(255, 255, 255, 0.2)',
};

export const palette = Object.freeze({
  alabaster: '#F7FAFC',
  'bali-hai': '#8797AC',
  black: '#000000',
  'blaze-orange': '#FF5E03',
  'deep-sapphire': '#12246C',
  'light-gray': '#F5F7F9',
  'link-water': '#E0E5EE',
  'pattens-blue': '#DAEDF4',
  pomegranate: '#E1280F',
  shakespeare: '#48a6c9',
  white: '#FFFFFF',
  scandal: '#D0F6E9',
  oasis: '#FFEBCD',
});

export default { ...alphas, ...palette };
