import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { deleteLoan, restoreLoan } from '~/api';
import { DeleteModal, RestoreModal } from '~/components/Modals';
import { UserInfoContext } from '~/context/user';
import { TableColumnContext } from '~/context/tableColumn';
import { REPORT_TYPE, reportEnum } from '~/enums';
import { loanColumns, setColumns, resetForm } from '~/reducers/tableColumns/loanColumns.slice';
import { routesEnum } from '~/routes';
import { FlexLayout, Table } from '~/ui';
import { showToast } from '~/ui/components/Toast';
import { errorHandler } from '~/utils/errors';

import { getLoansColumns, getLoansData } from './LoansTable.utils';
import { renderTableActionColumn } from '../ReportsTables.utils';

function LoansTable({ data = [], setRefreshTrigger, isEditable = false, isSearchable = false }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const visibleColumns = useSelector(loanColumns);
  const [loanReports, setLoanReports] = useState(data);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { userInfo } = useContext(UserInfoContext);
  const { dbTableColumns } = useContext(TableColumnContext);

  const isAnalyses = location.pathname === routesEnum.ANALYSES;
  const isPortfolio = location.pathname === routesEnum.PORTFOLIO;
  const isTrash = location.pathname === routesEnum.TRASH;

  useEffect(() => {
    if (isPortfolio) {
      dispatch(setColumns({ Rate: true }));
    }
    if (isTrash) {
      dispatch(setColumns({ Deleted: true }));
    }
    return () => dispatch(resetForm());
  }, [dispatch, isPortfolio, isTrash]);

  function onItemDelete() {
    deleteLoan({ id: selectedItem.id, force: isTrash })
      .then(() => {
        setLoanReports(loanReports.filter((loanReport) => loanReport.id !== selectedItem.id));
        setRefreshTrigger((prev) => !prev);
        setShowDeleteModal(false);
        setSelectedItem(null);
        showToast('Loan has been successfully deleted.');
      })
      .catch(errorHandler);
  }

  function onItemRestore() {
    restoreLoan(selectedItem.id)
      .then(() => {
        setLoanReports(loanReports.filter((loanReport) => loanReport.id !== selectedItem.id));
        setRefreshTrigger((prev) => !prev);
        setShowRestoreModal(false);
        setSelectedItem(null);
        showToast('Loan has been successfully restored.');
      })
      .catch(errorHandler);
  }

  return (
    <FlexLayout flexDirection="column">
      <Table
        actionColumn={
          isEditable &&
          ((item) =>
            renderTableActionColumn({
              item,
              reportType: reportEnum.LOAN,
              setShowDeleteModal,
              setShowRestoreModal,
              setSelectedItem,
              history,
              role: userInfo.role,
              dispatch,
              isAnalyses,
              isPortfolio,
              isTrash,
            }))
        }
        columns={getLoansColumns(dbTableColumns[reportEnum.LOAN] ?? visibleColumns)}
        data={getLoansData(loanReports, userInfo)}
        isSearchable={isSearchable}
        onItemClick={
          isSearchable ? ({ id }) => history.push(`${location.pathname}/${id}?${REPORT_TYPE}=${reportEnum.LOAN}`) : null
        }
      />
      {showDeleteModal && (
        <DeleteModal
          item="loan"
          isShowing={showDeleteModal}
          handleOnDeleteClick={onItemDelete}
          handleOnHide={() => {
            setShowDeleteModal(false);
            setSelectedItem(null);
          }}
          isDeletePermanent={isTrash}
        />
      )}
      <RestoreModal
        item="loan"
        isShowing={showRestoreModal}
        handleOnRestoreClick={onItemRestore}
        handleOnHide={() => {
          setShowRestoreModal(false);
          setSelectedItem(null);
        }}
      />
    </FlexLayout>
  );
}

export default LoansTable;
