import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getTableColumns } from 'api';
import { getAccessToken } from 'auth';
import { routesEnum } from 'routes';

type TableColumnsContextProps = {
  children: React.ReactNode;
};

type TableColumns = {
  loan: Record<string, boolean> | null;
  guarantee: Record<string, boolean> | null;
};

type ProviderDataType = {
  dbTableColumns: TableColumns;
  setTrigger: Dispatch<SetStateAction<object>>;
};

export const TableColumnContext = createContext<ProviderDataType>({} as ProviderDataType);

const TableColumn = ({ children }: TableColumnsContextProps) => {
  const history = useHistory();
  const [dbTableColumns, setDbTableColumns] = useState<TableColumns>({ loan: null, guarantee: null });
  const [trigger, setTrigger] = useState({});

  useEffect(() => {
    const pathname = history.location.pathname;
    if (![routesEnum.LOGIN, routesEnum.RESET_PASSWORD].includes(pathname)) {
      if (getAccessToken()) {
        getTableColumns().then(setDbTableColumns);
      } else {
        history.replace(routesEnum.LOGIN);
      }
    }
  }, [history, trigger]);

  return (
    <TableColumnContext.Provider value={{ dbTableColumns, setTrigger } as ProviderDataType}>
      {children}
    </TableColumnContext.Provider>
  );
};

export default TableColumn;
