import { addMinutes, isAfter } from 'date-fns';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getUnhandledNotifications } from '~/api';
import { getAccessToken } from '~/auth';

export const UnhandledNotificationsContext = createContext();

const UnhandledNotifications = ({ children }) => {
  const [unhandledNotifications, setUnhandledNotifications] = useState();
  const nextUpdateTime = useRef(new Date());
  const [shouldRefreshUnhandledNotifications, setShouldRefreshUnhandledNotifications] = useState();
  const location = useLocation();
  const NEXT_UPDATE_MINUTES = 3;

  useEffect(() => {
    if (!getAccessToken()) return;

    if (shouldRefreshUnhandledNotifications) {
      getUnhandledNotifications().then(setUnhandledNotifications);
      return setShouldRefreshUnhandledNotifications(false);
    }

    if (isAfter(new Date(), nextUpdateTime.current)) {
      getUnhandledNotifications().then(setUnhandledNotifications);
      nextUpdateTime.current = addMinutes(nextUpdateTime.current, NEXT_UPDATE_MINUTES);
      return;
    }
  }, [location.pathname, shouldRefreshUnhandledNotifications]);

  return (
    <UnhandledNotificationsContext.Provider
      value={{ unhandledNotifications, setUnhandledNotifications, setShouldRefreshUnhandledNotifications }}
    >
      {children}
    </UnhandledNotificationsContext.Provider>
  );
};

export default UnhandledNotifications;
