import React from 'react';

import { Box, FlexLayout, Text } from '~/ui';

function BoundPoint({ point }) {
  const { label, value } = point;

  return (
    <FlexLayout alignItems="center" space={4}>
      <Text color="bali-hai" variant="m-spaced-medium">
        {label}
      </Text>
      <Box bg="bali-hai" sx={{ borderRadius: '1px', height: '2px', width: '10px' }} />
      <Text
        bg="white"
        color="deep-sapphire"
        py={4}
        sx={{ border: 'border', borderRadius: 'm', textAlign: 'center', width: '96px' }}
        variant="m-spaced-medium"
      >
        {value}
      </Text>
    </FlexLayout>
  );
}

function BoundsGraph({ data }) {
  return (
    <Box bg="alabaster" p={4} sx={{ border: 'border', borderRadius: 'm', flexGrow: '1', position: 'relative' }}>
      <FlexLayout
        alignItems="center"
        flexDirection="column"
        margin="0 auto"
        sx={{ height: '100%', width: 'fit-content' }}
      >
        <Box bg="link-water" sx={{ borderRadius: '1px', height: '2px', width: '16px' }} />
        <Box bg="link-water" sx={{ height: '100%', width: '2px' }} />
        <Box bg="link-water" sx={{ borderRadius: '1px', height: '2px', width: '16px' }} />
      </FlexLayout>
      <FlexLayout
        alignItems="flex-end"
        flexDirection="column"
        justifyContent="space-between"
        margin="0 auto"
        sx={{ bottom: '32px', left: 0, position: 'absolute', right: '-2px', top: '32px', width: 'fit-content' }}
      >
        {data.map((point, index) => (
          <BoundPoint key={index} point={point} />
        ))}
      </FlexLayout>
    </Box>
  );
}

export default BoundsGraph;
