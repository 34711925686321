import { Helmet } from 'react-helmet';

const GOOGLE_ANALYTICS_ID = 'UA-213796548-1';
const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

const ReactHelmet = () => {
  return (
    <Helmet>
      {isProduction ? (
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`}></script>
      ) : null}
      {isProduction ? (
        <script>
          {`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments)}gtag('js', new Date()); gtag('config', '${GOOGLE_ANALYTICS_ID}')`}
        </script>
      ) : null}
    </Helmet>
  );
};

export default ReactHelmet;
