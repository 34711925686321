import { backend } from 'services';
import { UserInfoType, UserType, CreateUserType, UpdateUserSettingsDataType } from 'types';

import { apiPaths } from './routes';

export function login(data: { username: string; password: string }): Promise<{ accessToken: string }> {
  return backend.post(apiPaths.user, data);
}

export function loginAsAnyone(data: any): Promise<{ accessToken: string }> {
  return backend.post(`${apiPaths.user}/login-as-anyone`, data);
}

export function logout() {
  return backend.post(`${apiPaths.user}/logout`);
}

export function getUsers(): Promise<UserType[]> {
  return backend.get(apiPaths.user);
}

export function getAdmins(): Promise<UserType[]> {
  return backend.get(`${apiPaths.user}/admins`);
}

export function createUser(data: CreateUserType, shouldConfirmUserCreate: boolean): Promise<UserType> {
  let endpoint = `${apiPaths.user}/new`;
  if (shouldConfirmUserCreate) endpoint = `${endpoint}?confirm=true`;

  return backend.post(endpoint, data);
}

export function getMyUserInfo(): Promise<UserInfoType> {
  return backend.get(`${apiPaths.user}/me`);
}

export function promoteToAdmin(id: number) {
  return backend.patch(`${apiPaths.user}/promote/${id}`);
}

export function demoteToUser(id: number) {
  return backend.patch(`${apiPaths.user}/demote/${id}`);
}

export function deleteUser(id: number) {
  return backend.delete(`${apiPaths.user}/${id}`);
}

export function muteNotifications(data: { areNotificationsMuted: boolean }) {
  return backend.patch(`${apiPaths.user}/mute-notifications`, data);
}

export function updateSettings(data: UpdateUserSettingsDataType): Promise<UserType> {
  return backend.patch(`${apiPaths.user}/settings`, data);
}

export function requestPasswordReset(data: { username: string }) {
  return backend.post(`${apiPaths.user}/request-password-reset`, data);
}

export function resetPassword(data: { newPassword: string; token: string }) {
  return backend.patch(`${apiPaths.user}/reset-password`, data);
}
