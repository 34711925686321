import React from 'react';

import { FlexLayout, Icon } from '~/ui';

const LockInput = ({ isShowing, isShowingPassword, setIsShowingPassword }) => {
  if (!isShowing) return null;

  return (
    <FlexLayout alignItems="center" justifyContent="center" sx={{ height: '100%', outline: 'none', width: '56px' }}>
      <Icon
        icon={isShowingPassword ? 'eyeClosed' : 'eyeOpened'}
        size="m"
        onClick={() => setIsShowingPassword(!isShowingPassword)}
      />
    </FlexLayout>
  );
};

export default LockInput;
