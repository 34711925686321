import { backend } from 'services';
import { toFile } from 'utils/documents';
import type {
  CountryValueType,
  CreditRatingValueType,
  CuftDataFileType,
  TrancheAssetClassType,
  CuftDataSummaryStatisticsType,
  DbCuftDataTypeWithCreditRatingsType,
  CuftDataSaveSearchBodyType,
  CuftDataSavedSearchType,
  CurrencyValueType,
} from 'types';

import { apiPaths } from './routes';

type GetCuftDataResponseType = {
  totalNumberOfCuftData: number;
  cuftData: DbCuftDataTypeWithCreditRatingsType[];
  summaryStatistics: CuftDataSummaryStatisticsType;
};

type GetCuftDataAvailableFilteringOptionsType = {
  uniqueBorrowerCountries: Array<CountryValueType>;
  uniqueTrancheAssetClasses: Array<TrancheAssetClassType>;
  uniqueCurrencies: Array<CurrencyValueType>;
};

type GetCuftDataBodyType = {
  offset: number;
  limit: number;
  issueDate: Date;
  dateMonthsBeforeIssueDate: Date;
  tenor: number | null;
  numberOfYearsBeforeAndAfterTenor: number;
  creditRatings: Array<CreditRatingValueType>;
  currencies: Array<string>;
  countries: Array<CountryValueType>;
  trancheAssetClasses: Array<TrancheAssetClassType>;
  excludedCreditRatingIds: Array<number>;
  sortBy: string | null;
};

export function getCuftData(body: GetCuftDataBodyType): Promise<GetCuftDataResponseType> {
  return backend.post(apiPaths.cuftData, body);
}

export function getCuftDataAvailableFilteringOptions(
  body: GetCuftDataBodyType
): Promise<GetCuftDataAvailableFilteringOptionsType> {
  return backend.post(`${apiPaths.cuftData}/available-filtering-options`, body);
}

export async function exportCuftData(body: GetCuftDataBodyType): Promise<File> {
  const response = await backend.post(`${apiPaths.cuftData}/export`, body, { fullResponse: true, responseType: 'blob' });
  return toFile(response);
}

export function getCuftDataFiles(): Promise<CuftDataFileType[]> {
  return backend.get(`${apiPaths.cuftData}/file`);
}

export async function getCuftDataFile(fileId: number): Promise<File> {
  const response = await backend.get(`${apiPaths.cuftData}/file/${fileId}`, {
    fullResponse: true,
    responseType: 'blob',
  });
  return toFile(response);
}

export function uploadCuftDataFile(file: File): Promise<void> {
  const formData = new FormData();
  formData.append('file', file);

  return backend.post(`${apiPaths.cuftData}/file`, formData);
}

export function deleteCuftDataFile(fileId: number): Promise<void> {
  return backend.delete(`${apiPaths.cuftData}/file/${fileId}`);
}

export function createCuftDataSavedSearch(name: string, body: CuftDataSaveSearchBodyType): Promise<void> {
  return backend.post(`${apiPaths.cuftData}/saved-search`, { name, ...body });
}

export function getCuftDataSavedSearch(): Promise<CuftDataSavedSearchType[]> {
  return backend.get(`${apiPaths.cuftData}/saved-search`);
}
