import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { updateGuarantee, updateLoan, updateB2BLoan } from '~/api';
import { currenciesWithoutAnnual, currenciesWithoutSemiAnnual } from '~/components/Shared/PaymentFrequencySingleSelect';
import { reportEnum, pricingMethodologyEnum, pricingApproachEnum, b2bLoansEnums } from '~/enums';
import { END_DATE_INPUT_TYPE_DATE, END_DATE_INPUT_TYPE_TENOR } from '~/enums/reports';
import { createCompanyValue, getCompanyById } from '~/utils/companies';
import { createTenor, getEndDateFromTenor, getTenorFromEndDate } from '~/utils/dates';
import { errorHandler } from '~/utils/errors';
import { hasAllValuesPresent } from '~/utils/form';

const { standardRenumerationTypes } = b2bLoansEnums;

export function isReportFormValid(data) {
  if (data?.reportType === reportEnum.LOAN) {
    const { lender, borrower, rateType, ...rest } = transformLoan(data);
    return (
      hasAllValuesPresent(rest) &&
      hasAllValuesPresent(rateType) &&
      Object.keys(lender).length !== 0 &&
      Object.keys(borrower).length !== 0
    );
  }
  if (data?.reportType === reportEnum.BACK_TO_BACK_LOAN) {
    const backToBackLoanFormKeys = [
      'issueDate',
      'maturityDate',
      'currency',
      'amount',
      'type',
      'paymentFrequency',
      'seniority',
      'rateType',
      'pricingApproach',
    ];
    const { lenders, borrowers, capm, expectedLoss, riskTakerId, standardRemuneration, isRenumerationSectionShown } =
      data;
    const areLendersAndBorrowersSet =
      lenders.length >= 2 && borrowers.length >= 2 && lenders.every((l) => l?.id) && borrowers.every((b) => b?.id);
    const isRiskTakerValid = borrowers.some((borrower) => borrower.id === data.riskTakerId);
    const isRiskTakerSet = riskTakerId != null;
    const isRiskTakerValidAndSet = isRiskTakerSet && isRiskTakerValid;
    const isRequiredRateOfReturnSet = capm?.requiredRateOfReturn != null && !Number.isNaN(capm?.requiredRateOfReturn);
    const isExpectedLossSet = expectedLoss?.expectedLoss != null && !Number.isNaN(expectedLoss?.expectedLoss);
    const backToBackLoanData = _.pick(data, backToBackLoanFormKeys);

    const hasAllRemunerationValues = Object.values(standardRemuneration).every((sr) => {
      if (sr.type === standardRenumerationTypes.opexAndMarkup) {
        return sr.operationalCost != null && sr.markup != null;
      }
      if ([standardRenumerationTypes.percentage, standardRenumerationTypes.basisPoints].includes(sr.type)) {
        return sr.margin != null;
      }
      return true;
    });

    return (
      hasAllValuesPresent(backToBackLoanData) &&
      areLendersAndBorrowersSet &&
      isRiskTakerValidAndSet &&
      isRequiredRateOfReturnSet &&
      ((isRenumerationSectionShown && hasAllRemunerationValues) || !isRenumerationSectionShown) &&
      isExpectedLossSet
    );
  }
  if (data?.reportType === reportEnum.GUARANTEE) {
    const { guarantor, principal, pricingMethodology, seniority, ...rest } = transformGuarantee(data);
    const isSeniorityRequiredAndSet =
      pricingMethodology === pricingMethodologyEnum.YIELD_EXPECTED_LOSS_APPROACH && seniority != null;
    const isSeniorityNotRequired = pricingMethodology === pricingMethodologyEnum.SECURITY_APPROACH;
    const isSeniorityValid = isSeniorityRequiredAndSet || isSeniorityNotRequired;

    return (
      hasAllValuesPresent(rest) &&
      isSeniorityValid &&
      Object.keys(guarantor).length !== 0 &&
      Object.keys(principal).length !== 0
    );
  }
}

function updatePaymentFrequencyBasedOnTenor(issueDate, endDate, paymentFrequency) {
  if (issueDate == null || endDate == null) {
    return paymentFrequency;
  } else {
    const tenor = createTenor(issueDate, endDate, 'months');
    if (
      (tenor < 1 && paymentFrequency === 'Monthly') ||
      (tenor < 3 && paymentFrequency === 'Quarterly') ||
      (tenor < 6 && paymentFrequency === 'Semi-annual') ||
      (tenor < 12 && paymentFrequency === 'Annual')
    ) {
      return null;
    } else {
      return paymentFrequency;
    }
  }
}

const initialStates = {
  [reportEnum.LOAN]: {
    reportType: reportEnum.LOAN,
    lender: {},
    borrower: {},
    isThirdParty: false,
    embeddedLender: {},
    embeddedBorrower: {},
    issueDate: null,
    maturityDate: null,
    tenor: { years: null, months: null },
    endDateInputType: END_DATE_INPUT_TYPE_TENOR,
    movedToAnalysesDate: null,
    currency: null,
    amount: null,
    rateType: { type: 'fixed' },
    type: null,
    paymentFrequency: null,
    seniority: null,
    pricingApproach: pricingApproachEnum.STANDALONE,
    report: {},
    editable: true,
    status: 'Draft',
    isPortfolio: false,
    createdBy: null,
    updatedBy: null,
    finalizedBy: null,
    note: '',
    files: [],
    dayCount: 'ACT/365',
    isDirty: false,
    isSubmitting: false,
    deletedAt: null,
  },
  [reportEnum.BACK_TO_BACK_LOAN]: {
    reportType: reportEnum.BACK_TO_BACK_LOAN,
    lenders: [{}, {}],
    borrowers: [{}, {}],
    legInterestRates: [],
    riskTakerId: null,
    embeddedLender: {},
    embeddedBorrower: {},
    issueDate: null,
    maturityDate: null,
    tenor: { years: null, months: null },
    endDateInputType: END_DATE_INPUT_TYPE_TENOR,
    movedToAnalysesDate: null,
    currency: null,
    amount: null,
    rateType: { type: 'fixed' },
    type: null,
    paymentFrequency: null,
    seniority: null,
    pricingApproach: pricingApproachEnum.STANDALONE,
    report: {},
    // has betaUnlevered, betaDebt and betaEquity which capmOverride and capmRecommendation do not
    capm: {
      requiredRateOfReturn: undefined,
      riskFreeRate: undefined,
      riskFreeRateSource: undefined,
      beta: undefined,
      betaUnlevered: undefined,
      betaSource: undefined,
      betaIndustrySector: null,
      betaRegion: null,
      betaType: null,
      betaDebt: undefined,
      betaEquity: undefined,
      equityRiskPremium: undefined,
      equityRiskPremiumSource: undefined,
      equityRiskPremiumCountry: null,
    },
    expectedLoss: {
      expectedLoss: undefined,
      probabilityOfDefault: undefined,
      probabilityOfDefaultSource: undefined,
      probabilityOfDefaultType: null,
      lossGivenDefault: undefined,
      lossGivenDefaultSource: undefined,
    },
    capmRecommendation: {
      requiredRateOfReturn: undefined,
      riskFreeRate: undefined,
      riskFreeRateSource: undefined,
      beta: undefined,
      betaSource: undefined,
      betaIndustrySector: null,
      betaRegion: null,
      betaType: null,
      equityRiskPremium: undefined,
      equityRiskPremiumSource: undefined,
      equityRiskPremiumCountry: null,
    },
    expectedLossRecommendation: {
      expectedLoss: undefined,
      probabilityOfDefault: undefined,
      probabilityOfDefaultSource: undefined,
      probabilityOfDefaultType: null,
      lossGivenDefault: undefined,
      lossGivenDefaultSource: undefined,
    },
    // has riskFreeRateIssueDate, riskFreeRateCurrency, riskFreeRateTenor, betaIssueDate and equityRiskPremiumIssueDate which capm and capmRecommendation do not
    capmOverride: {
      requiredRateOfReturn: undefined,
      riskFreeRate: undefined,
      riskFreeRateSource: undefined,
      riskFreeRateIssueDate: undefined,
      riskFreeRateCurrency: undefined,
      riskFreeRateTenor: undefined,
      beta: undefined,
      betaIssueDate: undefined,
      betaSource: undefined,
      betaIndustrySector: null,
      betaRegion: null,
      betaType: null,
      equityRiskPremium: undefined,
      equityRiskPremiumIssueDate: undefined,
      equityRiskPremiumSource: undefined,
      equityRiskPremiumCountry: null,
    },
    expectedLossOverride: {
      expectedLoss: undefined,
      probabilityOfDefault: undefined,
      probabilityOfDefaultSource: undefined,
      probabilityOfDefaultType: null,
      lossGivenDefault: undefined,
      lossGivenDefaultSource: undefined,
    },
    overrideToggles: {
      riskFreeRate: true,
      beta: false,
      equityRiskPremium: false,
      probabilityOfDefault: false,
      lossGivenDefault: false,
    },
    isRenumerationSectionShown: false,
    standardRemuneration: {},
    editable: true,
    status: 'Draft',
    isPortfolio: false,
    createdBy: null,
    updatedBy: null,
    finalizedBy: null,
    note: '',
    files: [],
    isDirty: false,
    isSubmitting: false,
    deletedAt: null,
  },
  [reportEnum.GUARANTEE]: {
    reportType: reportEnum.GUARANTEE,
    guarantor: {},
    principal: {},
    isThirdParty: false,
    embeddedGuarantor: {},
    embeddedPrincipal: {},
    issueDate: null,
    terminationDate: null,
    tenor: { years: null, months: null },
    endDateInputType: END_DATE_INPUT_TYPE_TENOR,
    movedToAnalysesDate: null,
    currency: null,
    amount: null,
    pricingApproach: pricingApproachEnum.STANDALONE,
    pricingMethodology: pricingMethodologyEnum.YIELD_EXPECTED_LOSS_APPROACH,
    paymentFrequency: null,
    seniority: null,
    editable: true,
    status: 'Draft',
    report: {},
    isPortfolio: false,
    createdBy: null,
    updatedBy: null,
    finalizedBy: null,
    note: '',
    files: [],
    isDirty: false,
    isSubmitting: false,
    deletedAt: null,
  },
};

export function transformLoan(data) {
  const loanFormKeys = [
    'lender',
    'borrower',
    'issueDate',
    'maturityDate',
    'currency',
    'amount',
    'type',
    'paymentFrequency',
    'seniority',
    'rateType',
    'pricingApproach',
    'dayCount',
  ];
  const requestData = _.pick(data, loanFormKeys);
  requestData.rateType = { type: requestData?.rateType?.type };
  return requestData;
}

export function transformGuarantee(data) {
  const guaranteeFormKeys = [
    'guarantor',
    'principal',
    'issueDate',
    'terminationDate',
    'currency',
    'amount',
    'paymentFrequency',
    'seniority',
    'pricingApproach',
    'pricingMethodology',
  ];
  return _.pick(data, guaranteeFormKeys);
}

export function transformBackToBackLoan(data) {
  const clonedData = _.cloneDeep(data);
  const backToBackLoanFormKeys = [
    'lenders',
    'borrowers',
    'riskTakerId',
    'issueDate',
    'maturityDate',
    'currency',
    'amount',
    'type',
    'paymentFrequency',
    'seniority',
    'rateType',
    'pricingApproach',
    'capm',
    'expectedLoss',
    'capmRecommendation',
    'expectedLossRecommendation',
    'capmOverride',
    'expectedLossOverride',
    'overrideToggles',
    'standardRemuneration',
  ];
  const pickedData = _.pick(clonedData, backToBackLoanFormKeys);
  pickedData.rateType = { type: pickedData?.rateType?.type };

  /**
   * Used to remove the data that is not relevant. For example if the standard renumeration type is `opexAndMarkup` and user also
   * entered the `margin`, that is not necessary to save in the db since it's not relevant for the type selected. Same goes for
   * `operationalCost` and `markup` when `percentage` or `basisPoints` type is selected
   */
  pickedData.standardRemuneration = _.keyBy(
    Object.values(pickedData.standardRemuneration).map((sr) => {
      if (sr.type === standardRenumerationTypes.opexAndMarkup) {
        return { id: sr.id, name: sr.name, type: sr.type, operationalCost: sr.operationalCost, markup: sr.markup };
      }
      if ([standardRenumerationTypes.percentage, standardRenumerationTypes.basisPoints].includes(sr.type)) {
        return { id: sr.id, name: sr.name, type: sr.type, margin: sr.margin };
      }
      return sr;
    }),
    'id'
  );

  /** If user chose not to show renumeration section it's set to an empty object */
  if (!data.isRenumerationSectionShown) pickedData.standardRemuneration = {};

  return pickedData;
}

export const updateReportThunk = createAsyncThunk(
  'reports/updateLoanThunk',
  ({ id, data, reportType }, { dispatch }) => {
    const updateReportApiMethodMapper = {
      [reportEnum.LOAN]: [updateLoan, transformLoan],
      [reportEnum.BACK_TO_BACK_LOAN]: [updateB2BLoan, transformBackToBackLoan],
      [reportEnum.GUARANTEE]: [updateGuarantee, transformGuarantee],
    };

    dispatch(setIsSubmitting(true));
    const [updateReport, transformData] = updateReportApiMethodMapper[reportType];

    return updateReport({ id, data: transformData(data) }).catch((err) => {
      errorHandler(err);
      throw err;
    });
  }
);

export const reportSlice = createSlice({
  name: 'report',
  initialState: {},
  reducers: {
    resetReport: () => {
      return {};
    },
    // For report edit page ( set companies with new data )
    setReport: (_state, action) => {
      const { reportType, ...data } = action.payload;
      if (reportType === reportEnum.LOAN) {
        const { companies, lender, borrower, ...rest } = data;
        const tenor = getTenorFromEndDate(rest.issueDate, rest.maturityDate);
        return {
          ...initialStates[reportEnum.LOAN],
          ...rest,
          tenor,
          lender: createCompanyValue(getCompanyById(companies, lender?.id)),
          borrower: createCompanyValue(getCompanyById(companies, borrower?.id)),
          embeddedLender: lender,
          embeddedBorrower: borrower,
        };
      }
      if (reportType === reportEnum.BACK_TO_BACK_LOAN) {
        const { companies, lenders, borrowers, ...rest } = data;
        const tenor = getTenorFromEndDate(rest.issueDate, rest.maturityDate);

        return {
          ...initialStates[reportEnum.BACK_TO_BACK_LOAN],
          ...rest,
          tenor,
          lenders: lenders.map((lender) => createCompanyValue(getCompanyById(companies, lender?.id))),
          borrowers: borrowers.map((borrower) => createCompanyValue(getCompanyById(companies, borrower?.id))),
        };
      }
      if (reportType === reportEnum.GUARANTEE) {
        const { companies, guarantor, principal, ...rest } = action.payload;
        const tenor = getTenorFromEndDate(rest.issueDate, rest.terminationDate);
        return {
          ...initialStates[reportEnum.GUARANTEE],
          ...rest,
          tenor,
          guarantor: createCompanyValue(getCompanyById(companies, guarantor?.id)),
          principal: createCompanyValue(getCompanyById(companies, principal?.id)),
          embeddedGuarantor: guarantor,
          embeddedPrincipal: principal,
        };
      }
    },
    // For report view page and price and benchmark page
    setReportData: (_state, action) => {
      const { reportType, ...data } = action.payload;

      const endDate = [reportEnum.LOAN, reportEnum.BACK_TO_BACK_LOAN].includes(reportType)
        ? data.maturityDate
        : data.terminationDate;
      const tenor = getTenorFromEndDate(data.issueDate, endDate);
      return { ...initialStates[reportType], ...data, tenor, isDirty: false };
    },
    updateField: (state, action) => {
      const isDirty = action.payload.isDirty ?? true;
      return { ...state, ...action.payload, isDirty };
    },
    updateCurrency: (state, action) => {
      const currency = action.payload;
      if (
        state?.rateType?.type === 'float' &&
        ((currenciesWithoutSemiAnnual.includes(currency) && state?.paymentFrequency === 'Semi-annual') ||
          (currenciesWithoutAnnual.includes(currency) && state?.paymentFrequency === 'Annual'))
      ) {
        return { ...state, currency, paymentFrequency: null, isDirty: true };
      } else {
        return { ...state, currency, isDirty: true };
      }
    },
    updateIssueDate: (state, action) => {
      const { issueDate } = action.payload;
      let endDate, tenor;
      if (state.endDateInputType === END_DATE_INPUT_TYPE_DATE) {
        endDate = state.reportType === reportEnum.LOAN ? state.maturityDate : state.terminationDate;
        tenor = state.maturityDate || state.terminationDate ? getTenorFromEndDate(issueDate, endDate) : state.tenor;
      } else {
        endDate = getEndDateFromTenor(issueDate, state.tenor);
        tenor = state.tenor;
      }
      return {
        ...state,
        issueDate,
        ...(state.reportType === reportEnum.LOAN && { maturityDate: endDate }),
        ...(state.reportType === reportEnum.GUARANTEE && { terminationDate: endDate }),
        tenor,
        paymentFrequency: updatePaymentFrequencyBasedOnTenor(issueDate, endDate, state?.paymentFrequency),
        isDirty: true,
      };
    },
    updateEndDate: (state, action) => {
      const { endDate } = action.payload;
      const tenor = getTenorFromEndDate(state?.issueDate, endDate);
      return {
        ...state,
        ...([reportEnum.LOAN, reportEnum.BACK_TO_BACK_LOAN].includes(state.reportType) && { maturityDate: endDate }),
        ...(state.reportType === reportEnum.GUARANTEE && { terminationDate: endDate }),
        tenor,
        paymentFrequency: updatePaymentFrequencyBasedOnTenor(state.issueDate, endDate, state?.paymentFrequency),
        isDirty: true,
      };
    },
    updateTenor: (state, action) => {
      const { tenor } = action.payload;
      const endDate = getEndDateFromTenor(state.issueDate, tenor);
      return {
        ...state,
        ...([reportEnum.LOAN, reportEnum.BACK_TO_BACK_LOAN].includes(state.reportType) && { maturityDate: endDate }),
        ...(state.reportType === reportEnum.GUARANTEE && { terminationDate: endDate }),
        tenor,
        paymentFrequency: updatePaymentFrequencyBasedOnTenor(state.issueDate, endDate, state?.paymentFrequency),
        isDirty: true,
      };
    },
    updateRateType: (state, action) => {
      const { rateType } = action.payload;
      if (
        rateType?.type === 'float' &&
        ((currenciesWithoutSemiAnnual.includes(state?.currency) && state?.paymentFrequency === 'Semi-annual') ||
          (currenciesWithoutAnnual.includes(state?.currency) && state?.paymentFrequency === 'Annual'))
      ) {
        return { ...state, rateType, paymentFrequency: null, isDirty: true };
      } else {
        return { ...state, rateType, isDirty: true };
      }
    },
    setIsPristine: (state) => {
      state.isDirty = false;
    },
    setIsSubmitting: (state, action) => {
      state.isSubmitting = action.payload;
    },
    toggleEndDateInputType: (state) => {
      const endDateInputType =
        state.endDateInputType === END_DATE_INPUT_TYPE_TENOR ? END_DATE_INPUT_TYPE_DATE : END_DATE_INPUT_TYPE_TENOR;
      return {
        ...state,
        endDateInputType,
      };
    },
    updateEstimationOfReferenceRate: (state, action) => {
      const { estimationOfReferenceRate } = action.payload;
      const floatingInterestRate = estimationOfReferenceRate + (state.report.finalInterestRate || 0) / 100;
      state.report = { ...state.report, estimationOfReferenceRate, floatingInterestRate };
    },
    updateBasisPoints: (state, action) => {
      const { finalInterestRate } = action.payload;
      const floatingInterestRate = finalInterestRate / 100 + (state.report.estimationOfReferenceRate || 0);
      state.report = { ...state.report, finalInterestRate, floatingInterestRate };
    },
    updateB2BLenderField: (state, action) => {
      const { index, lender } = action.payload;
      const lenders = state.lenders.map((stateLender, stateIndex) => {
        if (stateIndex === index) return lender;
        return stateLender;
      });
      return { ...state, lenders, isDirty: true };
    },
    updateB2BBorrowerField: (state, action) => {
      const { index, borrower } = action.payload;
      const borrowers = state.borrowers.map((stateBorrower, stateIndex) => {
        if (stateIndex === index) return borrower;
        return stateBorrower;
      });
      const lenders = state.lenders.map((stateLender, stateIndex) => {
        if (stateIndex === index + 1) return borrower;
        return stateLender;
      });
      // when the first borrower is picked set him as the risk taker
      const riskTakerId = index === 0 ? borrower.id : state.riskTakerId;

      return { ...state, borrowers, lenders, riskTakerId, isDirty: true };
    },
    addB2BRow: (state) => {
      const lastBorrower = state.borrowers[state.borrowers.length - 1];
      return { ...state, borrowers: [...state.borrowers, {}], lenders: [...state.lenders, lastBorrower] };
    },
    removeB2BRow: (state, action) => {
      const { index } = action.payload;
      const lenders = state.lenders.filter((_, stateIndex) => stateIndex !== index);
      const borrowers = state.borrowers.filter((_, stateIndex) => stateIndex !== index);

      const borrowersCopy = _.cloneDeep(borrowers);
      borrowersCopy.pop();

      /**
       * Used to update the Risk taker id when the current borrower cannot be risk taker anymore.
       * For example when there are three legs and the second borrower is the risk taker. If the user
       * removes the third leg the second borrower cannot be the risk taker anymore. Last one is popped
       * because it can not be the risk taker.
       */
      if (!borrowersCopy.find((b) => b.id === state.riskTakerId)) {
        return { ...state, borrowers, lenders, riskTakerId: borrowersCopy[0]?.id };
      }

      return { ...state, borrowers, lenders };
    },
    clearB2BAllRows: (state) => {
      return {
        ...state,
        standardRemuneration: {},
        lenders: initialStates[reportEnum.BACK_TO_BACK_LOAN].lenders,
        borrowers: initialStates[reportEnum.BACK_TO_BACK_LOAN].borrowers,
      };
    },
    setStandardRenumeration: (state, action) => {
      state.standardRemuneration = _.keyBy(action.payload, 'id');
    },
    restartStandardRenumeration: (state) => {
      const standardRenumeration = Object.values(state.standardRemuneration).map((sr) => ({
        id: sr.id,
        name: sr.name,
        type: standardRenumerationTypes.opexAndMarkup,
      }));
      state.standardRemuneration = _.keyBy(standardRenumeration, 'id');
    },
    updateStandardRenumeration: (state, action) => {
      const { borrowerId, ...data } = action.payload;
      state.standardRemuneration[borrowerId] = { ...state.standardRemuneration[borrowerId], ...data };
    },
    setInitialCapmData: (state, action) => {
      return { ...state, capm: action.payload, capmRecommendation: action.payload };
    },
    setCapmData: (state, action) => {
      return { ...state, capm: action.payload };
    },
    setInitialExpectedLossData: (state, action) => {
      return { ...state, expectedLoss: action.payload, expectedLossRecommendation: action.payload };
    },
    setExpectedLossData: (state, action) => {
      return { ...state, expectedLoss: action.payload };
    },
    resetToRecommendation: (state, action) => {
      const { isCapmReset, isExpectedLossReset } = action.payload;
      const overrideToggles = initialStates[reportEnum.BACK_TO_BACK_LOAN].overrideToggles;

      if (isCapmReset) {
        return { ...state, capm: state.capmRecommendation, overrideToggles: { ...overrideToggles } };
      }

      if (isExpectedLossReset) {
        return { ...state, expectedLoss: state.expectedLossRecommendation, overrideToggles: { ...overrideToggles } };
      }
    },
    resetSectionToRecommendation: (state, action) => {
      const { section, toggle } = action.payload;
      if (!toggle) return state;

      if (section === 'beta') {
        return {
          ...state,
          capm: {
            ...state.capm,
            beta: state.capmRecommendation.beta,
            betaSource: state.capmRecommendation.betaSource,
            betaIndustrySector: state.capmRecommendation.betaIndustrySector,
            betaRegion: state.capmRecommendation.betaRegion,
            betaType: state.capmRecommendation.betaType,
          },
        };
      }
      if (section === 'equityRiskPremium') {
        return {
          ...state,
          capm: {
            ...state.capm,
            equityRiskPremium: state.capmRecommendation.equityRiskPremium,
            equityRiskPremiumSource: state.capmRecommendation.equityRiskPremiumSource,
            equityRiskPremiumCountry: state.capmRecommendation.equityRiskPremiumCountry,
          },
        };
      }
      if (section === 'riskFreeRate') {
        return {
          ...state,
          capm: {
            ...state.capm,
            riskFreeRate: state.capmRecommendation.riskFreeRate,
            riskFreeRateSource: state.capmRecommendation.riskFreeRateSource,
          },
        };
      }
      if (section === 'lossGivenDefault') {
        return {
          ...state,
          expectedLoss: {
            ...state.expectedLoss,
            lossGivenDefault: state.expectedLossRecommendation.lossGivenDefault,
            lossGivenDefaultSource: state.expectedLossRecommendation.lossGivenDefaultSource,
          },
        };
      }
      if (section === 'probabilityOfDefault') {
        return {
          ...state,
          expectedLoss: {
            ...state.expectedLoss,
            probabilityOfDefault: state.expectedLossRecommendation.probabilityOfDefault,
            probabilityOfDefaultSource: state.expectedLossRecommendation.probabilityOfDefaultSource,
            probabilityOfDefaultType: state.expectedLossRecommendation.probabilityOfDefaultType,
          },
        };
      }
    },
    updateMainField: (state, action) => {
      const { requiredRateOfReturn, isCapm, expectedLoss, isExpectedLoss } = action.payload;
      const initialState = initialStates[reportEnum.BACK_TO_BACK_LOAN];

      if (isCapm) {
        return { ...state, capm: { ...initialState.capm, requiredRateOfReturn } };
      }

      if (isExpectedLoss) {
        return { ...state, expectedLoss: { ...initialState.expectedLoss, expectedLoss } };
      }
    },
    updateB2BDetailField: (state, action) => {
      const { isOverrideField, isCapm, isExpectedLoss, ...data } = action.payload;

      if (isOverrideField) {
        if (isCapm) return { ...state, capmOverride: { ...state.capmOverride, ...data } };
        if (isExpectedLoss) return { ...state, expectedLossOverride: { ...state.expectedLossOverride, ...data } };
      }

      if (isCapm) return { ...state, capm: { ...state.capm, ...data } };
      if (isExpectedLoss) return { ...state, expectedLoss: { ...state.expectedLoss, ...data } };

      throw new Error('isCapm or isExpectedLoss must be true.');
    },
    toggleOverride: (state, action) => {
      const field = action.payload;
      const overrideToggles = { ...state.overrideToggles, [field]: !state.overrideToggles[field] };
      return { ...state, overrideToggles };
    },
  },
});

export default reportSlice.reducer;

export const {
  resetReport,
  setReport,
  setIsPristine,
  setIsSubmitting,
  updateEndDate,
  updateMaturityDate,
  updateTerminationDate,
  updateTenor,
  updateIssueDate,
  updateCurrency,
  updateField,
  updateRateType,
  setReportData,
  removeFile,
  toggleEndDateInputType,
  updateEstimationOfReferenceRate,
  updateBasisPoints,
  updateB2BLenderField,
  updateB2BBorrowerField,
  addB2BRow,
  removeB2BRow,
  clearB2BAllRows,
  setStandardRenumeration,
  restartStandardRenumeration,
  updateStandardRenumeration,
  setInitialCapmData,
  setInitialExpectedLossData,
  resetToRecommendation,
  resetSectionToRecommendation,
  updateMainField,
  updateB2BDetailField,
  updateOverrideCapmField,
  toggleOverride,
  setCapmData,
  setExpectedLossData,
} = reportSlice.actions;

export const report = (state) => state.report;
