import React from 'react';

import { SingleSelect } from '~/ui';

const options = [
  { label: 'Paid', value: 'true' },
  { label: 'Unpaid', value: 'false' },
];

function PaymentStatusSingleSelect({ label = 'Status', tooltip, value = null, width = 'm', onChange }) {
  return (
    <SingleSelect label={label} options={options} tooltip={tooltip} value={value} width={width} onChange={onChange} />
  );
}

export default PaymentStatusSingleSelect;
