import { sortByStringValue } from '~/utils/arrays';

import { displayNumber2 } from './strings';

export function sortCompanies(companies = []) {
  const parentCompany = companies.find((company) => company.id === company.parentCompanyId);
  if (parentCompany) {
    const otherCompanies = companies
      .filter((company) => company.id !== parentCompany.id)
      .sort(sortByStringValue('name'));
    return [parentCompany, ...otherCompanies];
  } else {
    return companies;
  }
}

export function getCompanyByName(companies, name) {
  return companies.find((company) => company.name === name) || {};
}

export function getCompanyById(companies, id) {
  return companies.find((company) => company.id === id) || {};
}

export function isParentCompany(company) {
  return company.id && company.parentCompanyId && company.id === company.parentCompanyId;
}

export function getCompanyRating(company) {
  return company?.creditRating?.rating || 'n/a';
}

export function getCompanyRatingAdj(company, missingValue = 'n/a') {
  if (isParentCompany(company)) {
    return '\u2014';
  }

  return company?.creditRating?.ratingAdj || missingValue;
}

const getNumberDisplayOptions = (userInfo, defaultValue) => ({
  decimalPoint: userInfo.decimalPoint,
  minDig: 2,
  maxDig: 2,
  unit: '%',
  defaultValue,
});

export function getCompanyProbabilityOfDefault(company, userInfo, missingValue = 'n/a') {
  const numberDisplayOptions = getNumberDisplayOptions(userInfo, missingValue);

  return displayNumber2(company?.creditRating?.probabilityOfDefault, numberDisplayOptions);
}

export function getCompanyProbabilityOfDefaultAdj(company, userInfo, missingValue = 'n/a') {
  const numberDisplayOptions = getNumberDisplayOptions(userInfo, missingValue);

  return displayNumber2(company?.creditRating?.probabilityOfDefaultAdj, numberDisplayOptions);
}

export function getCompanyCumulativeProbabilityOfDefault(company, userInfo) {
  const numberDisplayOptions = getNumberDisplayOptions(userInfo, null);

  return displayNumber2(company?.creditRating?.cumulativeProbabilityOfDefault, numberDisplayOptions);
}

export function getCompanyAssessment(company) {
  if (isParentCompany(company)) {
    return '\u2014';
  }

  return company?.assessment?.name || 'n/a';
}

export function createCompanyValue(
  company,
  properties = ['id', 'parentCompanyId', 'creditRating', 'name', 'industry', 'country']
) {
  const returnValue = {};
  for (let [key, value] of Object.entries(company)) {
    if (properties.includes(key)) {
      returnValue[key] = value;
    }
  }

  return returnValue;
}
