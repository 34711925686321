import React from 'react';

import { Box, theme } from '~/ui';

const widths = {
  m: '820px',
  fullWidth: '100%',
};

const variants = {
  primary: {
    height: '90px',
    width: widths.m,
  },
};

const Textarea = React.forwardRef(
  (
    {
      autoFocus = false,
      disabled = false,
      maxLength = 1500,
      placeholder,
      sx = {},
      value,
      variant = 'primary',
      onChange,
    },
    ref
  ) => {
    const { height, width } = variants[variant];

    return (
      <Box
        as={'textarea'}
        autoFocus={autoFocus}
        disabled={disabled}
        p={4}
        placeholder={placeholder}
        maxLength={maxLength}
        ref={ref}
        sx={{
          ...theme.text['m-spaced'],
          bg: 'white',
          border: 'border',
          borderRadius: 'm',
          color: 'deep-sapphire',
          height,
          outline: 'none',
          overflowWrap: 'break-word',
          resize: 'none',
          width,
          ...sx,

          '::placeholder': {
            color: 'bali-hai',
            fontStyle: 'italic',
          },
        }}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    );
  }
);

export default Textarea;
