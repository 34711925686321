export default {
  'action-menu-width': 'auto',
  'icon-2xs': '12px',
  'icon-xs': '16px',
  'icon-s': '20px',
  'icon-m': '24px',
  'icon-l': '28px',
  'icon-xl': '32px',
  'input-height': '50px',
  'input-height-text': '41px',
  'input-height-small': '32px',
  'login-form-width': '400px',
  'tab-height': '48px',
  'tab-height-small': '30px',
  'tab-width': '253px',
  'tab-width-small': '153px',
  'tab-width-xs': '80px',
  'toast-width': '560px',
  'sidebar-width': '240px',
};
