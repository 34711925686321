import React from 'react';

import { SingleSelect } from '~/ui';
import { getOptionsFromArray } from '~/utils/arrays';

const options = getOptionsFromArray([
  'BISTTREF',
  'BUBRON',
  'CORRA',
  'CZEOINDX',
  'DE TNT/N',
  'EIBOON',
  'ESTR',
  'HFDRO/N',
  'HICNHON',
  'HONIA',
  'LIBID',
  'LIBOR',
  'MXIBTIEF',
  'NOWA',
  'NZOCRS',
  'PZCFPLNI',
  'RBACOR',
  'RUONIA',
  'SAIBON',
  'SAONBOR',
  'SARON',
  'SOFR',
  'SONIA',
  'SORA',
  'SWBRDEP',
  'TELBORON',
  'TONAR',
  'TTHORON',
]);

function OvernightRateSingleSelect({ label = 'Overnight Rate', tooltip, value, width = 'fullWidth', onChange }) {
  return (
    <SingleSelect label={label} options={options} tooltip={tooltip} value={value} width={width} onChange={onChange} />
  );
}

export default OvernightRateSingleSelect;
