import { backend } from 'services';

import { apiPaths } from './routes';

export function getTableColumns(): Promise<any> {
  return backend.get(apiPaths.tableColumn);
}

export function updateTableColumns(data: any) {
  return backend.patch(apiPaths.tableColumn, data);
}
