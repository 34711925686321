import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal, TextInput } from 'ui';
import { updateAccounts, cashPoolTopCurrencyAccountSelector } from 'reducers/cashPoolTopCurrencyAccount.slice';

type ExternalIdsModalPropsType = {
  dataTestId?: string;
  participant: any;
  onHide: () => void;
};

function ExternalIdsModal({ participant, dataTestId, onHide }: ExternalIdsModalPropsType) {
  const [externalIds, setExternalIds] = useState<string>('');
  const dispatch = useDispatch();
  const topCurrencyAccount = useSelector(cashPoolTopCurrencyAccountSelector);
  const account = topCurrencyAccount?.accounts?.find((account: any) => account?.companyId === participant?.id);

  const handleOnSubmitClick = () => {
    const externalIdsSplitted = externalIds?.split(',')?.map((id: string) => ({ externalId: id.trim() }));
    const data = { ...account, externalIds: externalIdsSplitted };
    dispatch(updateAccounts({ ...data, value: true }));
    onHide();
  };

  useEffect(() => {
    if (account) {
      setExternalIds(account.externalIds?.map(({ externalId }: { externalId: string }) => externalId).join(','));
    }

    return () => {
      setExternalIds('');
    };
  }, [account]);

  if (!participant) return null;

  return (
    <Modal
      actionButtons={<Button text="Submit" onClick={handleOnSubmitClick} dataTestId={dataTestId} />}
      title={`Edit External Ids of ${account?.participant?.company?.name || account?.name}`}
      width="s"
      onHide={onHide}
    >
      <TextInput
        inputType="float"
        allowNegatives
        label="External IDs (separated by comma)"
        width="fullWidth"
        value={externalIds}
        onChange={setExternalIds}
      />
    </Modal>
  );
}

export default ExternalIdsModal;
