import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { azureLoginQueryString, setAccessToken, setAuthMethod } from 'auth';
import { azureCodeRequest } from 'api';
import { routesEnum } from 'routes';
import { showErrorToast } from 'ui/components/Toast';
import { errorHandler } from 'utils/errors';

const AzureRedirect = () => {
  const history = useHistory();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (!code) {
      showErrorToast('Auth failed. No code.');
      history.push(routesEnum.LOGIN);
    }

    history.push({ pathname: routesEnum.LOGIN, search: `${azureLoginQueryString}=true` });

    azureCodeRequest(String(code))
      .then((res) => {
        setAccessToken(res.accessToken);
        setAuthMethod('azure');
        window.location.href = routesEnum.DASHBOARD;
      })
      .catch(errorHandler);
  }, [history]);

  return null;
};

export default AzureRedirect;
