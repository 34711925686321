import { createSlice } from '@reduxjs/toolkit';

import { CHART } from '~/enums';

const initialState = {
  selectedView: CHART,
  selectedData: 'balance',
  chosenCurrency: null,
  chosenTopCurrencyAccountId: null,
  chosenCompanyId: null,
  chosenCompanyIds: null,
  dateRange: { startDate: null, endDate: null },
  allParticipants: [],
  participantsTrails: [],
  leaderBenefit: [],
  isShowingColumnsFilterModal: false,
};

// Slice
export const cashPoolDataSlice = createSlice({
  name: 'cashPoolData',
  initialState,
  reducers: {
    resetCashPoolData: (state) => {
      return { ...initialState, allParticipants: state.allParticipants };
    },
    updateField: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateDateRange: (state, action) => {
      return { ...state, dateRange: action.payload };
    },
    /** If only one company is selected it stays selected when toggling between graph/table (single and multi select) */
    resetCompanyIds: (state, action) => {
      const { chosenCompanyId, chosenCompanyIds, firstNonLeaderId } = action.payload;
      if (chosenCompanyIds?.length) {
        return { ...state, chosenCompanyId: firstNonLeaderId, chosenCompanyIds: null };
      }
      if (chosenCompanyId) {
        return { ...state, chosenCompanyId: null, chosenCompanyIds: [chosenCompanyId] };
      }
      return { ...state, chosenCompanyId: null, chosenCompanyIds: null };
    },
  },
});

export default cashPoolDataSlice.reducer;

// Actions
export const { resetCashPoolData, updateField, updateDateRange, resetCompanyIds } = cashPoolDataSlice.actions;

// Selectors
export const cashPoolDataSelector = (state) => state.cashPoolData;
