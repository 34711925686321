/** Same enum is on the backend */
export enum featureNames {
  PAYMENT = 'payment',
  CASH_POOL = 'cashPool',
  CREDIT_RATING = 'creditRating',
  LOAN = 'loan',
  BACK_TO_BACK_LOAN = 'backToBackLoan',
  GUARANTEE = 'guarantee',
  CURRENCY = 'currency',
  GEOGRAPHY_DATA = 'geographyData',
  USER_NUMBER = 'userNumber',
  LOAN_NUMBER = 'loanNumber',
  BACK_TO_BACK_LOAN_NUMBER = 'backToBackLoanNumber',
  GUARANTEE_NUMBER = 'guaranteeNumber',
  LOAN_GUARANTEE_NUMBER = 'loanGuaranteeNumber',
  CREDIT_RATING_NUMBER = 'creditRatingNumber',
  CASH_POOL_NUMBER = 'cashPoolNumber',
  FINANCING_ADVISORY = 'financingAdvisory',
  CUFT_DATA = 'cuftData',
  IS_TEMPLATE_CASH_POOL_BATCH_UPLOAD = 'isTemplateCashPoolBatchUpload',
  PHYSICAL_CASH_POOL = 'physicalCashPool',
  NOTIONAL_CASH_POOL = 'notionalCashPool',
  NORDIC_CASH_POOL = 'nordicCashPool',
}
