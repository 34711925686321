import axios, { AxiosRequestConfig } from 'axios';

import { getAccessToken, removeAccessToken, removeAuthMethod } from 'auth';
import { routesEnum } from 'routes';

import Configuration from './configuration';

type ResponseDestructuredDataType = {
  config: AxiosRequestConfig;
  data: any;
};

export default function createService() {
  const instance = axios.create({
    baseURL: Configuration.backendEndpoint,
  });

  instance.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      config.headers.authorization = `Bearer ${getAccessToken()}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    async (response) => {
      const { config, data }: ResponseDestructuredDataType = response;

      return config.fullResponse ? response : data;
    },
    async (error) => {
      if (error.response) {
        const { status } = error.response;
        if (status === 401) {
          removeAccessToken();
          removeAuthMethod();
          window.location.href = routesEnum.LOGIN;
        }
      }
      return Promise.reject(error);
    }
  );

  return {
    get: instance.get,
    post: instance.post,
    delete: instance.delete,
    put: instance.put,
    patch: instance.patch,
  };
}
