import { useEffect, useState } from 'react';
import InlineSvg from 'react-inlinesvg';
import { useHistory } from 'react-router-dom';

import { login, requestPasswordReset } from 'api';
import logoSrc from 'assets/images/logo-light-bg.svg';
import { azureLoginQueryString, isUserAuthenticated, setAccessToken, setAuthMethod } from 'auth';
import { azureUtils } from 'auth/azure';
import { routesEnum } from 'routes';
import { Button, FlexLayout, Text, TextInput } from 'ui';
import { showErrorToast, showToast } from 'ui/components/Toast';
import { useEnter } from 'ui/hooks';
import { errorHandler } from 'utils/errors';

function Login() {
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [isUsernameLoginInProcess, setIsUsernameLoginInProcess] = useState(false);
  const [isAzureLoginInProcess, setIsAzureLoginInProcess] = useState(false);
  const [forgotPasswordUsername, setForgotPasswordUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoginShown, setIsLoginShown] = useState(true);

  useEnter(isLoginShown ? handleOnLoginClick : handleResetPasswordClick);

  async function handleOnLoginClick() {
    try {
      setIsUsernameLoginInProcess(true);
      const { accessToken } = await login({ username, password });
      setAccessToken(accessToken);
      setAuthMethod('default');
      window.location.href = routesEnum.DASHBOARD;
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsUsernameLoginInProcess(false);
    }
  }

  function handleRenderAzureLogin() {
    setIsAzureLoginInProcess(true);
    window.open(azureUtils.getAzureLoginUrl(), '_self');
  }

  function handleResetPasswordClick() {
    requestPasswordReset({ username: forgotPasswordUsername })
      .then(() => showToast('Password reset sent to email'))
      .catch((err) => {
        if (err?.response?.status === 400) {
          return showErrorToast(err.response.data.message);
        }
        showErrorToast();
      });
  }

  useEffect(() => {
    const isAzureLoginInProcess = !!new URLSearchParams(window.location.search).get(azureLoginQueryString);
    setIsAzureLoginInProcess(isAzureLoginInProcess);
  }, [history.location.search]);

  if (isUserAuthenticated) {
    history.push(routesEnum.DASHBOARD);
    return null;
  }

  return (
    <FlexLayout
      alignItems="center"
      alignSelf="center"
      bg="white"
      flexDirection="column"
      m="0 auto"
      px={12}
      py={8}
      space={6}
      sx={{ alignSelf: 'center', borderRadius: 'm', boxShadow: 'depth-1', width: 'login-form-width' }}
    >
      <a href={process.env.REACT_APP_LANDING_URL}>
        <InlineSvg src={logoSrc} />
      </a>
      {isLoginShown ? (
        <>
          <TextInput
            label="Username"
            value={username}
            sx={{ width: '100%' }}
            width="fullWidth"
            onChange={setUsername}
          />
          <TextInput
            label="Password"
            type="password"
            value={password}
            variant="password"
            sx={{ width: '100%' }}
            width="fullWidth"
            onChange={setPassword}
          />
          <Button
            text="Log In"
            size="l"
            sx={{ mt: 4, width: '100%' }}
            loading={isUsernameLoginInProcess}
            disabled={!username.length || !password.length}
            onClick={handleOnLoginClick}
          />
          <Button
            text="Log in with Azure"
            size="l"
            sx={{ mt: 4, width: '100%' }}
            loading={isAzureLoginInProcess}
            onClick={handleRenderAzureLogin}
          />
          <Text color="deep-sapphire" variant="m-spaced" onClick={() => setIsLoginShown(false)}>
            Forgot password?
          </Text>
        </>
      ) : (
        <>
          <TextInput
            label="Username"
            value={forgotPasswordUsername}
            sx={{ width: '100%' }}
            width="fullWidth"
            onChange={setForgotPasswordUsername}
          />
          <Button
            disabled={!forgotPasswordUsername}
            text="Reset password"
            size="l"
            sx={{ mt: 4, width: '100%' }}
            onClick={handleResetPasswordClick}
          />
          <Text color="deep-sapphire" variant="m-spaced" onClick={() => setIsLoginShown(true)}>
            Back to Log In
          </Text>
        </>
      )}
    </FlexLayout>
  );
}

export default Login;
