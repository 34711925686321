import { CheckboxGroup, FlexLayout, Text } from 'ui';

type QuestionGroupProps = {
  title?: string;
  options: Array<{ label: string; value: string }>;
  values: Array<string>;
  updateFields: Function;
  children?: React.ReactNode;
};

function QuestionGroup({ title, options = [], values = [], updateFields }: QuestionGroupProps) {
  return (
    <FlexLayout flexDirection="column" flexGrow="1" space={6}>
      {title && (
        <Text color="shakespeare" variant="l-spaced-medium">
          {title}
        </Text>
      )}
      <CheckboxGroup
        options={options}
        values={values}
        onChange={updateFields}
        error={undefined}
        label={undefined}
        sx={undefined}
        tooltip={undefined}
      />
    </FlexLayout>
  );
}

export default QuestionGroup;
