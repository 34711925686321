export enum reportEnum {
  CREDIT_RATING = 'credit rating',
  GUARANTEE = 'guarantee',
  LOAN = 'loan',
  BACK_TO_BACK_LOAN = 'back-to-back loan',
  CASH_POOL = 'cash pool',
  WITHHOLDING_TAX = 'withholding tax',
}

export const END_DATE_INPUT_TYPE_TENOR = 'end date input type tenor';
export const END_DATE_INPUT_TYPE_DATE = 'end date input type date';
export const ORIGINAL = 'original';
export const UPDATED = 'updated';
export const REPORT_TYPE = 'reportType';

export enum frequencyEnum {
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
  SEMI_ANNUAL = 'Semi-annual',
  ANNUAL = 'Annual',
}

export enum monthsInPaymentFrequencyEnum {
  Monthly = 1,
  Quarterly = 3,
  'Semi-annual' = 6,
  Annual = 12,
}

export const reportStatusColors = Object.freeze({
  Expired: 'bali-hai',
  Draft: 'blaze-orange',
  Final: 'deep-sapphire',
});

export enum expenseSummaryTabsEnum {
  TOTAL = 'total',
  INTEREST = 'interest',
  PRINCIPAL = 'principal',
  WHT = 'wht',
}

export enum lenderOrGuarantorTypeEnum {
  INTERCOMPANY = 'Intercompany',
  THIRD_PARTY = 'Third-Party',
}

export enum pricingApproachEnum {
  IMPLICIT = 'implicit',
  STANDALONE = 'stand-alone',
}

export enum pricingApproachInTemplatesEnum {
  IMPLICIT = 'Implicit Support',
  STANDALONE = 'Standalone',
}

export enum pricingMethodologyEnum {
  YIELD_EXPECTED_LOSS_APPROACH = 'Yield - Expected Loss Approach',
  SECURITY_APPROACH = 'Security Approach',
}
