import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  CompanySingleSelect,
  CountrySingleSelect,
  CurrencySingleSelect,
  OvernightRateSingleSelect,
} from 'components/Shared';
import { NOTIONAL } from 'enums';
import { cashPoolSelector, updateField } from 'reducers/cashPool.slice';
import { Box, NumberInput, RadioGroup, TextInput } from 'ui';
import { CurrencyValueType } from 'types';

import { cashPoolTexts } from './CashPoolTexts';

const NotionalFieldsCard = ({ isAuditTrail = false }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const cashPool = useSelector(cashPoolSelector);
  const isEdit = history.location.pathname.includes('/edit');
  const {
    name,
    currencies,
    country,
    leaderId,
    type,
    creditInterestRate,
    debitInterestRate,
    interestType,
    overnightRate,
    operatingCost,
    operatingCostMarkup,
    errors,
  } = cashPool;
  const isFixed = interestType === 'fixed';
  const texts = isFixed ? cashPoolTexts.fixedTexts : cashPoolTexts.floatTexts;
  const isNotional = type === NOTIONAL;

  if (!isNotional) return null;

  return (
    <>
      <Box disabled={isAuditTrail} sx={{ display: 'grid', gridGap: 8, gridTemplateColumns: 'repeat(2, 1fr)' }}>
        <TextInput
          label="Cash Pool Name"
          tooltip="Provide a name identifying the cash pool."
          width="fullWidth"
          value={name}
          onChange={(name: string) => dispatch(updateField({ name }))}
        />
        <Box sx={{ display: 'grid', gridGap: 8, gridTemplateColumns: 'repeat(2, 1fr)' }}>
          <CurrencySingleSelect
            tooltip="Select the currency of the cash pool."
            width="fullWidth"
            disabled={isEdit}
            variant="extended"
            value={currencies}
            onChange={(currencies: CurrencyValueType) => dispatch(updateField({ currencies }))}
          />
          <CountrySingleSelect
            tooltip="Select the country in which the cash pool is based."
            width="fullWidth"
            value={country}
            disabled={isEdit}
            onChange={(country: string) => dispatch(updateField({ country }))}
          />
        </Box>
      </Box>
      <Box disabled={isAuditTrail} sx={{ display: 'grid', gridGap: 8, gridTemplateColumns: 'repeat(2, 1fr)' }}>
        <CompanySingleSelect
          label="Cash Pool Leader"
          tooltip="Select the entity that administers the cash pool."
          width="fullWidth"
          disabled={isEdit}
          value={leaderId}
          onChange={({ id }: { id: number }) => dispatch(updateField({ leaderId: id }))}
          error={undefined}
          sx={undefined}
          dataTestId={undefined}
        />

        <Box sx={{ display: 'grid', gridGap: 8, gridTemplateColumns: 'repeat(2, 1fr)' }}>
          <RadioGroup
            label="Interest Type"
            tooltip="Select the interest type applied to the cash pool by the bank when calculating interest."
            options={[
              { label: 'Fixed', value: 'fixed' },
              { label: 'Float', value: 'float' },
            ]}
            value={interestType}
            disabled={isEdit}
            width="fullWidth"
            onChange={(interestType) => dispatch(updateField({ interestType, overnightRate: null }))}
          />
          {!isFixed && (
            <OvernightRateSingleSelect
              tooltip="Select the overnight rate applied to the cash pool by the bank in calculating interest."
              value={overnightRate}
              onChange={(overnightRate: string) => dispatch(updateField({ overnightRate }))}
            />
          )}
        </Box>
        <Box sx={{ display: 'grid', gridGap: 8, gridTemplateColumns: 'repeat(2, 1fr)' }}>
          <NumberInput
            inputType="float"
            allowNegatives={!isFixed}
            label={texts.cirLabel}
            tooltip={texts.cir}
            unit={texts.unit}
            width="fullWidth"
            value={creditInterestRate}
            error={errors?.creditInterestRate}
            onChange={(creditInterestRate: number) => dispatch(updateField({ creditInterestRate }))}
          />
          <NumberInput
            inputType="float"
            allowNegatives={!isFixed}
            label={texts.dirLabel}
            tooltip={texts.dir}
            unit={texts.unit}
            width="fullWidth"
            value={debitInterestRate}
            onChange={(debitInterestRate: number) => dispatch(updateField({ debitInterestRate }))}
          />
        </Box>
        <Box sx={{ display: 'grid', gridGap: 8, gridTemplateColumns: 'repeat(2, 1fr)' }}>
          <NumberInput
            label="Operating Expenses"
            inputType="float"
            tooltip={cashPoolTexts.tooltips.operatingExpenses}
            width="fullWidth"
            value={operatingCost}
            onChange={(operatingCost: number) => dispatch(updateField({ operatingCost }))}
          />
          <NumberInput
            label="Operating Expenses Markup"
            inputType="float"
            tooltip="Input the percentage markup to remunerate routine and administrative functions of operating the cash pool."
            width="fullWidth"
            unit="%"
            value={operatingCostMarkup}
            onChange={(operatingCostMarkup: number) => dispatch(updateField({ operatingCostMarkup }))}
          />
        </Box>
      </Box>
    </>
  );
};

export default NotionalFieldsCard;
