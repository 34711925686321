export const truncate = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const wrap = {
  overflowWrap: 'break-word',
};

export const xScrollable = {
  maxWidth: '100%',
  overflowX: 'auto',
};

export const yScrollable = {
  maxHeight: '100%',
  overflowY: 'auto',
};
