import { getInitialsAvatar } from 'services';

type UserAvatarPropsType = {
  width?: string;
  height?: string;
  fullName: string;
};

const UserAvatar = ({ width = '32px', height = '32px', fullName }: UserAvatarPropsType) => (
  <img src={getInitialsAvatar(fullName)} alt="avatar" width={width} height={height} style={{ borderRadius: '50%' }} />
);

export default UserAvatar;
