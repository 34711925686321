import React from 'react';

import { Button, Modal, Text } from '~/ui';

const MovedModal = ({ title, handleReportMovedClick, isShowing }) => {
  if (!isShowing) return null;

  return (
    <Modal
      actionButtons={<Button text="OK" onClick={handleReportMovedClick} />}
      onHide={handleReportMovedClick}
      showCancelButton={false}
    >
      <Text color="deep-sapphire" variant="xl-spaced-bold">
        {title}
      </Text>
    </Modal>
  );
};

export default MovedModal;
