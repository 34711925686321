import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { Button, FlexLayout, Modal, Text } from 'ui';
import { UserInfoContext } from 'context';
import { displayNumber2 } from 'utils/strings';
import { cashPoolTopCurrencyAccountSelector } from 'reducers/cashPoolTopCurrencyAccount.slice';

type SeeAccountsDetailsModalPropsType = {
  dataTestId?: string;
  isDetailsModalShowing: boolean;
  onHide: () => void;
};

function SeeAccountsDetailsModal({ dataTestId, isDetailsModalShowing, onHide }: SeeAccountsDetailsModalPropsType) {
  const { userInfo } = useContext(UserInfoContext);
  const topCurrencyAccount = useSelector(cashPoolTopCurrencyAccountSelector);
  const numberDisplayOptions = {
    decimalPoint: userInfo.decimalPoint,
    defaultValue: '-',
    currency: topCurrencyAccount.currency,
    minDig: 2,
  };

  if (!isDetailsModalShowing) return null;

  return (
    <Modal
      actionButtons={<Button text="OK" onClick={onHide} dataTestId={dataTestId} />}
      title="Account details"
      width="s"
      showCancelButton={false}
      onHide={onHide}
      overflowY="scroll"
    >
      {topCurrencyAccount?.accounts?.map((account: any) => (
        <FlexLayout
          key={account.companyId}
          flexDirection="column"
          sx={{ gap: '8px', borderTop: '1px solid grey', padding: '4px 0', marginBottom: '8px !important' }}
        >
          <Text color="deep-sapphire" variant="l-spaced-medium">
            {account?.participant?.company?.name || account?.name}
          </Text>
          <Text>Current balance: {displayNumber2(account.balance, numberDisplayOptions)}</Text>
          <FlexLayout flexDirection="column" sx={{ margin: '0 123px' }}>
            {account.externalIds?.map((e: any) => (
              <Text key={e.externalId}>{e.externalId}</Text>
            ))}
          </FlexLayout>
        </FlexLayout>
      ))}
    </Modal>
  );
}

export default SeeAccountsDetailsModal;
