export function hasAllValuesPresent(form) {
  return !Object.values(form).filter((val) => val === null).length;
}

export function findMissingValues(form, fields) {
  const missingValues = [];
  for (let i = 0, len = fields.length; i < len; i++) {
    if (form[fields[i]] == null) {
      missingValues.push(fields[i]);
    }
  }

  return missingValues;
}
