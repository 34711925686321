export function arraysEqual(array1, array2) {
  return JSON.stringify(array1) === JSON.stringify(array2);
}

export function removeDuplicates(array) {
  return array.reduce(function (a, b) {
    if (a.indexOf(b) < 0) a.push(b);
    return a;
  }, []);
}

export function getOptionsFromArray(array) {
  return array.map((item) => ({
    label: item || '',
    value: item || '',
  }));
}

export function getOptionsWithValueFromArray(array, labelKey, valueKey) {
  return array.map((item) => ({
    label: item[labelKey],
    value: item[valueKey],
  }));
}

export function sortByStringValue(key) {
  return (a, b) => (a[key].toLowerCase() > b[key].toLowerCase() ? 1 : -1);
}
