import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getCashPoolTopCurrencyAccounts } from '~/api';
import { SingleSelect } from '~/ui';
import { getOptionsWithValueFromArray } from '~/utils/arrays';

function TopCurrencyAccountSingleSelect({
  label = 'Top Currency Account',
  width = 'fullWidth',
  height,
  isShowing = true,
  value,
  onChange,
}) {
  const { cashPoolId } = useParams();
  const [topCurrencyAccounts, setTopCurrencyAccounts] = useState();

  useEffect(() => {
    if (isShowing) {
      getCashPoolTopCurrencyAccounts({ cashPoolId }).then(setTopCurrencyAccounts);
    }
  }, [isShowing, cashPoolId]);

  if (!isShowing) return null;

  return (
    <SingleSelect
      label={label}
      options={topCurrencyAccounts && getOptionsWithValueFromArray(topCurrencyAccounts, 'name', 'id')}
      value={value}
      width={width}
      height={height}
      onChange={onChange}
    />
  );
}

export default TopCurrencyAccountSingleSelect;
