export const betaRegions = Object.freeze({
  japan: 'japan',
  emerging: 'emerging',
  europe: 'europe',
  global: 'global',
  us: 'us',
});

export const betaTypes = Object.freeze({
  beta: 'Beta',
  unleveredBeta: 'Unlevered Beta',
  unleveredBetaCorrectedForCash: 'Unlevered Beta Corrected for Cash',
});

export const standardRenumerationTypes = Object.freeze({
  opexAndMarkup: 'Operating cost & Markup',
  percentage: '%',
  basisPoints: 'basis points',
});
