import { Dispatch } from 'redux';
import { updateField } from 'reducers/cashPool.slice';

export const updateAssessment = (totalRisk: number, dispatch: Dispatch) => {
  if (totalRisk >= 0.6667) return dispatch(updateField({ assessment: 'High' }));
  if (totalRisk >= 0.3333) return dispatch(updateField({ assessment: 'Medium' }));
  return dispatch(updateField({ assessment: 'Low' }));
};

export const getAssessmentAnswersValues = (analysisAnswers: Record<string, boolean>) => {
  if (!analysisAnswers) return [];

  return Object.entries(analysisAnswers)
    .filter(([_, value]) => !!value)
    .map(([key]) => key);
};
