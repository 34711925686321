import { CurrencyRegions, DropdownOptionsType } from 'types';

export const getOptionsFromAvailableOptions = <OptionsValueType extends string>(
  options: DropdownOptionsType<OptionsValueType>,
  availableOptions?: string[]
) => {
  if (!availableOptions || availableOptions.length === 0) return options;

  return options.filter((option) => availableOptions.includes(option.value));
};

type CurrencyOptionType = {
  label: string;
  value: string;
};

type CurrencyOptionsType = Array<{
  label: CurrencyRegions;
  options: Array<CurrencyOptionType>;
}>;

type RegionCurrencyType = { label: CurrencyRegions; options: Array<CurrencyOptionType> };

/**
 * Currently applicable only for currency dropdown options,
 * because only they have variants in dropdown options.
 */
export const getOptionsFromAvailableOptionsForVariantOptions = (
  options: CurrencyOptionsType,
  availableOptions?: Array<string>
): CurrencyOptionsType => {
  if (!availableOptions || availableOptions.length === 0) return options;

  const currencyOptions: CurrencyOptionsType = [];
  for (const region of options) {
    const regionCurrencies: RegionCurrencyType = { label: region.label, options: [] };
    for (const country of region.options) {
      if (availableOptions.includes(country.value)) {
        regionCurrencies.options.push({ label: country.label, value: country.value });
      }
    }
    currencyOptions.push(regionCurrencies);
  }
  return currencyOptions;
};
