import { SingleSelect } from 'ui';
import { getOptionsFromArray } from 'utils/arrays';
import { CreditRatingValueEnum } from 'enums/creditRating';
import { SingleSelectWidthType } from 'types';

type CreditRatingSingleSelectPropsType = {
  isShowing?: boolean;
  disabled?: boolean;
  label?: string;
  tooltip?: string;
  width?: SingleSelectWidthType;
  value: string;
  onChange: Function;
};

export const creditRatings = Object.values(CreditRatingValueEnum);

const options = getOptionsFromArray(creditRatings);

function CreditRatingSingleSelect({
  isShowing = true,
  disabled = false,
  label = 'Rating',
  tooltip,
  width = 'm',
  value,
  onChange,
}: CreditRatingSingleSelectPropsType) {
  if (!isShowing) return null;
  return (
    <SingleSelect
      disabled={disabled}
      label={label}
      options={options}
      tooltip={tooltip}
      width={width}
      value={value}
      onChange={onChange}
    />
  );
}

export default CreditRatingSingleSelect;
