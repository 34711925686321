import PT from 'prop-types';
import React, { useState } from 'react';

import { Box, FlexLayout, Icon, Text, theme, LockInputIcon } from '~/ui';
import { withLabel } from '~/ui/hocs';

import ShowPasswordIcon from './ShowPasswordIcon';

const widths = {
  xs: '114px',
  s: '227px',
  m: '252px',
  l: '536px',
  fullWidth: '100%',
};

const variants = {
  default: {
    height: 'input-height',
    inputPx: 4,
  },
  searchable: {
    containerPx: 4,
    height: 'input-height-text',
    showSearchIcon: true,
    space: 2,
  },
  withUnit: {
    containerPx: 4,
    height: 'input-height',
    showUnit: true,
    space: 2,
  },
  password: {
    height: 'input-height',
    showLockIcon: true,
    space: 2,
    inputPx: 4,
  },
};

const TextInput = React.forwardRef(
  (
    {
      isShowing = true,
      autoFocus = false,
      disabled = false,
      placeholder = '',
      type = 'text',
      unit = '',
      value = '',
      variant = 'default',
      width = 'm',
      withLock = false,
      isUnlockable = true,
      dataTestId,
      error,
      onChange,
    },
    ref
  ) => {
    const [isLocked, setIsLocked] = useState(true);
    const [isShowingPassword, setIsShowingPassword] = useState(false);
    const { containerPx, height, inputPx, showUnit, showSearchIcon, space, showLockIcon } = variants[variant];

    const getInputType = () => {
      if (isShowingPassword) return 'text';
      return type;
    };

    if (!isShowing) return null;
    return (
      <FlexLayout
        alignItems="center"
        bg={withLock ? 'alabaster' : 'white'}
        px={containerPx}
        ref={ref}
        space={space}
        dataTestId={dataTestId}
        sx={{
          border: error ? 'border-error' : 'border',
          borderRadius: 'm',
          height,
          width: widths[width],
        }}
      >
        {showSearchIcon && <Icon color="bali-hai" icon="search" size="m" />}
        <Box
          as="input"
          autoFocus={autoFocus}
          disabled={disabled && isLocked}
          placeholder={placeholder}
          sx={{
            ...theme.text['m-spaced'],
            bg: 'white',
            border: 'none',
            borderRadius: 'm',
            color: 'deep-sapphire',
            flexGrow: '1',
            height: '100%',
            outline: 'none',
            px: inputPx,
            py: 0,
          }}
          type={getInputType()}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
        {showUnit && <Text color="deep-sapphire">{unit}</Text>}
        <LockInputIcon isShowing={withLock} isUnlockable={isUnlockable} isLocked={isLocked} setIsLocked={setIsLocked} />
        <ShowPasswordIcon
          isShowing={showLockIcon}
          isShowingPassword={isShowingPassword}
          setIsShowingPassword={setIsShowingPassword}
        />
      </FlexLayout>
    );
  }
);

TextInput.propTypes = {
  autoFocus: PT.bool,
  disabled: PT.bool,
  placeholder: PT.string,
  unit: PT.string,
  value: PT.string,
  width: PT.oneOf(Object.keys(widths)),
  onChange: PT.func,
};

export default withLabel(TextInput);
