import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateAccounts } from '~/reducers/cashPoolTopCurrencyAccount.slice';
import { Button, Modal } from '~/ui';
import { showToast } from '~/ui/components/Toast';

import { CurrencySingleSelect } from '../Shared';

const UpdateCurrencyModal = ({
  buttonText = 'Submit',
  onHide,
  title = 'Assign Currency',
  modalWidth = 's',
  participant,
}) => {
  const dispatch = useDispatch();
  const [currency, setCurrency] = useState();

  const onSubmit = async () => {
    const data = {
      currency,
      creditInterestRate: participant.creditInterestRate,
      debitInterestRate: participant.debitInterestRate,
      companyId: participant.id,
      name: participant.name,
    };
    dispatch(updateAccounts({ ...data, value: true }));

    showToast('Participant successfully updated.');
    setCurrency();
    onHide();
  };

  useEffect(() => {
    setCurrency(participant?.currency);
  }, [participant]);

  if (!participant) return null;

  return (
    <Modal
      actionButtons={<Button disabled={!currency} text={buttonText} onClick={onSubmit} />}
      title={title}
      width={modalWidth}
      onHide={onHide}
    >
      <CurrencySingleSelect
        tooltip="Select the participant's currency."
        width="fullWidth"
        variant="extended"
        value={currency}
        onChange={setCurrency}
      />
    </Modal>
  );
};

export default UpdateCurrencyModal;
