import React from 'react';

import { SingleSelect } from '~/ui';
import { getOptionsFromArray } from '~/utils/arrays';
import { createTenor } from '~/utils/dates';

export const currenciesWithoutAnnual = ['AUD', 'CAD', 'MYR', 'RUB', 'NZD', 'MXN'];
export const currenciesWithoutSemiAnnual = ['CAD', 'MYR'];

function getOptions(currency, issueDate, endDate, rateType) {
  let paymentFrequencies;
  if (issueDate == null || endDate == null) {
    if (rateType === 'float') {
      paymentFrequencies = ['Monthly', 'Quarterly'];
      if (!currenciesWithoutSemiAnnual.includes(currency)) {
        paymentFrequencies.push('Semi-annual');
      }
      if (!currenciesWithoutAnnual.includes(currency)) {
        paymentFrequencies.push('Annual');
      }
    } else {
      paymentFrequencies = ['Monthly', 'Quarterly', 'Semi-annual', 'Annual'];
    }
  } else {
    paymentFrequencies = [];
    const tenor = createTenor(issueDate, endDate, 'months');
    if (tenor >= 1) {
      paymentFrequencies.push('Monthly');
    }
    if (tenor >= 3) {
      paymentFrequencies.push('Quarterly');
    }
    if (tenor >= 6 && (rateType === 'fixed' || !currenciesWithoutSemiAnnual.includes(currency))) {
      paymentFrequencies.push('Semi-annual');
    }
    if (tenor >= 12 && (rateType === 'fixed' || !currenciesWithoutAnnual.includes(currency))) {
      paymentFrequencies.push('Annual');
    }
  }

  return getOptionsFromArray(paymentFrequencies);
}

function PaymentFrequencySingleSelect({
  currency,
  label = 'Compounding Frequency',
  dataTestId,
  issueDate,
  endDate,
  rateType,
  tooltip,
  value,
  width = 'm',
  onChange,
}) {
  return (
    <SingleSelect
      label={label}
      options={getOptions(currency, issueDate, endDate, rateType)}
      tooltip={tooltip}
      dataTestId={dataTestId}
      value={value}
      width={width}
      onChange={onChange}
    />
  );
}

export default PaymentFrequencySingleSelect;
