import { FlexLayout, Icon, Text } from 'ui';
import borders from 'ui/theme/borders';
import { ColorType } from 'types';

export const variants = Object.freeze({
  regular: { alignItems: 'center', space: 2 },
  outlined: {
    alignItems: 'center',
    flexWrap: 'wrap',
    space: 2,
    p: 4,
    sx: { border: 'border', borderRadius: 'm', marginBottom: '8px' },
  },
  outlinedAlternative: {
    alignItems: 'center',
    space: 2,
    p: 4,
    sx: { border: 'border', borderRadius: 'm', height: 'input-height-text' },
  },
});

type CheckboxPropsType = {
  variant?: keyof typeof variants;
  border?: keyof typeof borders;
  label?: string;
  labelSide?: 'left' | 'right';
  labelColor?: ColorType;
  disabled?: boolean;
  bg?: string;
  isActive: boolean;
  isShowing?: boolean;
  onChange: Function;
};

const CheckboxBox = ({
  isActive,
  border,
  icon,
  isShowing,
}: Partial<CheckboxPropsType> & { icon: 'checkmark' | null; isShowing: boolean }) => {
  if (!isShowing) return null;

  return (
    <FlexLayout
      alignItems="center"
      flexShrink="0"
      justifyContent="center"
      sx={{
        backgroundColor: isActive ? 'shakespeare' : 'transparent',
        border: isActive ? undefined : border,
        borderRadius: 's',
        height: 'icon-xs',
        width: 'icon-xs',
      }}
    >
      {icon && <Icon color="white" icon={icon} size="xs" />}
    </FlexLayout>
  );
};

function Checkbox({
  variant = 'regular',
  border = 'border-thick',
  label,
  labelSide = 'right',
  labelColor = 'deep-sapphire',
  isActive = false,
  isShowing = true,
  onChange,
  disabled,
  bg = 'transparent',
}: CheckboxPropsType) {
  const icon: 'checkmark' | null = isActive ? 'checkmark' : null;
  const checkboxBoxSharedProps = { isActive, border, icon };

  if (!isShowing) return null;

  return (
    <FlexLayout {...variants[variant]} onClick={() => onChange(!isActive)} disabled={disabled} bg={bg}>
      <CheckboxBox {...checkboxBoxSharedProps} isShowing={labelSide === 'right'} />
      {label && (
        <Text color={labelColor} variant="m-spaced">
          {label}
        </Text>
      )}
      <CheckboxBox {...checkboxBoxSharedProps} isShowing={labelSide === 'left'} />
    </FlexLayout>
  );
}

export default Checkbox;
