import { TextWithTooltip } from 'components/Shared';
import { Box, FlexLayout } from 'ui';

type RadioPropsType = {
  sx?: object;
  disabled?: boolean;
  variant?: keyof typeof variants;
  width?: keyof typeof widths;
  bg?: string;
  selected: boolean;
  option: { label: string; value: string | boolean; tooltip?: string };
  onChange: (...args: any[]) => any;
};

export const widths = Object.freeze({
  s: '114px',
  sm: '160px',
  m: '256px',
  l: '536px',
  fullWidth: '100%',
  fitContent: 'fit-content',
});

export const variants = Object.freeze({
  primary: Object.freeze({
    border: 'border',
    borderRadius: 'm',
    color: 'deep-sapphire',
    py: 2,
    pr: 4,
    pl: 2,
    space: 2,
    m: 2,
  }),
  primarySmallerPadding: Object.freeze({
    border: 'border',
    borderRadius: 'm',
    color: 'deep-sapphire',
    py: 1,
    space: 2,
    m: 2,
  }),
  secondary: Object.freeze({
    color: 'bali-hai',
    space: 6,
    m: 2,
  }),
});

function Radio({
  sx = {},
  disabled,
  variant = 'primary',
  width = 'm',
  bg = 'transparent',
  selected,
  option,
  onChange,
}: RadioPropsType) {
  const { label, tooltip, value } = option;
  const { color, m, space, ...variantStyles } = variants[variant];

  return (
    <FlexLayout alignItems="center" disabled={disabled} sx={{ ...variantStyles, width: widths[width], ...sx }} bg={bg}>
      <FlexLayout
        alignItems="center"
        justifyContent="center"
        space={space}
        sx={{ width: 'fit-content' }}
        onClick={() => onChange(value)}
      >
        <FlexLayout
          alignItems="center"
          justifyContent="center"
          flexShrink="0"
          m={m}
          sx={{
            border: 'border-thick',
            borderColor: selected ? 'shakespeare' : 'link-water',
            borderRadius: 'round',
            height: '16px',
            width: '16px',
          }}
        >
          <Box
            bg={selected ? 'shakespeare' : 'transparent'}
            sx={{ borderRadius: 'round', height: '8px', width: '8px' }}
          />
        </FlexLayout>
        <TextWithTooltip
          color={color}
          label={label}
          shouldTruncate
          tooltip={tooltip}
          variant="m-spaced"
          id={undefined}
        />
      </FlexLayout>
    </FlexLayout>
  );
}

export default Radio;
