import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getCompanies } from '~/api/company';
import { sortCompanies } from '~/utils/companies';

// Thunk
export const fetchCompanies = createAsyncThunk('companies/fetchCompaniesStatus', getCompanies);

// Slice
const companiesSlice = createSlice({
  name: 'companies',
  initialState: [],
  reducers: {
    setCompanies: (_state, action) => action.payload,
    addCompany: (state, action) => {
      return sortCompanies([...state, action.payload]);
    },
    addCompanies: (state, action) => {
      return sortCompanies([...state, ...action.payload]);
    },
    removeCompany: (state, action) => {
      return state.filter((company) => company.id !== action.payload.id);
    },
    changeCompany: (state, action) => {
      return state.map((company) => (company.id === action.payload.id ? action.payload : company));
    },
  },
  extraReducers: {
    [fetchCompanies.fulfilled]: (_state, action) => action.payload.filter((company) => !!company),
  },
});

export default companiesSlice.reducer;

// Actions
export const { setCompanies, addCompany, addCompanies, removeCompany, changeCompany } = companiesSlice.actions;
