import { Box } from 'ui';
import { withLabel } from 'ui/hocs';
import { showErrorToast } from 'ui/components/Toast';

type FileInputPropsType = {
  accept?: string;
  disabled?: boolean;
  multiple?: boolean;
  maxFileCount?: number;
  sx?: object;
  children?: React.ReactNode;
  onChange: (file: File | FileList) => void;
};

const labelStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: '100%',
  opacity: 0,
  boxSizing: 'border-box',
  cursor: 'pointer',
  outline: 'none',
};

const FileInput = ({
  accept,
  children,
  disabled = false,
  multiple = false,
  maxFileCount = 10,
  sx = {},
  onChange,
}: FileInputPropsType) => {
  const randomString = (Math.random() + 1).toString(36).substring(2, 12);

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = '';
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.files || event.target.files.length === 0) return;

    if (!multiple) return onChange(event.target.files[0]);

    if (event.target.files.length > maxFileCount) {
      return showErrorToast(`You can upload up to ${maxFileCount} files at a time.`);
    }

    onChange(event.target.files);
  };

  return (
    <Box disabled={disabled} sx={{ height: 'fit-content', position: 'relative', width: 'fit-content', ...sx }}>
      <Box as="label" htmlFor={`file-upload-${randomString}`} sx={labelStyle} />
      <Box
        as="input"
        id={`file-upload-${randomString}`}
        accept={accept}
        type="file"
        sx={{ display: 'none' }}
        multiple={multiple}
        onClick={handleOnClick}
        onChange={handleOnChange}
      />
      {children}
    </Box>
  );
};

export default withLabel(FileInput);
