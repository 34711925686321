import PT from 'prop-types';
import React from 'react';

import { FlexLayout, theme } from '~/ui';

function ButtonSpinner({ color = 'white', justifyContent = 'center', size = 's' }) {
  return (
    <FlexLayout alignItems="center" flexGrow="1" justifyContent={justifyContent}>
      <svg
        width={theme.sizes[`icon-${size}`]}
        height={theme.sizes[`icon-${size}`]}
        viewBox="0 0 38 38"
        stroke={theme.colors[color]}
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1 1)" strokeWidth="2">
            <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="1s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
      </svg>
    </FlexLayout>
  );
}

ButtonSpinner.propTypes = {
  size: PT.oneOf(['2xs', 'xs', 's', 'm', 'l', 'xl']),
  color: PT.string,
};

export default ButtonSpinner;
