import { Button, Modal, Text } from 'ui';

type ConfirmModalType = {
  isLoading?: boolean;
  buttonText: string;
  isButtonDisabled: boolean;
  isShowing: boolean;
  onClick: () => void;
  onHide: () => void;
  title: string;
  additionalInfo: string;
  modalWidth: string;
};

const ConfirmModal = ({
  isLoading,
  buttonText,
  isButtonDisabled,
  isShowing,
  onClick,
  onHide,
  title,
  additionalInfo,
  modalWidth = 's',
}: ConfirmModalType) => {
  if (!isShowing) return null;
  return (
    <Modal
      actionButtons={<Button loading={isLoading} disabled={isButtonDisabled} text={buttonText} onClick={onClick} />}
      title={title}
      width={modalWidth}
      onHide={onHide}
    >
      <Text color="deep-sapphire" variant="m-spaced" sx={{ lineHeight: '21px' }}>
        {additionalInfo}
      </Text>
    </Modal>
  );
};

export default ConfirmModal;
