import React from 'react';
import { components } from 'react-select';

import { Checkbox, FlexLayout, Text } from '~/ui';

import CustomComponents from '../SingleSelect/components';

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <FlexLayout>
          {props.data.value !== 'SELECT_ALL' && (
            <Checkbox isActive={props.isSelected} border="border-thick-sapphire" onChange={() => {}} />
          )}
          <Text color="deep-sapphire" variant="m-spaced" ml={2}>
            {props.label}
          </Text>
        </FlexLayout>
      </components.Option>
    </div>
  );
};

const MoreSelectedBadge = ({ items, nameSingular, namePlural }) => {
  const length = items.length;
  const label = `${length} ${length !== 1 ? namePlural : nameSingular} selected`;

  return <Text variant="s-spaced-medium">{label}</Text>;
};

const MultiValue = ({ index, getValue, selectProps }) => {
  const items = getValue().map(({ label }) => label);

  /** So only one item shows inside the select. After that the number of selected items is just updated */
  return index === 0 ? (
    <MoreSelectedBadge
      nameSingular={selectProps.customProps.nameSingular}
      namePlural={selectProps.customProps.namePlural}
      items={items}
    />
  ) : null;
};

export default { ...CustomComponents, Option, MultiValue };
