import { createSlice } from '@reduxjs/toolkit';

import { TABLE } from '~/enums';

const initialState = {
  selectedView: TABLE,
  structuralPositions: [],
  chosenCompanyIds: null,
  chosenCompanyId: null,
  chosenTopCurrencyAccountId: null,
  dateRange: { startDate: null, endDate: null },
  participantsTrails: null,
  structuralThreshold: 9,
};

// Slice
export const cashPoolStructuralPositionsSlice = createSlice({
  name: 'cashPoolStructuralPositions',
  initialState,
  reducers: {
    resetStructuralPositionsData: () => {
      return { ...initialState };
    },
    updateField: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateDateRange: (state, action) => {
      const { startDate, endDate } = action.payload;
      state.dateRange.startDate = startDate ?? undefined;
      state.dateRange.endDate = endDate ?? undefined;
    },
    setDefaultChosenCompanies: (state, action) => {
      const { chosenCompanyIds } = action.payload;
      return { ...state, chosenCompanyIds: chosenCompanyIds, chosenCompanyId: chosenCompanyIds[0] };
    },
  },
});

export default cashPoolStructuralPositionsSlice.reducer;

// Actions
export const { resetStructuralPositionsData, updateField, updateDateRange, setDefaultChosenCompanies } =
  cashPoolStructuralPositionsSlice.actions;

// Selectors
export const cashPoolStructuralPositionsSelector = (state) => state.cashPoolStructuralPositions;
