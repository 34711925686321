import React from 'react';

import { FlexLayout, Text } from '~/ui';

function UserLog({ label, user }) {
  return (
    <FlexLayout alignItems="center" space={1}>
      <Text color="bali-hai" variant="s-spaced">
        {label}
      </Text>
      <Text color="deep-sapphire" variant="s-spaced">
        {user}
      </Text>
    </FlexLayout>
  );
}

export default UserLog;
