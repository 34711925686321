import React from 'react';

import { Card, Text } from '~/ui';

function ReportsCard({ table, title }) {
  return (
    <Card pt={6} space={3}>
      <Text color="deep-sapphire" variant="l-spaced-medium">
        {title}
      </Text>
      {table && table}
    </Card>
  );
}

export default ReportsCard;
