import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { reportEnum, pricingMethodologyEnum } from '~/enums';
import { createCompanyValue, getCompanyById } from '~/utils/companies';
import { hasAllValuesPresent } from '~/utils/form';

export function isFormValid(formData) {
  if (formData?.reportType === reportEnum.LOAN) {
    const { lender, borrower, rateType, note, report, pricingApproach, isThirdParty, ...rest } = formData;
    return (
      hasAllValuesPresent(rest) &&
      hasAllValuesPresent(rateType) &&
      hasAllValuesPresent(report) &&
      lender?.name &&
      lender?.country &&
      Object.keys(borrower).length !== 0 &&
      (isThirdParty || pricingApproach)
    );
  } else if (formData?.reportType === reportEnum.GUARANTEE) {
    const { guarantor, principal, note, report, pricingApproach, seniority, isThirdParty, ...rest } = formData;
    return (
      hasAllValuesPresent(rest) &&
      hasAllValuesPresent(report) &&
      guarantor?.name &&
      guarantor?.country &&
      Object.keys(principal).length !== 0 &&
      (isThirdParty || (pricingApproach && seniority))
    );
  } else if (formData?.reportType === reportEnum.CREDIT_RATING) {
    const { company, attributes, note, creditRating, ...rest } = formData;
    return (
      hasAllValuesPresent(rest) &&
      rest?.probabilityOfDefault >= 0 &&
      rest?.probabilityOfDefault <= 100 &&
      hasAllValuesPresent(creditRating) &&
      Object.keys(company).length !== 0
    );
  }
}

// Slice
const initialStates = {
  [reportEnum.LOAN]: {
    reportType: reportEnum.LOAN,
    lender: {
      id: null,
      parentCompanyId: null,
      country: null,
      industry: null,
      creditRating: null,
    },
    borrower: {},
    isThirdParty: false,
    issueDate: null,
    maturityDate: null,
    currency: null,
    amount: null,
    rateType: { type: 'fixed' },
    type: null,
    note: '',
    paymentFrequency: null,
    seniority: null,
    pricingApproach: null,
    report: { finalInterestRate: null },
    isDirty: false,
  },
  [reportEnum.GUARANTEE]: {
    reportType: reportEnum.GUARANTEE,
    guarantor: {
      id: null,
      parentCompanyId: null,
      country: null,
      industry: null,
      creditRating: null,
    },
    principal: {},
    isThirdParty: false,
    issueDate: null,
    terminationDate: null,
    currency: null,
    amount: null,
    paymentFrequency: null,
    seniority: null,
    pricingApproach: null,
    pricingMethodology: pricingMethodologyEnum.YIELD_EXPECTED_LOSS_APPROACH,
    report: { finalInterestRate: null },
    note: '',
    isDirty: false,
  },
  [reportEnum.CREDIT_RATING]: {
    reportType: reportEnum.CREDIT_RATING,
    company: {},
    closingDate: null,
    creditRating: { rating: null },
    probabilityOfDefault: null,
    attributes: null,
    note: '',
    isDirty: false,
  },
};

export const importedReportFormSlice = createSlice({
  name: 'importedReportForm',
  initialState: {},
  reducers: {
    resetForm: () => {
      return {};
    },
    setForm: (_state, action) => {
      const reportType = action.payload?.reportType;
      const { companies, ...restPayload } = action.payload;
      const data = _.pick(restPayload, Object.keys(initialStates[reportType]));

      if (reportType === reportEnum.LOAN) {
        const { lender, borrower, ...rest } = data;
        return {
          ...initialStates[reportType],
          ...rest,
          lender: rest.isThirdParty ? lender : createCompanyValue(getCompanyById(companies, lender?.id)),
          borrower: createCompanyValue(getCompanyById(companies, borrower?.id)),
          currentCreditRating: borrower.creditRating.rating,
        };
      } else if (reportType === reportEnum.GUARANTEE) {
        const { guarantor, principal, ...rest } = data;
        return {
          ...initialStates[reportType],
          ...rest,
          guarantor: rest.isThirdParty ? guarantor : createCompanyValue(getCompanyById(companies, guarantor?.id)),
          principal: createCompanyValue(getCompanyById(companies, principal?.id)),
          currentGuarantorRating: rest.isThirdParty ? null : guarantor.creditRating.rating,
          currentPrincipalRating: principal.creditRating.rating,
        };
      } else {
        const { company, ...rest } = data;
        return {
          ...initialStates[reportType],
          ...rest,
          company: createCompanyValue(getCompanyById(companies, company?.id), ['id', 'name', 'country']),
        };
      }
    },
    setFormInitial: (_state, action) => {
      const { reportType } = action.payload;
      return initialStates[reportType];
    },
    updateField: (state, action) => {
      return { ...state, ...action.payload, isDirty: true };
    },
    updateThirdPartyLender: (state, action) => {
      const lenderOrGuarantor = state.reportType === reportEnum.LOAN ? 'lender' : 'guarantor';
      return { ...state, [lenderOrGuarantor]: { ...state[lenderOrGuarantor], ...action.payload } };
    },
    updateIsThirdParty: (state, action) => {
      const { isThirdParty } = action.payload;
      const lenderOrGuarantor = state.reportType === reportEnum.LOAN ? 'lender' : 'guarantor';
      return {
        ...state,
        [lenderOrGuarantor]: initialStates[state.reportType][lenderOrGuarantor],
        isThirdParty,
        seniority: null,
      };
    },
    setIsPristine: (state) => {
      state.isDirty = false;
    },
  },
});

export default importedReportFormSlice.reducer;

// Actions
export const {
  resetForm,
  setFormInitial,
  setForm,
  setIsPristine,
  updateField,
  updateThirdPartyLender,
  updateIsThirdParty,
} = importedReportFormSlice.actions;

// Selectors
export const importedReportForm = (state) => state.importedReportForm;
