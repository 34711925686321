import React from 'react';

import { SingleSelect } from '~/ui';
import { getOptionsFromArray } from '~/utils/arrays';

const options = getOptionsFromArray(['1 month', '3 months', '6 months', '12 months']);

function ReferenceRateMaturitySingleSelect({
  isShowing = true,
  label = 'Reference Rate Maturity',
  value,
  width = 'm',
  onChange,
}) {
  if (!isShowing) return null;
  return <SingleSelect label={label} options={options} value={value} width={width} onChange={onChange} />;
}

export default ReferenceRateMaturitySingleSelect;
