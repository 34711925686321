type ConfigurationType = {
  backendEndpoint?: string;
  corsRequests?: boolean;
};

let Configuration: ConfigurationType;

if (process.env.NODE_ENV === 'test') {
  Configuration = {};
} else if (process.env.NODE_ENV === 'development') {
  Configuration = {
    backendEndpoint: process.env.REACT_APP_BACKEND_ENDPOINT,
    corsRequests: false,
  };
} else {
  Configuration = {
    backendEndpoint: process.env.REACT_APP_BACKEND_ENDPOINT,
    corsRequests: true,
  };
}

export default Configuration;
