import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { PHYSICAL } from '~/enums';

export const isCashPoolFormValid = (cashPool) => {
  const isFixed = cashPool.interestType === 'fixed';
  const word = isFixed ? 'rate' : 'spread';
  const errors = {};

  if (cashPool.creditInterestRate >= cashPool.debitInterestRate) {
    errors.creditInterestRate = `The credit interest ${word} must be lower than the debit interest ${word}.`;
  }

  return errors;
};

const initialState = {
  name: '',
  type: PHYSICAL,
  currencies: null,
  country: null,
  leaderId: null,
  creditInterestRate: null,
  debitInterestRate: null,
  interestType: 'fixed',
  overnightRate: null,
  operatingCost: null,
  operatingCostMarkup: null,
  nordicPhysicalRiskAnalysisAnswers: {
    guarantee: false,
    liquidityRisk1: false,
    liquidityRisk2: false,
    creditRisk1: false,
    creditRisk2: false,
    functions1: false,
    functions2: false,
    functions3: false,
    functions4: false,
    functions5: false,
    functions6: false,
  },
  notionalRiskAnalysisAnswers: {
    guarantee: false,
    liquidityRisk1: false,
    liquidityRisk2: false,
    creditRisk1: false,
    creditRisk2: false,
    fxRisk1: false,
    fxRisk2: false,
    functions1: false,
    functions2: false,
    functions3: false,
    functions4: false,
    functions5: false,
    functions6: false,
    functions7: false,
  },
  totalRisk: null,
  assessment: null,
  cashPoolUpdateTrigger: null,
  isDirty: false,
  errors: null,
  showErrors: false,
  grossBenefit: null,
  leader: null,
  topCurrencyAccounts: [],
};

// Slice
export const cashPoolSlice = createSlice({
  name: 'cashPool',
  initialState,
  reducers: {
    resetCashPool: () => {
      return initialState;
    },
    setCashPool: (state, action) => {
      return {
        ...state,
        ...action.payload,
        nordicPhysicalRiskAnalysisAnswers: action.payload.riskAnalysisAnswers,
        notionalRiskAnalysisAnswers: action.payload.riskAnalysisAnswers,
        totalRisk: action.payload.totalRisk * 100, // comes as decimal from the backend
        isDirty: true,
      };
    },
    updateField: (state, action) => {
      return {
        ...state,
        ...action.payload,
        errors: (state.showErrors || action.payload.showErrors) && isCashPoolFormValid({ ...state, ...action.payload }),
        isDirty: true,
      };
    },
    updateCashPoolType: (_, action) => {
      return { ...initialState, ...action.payload };
    },
    setIsPristine: (state) => {
      return { ...state, isDirty: false };
    },
    updateNordicPhysicalRiskAnalysisAnswers: (state, action) => {
      return { ...state, nordicPhysicalRiskAnalysisAnswers: action.payload, isDirty: true };
    },
    updateNotionalRiskAnalysisAnswers: (state, action) => {
      return { ...state, notionalRiskAnalysisAnswers: action.payload, isDirty: true };
    },
    updateCashPoolTrigger: (state) => {
      return { ...state, cashPoolUpdateTrigger: !state.cashPoolUpdateTrigger };
    },
    addTopCurrencyAccount: (state, action) => {
      return {
        ...state,
        topCurrencyAccounts: [...state.topCurrencyAccounts, _.cloneDeep(action.payload)],
        isDirty: true,
      };
    },
    updateTopCurrencyAccount: (state, { payload }) => {
      return {
        ...state,
        topCurrencyAccounts: [...state.topCurrencyAccounts.filter((tca) => tca.id !== payload.id), { ...payload }],
        isDirty: true,
      };
    },
    removeTopCurrencyAccount: (state, { payload }) => {
      const topCurrencyAccountTempId = payload.topCurrencyAccountTempId;
      // if there is a temp id (topCurrencyAccountTempId) that means this is not a top currency account that exists in the db
      if (topCurrencyAccountTempId) {
        return {
          ...state,
          topCurrencyAccounts: [
            ...state.topCurrencyAccounts.filter((tca) => tca.topCurrencyAccountTempId !== topCurrencyAccountTempId),
          ],
          isDirty: true,
        };
      }

      return {
        ...state,
        topCurrencyAccounts: [...state.topCurrencyAccounts.filter((tca) => tca.id !== payload.id)],
        isDirty: true,
      };
    },
  },
});

export default cashPoolSlice.reducer;

// Actions
export const {
  resetCashPool,
  setCashPool,
  updateField,
  updateCashPoolType,
  updateCashPoolTrigger,
  setIsPristine,
  updateNordicPhysicalRiskAnalysisAnswers,
  updateNotionalRiskAnalysisAnswers,
  addTopCurrencyAccount,
  updateTopCurrencyAccount,
  removeTopCurrencyAccount,
} = cashPoolSlice.actions;

// Selectors
export const cashPoolSelector = (state) => state.cashPool;
