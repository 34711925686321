import { MultiSelect } from 'ui';
import { getOptionsFromAvailableOptionsForVariantOptions } from 'utils/select';
import { CurrencyVariantType, SingleSelectWidthType } from 'types';

import { allCurrencies } from './CurrencySingleSelect';

type CurrencyMultiSelectProps = {
  label?: string;
  tooltip?: string;
  variant?: CurrencyVariantType;
  width?: SingleSelectWidthType;
  disabled?: boolean;
  includeSelectAll?: boolean;
  availableOptions?: Array<string>;
  error?: string;
  value: string[] | undefined;
  onChange: Function;
};

function CurrencyMultiSelect({
  label = 'Currency',
  tooltip = '',
  variant = 'default',
  width = 'm',
  disabled = false,
  includeSelectAll = false,
  availableOptions,
  error,
  value,
  onChange,
}: CurrencyMultiSelectProps) {
  return (
    <MultiSelect
      hasGroupedOptions
      label={label}
      options={getOptionsFromAvailableOptionsForVariantOptions(allCurrencies[variant], availableOptions)}
      tooltip={tooltip}
      value={value}
      width={width}
      disabled={disabled}
      includeSelectAll={includeSelectAll}
      error={error}
      nameSingular="currency"
      namePlural="currencies"
      onChange={onChange}
    />
  );
}

export default CurrencyMultiSelect;
