import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getInterestCalculationDate, markLoanPaymentAsPaid } from '~/api';
import { paymentSelector, updateField } from '~/reducers/payment.slice';
import { Box, Button, DateInput, Modal, NumberInput } from '~/ui';
import { showErrorToast, showToast } from '~/ui/components/Toast';

/** Used in PaymentsPage and CompoundPeriodPaymentsPage */
const ReferenceRateModal = ({
  buttonText = 'Pay',
  payment,
  onHide,
  title = 'Enter Reference Rate',
  modalWidth = 's',
}) => {
  const dispatch = useDispatch();
  const { markedAsPaid } = useSelector(paymentSelector);
  const [estimationOfReferenceRate, setEstimationOfReferenceRate] = useState();
  const [interestCalculationDate, setInterestCalculationDate] = useState();

  const onMarkAsPaidClick = () => {
    const { id: loanId, paymentId } = payment;
    const data = { estimationOfReferenceRate, interestCalculationDate, isPaid: true };

    markLoanPaymentAsPaid(loanId, paymentId, data)
      .then(() => {
        showToast('Payment marked as paid');
        // used to trigger useEffect in PaymentsPage and refetch payments
        dispatch(updateField({ markedAsPaid: !markedAsPaid }));
        onHide();
        setEstimationOfReferenceRate();
        setInterestCalculationDate();
      })
      .catch((err) => {
        if (err.response.data.statusCode < 500) {
          return showErrorToast(err.response.data.message);
        }
        showErrorToast();
      });
  };

  useEffect(() => {
    if (!payment) return;
    const { id: loanId, paymentId, paymentNumberNum, issueDateNotFormatted } = payment;

    /** For the first interest calculation date it is always the issue date */
    if (paymentNumberNum === 1) {
      return setInterestCalculationDate(issueDateNotFormatted);
    }
    /**
     * Interest calculation should be previous payment due date (or compoundingPeriodEndDate for balloons) + one day.
     * This endpoint is used because we need to get the previous payment while disregarding a principal payment
     * that might have been made in between current and previous payment. It was easier to query the db than to do it here.
     */
    getInterestCalculationDate({ loanId, paymentId }).then(setInterestCalculationDate);
  }, [payment]);

  if (!payment) return null;

  return (
    <Modal
      actionButtons={
        <Button
          disabled={(!estimationOfReferenceRate && estimationOfReferenceRate !== 0) || !interestCalculationDate}
          text={buttonText}
          onClick={onMarkAsPaidClick}
        />
      }
      title={title}
      width={modalWidth}
      onHide={onHide}
    >
      <Box sx={{ display: 'grid', gridGap: 8, gridTemplateColumns: 'repeat(2, 1fr)' }}>
        <NumberInput
          label={`${payment?.referenceRate} ${payment?.referenceRateMaturity}`}
          inputType="float"
          width="fullWidth"
          unit="%"
          allowNegatives
          value={estimationOfReferenceRate}
          onChange={setEstimationOfReferenceRate}
        />
        <DateInput
          label="Interest Calculation Date"
          width="fullWidth"
          value={interestCalculationDate}
          onChange={setInterestCalculationDate}
        />
      </Box>
    </Modal>
  );
};

export default ReferenceRateModal;
