import qs from 'query-string';

import { backend } from '~/services';
import { toFile } from '~/utils/documents';

import { apiPaths } from './routes';

export function getLoans({ limit, isPortfolio }) {
  const endpoint = `${apiPaths.loan}?limit=${limit}&isPortfolio=${isPortfolio}`;
  return backend.get(endpoint);
}

export function getDeletedLoans() {
  return backend.get(`${apiPaths.loan}/deleted`);
}

export function getLoan(id) {
  return backend.get(`${apiPaths.loan}/${id}`);
}

export function createLoan(data) {
  return backend.post(apiPaths.loan, data);
}

export function createLoans(data) {
  return backend.post(`${apiPaths.loan}/mass`, data);
}

export function createImportedLoan(data) {
  return backend.post(`${apiPaths.loan}/import`, data);
}

export function createMassImportedLoans(data) {
  return backend.post(`${apiPaths.loan}/import/mass`, data);
}

export function updateLoan({ id, data }) {
  return backend.put(`${apiPaths.loan}/${id}`, data);
}

export function updateLoanWithUpdatedValues({ id, data }) {
  return backend.put(`${apiPaths.loan}/${id}/save-updated`, data);
}

export function updateImportedLoan({ id, data }) {
  return backend.put(`${apiPaths.loan}/imported/${id}`, data);
}

export function updateLoanNote({ id, data }) {
  return backend.put(`${apiPaths.loan}/${id}/note`, data);
}

export function updateLoanStatus({ id, data }) {
  return backend.put(`${apiPaths.loan}/${id}/status`, data);
}

export function updateLoanIsPortfolio({ id, data }) {
  return backend.put(`${apiPaths.loan}/${id}/portfolio`, data);
}

export function updateLoanRates({ id, data }) {
  return backend.put(`${apiPaths.loan}/${id}/rates`, data);
}

export function deleteLoan({ id, force = false }) {
  return backend.delete(`${apiPaths.loan}/${id}?force=${force}`);
}

export function restoreLoan(id) {
  return backend.patch(`${apiPaths.loan}/${id}`);
}

export function createLoanFile({ reportId, file, label, newName }) {
  const formData = new FormData();
  formData.append('file', file);
  let endpoint = `${apiPaths.azureStorage}${apiPaths.loan}/${reportId}?label=${label}`;

  if (newName) {
    endpoint = endpoint + `&newName=${newName}`;
  }

  return backend.post(endpoint, formData);
}

export async function getLoanFile({ fileId, type }) {
  let endpoint = `${apiPaths.azureStorage}${apiPaths.loan}/file/${fileId}`;
  if (type) endpoint = `${endpoint}?type=${type}`;

  const response = await backend.get(endpoint, { fullResponse: true, responseType: 'blob' });
  return toFile(response);
}

export async function deleteLoanFile({ fileId, reportId }) {
  return await backend.delete(`${apiPaths.azureStorage}${apiPaths.loan}/${reportId}/file/${fileId}`);
}

export async function updateLoanFileStatus({ fileId, data }) {
  return await backend.put(`${apiPaths.azureStorage}${apiPaths.loan}/file/${fileId}`, data);
}

export function getLoanMassImportTemplate() {
  return backend.get(`${apiPaths.loan}/template/import`, { responseType: 'blob' });
}

export function getLoanMassUploadTemplate() {
  return backend.get(`${apiPaths.loan}/template/upload`, { responseType: 'blob' });
}

export function generateLoanAgreement({ id }) {
  return backend.post(`${apiPaths.loan}/${id}/agreement`);
}

export function generateLoanTpReport({ id }) {
  return backend.post(`${apiPaths.loan}/${id}/tp-report`);
}

export function runLoanAlgorithm({ id, data }) {
  return backend.post(`${apiPaths.loan}/${id}/algorithm`, data);
}

export function getLoanPaymentsByLoanId({ id, limit = 3, isPaid = false, isBalloon }) {
  if (isBalloon) {
    return backend.get(`${apiPaths.loan}/${id}/payment?&isPaid=${isPaid}`);
  }
  return backend.get(`${apiPaths.loan}/${id}/payment?limit=${limit}&isPaid=${isPaid}`);
}

/* gets the limit = 3 of upcoming payments for this client based on paymentDueDate */
export function getNextLoanPayments() {
  return backend.get(`${apiPaths.loan}/LOAN_ID_NOT_NEEDED/payment/next`);
}

export function markLoanPaymentAsPaid(loanId, paymentId, data) {
  return backend.patch(`${apiPaths.loan}/${loanId}/payment/${paymentId}`, data);
}

export function markWHTLoanPaymentAsPaid(loanId, paymentId, data) {
  return backend.patch(`${apiPaths.loan}/${loanId}/payment/wht/${paymentId}`, data);
}

export function getLoanPayments({ searchQuery = {}, limit = 10, offset = 0 }) {
  const queryString = qs.stringify({ limit, offset, ...searchQuery, reportType: 'loan' });
  return backend.get(`${apiPaths.loan}/LOAN_ID_NOT_NEEDED/payment/all?${queryString}`);
}

export function getLoanWHTPayments({ searchQuery = {}, limit = 10, offset = 0 }) {
  const queryString = qs.stringify({ limit, offset, ...searchQuery, reportType: 'loan' });
  return backend.get(`${apiPaths.loan}/LOAN_ID_NOT_NEEDED/payment/wht/all?${queryString}`);
}

export function payPrincipal({ loanId, data }) {
  return backend.post(`${apiPaths.loan}/${loanId}/payment/principal`, data);
}

export function getLoanCalculatedPaymentsInterest({ id, data }) {
  return backend.post(`${apiPaths.loan}/${id}/payment/calculate-payments`, data);
}

export function getBalloonCompoundPeriodPayments({ id }) {
  return backend.get(`${apiPaths.loan}/${id}/payment/compound-payments`);
}

export function getInterestCalculationDate({ loanId, paymentId }) {
  return backend.get(`${apiPaths.loan}/${loanId}/payment/${paymentId}/interest-calculation-date`);
}

export async function exportLoanPaymentsAsExcel({ searchQuery = {} }) {
  const queryString = qs.stringify({ ...searchQuery, reportType: 'loan' });
  const response = await backend.get(`${apiPaths.loan}/LOAN_ID_NOT_NEEDED/payment/export-excel?${queryString}`, {
    fullResponse: true,
    responseType: 'blob',
  });
  return toFile(response);
}
