import React from 'react';

import { Button, FlexLayout, Modal, Text } from '~/ui';

function AuditTrailModal({
  handleOnCreateClick,
  handleOnUpdateClick,
  onHide,
  modalInfoMessage = 'Clicking Overwrite will overwrite the current entry',
  parentCompanyCreditRatingChanged = false,
}) {
  return (
    <Modal
      actionButtons={
        <FlexLayout alignItems="center" space={8}>
          <Button text="Overwrite" onClick={handleOnUpdateClick} />
          <Button text="Create new" onClick={handleOnCreateClick} />
        </FlexLayout>
      }
      title="Do you want to create a new entry in the Audit Trail?"
      onHide={onHide}
    >
      <Text color="bali-hai" variant="m-spaced">
        {modalInfoMessage}
      </Text>
    </Modal>
  );
}

export default AuditTrailModal;
