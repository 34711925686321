/** Same enum is on the backend */
export enum WHT_APPROACHES {
  BORROWER_PAYS = 'BORROWER_PAYS',
  GROSS_UP = 'GROSS_UP',
}

export enum WHT_APPROACHES_LABELS {
  BORROWER_PAYS = 'net interest',
  GROSS_UP = 'gross-up',
}
