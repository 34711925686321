import { ReactNodeLike } from 'prop-types';

import { Box, FlexLayout, Text, Tooltip } from 'ui';

type NestedOptions = Array<{ label: string; onClick: Function | null }>;

type OptionsType = Array<{
  label: string;
  onClick: Function | null;
  nestedOptions?: NestedOptions;
}>;

type ActionMenuProps = {
  visible: boolean;
  children: NonNullable<ReactNodeLike>;
  options: OptionsType;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const menuItemStyle = { borderRadius: 's', boxShadow: 'depth-2', width: 'action-menu-width' };

const onClickHandler = (onClick: Function | null, setVisible: React.Dispatch<React.SetStateAction<boolean>>) => {
  if (!onClick) return;
  onClick();
  setVisible(false);
};

const NestedDropdown = ({
  nestedOptions = [],
  setVisible,
}: {
  nestedOptions?: NestedOptions;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Box bg="white" sx={{ ...menuItemStyle, position: 'absolute', right: '100%', top: 0 }}>
      {nestedOptions.map(({ label, onClick }, index) => (
        <FlexLayout
          className="actionmenu-submenu-item"
          bg="white"
          alignItems="center"
          justifyContent="flex-end"
          key={`${label}-${index}`}
          p={3}
          sx={{ '&:hover': { bg: onClick && 'link-water' } }}
          onClick={() => onClickHandler(onClick, setVisible)}
        >
          <Text color="deep-sapphire">{label}</Text>
        </FlexLayout>
      ))}
    </Box>
  );
};

function Dropdown({
  options = [],
  setVisible,
}: {
  options: OptionsType;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <Box bg="white" sx={menuItemStyle}>
      {options.map(({ label, onClick, nestedOptions }) => (
        <FlexLayout
          alignItems="center"
          justifyContent="flex-end"
          key={label}
          p={3}
          className="actionmenu-item"
          sx={{ '&:hover': { bg: onClick && 'link-water' }, position: 'relative' }}
          onClick={() => onClickHandler(onClick, setVisible)}
        >
          <Text color="deep-sapphire">{label}</Text>

          <NestedDropdown nestedOptions={nestedOptions} setVisible={setVisible} />
        </FlexLayout>
      ))}
    </Box>
  );
}

function ActionMenu({ children, options = [], visible = false, setVisible }: ActionMenuProps) {
  return (
    <Tooltip
      content={<Dropdown setVisible={setVisible} options={options} />}
      interactive={true}
      placement="bottom-end"
      visible={visible}
      onClickOutside={() => setVisible(false)}
    >
      {children}
    </Tooltip>
  );
}

export default ActionMenu;
