import qs from 'query-string';

import { backend } from '~/services';
import { toFile } from '~/utils/documents';

import { apiPaths } from './routes';

export function getGuarantees({ limit, isPortfolio }) {
  const endpoint = `${apiPaths.guarantee}?limit=${limit}&isPortfolio=${isPortfolio}`;
  return backend.get(endpoint);
}

export function getDeletedGuarantees() {
  return backend.get(`${apiPaths.guarantee}/deleted`);
}

export function getGuarantee(id) {
  return backend.get(`${apiPaths.guarantee}/${id}`);
}

export function createGuarantee(data) {
  return backend.post(apiPaths.guarantee, data);
}

export function createGuarantees(data) {
  return backend.post(`${apiPaths.guarantee}/mass`, data);
}

export function createImportedGuarantee(data) {
  return backend.post(`${apiPaths.guarantee}/import`, data);
}

export function createMassImportedGuarantees(data) {
  return backend.post(`${apiPaths.guarantee}/import/mass`, data);
}

export function updateGuarantee({ id, data }) {
  return backend.put(`${apiPaths.guarantee}/${id}`, data);
}

export function updateGuaranteeWithUpdatedValues({ id, data }) {
  return backend.put(`${apiPaths.guarantee}/${id}/save-updated`, data);
}

export function updateImportedGuarantee({ id, data }) {
  return backend.put(`${apiPaths.guarantee}/imported/${id}`, data);
}

export function updateGuaranteeNote({ id, data }) {
  return backend.put(`${apiPaths.guarantee}/${id}/note`, data);
}

export function updateGuaranteeStatus({ id, data }) {
  return backend.put(`${apiPaths.guarantee}/${id}/status`, data);
}

export function updateGuaranteeIsPortfolio({ id, data }) {
  return backend.put(`${apiPaths.guarantee}/${id}/portfolio`, data);
}

export function updateGuaranteeRates({ id, data }) {
  return backend.put(`${apiPaths.guarantee}/${id}/rates`, data);
}

export function deleteGuarantee({ id, force = false }) {
  return backend.delete(`${apiPaths.guarantee}/${id}?force=${force}`);
}

export function restoreGuarantee(id) {
  return backend.patch(`${apiPaths.guarantee}/${id}`);
}

export function createGuaranteeFile({ reportId, file, label, newName }) {
  const formData = new FormData();
  formData.append('file', file);
  let endpoint = `${apiPaths.azureStorage}${apiPaths.guarantee}/${reportId}?label=${label}`;

  if (newName) {
    endpoint = endpoint + `&newName=${newName}`;
  }

  return backend.post(endpoint, formData);
}

export async function getGuaranteeFile({ fileId, type }) {
  let endpoint = `${apiPaths.azureStorage}${apiPaths.guarantee}/file/${fileId}`;
  if (type) endpoint = `${endpoint}?type=${type}`;

  const response = await backend.get(endpoint, { fullResponse: true, responseType: 'blob' });
  return toFile(response);
}

export async function deleteGuaranteeFile({ fileId, reportId }) {
  return await backend.delete(`${apiPaths.azureStorage}${apiPaths.guarantee}/${reportId}/file/${fileId}`);
}

export async function updateGuaranteeFileStatus({ fileId, data }) {
  return await backend.put(`${apiPaths.azureStorage}${apiPaths.guarantee}/file/${fileId}`, data);
}

export function getGuaranteeMassImportTemplate() {
  return backend.get(`${apiPaths.guarantee}/template/import`, { responseType: 'blob' });
}

export function getGuaranteeMassUploadTemplate() {
  return backend.get(`${apiPaths.guarantee}/template/upload`, { responseType: 'blob' });
}

export function generateGuaranteeAgreement({ id }) {
  return backend.post(`${apiPaths.guarantee}/${id}/agreement`);
}

export function generateGuaranteeTpReport({ id }) {
  return backend.post(`${apiPaths.guarantee}/${id}/tp-report`);
}

export function runGuaranteeAlgorithm({ id, data }) {
  return backend.post(`${apiPaths.guarantee}/${id}/algorithm`, data);
}

export function getGuaranteePaymentsByGuaranteeId({ id, limit = 3, isPaid = false }) {
  return backend.get(`${apiPaths.guarantee}/${id}/payment?limit=${limit}&isPaid=${isPaid}`);
}

/* gets the limit = 3 of upcoming payments for this client based on paymentDueDate */
export function getNextGuaranteePayments() {
  return backend.get(`${apiPaths.guarantee}/GUARANTEE_ID_NOT_NEEDED/payment/next`);
}

export function markGuaranteePaymentAsPaid(guaranteeId, paymentId, data) {
  return backend.patch(`${apiPaths.guarantee}/${guaranteeId}/payment/${paymentId}`, data);
}

export function getGuaranteePayments({ searchQuery = {}, limit = 10, offset = 0 }) {
  const queryString = qs.stringify({ limit, offset, ...searchQuery, reportType: 'guarantee' });
  return backend.get(`${apiPaths.guarantee}/GUARANTEE_ID_NOT_NEEDED/payment/all?${queryString}`);
}

export function getGuaranteeCalculatedPaymentsInterest({ id, data }) {
  return backend.post(`${apiPaths.guarantee}/${id}/payment/calculate-payments`, data);
}

export async function exportGuaranteePaymentsAsExcel({ searchQuery = {} }) {
  const queryString = qs.stringify({ ...searchQuery, reportType: 'guarantee' });
  const response = await backend.get(
    `${apiPaths.guarantee}/GUARANTEE_ID_NOT_NEEDED/payment/export-excel?${queryString}`,
    { fullResponse: true, responseType: 'blob' }
  );
  return toFile(response);
}
