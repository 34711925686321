import React from 'react';
import { Text as ThemeText } from 'theme-ui';

import { styles } from 'ui';
import { TypographyVariantType } from 'ui/theme/typography';

interface TextProps {
  as?: string;
  children?: React.ReactNode;
  color?: string;
  isReadable?: boolean;
  shouldTruncate?: boolean;
  p?: number;
  px?: number;
  py?: number;
  sx?: any;
  textAlign?: string;
  variant?: TypographyVariantType;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
}

const Text = React.forwardRef<HTMLElement, TextProps>(
  (
    {
      as = 'span',
      children,
      color,
      isReadable = false,
      shouldTruncate = false,
      sx,
      textAlign,
      variant = 'm-spaced',
      ...rest
    },
    ref
  ) => {
    const clickableStyle = rest.disabled || rest.onClick || rest.onMouseEnter ? styles.interactions.clickable : {};
    const truncateStyle = shouldTruncate ? styles.typography.truncate : {};

    return (
      <ThemeText
        as={as}
        color={color}
        ref={ref}
        sx={{
          maxWidth: isReadable ? 'readable-width' : undefined,
          ...clickableStyle,
          ...truncateStyle,
          ...sx,
        }}
        variant={variant}
        {...rest}
      >
        {children}
      </ThemeText>
    );
  }
);

export default Text;
