import { useCallback } from 'react';

import { NORDIC } from '~/enums';

function useCompanySelectOptions(allParticipants, chosenTopCurrencyAccountId, type) {
  const isNordic = type === NORDIC;

  /** Removes leader company in case of Physical cash pool, otherwise puts leader at the top */
  const getCompanySelectOptions = useCallback(() => {
    const participants = [...allParticipants].sort((p1, p2) => Number(p2.isLeader) - Number(p1.isLeader));

    /**
     * Filter to show only companies that are in the currently chosen top currency account.
     * Applies only to nordic. If no top currency account is chosen it shows all the companies.
     */
    return participants
      .filter((p) => {
        if (isNordic && chosenTopCurrencyAccountId) return p.topCurrencyAccountIds.includes(chosenTopCurrencyAccountId);
        return true;
      })
      .map(({ companyId, companyName }) => ({ id: companyId, name: companyName }));
  }, [allParticipants, chosenTopCurrencyAccountId, isNordic]);

  return getCompanySelectOptions;
}

export default useCompanySelectOptions;
