import PT from 'prop-types';
import Select from 'react-select';

import { Box, Text } from '~/ui';
import { withLabel } from '~/ui/hocs';

import components from './components';
import { getStyles } from './stylesFactory';

const widths = {
  s: '114px',
  m: '252px',
  l: '536px',
  fullWidth: '100%',
};

function SingleSelect({
  error,
  disabled = false,
  hasGroupedOptions = false,
  isShowing = true,
  options = [],
  placeholder = '',
  value,
  width = 'm',
  height = 'input-height',
  dataTestId,
  onChange,
}) {
  function formatGroupLabel({ label }) {
    return (
      <Text color="bali-hai" variant="s-spaced-medium-caps">
        {label}
      </Text>
    );
  }

  function setValue() {
    if (hasGroupedOptions) {
      return (
        options
          .map((options) => options.options)
          .flat()
          .find((option) => option.value === value) || null
      );
    } else {
      return options.find((option) => option.value === value || option.value?.id === value) || null;
    }
  }

  if (!isShowing) return null;

  return (
    <Box disabled={disabled} sx={{ flexShrink: '0', width: widths[width] }} dataTestId={dataTestId}>
      <Select
        components={components}
        formatGroupLabel={formatGroupLabel}
        menuPlacement="auto"
        options={options}
        placeholder={placeholder}
        styles={getStyles({ error, height })}
        value={setValue()}
        onChange={(option) => onChange(option.value)}
      />
    </Box>
  );
}

const optionPropType = PT.shape({
  label: PT.oneOfType([PT.string, PT.number]),
  value: PT.oneOfType([PT.string, PT.number, PT.object]),
});

SingleSelect.propTypes = {
  error: PT.string,
  label: PT.oneOfType([PT.string, PT.number]),
  options: PT.arrayOf(optionPropType),
  placeholder: PT.string,
  tooltip: PT.string,
  value: PT.oneOfType([PT.string, PT.number, PT.object]),
  width: PT.oneOf(Object.keys(widths)),
  onChange: PT.func,
};

export default withLabel(SingleSelect);
