import { Button, Modal, Text } from 'ui';

type RestoreModalPropsType = {
  dataTestId?: string;
  additionalInfo?: string;
  isShowing: boolean;
  item: string;
  handleOnRestoreClick: () => void;
  handleOnHide: () => void;
};

function RestoreModal({
  isShowing = true,
  additionalInfo = '',
  item,
  dataTestId,
  handleOnRestoreClick,
  handleOnHide,
}: RestoreModalPropsType) {
  if (!isShowing) return null;

  return (
    <Modal
      actionButtons={<Button text="Restore" onClick={handleOnRestoreClick} dataTestId={dataTestId} />}
      title={`Restore ${item}`}
      width="s"
      onHide={handleOnHide}
    >
      <Text color="deep-sapphire" variant="m-spaced" sx={{ lineHeight: '21px' }}>
        Are you sure you want to restore this {item}? {additionalInfo}
      </Text>
    </Modal>
  );
}

export default RestoreModal;
