// Same on backend
export enum TemplateFileLabelsEnum {
  LOAN_FIXED_AGREEMENT = 'Fixed Loan Legal Agreement',
  LOAN_FLOAT_AGREEMENT = 'Float Loan Legal Agreement',
  LOAN_STANDALONE_REPORT = 'Loan Transfer Pricing Report Standalone Credit Rating',
  LOAN_ADJUSTED_REPORT = 'Loan Transfer Pricing Report Implicit Support Adjusted Credit Rating',
  LOAN_STANDALONE_REPORT_NO_LENDER_PERSPECTIVE = 'Loan Transfer Pricing Report Standalone Credit Rating No Lender Perspective',
  LOAN_ADJUSTED_REPORT_NO_LENDER_PERSPECTIVE = 'Loan Transfer Pricing Report Implicit Support Adjusted Credit Rating No Lender Perspective',

  B2B_LOAN_FIXED_AGREEMENT = 'Fixed Back-to-back Loan Legal Agreement',
  B2B_LOAN_FLOAT_AGREEMENT = 'Float Back-to-back Loan Legal Agreement',
  B2B_LOAN_STANDALONE_REPORT = 'Back-to-back Loan Transfer Pricing Report Standalone Credit Rating',
  B2B_LOAN_ADJUSTED_REPORT = 'Back-to-back Loan Transfer Pricing Report Implicit Support Adjusted Credit Rating',

  GUARANTEE_AGREEMENT = 'Guarantee Legal Agreement',
  GUARANTEE_YIELD_EXPECTED_LOSS_STANDALONE_REPORT = 'Guarantee Transfer Pricing Report Yield - Expected Loss Approach Standalone Credit Rating',
  GUARANTEE_YIELD_EXPECTED_LOSS_ADJUSTED_REPORT = 'Guarantee Transfer Pricing Report Yield - Expected Loss Approach Implicit Support Adjusted Credit Rating',
  GUARANTEE_SECURITY_STANDALONE_REPORT = 'Guarantee Transfer Pricing Report Security Approach Standalone Credit Rating',
  GUARANTEE_SECURITY_ADJUSTED_REPORT = 'Guarantee Transfer Pricing Report Security Approach Implicit Support Adjusted Credit Rating',
}

export enum TemplateFileTypeEnum {
  CLIENT = 'client',
  COUNTRY = 'country',
  COMPANY = 'company',
}
