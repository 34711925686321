import React from 'react';

import { ButtonSpinner, FlexLayout, Icon, Text } from 'ui';
import { IconTypes } from 'ui/components/Icon';
import type { TypographyVariantType } from 'ui/theme/typography';

const variants = {
  primary: {
    bg: 'deep-sapphire',
    color: 'white',
  },
  secondary: {
    bg: 'shakespeare',
    color: 'white',
  },
  gray: {
    bg: 'link-water',
    color: 'deep-sapphire',
  },
  gray2: {
    bg: 'alabaster',
    color: 'bali-hai',
  },
};

type IconSizeType = 'xs' | 's' | 'm' | 'l';
type SizeType = { iconSize: IconSizeType; px: number; py: number; space: number; textVariant: TypographyVariantType };
type SizesType = { s: SizeType; m: SizeType; m2: SizeType; l: SizeType };

const sizes: SizesType = {
  s: {
    iconSize: 'xs',
    px: 3,
    py: 2,
    space: 1,
    textVariant: 's-spaced-medium-caps',
  },
  m: {
    iconSize: 's',
    px: 6,
    py: 3,
    space: 2,
    textVariant: 'm-spaced-bold-caps',
  },
  m2: {
    iconSize: 'l',
    px: 4,
    py: 2,
    space: 2,
    textVariant: 'm-spaced-bold-caps',
  },
  l: {
    iconSize: 'm',
    px: 8,
    py: 3,
    space: 2,
    textVariant: 'm-spaced-bold-caps',
  },
};

interface ButtonProps {
  disabled?: boolean;
  isShowing?: boolean;
  iconLeft?: IconTypes;
  iconRight?: IconTypes;
  size?: keyof SizesType;
  sx?: any;
  text: string;
  variant?: 'primary' | 'secondary' | 'gray' | 'gray2';
  dataTestId?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  children?: React.ReactNode;
}

const Button = React.forwardRef<HTMLElement, ButtonProps>(
  (
    {
      disabled = false,
      isShowing = true,
      iconLeft,
      iconRight,
      loading = false,
      size = 'm',
      sx = {},
      text,
      dataTestId,
      variant = 'primary',
      onClick,
    },
    ref
  ) => {
    const { bg, color } = variants[variant];
    const { iconSize, px, py, space, textVariant } = sizes[size];

    let IconLeft;
    let IconRight;
    if (iconLeft) {
      IconLeft = loading ? (
        <ButtonSpinner color={color} size={iconSize} />
      ) : (
        <Icon color={color} icon={iconLeft} size={iconSize} />
      );
    }
    if (iconRight) {
      IconRight = loading ? (
        <ButtonSpinner color={color} size={iconSize} />
      ) : (
        <Icon color={color} icon={iconRight} size={iconSize} />
      );
    }

    const ButtonContent =
      loading && !iconLeft && !iconRight ? (
        <ButtonSpinner size={iconSize} />
      ) : text ? (
        <Text color={color} variant={textVariant}>
          {text}
        </Text>
      ) : null;

    if (!isShowing) return null;
    return (
      <FlexLayout
        dataTestId={dataTestId}
        alignItems="center"
        bg={bg}
        disabled={disabled}
        flexShrink="0"
        justifyContent="center"
        px={px}
        py={py}
        ref={ref}
        space={space}
        sx={{
          borderRadius: 'm',
          width: 'fit-content',
          ...sx,
        }}
        onClick={onClick}
      >
        {IconLeft}
        {ButtonContent}
        {IconRight}
      </FlexLayout>
    );
  }
);

export default Button;
