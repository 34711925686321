import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

import { CreditRatingValueType, CountryValueType, TrancheAssetClassType, CurrencyValueType } from 'types';

type InitialStateType = {
  offset: number;
  limit: number;
  issueDate: Date | null;
  numberOfMonthsBeforeIssueDate: number;
  creditRatings: Array<CreditRatingValueType>;
  tenor: number | null;
  numberOfYearsBeforeAndAfterTenor: number;
  currencies: Array<CurrencyValueType>;
  countries: Array<CountryValueType>;
  trancheAssetClasses: Array<TrancheAssetClassType>;
  excludedCreditRatingIds: Array<number>;

  sortBy: string | null;
  isDirty: boolean;
};

const initialState: InitialStateType = {
  offset: 0,
  limit: 10,
  issueDate: null,
  numberOfMonthsBeforeIssueDate: 3,
  creditRatings: [],
  tenor: null,
  numberOfYearsBeforeAndAfterTenor: 2,
  currencies: [],
  countries: [],
  trancheAssetClasses: [],
  excludedCreditRatingIds: [],

  sortBy: null,
  isDirty: false,
};

export const cuftDataFiltersSlice = createSlice({
  name: 'cuftDataFilters',
  initialState,
  reducers: {
    resetCuftDataFilter: () => {
      return initialState;
    },
    setCuftDataFilter: (state, action) => {
      return { ...state, ...action.payload, isDirty: true };
    },
    updatePagination: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateField: (state, action) => {
      return { ...state, ...action.payload, offset: 0, isDirty: true };
    },
    updateIsDirty: (state, action) => {
      return { ...state, isDirty: action.payload };
    },
    excludeCreditRatingIds: (state, action) => {
      return {
        ...state,
        excludedCreditRatingIds: [...state.excludedCreditRatingIds, action.payload],
      };
    },
  },
});

export default cuftDataFiltersSlice.reducer;

export const {
  resetCuftDataFilter,
  setCuftDataFilter,
  updatePagination,
  updateField,
  updateIsDirty,
  excludeCreditRatingIds,
} = cuftDataFiltersSlice.actions;

export const cuftDataFiltersSelector = (state: RootState) => state.cuftDataFilters;
