export const BALANCES = 'Balances';
export const CHART = 'Chart';
export const TABLE = 'Table';
export const CASH_POOL = 'cashPool';
export const PHYSICAL = 'Physical';
export const NOTIONAL = 'Notional';
export const NORDIC = 'Nordic';
export const batchStatus = {
  UNPOOLED: 'Unpooled',
  UNPAID: 'Unpaid',
  PARTIALLY_PAID: 'Partially Paid',
  PAID: 'Paid',
};
export const riskAnalysisAssessments = ['Low', 'Medium', 'High'];
