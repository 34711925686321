import ReactTooltip from 'react-tooltip';

import { FlexLayout, Icon, Text } from '~/ui';

/**
 * Pass unique id if labels can be the same because all tooltips will appear at the same time for same labels.
 * This bug appeared in SelectParticipantsTable.js where the label (value) for the same row
 * could be - for both Credit Rate and Debit rate columns. So both tooltips appeared at the same time.
 * It was solved by passing a unique id for each column.
 */
function TextWithTooltip({ id, color, label, shouldTruncate = false, tooltip, variant, isShowing = true }) {
  if (!isShowing) return null;

  return (
    <FlexLayout alignItems="center" space={2}>
      <Text color={color} shouldTruncate={shouldTruncate} variant={variant}>
        {label}
      </Text>
      {tooltip && (
        <>
          <Icon data-for={id || label} data-tip="" icon="helpTooltip" size="xs" />
          <ReactTooltip html={true} id={id || label}>
            {tooltip}
          </ReactTooltip>
        </>
      )}
    </FlexLayout>
  );
}

export default TextWithTooltip;
