import { createSlice } from '@reduxjs/toolkit';

const getPayerPayeeId = ({ lender, borrower, guarantor, principal, creditor, debtor }) => {
  const payerPayee = {};

  if (lender) payerPayee.lender = { id: lender };
  if (borrower) payerPayee.borrower = { id: borrower };
  if (guarantor) payerPayee.guarantor = { id: guarantor };
  if (principal) payerPayee.principal = { id: principal };
  if (creditor) payerPayee.creditor = { id: creditor };
  if (debtor) payerPayee.debtor = { id: debtor };

  return payerPayee;
};

// Slice
export const paymentSlice = createSlice({
  name: 'payments',
  initialState: {},
  reducers: {
    setPayment: (state, action) => {
      return { ...state, ...action.payload, ...getPayerPayeeId(action.payload) };
    },
    resetPayment: () => {
      return {};
    },
    updatePagination: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetReportId: (state) => {
      return { ...state, loanId: undefined, guaranteeId: undefined, batchId: undefined };
    },
    updateField: (state, action) => {
      return { ...state, ...action.payload, limit: 10, offset: 0 };
    },
    updatePaymentRateType: (state, action) => {
      if (state.rateType === action.payload) {
        state.rateType = null;
      } else {
        state.rateType = action.payload;
      }
    },
  },
});

export default paymentSlice.reducer;

// Actions
export const { setPayment, resetPayment, updateField, updatePagination, updatePaymentRateType, resetReportId } =
  paymentSlice.actions;

// Selectors
export const paymentSelector = (state) => state.payments;
