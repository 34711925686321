import { useContext, useEffect, useState } from 'react';

import { UserInfoContext } from '~/context/user';
import { ClientSingleSelect, RoleSingleSelect } from '~/components/Shared';
import { rolesEnum } from '~/enums';
import { Button, FlexLayout, Modal, Tabs, TextInput, WithTooltip } from '~/ui';

const CreateUserModal = ({
  azureString,
  usernameString,
  isShowing,
  onHide,
  shouldConfirmUserCreate,
  setShouldConfirmUserCreate,
  onCreateUser,
}) => {
  const { userInfo } = useContext(UserInfoContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [clientId, setClientId] = useState(null);
  const [role, setRole] = useState(rolesEnum.USER);
  const [selectedTab, setSelectedTab] = useState(azureString);
  const [errors, setErrors] = useState({});

  const tabs = [
    { label: 'Azure', value: azureString },
    { label: 'Username', value: usernameString },
  ];

  const onInputChange = (setter) => (value) => {
    setter(value);
    setShouldConfirmUserCreate(false);
  };

  const isCreateUserDisabled = () => {
    const requiredClientId = userInfo.role === rolesEnum.SUPERADMIN ? [clientId] : [];
    if (selectedTab === azureString) {
      return [email, fullName, ...requiredClientId, role].some((field) => !field);
    }
    if (selectedTab === usernameString) {
      return [username, password, email, fullName, ...requiredClientId, role].some((field) => !field);
    }
  };

  const resetForm = () => {
    setUsername('');
    setPassword('');
    setEmail('');
    setFullName('');
    setClientId('');
    setRole(rolesEnum.USER);
    setErrors({});
  };

  const onCreateUserClick = async () => {
    setErrors({});
    const newErrors = {};

    if (selectedTab === usernameString) {
      if (password.length < 12) {
        newErrors.password = 'Password must be at least 12 characters long';
      }
    }
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      newErrors.email = 'Email is not valid';
    }

    if (Object.keys(newErrors).length) {
      return setErrors(newErrors);
    }

    await onCreateUser({ provider: selectedTab, username, password, email, fullName, clientId, role });
  };

  const onTabSelect = (tab) => {
    setSelectedTab(tab);
    resetForm();
  };

  useEffect(() => {
    return () => {
      resetForm();
      setShouldConfirmUserCreate(false);
    };
  }, [isShowing, setShouldConfirmUserCreate]);

  if (!isShowing) return null;

  return (
    <Modal
      actionButtons={
        <WithTooltip
          disabled={isCreateUserDisabled()}
          label="createUserButton"
          tooltip="All fields are required to create a user"
        >
          <Button
            variant={shouldConfirmUserCreate ? 'secondary' : 'primary'}
            text={shouldConfirmUserCreate ? 'Confirm Create' : 'Create'}
            disabled={isCreateUserDisabled()}
            onClick={onCreateUserClick}
          />
        </WithTooltip>
      }
      title="Create a new User"
      width="s"
      onHide={onHide}
    >
      <Tabs minWidth="200px" height="34px" selectedTab={selectedTab} tabs={tabs} onTabSelect={onTabSelect} />
      {selectedTab === usernameString && (
        <>
          <TextInput
            label="Username"
            width="fullWidth"
            inputWidth="fullWidth"
            value={username}
            onChange={onInputChange(setUsername)}
          />
          <TextInput
            label="Password"
            width="fullWidth"
            type="password"
            inputWidth="fullWidth"
            error={errors.password}
            value={password}
            onChange={onInputChange(setPassword)}
          />
        </>
      )}
      <TextInput
        label="Email"
        width="fullWidth"
        type="email"
        inputWidth="fullWidth"
        error={errors.email}
        value={email}
        onChange={onInputChange(setEmail)}
      />
      <FlexLayout justifyContent="space-between">
        <TextInput label="Full name" inputWidth="fullWidth" value={fullName} onChange={onInputChange(setFullName)} />
        <RoleSingleSelect value={role} onChange={onInputChange(setRole)} />
      </FlexLayout>
      {userInfo.role === rolesEnum.SUPERADMIN && (
        <ClientSingleSelect value={clientId} onChange={onInputChange(setClientId)} />
      )}
    </Modal>
  );
};

export default CreateUserModal;
