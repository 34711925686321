import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

const initialState = {
  Borrower: true,
  'Filing Company Name': false,
  Country: true,
  'Primary SIC': false,
  'Issue Date': true,
  'Maturity Date': true,
  Tenor: true,
  'Credit Rating': true,
  'Credit Spread': true,
  Currency: true,
  Remove: true,
  "Moody's Obligor Credit Rating": false,
  'S&P Obligor Credit Rating': false,
  'Tranche Asset Class': false,
  'Tranche Type': false,
  'Tranche Primary Reference Rate': false,
};

export const cuftDataColumnSlice = createSlice({
  name: 'cuftDataColumns',
  initialState,
  reducers: {
    resetForm: () => {
      return initialState;
    },
    setColumns: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateColumns: (_state, action) => {
      return action.payload;
    },
  },
});

export default cuftDataColumnSlice.reducer;

export const { resetForm, setColumns, updateColumns } = cuftDataColumnSlice.actions;

export const cuftDataColumns = (state: RootState) => state.cuftDataColumns;
