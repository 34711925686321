import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CompanySingleSelect, CountrySingleSelect, CurrencySingleSelect } from 'components/Shared';
import { NORDIC } from 'enums';
import { cashPoolSelector, updateField } from 'reducers/cashPool.slice';
import { Box, NumberInput, TextInput } from 'ui';
import { CurrencyValueType } from 'types';

import { cashPoolTexts } from './CashPoolTexts';

const NordicFieldsCard = ({ isAuditTrail = false }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const cashPool = useSelector(cashPoolSelector);
  const isEdit = history.location.pathname.includes('/edit');
  const { name, currencies, country, leaderId, type, operatingCost, operatingCostMarkup } = cashPool;
  const isNordic = type === NORDIC;

  if (!isNordic) return null;

  return (
    <>
      <Box disabled={isAuditTrail} sx={{ display: 'grid', gridGap: 8, gridTemplateColumns: 'repeat(2, 1fr)' }}>
        <TextInput
          label="Cash Pool Name"
          tooltip="Provide a name identifying the cash pool."
          width="fullWidth"
          value={name}
          onChange={(name: string) => dispatch(updateField({ name }))}
        />
        <Box sx={{ display: 'grid', gridGap: 8, gridTemplateColumns: 'repeat(2, 1fr)' }}>
          <CurrencySingleSelect
            tooltip="Select the currency of the cash pool."
            width="fullWidth"
            disabled={isEdit}
            variant="extended"
            value={currencies}
            onChange={(currencies: CurrencyValueType) => dispatch(updateField({ currencies }))}
          />
          <CountrySingleSelect
            tooltip="Select the country in which the cash pool is based."
            width="fullWidth"
            value={country}
            disabled={isEdit}
            onChange={(country: string) => dispatch(updateField({ country }))}
          />
        </Box>
      </Box>
      <Box disabled={isAuditTrail} sx={{ display: 'grid', gridGap: 8, gridTemplateColumns: 'repeat(2, 1fr)' }}>
        <CompanySingleSelect
          label="Cash Pool Leader"
          tooltip="Select the entity that administers the cash pool."
          width="fullWidth"
          disabled={isEdit}
          value={leaderId}
          onChange={({ id }: { id: number }) => dispatch(updateField({ leaderId: id }))}
          error={undefined}
          sx={undefined}
          dataTestId={undefined}
        />
        <Box sx={{ display: 'grid', gridGap: 8, gridTemplateColumns: 'repeat(2, 1fr)' }}>
          <NumberInput
            label="Operating Expenses"
            inputType="float"
            tooltip={cashPoolTexts.tooltips.operatingExpenses}
            width="fullWidth"
            value={operatingCost}
            onChange={(operatingCost: number) => dispatch(updateField({ operatingCost }))}
          />
          <NumberInput
            label="Operating Expenses Markup"
            inputType="float"
            tooltip="Input the percentage markup to remunerate routine and administrative functions of operating the cash pool."
            width="fullWidth"
            unit="%"
            value={operatingCostMarkup}
            onChange={(operatingCostMarkup: number) => dispatch(updateField({ operatingCostMarkup }))}
          />
        </Box>
      </Box>
    </>
  );
};

export default NordicFieldsCard;
