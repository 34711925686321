import isAfter from 'date-fns/isAfter';

import { pricingApproachEnum, pricingApproachInTemplatesEnum } from '~/enums';

export function getReportUnit(rateType, isShort) {
  const basisPoints = isShort ? ' bps' : ' basis points';
  if (rateType) {
    return rateType.type === 'fixed' ? '%' : basisPoints;
  } else {
    return '%';
  }
}

// return true if it was calculated / false if it was imported
export function hasPoints(report) {
  if (!report) return false;
  return 'lowerBound' in report && 'midPoint' in report && 'upperBound' in report;
}

export const checkIsImported = (report, attributes, isCreditRatingReport) => {
  if (isCreditRatingReport) return !attributes;
  return !hasPoints(report);
};

// reportType => loan, guarantee or credit rating
export const getUpperCaseReportType = (reportType) => reportType.toUpperCase().replace(' ', '_');

export const getReportUrlType = (action) => {
  if (action.includes('LOAN')) return 'loan';
  if (action.includes('GUARANTEE')) return 'guarantee';
  if (action.includes('CREDIT_RATING')) return 'credit%20rating';
};

export const areRublesDiscontinued = ({ date, currency }) => {
  const discontinuedDate = new Date('2022-03-14');
  return isAfter(new Date(date), discontinuedDate) && currency === 'RUB';
};

export const getPricingApproachFromTemplates = (pricingApproachInTemplate) => {
  if (pricingApproachInTemplate === pricingApproachInTemplatesEnum.IMPLICIT) return pricingApproachEnum.IMPLICIT;
  if (pricingApproachInTemplate === pricingApproachInTemplatesEnum.STANDALONE) return pricingApproachEnum.STANDALONE;

  throw new Error('Unsupported pricing approach in template');
};

export const calculateRequiredRateOfReturn = ({ riskFreeRate, beta, equityRiskPremium }) => {
  return riskFreeRate + beta * equityRiskPremium;
};

export const calculateExpectedLoss = ({ probabilityOfDefault, lossGivenDefault }) => {
  return probabilityOfDefault * lossGivenDefault;
};

export const getLossGivenDefault = (seniority, industry) => {
  if (seniority === 'Senior Secured') {
    return 0.15;
  }

  if (seniority === 'Unsubordinated') {
    if (industry === 'Financials') {
      return 0.45;
    }
    return 0.4;
  }

  return 0.75;
};

export const getProbabilityOfDefault = (company, pricingApproach) => {
  return pricingApproach.includes('stand-alone')
    ? company?.creditRating?.probabilityOfDefault
    : company?.creditRating?.probabilityOfDefaultAdj;
};

export const getCumulativeProbabilityOfDefault = (company, tenor, pricingApproach) => {
  const probabilityOfDefault = getProbabilityOfDefault(company, pricingApproach);

  const cumulativeProbabilityOfDefault = 1 - Math.pow(1 - probabilityOfDefault / 100, tenor);
  return cumulativeProbabilityOfDefault;
};
