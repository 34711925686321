import { TextWithTooltip } from 'components/Shared';
import { FlexLayout, Text } from 'ui';

type WithLabelPropsType = {
  onChangeHandler?: Function; // CreatableSingleSelectOnly
  formatCreateLabel?: Function; // CreatableSingleSelectOnly
  formatOptionLabel?: Function; // CreatableSingleSelectOnly
  bg?: string;
  error?: any;
  helperText?: string;
  label?: string;
  rightSideLabelComponent?: string | React.ReactNode;
  sx?: any;
  tooltip?: string | false;
  dataTestId?: string;
  accept?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  width?: string;
  height?: string;
  options?: {
    label: string;
    value: any; // any because of CompanySingleSelect
  }[];
  allowNegatives?: boolean;
  value?: string | number | null;
  placeholder?: string;
  inputType?: 'int' | 'float';
  isPercentage?: boolean;
  isLoading?: boolean;
  unit?: string;
  withLock?: boolean;
  isLocked?: boolean;
  rightSideComponent?: React.ReactNode | false;
  isShowing?: boolean;
  values?: Array<string>;
  onChange?: Function;
  isActive?: boolean;
};

/** `isShowing` used to prevent overlap. Helper text will show only if there is no error. */
const UnderInputText = ({
  color,
  text,
  isShowing,
}: {
  isShowing: boolean;
  color: 'pomegranate' | 'deep-sapphire';
  text: any;
}) => {
  if (!isShowing) return null;

  return (
    <Text color={color} variant="xs-spaced-italic" sx={{ position: 'absolute', bottom: '-16px', whiteSpace: 'nowrap' }}>
      {text}
    </Text>
  );
};

function withLabel(WrappedComponent: React.ComponentType<any>) {
  function WithLabel({
    error,
    helperText,
    label,
    rightSideLabelComponent,
    sx,
    tooltip,
    isShowing = true,
    ...rest
  }: WithLabelPropsType) {
    if (!isShowing) return null;
    const randomString = (Math.random() + 1).toString(36).substring(2, 12);

    const textWithTooltip = (
      <TextWithTooltip
        color="bali-hai"
        label={label}
        shouldTruncate
        tooltip={tooltip}
        variant="m-spaced"
        isShowing={!!label}
        id={`${label}-${randomString}`}
      />
    );

    return (
      <FlexLayout flexDirection="column" sx={{ position: 'relative', ...sx }}>
        <FlexLayout flexDirection="column" space={2}>
          {rightSideLabelComponent ? (
            <FlexLayout justifyContent="space-between">
              {textWithTooltip}
              {rightSideLabelComponent}
            </FlexLayout>
          ) : (
            textWithTooltip
          )}
          <WrappedComponent {...rest} error={error} />
        </FlexLayout>
        <UnderInputText color="deep-sapphire" text={helperText} isShowing={!!helperText && !error} />
        <UnderInputText color="pomegranate" text={error} isShowing={!!error} />
      </FlexLayout>
    );
  }

  return WithLabel;
}

export default withLabel;
