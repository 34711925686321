import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { reportEnum } from '~/enums';
import {
  cashPoolPaymentColumns,
  updateColumns as updateCashPoolPaymentColumns,
} from '~/reducers/tableColumns/cashPoolPaymentColumns.slice';
import {
  floatBalloonPaymentColumns,
  updateColumns as updateFloatBalloonPaymentColumns,
} from '~/reducers/tableColumns/floatBalloonPaymentColumns.slice';
import {
  guaranteePaymentColumns,
  updateColumns as updateGuaranteePaymentColumns,
} from '~/reducers/tableColumns/guaranteePaymentColumns.slice';
import {
  loanPaymentColumns,
  updateColumns as updateLoanPaymentColumns,
} from '~/reducers/tableColumns/loanPaymentColumns.slice';
import {
  withholdingTaxPaymentColumns,
  updateColumns as updateWithholdingPaymentColumns,
} from 'reducers/tableColumns/withholdingTaxPaymentsColumns.slice';
import { Button, FlexLayout, Modal } from '~/ui';

const typeToActionMapper = {
  [reportEnum.LOAN]: {
    reportColumns: loanPaymentColumns,
    updateColumns: updateLoanPaymentColumns,
  },
  [reportEnum.GUARANTEE]: {
    reportColumns: guaranteePaymentColumns,
    updateColumns: updateGuaranteePaymentColumns,
  },
  [reportEnum.CASH_POOL]: {
    reportColumns: cashPoolPaymentColumns,
    updateColumns: updateCashPoolPaymentColumns,
  },
  [reportEnum.WITHHOLDING_TAX]: {
    reportColumns: withholdingTaxPaymentColumns,
    updateColumns: updateWithholdingPaymentColumns,
  },
  floatBalloon: { reportColumns: floatBalloonPaymentColumns, updateColumns: updateFloatBalloonPaymentColumns },
};

function FilterPaymentColumnsModal({ reportType = 'loan', onHide, isShowing }) {
  const dispatch = useDispatch();
  const columns = useSelector(typeToActionMapper[reportType].reportColumns);
  const [columnsCopy, setColumnsCopy] = useState({ ...columns });

  function handleOnSelectClick() {
    const updateColumns = typeToActionMapper[reportType].updateColumns;
    dispatch(updateColumns(columnsCopy));
    onHide();
  }

  useEffect(() => {
    setColumnsCopy(columns);
  }, [reportType, columns]);

  if (!isShowing) return null;

  return (
    <Modal
      actionButtons={<Button text="Select" onClick={handleOnSelectClick} />}
      title="Add or remove columns."
      width="s"
      onHide={onHide}
    >
      <FlexLayout alignItems="center" flexWrap="wrap" justifyContent="flex-start" space={4} sx={{ mt: -4 }}>
        {Object.keys(columnsCopy).map((column) => (
          <Button
            key={column}
            size="s"
            sx={{ mt: 4 }}
            text={column}
            variant={columnsCopy[column] ? 'primary' : 'gray'}
            onClick={() => setColumnsCopy((_state) => ({ ..._state, [column]: !_state[column] }))}
          />
        ))}
      </FlexLayout>
    </Modal>
  );
}

export default FilterPaymentColumnsModal;
