import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  'Cash Pool': true,
  Creditor: true,
  Debtor: true,
  'Date Range': true,
  Currency: true,
  'Interest Payment': true,
  'Rate Type': false,
  'Standalone Rate': false,
  'Payment Due': true,
  Status: true,
  'Interest Added': false,
};

export const cashPoolPaymentColumnsSlice = createSlice({
  name: 'cashPoolPaymentColumns',
  initialState,
  reducers: {
    resetForm: () => {
      return initialState;
    },
    setColumns: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateColumns: (_state, action) => {
      return action.payload;
    },
  },
});

export default cashPoolPaymentColumnsSlice.reducer;

export const { resetForm, setColumns, updateColumns } = cashPoolPaymentColumnsSlice.actions;

export const cashPoolPaymentColumns = (state) => state.cashPoolPaymentColumns;
