import _ from 'lodash';
import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { allCurrencies } from '~/components/Shared/CurrencySingleSelect';
import { UserInfoContext } from '~/context/user';
import { NOTIONAL } from '~/enums';
import { cashPoolSelector, updateField } from '~/reducers/cashPool.slice';
import {
  cashPoolTopCurrencyAccountSelector,
  resetAccounts,
  updateAccounts,
} from '~/reducers/cashPoolTopCurrencyAccount.slice';
import { Button, FileInput, FlexLayout } from '~/ui';
import { showErrorToast, showToast } from '~/ui/components/Toast';
import { jsonToSheet, sheetToJson } from '~/utils/documents';

import { getSheetData } from '.';

const getValidationErrorMessages = (isFixed) => {
  const word = isFixed ? 'rates' : 'spread';
  return {
    allParticipantsMustHaveCirAndDir: `All included participants must have credit and debit interest ${word}.`,
    cirPositiveNumber: `Credit interest ${word} must be valid positive numbers.`,
    dirPositiveNumber: `Debit interest ${word} must be valid positive numbers.`,
    validNumbers: `Credit and debit interest ${word} must be valid numbers.`,
    validThreeLetterCurrencyNotation: 'Use a valid three letter currency notation.',
  };
};

const ExportImportButtons = ({ inTableParticipants, companies, isFixed }) => {
  const dispatch = useDispatch();
  const { type } = useSelector(cashPoolSelector);
  const { accounts } = useSelector(cashPoolTopCurrencyAccountSelector);
  const [isUploading, setIsUploading] = useState(false);
  const { userInfo } = useContext(UserInfoContext);
  const validationErrorMessages = getValidationErrorMessages(isFixed);
  const isNotional = type === NOTIONAL;

  const onDownloadTemplateClick = async () => {
    jsonToSheet(
      getSheetData({ inTableParticipants, isNotional, isFixed, userInfo }),
      'Cash_Pool_Participants_Template.xlsx'
    );
  };

  const checkUploadedData = (chosenCompany) => {
    const creditInterestRate = chosenCompany[6];
    const debitInterestRate = chosenCompany[7];
    const currency = chosenCompany[8];

    if ((!creditInterestRate && creditInterestRate !== 0) || (!debitInterestRate && debitInterestRate !== 0)) {
      throw new Error(validationErrorMessages.allParticipantsMustHaveCirAndDir);
    }

    if (typeof creditInterestRate === 'string' || typeof debitInterestRate === 'string') {
      throw new Error(validationErrorMessages.validNumbers);
    }

    if (typeof creditInterestRate === 'number' && creditInterestRate < 0 && isFixed) {
      throw new Error(validationErrorMessages.cirPositiveNumber);
    }
    if (typeof debitInterestRate === 'number' && debitInterestRate < 0 && isFixed) {
      throw new Error(validationErrorMessages.dirPositiveNumber);
    }

    if (isNotional) {
      const allShortCurrencies = _.flatten(allCurrencies.short.map(({ options }) => options)).map(({ value }) => value);
      if (![undefined, '', ...allShortCurrencies].includes(currency)) {
        throw new Error(validationErrorMessages.validThreeLetterCurrencyNotation);
      }
    }
  };

  const onUploadTemplateClick = (document) => {
    setIsUploading(true);
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result);
      sheetToJson(data)
        .then((jsonSheet) => {
          jsonSheet.shift(); // remove header row
          const chosenCompanies = jsonSheet.filter((row) => {
            if (typeof row[0] !== 'string' || !['yes', 'no'].includes(row[0].toLowerCase())) {
              throw new Error("First column values must be either 'Yes' or 'No'.");
            }
            return row[0].toLowerCase() === 'yes';
          });
          const participantsToUpdate = [];
          for (const company of companies) {
            for (const chosenCompany of chosenCompanies) {
              if (chosenCompany[1] === company.name) {
                checkUploadedData(chosenCompany);
                participantsToUpdate.push({
                  companyId: company.id,
                  creditInterestRate: chosenCompany[6],
                  debitInterestRate: chosenCompany[7],
                  generateInterestStatementData: chosenCompany[8].toLowerCase() === 'yes' ? true : false,
                  currency: chosenCompany[9],
                  value: true,
                });
              }
            }
          }
          dispatch(resetAccounts());
          updateField({ isDirty: true });
          for (const updatedParticipant of participantsToUpdate) {
            const account = accounts.find((a) => a.companyId === updatedParticipant.companyId);
            dispatch(updateAccounts({ ...account, ...updatedParticipant }));
          }
          showToast('Participants successfully uploaded.');
        })
        .catch((err) => {
          if (err.message) {
            return showErrorToast(err.message);
          }
          showErrorToast();
        })
        .finally(() => setIsUploading(false));
    };

    reader.onerror = function (e) {
      setIsUploading(false);
      showErrorToast();
    };

    reader.readAsArrayBuffer(document);
  };

  return (
    <FlexLayout alignItems="center" space={6}>
      <Button
        iconLeft="download"
        size="s"
        text="Download template"
        variant="secondary"
        onClick={onDownloadTemplateClick}
      />
      <FileInput accept=".xls, .xlsx" sx={{ alignSelf: 'center' }} onChange={onUploadTemplateClick}>
        <Button iconLeft="upload" loading={isUploading} size="s" text="Upload template" variant="secondary" />
      </FileInput>
    </FlexLayout>
  );
};

export default ExportImportButtons;
