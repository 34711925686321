import React from 'react';
import { Dispatch } from 'redux';
import { History } from 'history';

import { isAdmin, REPORT_TYPE, reportEnum } from 'enums';
import { ThreeDotActionMenu } from 'components/Shared';
import { showErrorToast } from 'ui/components/Toast';
import { getUpperCaseReportType } from 'utils/report';
import { setNotification } from 'reducers/notifications.slice';
import { updateField } from 'reducers/payment.slice';
import { routesEnum } from 'routes';
import { LoanTableItemType, GuaranteeTableItemType, ReportTypeType, RoleType, CreditRatingTableItemType } from 'types';
import { getCreditRating, getCreditRatingFile } from 'api';
import { saveAs } from 'file-saver';

type AllActionsPropsType = {
  item: LoanTableItemType | GuaranteeTableItemType | CreditRatingTableItemType;
  reportType: ReportTypeType;
  setShowRestoreModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedItem: React.Dispatch<
    React.SetStateAction<LoanTableItemType | GuaranteeTableItemType | CreditRatingTableItemType>
  >;
  history: History;
  role: RoleType;
  dispatch: Dispatch;
};

type AnalysesActionsPropsType = Omit<AllActionsPropsType, 'setShowRestoreModal' | 'role' | 'dispatch'>;

type PortfolioActionsPropsType = Omit<AllActionsPropsType, 'setShowRestoreModal'>;

type TrashActionsPropsType = Omit<AllActionsPropsType, 'history'>;

type CommonActionsPropsType = Pick<AllActionsPropsType, 'item' | 'reportType'>;

type RenderTableActionColumnPropsType = AllActionsPropsType & {
  isAnalyses: boolean;
  isPortfolio: boolean;
  isTrash: boolean;
};

const getCommonActions = ({ item, reportType }: CommonActionsPropsType) => {
  const options = [];
  if (reportType === reportEnum.CREDIT_RATING) {
    const { isTemplateUploaded } = item as CreditRatingTableItemType;
    if (isTemplateUploaded)
      options.push({
        label: 'Download template',
        onClick: async () => {
          const creditRating = await getCreditRating(item.id);
          if (creditRating?.files[1] == null) return showErrorToast('Template file is missing.');

          const uploadedTemplateFile = await getCreditRatingFile({ fileId: creditRating.files[1]?.id });
          saveAs(uploadedTemplateFile, uploadedTemplateFile.name);
        },
      });
  }

  return options;
};

const getPortfolioActions = ({
  item,
  reportType,
  setShowDeleteModal,
  setSelectedItem,
  history,
  role,
  dispatch,
}: PortfolioActionsPropsType) => {
  const options = [];

  if (item.status === 'Final' && reportType !== reportEnum.CREDIT_RATING) {
    options.push({
      label: 'Show all payments',
      onClick: () => {
        dispatch(updateField({ [`${reportType}Id`]: item.id }));
        history.push(`${routesEnum.PAYMENTS}?${REPORT_TYPE}=${reportType}`);
      },
    });
  }

  if (isAdmin(role)) {
    options.push({
      label: 'Delete',
      onClick: () => {
        setShowDeleteModal(true);
        setSelectedItem(item);
      },
    });
  } else {
    const upperCaseReportType = getUpperCaseReportType(reportType);

    if (item.status === 'Final') {
      options.push({
        label: 'Notify admin to mark as Draft',
        onClick: () =>
          dispatch(
            setNotification({
              action: `MARK_${upperCaseReportType}_AS_DRAFT`,
              title: 'Notify admin to mark as Draft',
              id: item.id,
            })
          ),
      });
    }

    if (item.status === 'Draft') {
      options.push({
        label: 'Notify admin to move to analyses',
        onClick: () =>
          dispatch(
            setNotification({
              action: `MOVE_${upperCaseReportType}_TO_ANALYSES`,
              title: 'Notify admin to move to analyses',
              id: item.id,
            })
          ),
      });
    }

    options.push({
      label: 'Notify admin to delete',
      onClick: () =>
        dispatch(
          setNotification({
            action: `DELETE_${upperCaseReportType}`,
            title: `Notify admin to delete ${reportType}`,
            id: item.id,
          })
        ),
    });
  }

  return options;
};

const getTrashActions = ({
  item,
  reportType,
  setShowRestoreModal,
  setShowDeleteModal,
  setSelectedItem,
  role,
  dispatch,
}: TrashActionsPropsType) => {
  const options = [];

  if (isAdmin(role)) {
    options.push({
      label: 'Restore',
      onClick: () => {
        setShowRestoreModal(true);
        setSelectedItem(item);
      },
    });
    options.push({
      label: 'Delete permanently',
      onClick: () => {
        setShowDeleteModal(true);
        setSelectedItem(item);
      },
    });
  } else {
    const upperCaseReportType = getUpperCaseReportType(reportType);
    options.push({
      label: 'Notify admin to restore',
      onClick: () =>
        dispatch(
          setNotification({
            action: `RESTORE_${upperCaseReportType}`,
            title: `Notify admin to restore ${reportType}`,
            id: item.id,
          })
        ),
    });
    options.push({
      label: 'Notify admin to permanently delete',
      onClick: () =>
        dispatch(
          setNotification({
            action: `PERMANENT_DELETE_${upperCaseReportType}`,
            title: `Notify admin to permanently delete ${reportType}`,
            id: item.id,
          })
        ),
    });
  }

  return options;
};

const getAnalysesActions = ({
  item,
  reportType,
  setShowDeleteModal,
  setSelectedItem,
  history,
}: AnalysesActionsPropsType) => {
  const options = [];

  /**
   * Show edit only to guarantees that have `principalCumulativeProbabilityOfDefault` and
   * `guarantorName` (makes sure it's guarantee and not loan or credit rating). The or part is to
   * make it true for loans and credit ratings.
   */
  const guaranteeItem = item as GuaranteeTableItemType;
  const isGuarantee = !!guaranteeItem.guarantorName;
  const hasGuaranteeCumulativeProbabilityOfDefault =
    (guaranteeItem.principalCumulativeProbabilityOfDefault != null && isGuarantee) || !isGuarantee;

  if (item.editable && item.status === 'Draft' && hasGuaranteeCumulativeProbabilityOfDefault) {
    options.push({
      label: 'Edit',
      onClick: () => history.push(`${history.location.pathname}/${item.id}/edit?${REPORT_TYPE}=${reportType}`),
    });
  }

  options.push({
    label: 'Delete',
    onClick: () => {
      setShowDeleteModal(true);
      setSelectedItem(item);
    },
  });

  return options;
};

export const renderTableActionColumn = ({
  item,
  reportType,
  setShowDeleteModal,
  setShowRestoreModal,
  setSelectedItem,
  history,
  role,
  dispatch,
  isAnalyses,
  isPortfolio,
  isTrash,
}: RenderTableActionColumnPropsType) => {
  const options = [];
  if (isPortfolio) {
    options.push(
      ...getPortfolioActions({ item, reportType, setShowDeleteModal, setSelectedItem, history, role, dispatch })
    );
  }
  if (isTrash) {
    options.push(
      ...getTrashActions({
        item,
        reportType,
        setShowRestoreModal,
        setShowDeleteModal,
        setSelectedItem,
        role,
        dispatch,
      })
    );
  }
  if (isAnalyses) {
    options.push(...getAnalysesActions({ item, reportType, setShowDeleteModal, setSelectedItem, history }));
  }

  options.push(...getCommonActions({ item, reportType }));

  if (options.length === 0) return null;

  return <ThreeDotActionMenu options={options} />;
};
