import { useState } from 'react';

import { ActionMenu, Box, Button } from 'ui';

type ButtonActionMenuProps = {
  options: { label: string; onClick: Function }[];
  buttonText: string;
  dataTestId?: string;
};

function ButtonActionMenu({ options, buttonText, dataTestId }: ButtonActionMenuProps) {
  const [visible, setVisible] = useState(false);

  return (
    <ActionMenu options={options} visible={visible} setVisible={setVisible}>
      <Box
        dataTestId={dataTestId}
        bg={visible ? 'link-water' : 'transparent'}
        px={0}
        py={0}
        sx={{ borderRadius: 's' }}
        onClick={() => setVisible(!visible)}
      >
        <Button
          variant="secondary"
          size="s"
          onClick={() => {}}
          text={buttonText}
          iconRight={visible ? 'chevronUp' : 'chevronDown'}
        />
      </Box>
    </ActionMenu>
  );
}

export default ButtonActionMenu;
