import { showErrorToast } from '~/ui/components/Toast';

export const errorHandler = (err) => {
  if (err?.response?.data?.statusCode < 500) {
    /**
     * This if is to allow easier developing since it shows joi errors instead of crashing.
     * They shouldn't be shown in production because joi validation should pass correctly.
     */
    if (err.response.data.name === 'ValidationError') {
      const { message, path } = err.response.data.payload[0];
      return showErrorToast(`${path[0]} is ${message.toLowerCase()}.`);
    }

    if (typeof err.response.data.message === 'object') {
      return showErrorToast(Object.values(err.response.data.message)[0]?.message);
    }
    return showErrorToast(err.response.data.message);
  }
  if (err?.response?.data?.statusCode >= 500) return showErrorToast();
  if (err?.message) return showErrorToast(err.message);

  showErrorToast();
};

export const fullResponseErrorHandler = (err) => {
  err?.response?.data?.text().then((stringifiedData) => {
    const data = JSON.parse(stringifiedData);

    if (data.statusCode >= 500) {
      return showErrorToast();
    }
    if (data?.message) {
      return showErrorToast(data.message);
    }
    showErrorToast();
  });
};
