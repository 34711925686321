import { SingleSelect } from '~/ui';
import { getOptionsFromArray } from '~/utils/arrays';

export const seniorities = ['Senior Secured', 'Unsubordinated', 'Subordinated'];

const options = getOptionsFromArray(seniorities);

function SenioritySingleSelect({
  isShowing = true,
  label = 'Seniority',
  tooltip,
  dataTestId,
  value,
  width = 'm',
  onChange,
}) {
  return (
    <SingleSelect
      label={label}
      options={options}
      tooltip={tooltip}
      dataTestId={dataTestId}
      isShowing={isShowing}
      width={width}
      value={value}
      onChange={onChange}
    />
  );
}

export default SenioritySingleSelect;
