import React, { useContext } from 'react';

import { UserInfoContext } from '~/context/user';
import { Table } from '~/ui';
import { ReportsCard } from '~/components/Shared';
import { getParticipantsColumns, getParticipantsData } from './AuditTrailParticipantsTable.utils';

const AuditTrailParticipantsTable = ({ participants, isNotional = false }) => {
  const { userInfo } = useContext(UserInfoContext);

  return (
    <ReportsCard
      title="Cash Pool Participants"
      table={
        <Table
          columns={getParticipantsColumns(isNotional)}
          data={getParticipantsData(participants, userInfo)}
          isSearchable
        />
      }
    />
  );
};

export default AuditTrailParticipantsTable;
