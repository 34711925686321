import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createNotification } from '~/api';
import { UserInfoContext } from '~/context/user';
import { notificationSelector, resetNotification, setIsSubmitting, updateField } from '~/reducers/notifications.slice';
import { routesEnum } from '~/routes';
import { Button, CheckboxGroup, Modal, Text, Textarea } from '~/ui';
import { showToast, showErrorToast } from '~/ui/components/Toast';
import { getReportUrlType } from '~/utils/report';

const getOptions = (admins) => admins?.map(({ id, fullName }) => ({ label: fullName, value: String(id) }));

const getUrl = (id, action) => {
  const companyAuditTrailRegex = new RegExp(`${routesEnum.GROUP_INFORMATION}/[0-9]+/audit-trail`);
  if (window.location.pathname.match(companyAuditTrailRegex)) {
    const companyId = window.location.pathname.split('/')[2];
    return `${window.location.origin}${routesEnum.GROUP_INFORMATION}/${companyId}/audit-trail/${id}`;
  }

  const cashPoolAuditTrailRegex = new RegExp(`${routesEnum.CASH_POOLS}/[0-9]+/audit-trail`);
  if (window.location.pathname.match(cashPoolAuditTrailRegex)) {
    const cashPoolId = window.location.pathname.split('/')[2];
    return `${window.location.origin}${routesEnum.CASH_POOLS}/${cashPoolId}/audit-trail/${id}`;
  }

  if (window.location.pathname.includes(routesEnum.GROUP_INFORMATION)) {
    return `${window.location.origin}${routesEnum.GROUP_INFORMATION}/${id}`;
  }

  if (window.location.pathname.includes(`${routesEnum.PORTFOLIO}`)) {
    return `${window.location.origin}${routesEnum.PORTFOLIO}/${id}?reportType=${getReportUrlType(action)}`;
  }

  if (window.location.pathname.includes(`${routesEnum.ANALYSES}`)) {
    return `${window.location.origin}${routesEnum.ANALYSES}/${id}?reportType=${getReportUrlType(action)}`;
  }

  if (window.location.pathname.includes(`${routesEnum.TRASH}`)) {
    return `${window.location.origin}${routesEnum.TRASH}/${id}?reportType=${getReportUrlType(action)}`;
  }
  throw new Error('Unsupported notification url');
};

const NotifyAdminModal = () => {
  const dispatch = useDispatch();
  const { id, action, isSubmitting, title, adminIds, note } = useSelector(notificationSelector);
  const { admins } = useContext(UserInfoContext);

  const onNotifyClick = async () => {
    if (!adminIds?.length) {
      return showErrorToast('Choose at least one admin to notify');
    }

    dispatch(setIsSubmitting(true));

    const data = { note, action, adminIds, url: getUrl(id, action) };

    await createNotification(data)
      .then(() => showToast('Successfully notified'))
      .catch(() => showErrorToast());

    dispatch(resetNotification());
  };

  if (!title) return null;

  return (
    <Modal
      actionButtons={
        <Button text="Notify admin" loading={isSubmitting} disabled={isSubmitting} onClick={onNotifyClick} />
      }
      onHide={() => dispatch(resetNotification())}
    >
      <Text color="deep-sapphire" variant="xl-spaced-bold">
        {title}
      </Text>
      <CheckboxGroup
        options={getOptions(admins)}
        values={adminIds}
        onChange={(adminIds) => dispatch(updateField({ adminIds }))}
        variant="row"
        checkboxVariant="outlined"
      />
      <Textarea
        value={note}
        placeholder="Write a note"
        onChange={(note) => dispatch(updateField({ note }))}
        sx={{ width: '100%' }}
      />
    </Modal>
  );
};
export default NotifyAdminModal;
