import { IconWithTooltip } from 'components/Shared';
import { Checkbox, FlexLayout } from 'ui';
import { withLabel } from 'ui/hocs';

import { variants as checkboxVariants } from 'ui/components/Checkbox/Checkbox';

const variants = Object.freeze({
  row: Object.freeze({
    flexDirection: 'row',
    flexWrap: 'wrap',
    space: 4,
  }),
  column: Object.freeze({
    flexDirection: 'column',
    space: 4,
  }),
});

type CheckboxGroupPropsType = {
  variant?: keyof typeof variants;
  checkboxVariant?: keyof typeof checkboxVariants;
  options: Array<{ value: string; label: string; tooltip?: string; disabled?: boolean }>;
  values: Array<string>;
  onChange: Function;
};

function CheckboxGroup({
  options = [],
  values = [],
  onChange,
  variant = 'column',
  checkboxVariant = 'regular',
}: CheckboxGroupPropsType) {
  function handleUpdateValues(activeValue: string) {
    const updatedValues = values.includes(activeValue)
      ? values.filter((v) => v !== activeValue)
      : [...values, activeValue];
    onChange(updatedValues);
  }

  return (
    <FlexLayout {...variants[variant]}>
      {options.map(({ label, value, tooltip, disabled }) => (
        <FlexLayout space={2} alignItems="center" key={`checkbox-${value}`}>
          <Checkbox
            disabled={disabled}
            isActive={values.includes(value)}
            label={label}
            onChange={() => handleUpdateValues(value)}
            variant={checkboxVariant}
          />
          {tooltip && <IconWithTooltip tooltip={tooltip} />}
        </FlexLayout>
      ))}
    </FlexLayout>
  );
}

export default withLabel(CheckboxGroup);
