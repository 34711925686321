import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import InlineSvg from 'react-inlinesvg';

import logoSrc from '~/assets/images/logo.svg';
import { UserInfoContext } from '~/context/user';
import { allPages, routesEnum } from '~/routes';
import { Box, FlexLayout } from '~/ui';

import SidebarItem from './SidebarItem';
import UserMenu from './UserMenu';

/**
 * Possible additional modules in sidebar at the time of documenting.
 * CREDIT_RATING, PAYMENTS, CASH_POOLS
 * Column `path` in Features table is same as path of module so that needs to match.
 */
const sharedModules = [
  routesEnum.DASHBOARD,
  routesEnum.GROUP_INFORMATION,
  routesEnum.PRICE_AND_BENCHMARK,
  routesEnum.ANALYSES,
  routesEnum.PORTFOLIO,
];

function Sidebar() {
  const history = useHistory();
  const { userInfo } = useContext(UserInfoContext);
  const [sidebarItems, setSidebarItems] = useState(sharedModules);

  useEffect(() => {
    if (!userInfo) return;

    const clientModules = userInfo?.client?.clientFeatures?.filter((cl) => cl.feature.isModule);
    const additionalModules = clientModules?.map((clientModule) => clientModule.feature.path) || [];
    const priorityOrder = {
      [routesEnum.CREDIT_RATING]: 1,
      [routesEnum.PAYMENTS]: 2,
      [routesEnum.CASH_POOLS]: 3,
      [routesEnum.CUFT_DATA]: 4,
      default: Number.MAX_SAFE_INTEGER,
    };
    additionalModules.sort(
      (a, b) =>
        (priorityOrder[a] || priorityOrder.default) - (priorityOrder[b] || priorityOrder.default) || a > b || -(a < b)
    );

    setSidebarItems([...sharedModules, ...additionalModules]);
  }, [userInfo]);

  return (
    <FlexLayout bg="deep-sapphire" flexDirection="column" flexShrink="0" space={8} sx={{ width: 'sidebar-width' }}>
      <Box ml={6} mt={6} sx={{ flexShrink: '0' }} onClick={() => history.push('/')}>
        <InlineSvg src={logoSrc} style={{ marginLeft: '-8px' }} />
      </Box>
      <FlexLayout flexDirection="column" flexGrow="1" flexShrink="0" space={2}>
        {sidebarItems.map((routeKey) => {
          const routeData = allPages[routeKey];
          return routeData ? <SidebarItem key={routeKey} {...routeData} /> : null;
        })}
      </FlexLayout>
      <UserMenu />
    </FlexLayout>
  );
}

export default Sidebar;
