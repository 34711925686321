import { useHistory } from 'react-router-dom';

import { FlexLayout, Icon, Text } from 'ui';
import { IconTypes } from 'ui/components/Icon';

type SidebarItemProps = {
  navbarIcon: IconTypes;
  navbarTitle: string;
  path: string;
};

function SidebarItem({ navbarIcon, navbarTitle, path }: SidebarItemProps) {
  const history = useHistory();
  return (
    <FlexLayout
      alignItems="center"
      bg={history?.location?.pathname.startsWith(path) ? 'shakespeare' : 'transparent'}
      mx={4}
      p={2}
      space={2}
      sx={{ borderRadius: 'm', flexWrap: 'nowrap' }}
      onClick={() => history.push(path)}
    >
      <Icon color="white" icon={navbarIcon} size="m" />
      <Text color="white" variant="m-spaced-medium">
        {navbarTitle}
      </Text>
    </FlexLayout>
  );
}

export default SidebarItem;
