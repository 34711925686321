import PT from 'prop-types';
import React from 'react';

import { FlexLayout, Switch } from '~/ui';
import { withLabel } from '~/ui/hocs';

function SwitchGroup({ options = [], values = [], onChange }) {
  function handleUpdateValues(activeValue) {
    const updatedValues = values.includes(activeValue)
      ? values.filter((v) => v !== activeValue)
      : [...values, activeValue];
    onChange(updatedValues);
  }

  return (
    <FlexLayout flexDirection="column" space={4}>
      {options.map(({ label, value }) => (
        <Switch
          isActive={values.includes(value)}
          key={`checkbox-${value}`}
          label={label}
          onChange={() => handleUpdateValues(value)}
        />
      ))}
    </FlexLayout>
  );
}

const optionPropType = PT.shape({
  label: PT.string,
  value: PT.string,
});

SwitchGroup.propTypes = {
  options: PT.arrayOf(optionPropType),
  values: PT.array,
  onChange: PT.func,
};

export default withLabel(SwitchGroup);
