import 'react-datepicker/dist/react-datepicker.css';
import '~/styles/react-datepicker-overrides.css';

import format from 'date-fns/format';
import PT from 'prop-types';
import React, { useContext, useState } from 'react';
import DatePicker from 'react-datepicker';

import { UserInfoContext } from '~/context/user';
import { DATE_FNS_FORMATS } from '~/enums/dates';
import { Box } from '~/ui';
import { withLabel } from '~/ui/hocs';
import { formatDateString } from '~/utils/dates';

import { CustomDateInput, renderCustomHeader, setDate } from '../DateInput/DateInput.utils';

const widths = {
  s: '114px',
  m: '252px',
  l: '536px',
  fullWidth: '100%',
};

function DateRangeInput({
  error,
  maxDate,
  minDate,
  placeholder = '',
  sx = {},
  dateRange,
  width = 'm',
  height,
  onChange,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { userInfo } = useContext(UserInfoContext);
  const { startDate, endDate } = dateRange;

  const getDateRangeValue = () => {
    if (!startDate && !endDate) return '';
    const formattedStartDate = formatDateString(startDate, userInfo.dateFormat) || '';
    const formattedEndDate = formatDateString(endDate, userInfo.dateFormat) || '';
    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  return (
    <Box sx={{ flexShrink: '0', ...sx, border: error ? 'border-error' : null, borderRadius: error ? 'm' : null }}>
      <DatePicker
        customInput={
          <CustomDateInput
            dateRangeValue={getDateRangeValue()}
            placeholder={placeholder}
            width={widths[width]}
            height={height}
          />
        }
        renderCustomHeader={renderCustomHeader}
        open={isOpen}
        onInputClick={() => setIsOpen(true)}
        onClickOutside={() => setIsOpen(false)}
        dropdownMode="select"
        maxDate={setDate(maxDate)}
        minDate={setDate(minDate)}
        selected={setDate(startDate)}
        popperPlacement="bottom-center"
        onChange={([startDate, endDate]) => {
          onChange({
            startDate: format(new Date(startDate), DATE_FNS_FORMATS.ISO),
            endDate: endDate ? format(new Date(endDate), DATE_FNS_FORMATS.ISO) : null,
          });
          if (endDate) setIsOpen(false);
        }}
        dateFormat={DATE_FNS_FORMATS[userInfo.dateFormat]}
        startDate={setDate(startDate)}
        endDate={setDate(endDate)}
        selectsRange
      />
    </Box>
  );
}

DateRangeInput.propTypes = {
  error: PT.string,
  label: PT.string,
  placeholder: PT.string,
  sx: PT.object,
  tooltip: PT.string,
  value: PT.oneOfType([PT.string, PT.object]),
  width: PT.oneOf(Object.keys(widths)),
  onChange: PT.func,
};

export default withLabel(DateRangeInput);
