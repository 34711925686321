import React from 'react';

import { FlexLayout, Text } from '~/ui';

export const assessments = [
  'Central',
  'Nearly Central',
  'Strongly Integral',
  'Integral',
  'Weakly Integral',
  'Peripheral',
];

function EndValue({ text }) {
  return (
    <FlexLayout bg="alabaster" px={4} py={2} sx={{ border: 'border', borderRadius: 'm', width: '106px' }}>
      <Text color="deep-sapphire" sx={{ textAlign: 'center' }} variant="xs-spaced">
        {text}
      </Text>
    </FlexLayout>
  );
}

function AssessmentGraph({ assessment }) {
  if (!assessment?.name) {
    return null;
  }

  return (
    <FlexLayout id="graph" alignItems="center" space={4}>
      <EndValue text="Consolidated Group Rating" />
      <FlexLayout flexDirection="column" space={2}>
        <FlexLayout alignItems="center" space={10}>
          {assessments.map((rating) => {
            const isActive = rating === assessment.name;
            return (
              <FlexLayout key={rating} px={2} sx={{ position: 'relative' }}>
                <Text color={isActive ? 'shakespeare' : 'bali-hai'} sx={{ textAlign: 'center' }} variant="s-spaced">
                  {rating}
                </Text>
                {isActive && (
                  <FlexLayout
                    bg="shakespeare"
                    sx={{
                      borderRadius: 'm',
                      bottom: '-11px',
                      height: '4px',
                      left: 0,
                      position: 'absolute',
                      right: 0,
                    }}
                  />
                )}
              </FlexLayout>
            );
          })}
        </FlexLayout>
        <FlexLayout bg="link-water" sx={{ borderRadius: 's', height: '2px' }} />
      </FlexLayout>
      <EndValue text="Standalone Rating" />
    </FlexLayout>
  );
}

export default AssessmentGraph;
