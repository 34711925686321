export const getSheetData = ({ data, columns, visibleColumns, userInfo, getColumnData }) =>
  data.map((item) => {
    const sheetData = {};
    const loanData = getColumnData(item, userInfo);

    columns.forEach((column) => {
      const label = column.label;
      const value = loanData[column.value];

      if (visibleColumns[label]) {
        sheetData[label] = value;
      }
    });

    return sheetData;
  });
