export enum GENERAL_NOTIFICATION_ACTIONS {
  MARK_AS_DRAFT = 'MARK_AS_DRAFT',
  MARK_AS_FINAL = 'MARK_AS_FINAL',
  MOVE_TO_ANALYSES = 'MOVE_TO_ANALYSES',
  MOVE_TO_PORTFOLIO = 'MOVE_TO_PORTFOLIO',
  DELETE = 'DELETE',
  PERMANENT_DELETE = 'PERMANENT_DELETE',
  RESTORE = 'RESTORE',
}

export enum NOTIFICATION_ACTIONS {
  MOVE_LOAN_TO_ANALYSES = 'MOVE_LOAN_TO_ANALYSES',
  MOVE_LOAN_TO_PORTFOLIO = 'MOVE_LOAN_TO_PORTFOLIO',
  MARK_LOAN_AS_DRAFT = 'MARK_LOAN_AS_DRAFT',
  MARK_LOAN_AS_FINAL = 'MARK_LOAN_AS_FINAL',
  MARK_LOAN_PAYMENT_AS_PAID = 'MARK_LOAN_PAYMENT_AS_PAID',

  MOVE_GUARANTEE_TO_ANALYSES = 'MOVE_GUARANTEE_TO_ANALYSES',
  MOVE_GUARANTEE_TO_PORTFOLIO = 'MOVE_GUARANTEE_TO_PORTFOLIO',
  MARK_GUARANTEE_AS_DRAFT = 'MARK_GUARANTEE_AS_DRAFT',
  MARK_GUARANTEE_AS_FINAL = 'MARK_GUARANTEE_AS_FINAL',
  MARK_GUARANTEE_PAYMENT_AS_PAID = 'MARK_GUARANTEE_PAYMENT_AS_PAID',

  MOVE_CREDIT_RATING_TO_ANALYSES = 'MOVE_CREDIT_RATING_TO_ANALYSES',
  MOVE_CREDIT_RATING_TO_PORTFOLIO = 'MOVE_CREDIT_RATING_TO_PORTFOLIO',
  MARK_CREDIT_RATING_AS_DRAFT = 'MARK_CREDIT_RATING_AS_DRAFT',
  MARK_CREDIT_RATING_AS_FINAL = 'MARK_CREDIT_RATING_AS_FINAL',

  RESTORE_LOAN = 'RESTORE_LOAN',
  RESTORE_GUARANTEE = 'RESTORE_GUARANTEE',
  RESTORE_CREDIT_RATING = 'RESTORE_CREDIT_RATING',

  DELETE_LOAN = 'DELETE_LOAN',
  PERMANENT_DELETE_LOAN = 'PERMANENT_DELETE_LOAN',
  DELETE_GUARANTEE = 'DELETE_GUARANTEE',
  PERMANENT_DELETE_GUARANTEE = 'PERMANENT_DELETE_GUARANTEE',
  DELETE_CREDIT_RATING = 'DELETE_CREDIT_RATING',
  PERMANENT_DELETE_CREDIT_RATING = 'PERMANENT_DELETE_CREDIT_RATING',
  DELETE_COMPANY = 'DELETE_COMPANY',

  DELETE_AUDIT_TRAIL = 'DELETE_AUDIT_TRAIL',

  LOAN_ABOUT_TO_EXPIRE = 'LOAN_ABOUT_TO_EXPIRE',
  GUARANTEE_ABOUT_TO_EXPIRE = 'GUARANTEE_ABOUT_TO_EXPIRE',
}

export const NOTIFICATION_ACTION_TEXT = Object.freeze({
  [NOTIFICATION_ACTIONS.MOVE_LOAN_TO_ANALYSES]: 'requests a loan to be moved to Analyses',
  [NOTIFICATION_ACTIONS.MOVE_LOAN_TO_PORTFOLIO]: 'requests a loan to be moved to Portfolio',
  [NOTIFICATION_ACTIONS.MARK_LOAN_AS_DRAFT]: 'requests a loan to be marked as Draft',
  [NOTIFICATION_ACTIONS.MARK_LOAN_AS_FINAL]: 'requests a loan to be marked as Final',
  [NOTIFICATION_ACTIONS.MARK_LOAN_PAYMENT_AS_PAID]: 'requests a loan payment to be marked as paid',

  [NOTIFICATION_ACTIONS.MOVE_GUARANTEE_TO_ANALYSES]: 'requests a guarantee to be moved to Analyses',
  [NOTIFICATION_ACTIONS.MOVE_GUARANTEE_TO_PORTFOLIO]: 'requests a guarantee to be moved to Portfolio',
  [NOTIFICATION_ACTIONS.MARK_GUARANTEE_AS_DRAFT]: 'requests a guarantee to be marked as Draft',
  [NOTIFICATION_ACTIONS.MARK_GUARANTEE_AS_FINAL]: 'requests a guarantee to be marked as Final',
  [NOTIFICATION_ACTIONS.MARK_GUARANTEE_PAYMENT_AS_PAID]: 'requests a guarantee payment to be marked as paid',

  [NOTIFICATION_ACTIONS.MOVE_CREDIT_RATING_TO_ANALYSES]: 'requests a credit rating to be moved to Analyses',
  [NOTIFICATION_ACTIONS.MOVE_CREDIT_RATING_TO_PORTFOLIO]: 'requests a credit rating to be moved to Portfolio',
  [NOTIFICATION_ACTIONS.MARK_CREDIT_RATING_AS_DRAFT]: 'requests a credit rating to be marked as Draft',
  [NOTIFICATION_ACTIONS.MARK_CREDIT_RATING_AS_FINAL]: 'requests a credit rating to be marked as Final',

  [NOTIFICATION_ACTIONS.RESTORE_LOAN]: 'requests a loan to be restored',
  [NOTIFICATION_ACTIONS.RESTORE_GUARANTEE]: 'requests a guarantee to be restored',
  [NOTIFICATION_ACTIONS.RESTORE_CREDIT_RATING]: 'requests a credit rating to be restored',

  [NOTIFICATION_ACTIONS.DELETE_LOAN]: 'requests a loan to be deleted',
  [NOTIFICATION_ACTIONS.PERMANENT_DELETE_LOAN]: 'requests a loan to be permanently deleted',
  [NOTIFICATION_ACTIONS.DELETE_GUARANTEE]: 'requests a guarantee to be deleted',
  [NOTIFICATION_ACTIONS.PERMANENT_DELETE_GUARANTEE]: 'requests a guarantee to be permanently deleted',
  [NOTIFICATION_ACTIONS.DELETE_CREDIT_RATING]: 'requests a credit rating to be deleted',
  [NOTIFICATION_ACTIONS.PERMANENT_DELETE_CREDIT_RATING]: 'requests a credit rating to be permanently deleted',
  [NOTIFICATION_ACTIONS.DELETE_COMPANY]: 'requests a company to be deleted',

  [NOTIFICATION_ACTIONS.DELETE_AUDIT_TRAIL]: 'requests an audit trail to be deleted',

  [NOTIFICATION_ACTIONS.LOAN_ABOUT_TO_EXPIRE]: 'Loan is about to mature',
  [NOTIFICATION_ACTIONS.GUARANTEE_ABOUT_TO_EXPIRE]: 'Guarantee is about to expire',
});
