import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  Guarantor: true,
  Principal: true,
  Currency: true,
  Amount: true,
  'Issue date': true,
  'Termination date': true,
  'Period (yr.)': false,
  'Pricing approach': false,
  'Guarantor rating standalone': false,
  'Guarantor rating implicit support adj.': false,
  'Principal rating standalone': false,
  'Principal rating implicit support adj.': false,
  'Fee payment frequency': false,
  Created: false,
  Updated: false,
  Status: true,
  'Lower bound': false,
  Base: false,
  'Upper bound': false,
  Deleted: false,
  'Guarantor Type': false,
  Note: false,
};

export const guaranteeColumnsSlice = createSlice({
  name: 'guaranteeColumns',
  initialState: initialState,
  reducers: {
    resetForm: () => {
      return initialState;
    },
    setColumns: (state, action) => {
      return { ...initialState, ...action.payload };
    },
    updateColumns: (state, action) => {
      return action.payload;
    },
  },
});

export default guaranteeColumnsSlice.reducer;

export const { resetForm, setColumns, updateColumns } = guaranteeColumnsSlice.actions;

export const guaranteeColumns = (state) => state.guaranteeColumns;
