import React, { createContext, useEffect, useState } from 'react';

import { getCountries } from 'api';
import { CountryType, CountryValueType } from 'types';

type CountryContextProps = {
  children: React.ReactNode;
};

type CountryDropdownOptionType = { value: CountryValueType; label: string };

type ProviderDataType = {
  countriesByName: Record<CountryValueType, CountryType>;
  countryNames: Array<CountryValueType>;
  countryDropdownOptions: Array<CountryDropdownOptionType>;
};

export const CountryContext = createContext<ProviderDataType>({} as ProviderDataType);

const Country = ({ children }: CountryContextProps) => {
  const [countriesByName, setCountriesByName] = useState<Record<CountryValueType, CountryType>>();
  const [countryNames, setCountryNames] = useState<Array<CountryValueType>>();
  const [countryDropdownOptions, setCountryDropdownOptions] = useState<Array<CountryDropdownOptionType>>([]);

  useEffect(() => {
    getCountries().then((countriesByName) => {
      setCountriesByName(countriesByName);
      setCountryNames(Object.keys(countriesByName) as Array<CountryValueType>);
      setCountryDropdownOptions(
        Object.values(countriesByName).map((c) => ({
          value: c.name as CountryValueType,
          label: `${c.flagEmoji} ${c.name}`,
        }))
      );
    });
  }, []);

  return (
    <CountryContext.Provider value={{ countriesByName, countryNames, countryDropdownOptions } as ProviderDataType}>
      {children}
    </CountryContext.Provider>
  );
};

export default Country;
