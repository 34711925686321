import { backend } from 'services';
import type { GetWHTRatesBody, WHTOriginType, WHTRecipientWithOriginType } from 'types';

import { apiPaths } from './routes';

type GetWhtRatesDefaultResponseType = {
  whtData: WHTOriginType;
  isDefault: true;
};

type GetWhtRatesResponseType = {
  whtData: WHTRecipientWithOriginType;
  isDefault: false;
};

export function getWhtRates(
  data: GetWHTRatesBody
): Promise<GetWhtRatesDefaultResponseType | GetWhtRatesResponseType | null> {
  return backend.post(apiPaths.wht, data);
}
