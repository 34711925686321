import { MouseEventHandler, Ref, useRef } from 'react';

import { Button, FlexLayout, Text } from 'ui';
import { useEnter, useEscape, useOnClickOutside } from 'ui/hooks';

type ModalPropsType = {
  isShowing?: boolean;
  actionButtons?: React.ReactNode;
  children?: React.ReactNode;
  showCancelButton?: boolean;
  title?: string;
  width?: string;
  showButtonsSection?: boolean;
  overflowY?: 'scroll' | 'visible';
  onSubmit?: Function;
  onHide: MouseEventHandler<HTMLButtonElement> | React.Dispatch<React.SetStateAction<boolean>>;
};

const widths: Record<string, string> = { s: '600px', m: '900px' };

function Modal({
  isShowing = true,
  actionButtons,
  children,
  showCancelButton = true,
  title,
  width = 'm',
  showButtonsSection = true,
  overflowY = 'visible',
  onSubmit,
  onHide,
}: ModalPropsType) {
  const ref = useRef();
  useOnClickOutside(ref, onHide);
  useEnter(onSubmit);
  useEscape(onHide);

  if (!isShowing) return null;

  return (
    <FlexLayout
      alignItems="center"
      bg="rgba(18, 36, 108, 0.3)"
      justifyContent="center"
      sx={{ bottom: 0, left: 0, position: 'absolute', right: 0, top: 0, height: '100%', zIndex: 'modal' }}
    >
      <FlexLayout
        bg="white"
        flexDirection="column"
        p={8}
        ref={ref as unknown as Ref<HTMLElement> | undefined}
        space={6}
        sx={{ borderRadius: 'm', height: 'fit-content', width: widths[width] || width, maxHeight: '90vh', overflowY }}
      >
        {title && (
          <Text color="deep-sapphire" variant="xl-spaced-bold">
            {title}
          </Text>
        )}
        {children}
        {showButtonsSection && (
          <FlexLayout alignItems="center" pt={6} sx={{ borderTop: 'border' }}>
            {showCancelButton && (
              <Button
                text="Cancel"
                variant="gray"
                onClick={onHide as MouseEventHandler<HTMLButtonElement>}
                dataTestId="modalCancelButton"
              />
            )}
            <FlexLayout alignItems="center" flexGrow="1" justifyContent="flex-end">
              {actionButtons && actionButtons}
            </FlexLayout>
          </FlexLayout>
        )}
      </FlexLayout>
    </FlexLayout>
  );
}

export default Modal;
