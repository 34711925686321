import { useDispatch, useSelector } from 'react-redux';

import { FlexLayout, NumberInput, Icon, Text } from 'ui';
import { cashPoolSelector, updateField } from 'reducers/cashPool.slice';

import { updateAssessment } from './FunctionalAnalysisUtils';

type RiskAssessmentInputPropsType = {
  isCalculationDone: boolean;
  setIsCalculationDone: React.Dispatch<React.SetStateAction<boolean>>;
};

const RiskAssessmentInput = ({ isCalculationDone, setIsCalculationDone }: RiskAssessmentInputPropsType) => {
  const dispatch = useDispatch();
  const { totalRisk } = useSelector(cashPoolSelector);

  return (
    <>
      <NumberInput
        withLock
        inputType="float"
        allowNegatives={false}
        label="Risk assessment"
        width="s"
        unit="%"
        value={totalRisk}
        onChange={(totalRisk: number) => {
          setIsCalculationDone(false);
          dispatch(updateField({ totalRisk }));
          updateAssessment(totalRisk / 100, dispatch);
        }}
      />

      {isCalculationDone && (
        <FlexLayout
          bg="link-water"
          px={4}
          py={3}
          space={2}
          alignItems="center"
          sx={{ height: '100%', borderRadius: '4px' }}
        >
          <Icon icon="warning" />
          <FlexLayout flexDirection="column">
            <Text variant="s-spaced-bold-caps" color="deep-sapphire">
              Recalculated
            </Text>
            <Text variant="xs-spaced" color="bali-hai">
              Risk assessment was recalculated.
            </Text>
          </FlexLayout>
        </FlexLayout>
      )}
    </>
  );
};

export default RiskAssessmentInput;
