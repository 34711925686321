import { useContext, useEffect, useState } from 'react';

import { getB2BLoans, getCreditRatings, getGuarantees, getLoans } from '~/api';
import { UserInfoContext } from '~/context/user';
import { cancellable } from '~/utils/promise';

function useReports(searchQuery) {
  const { userInfo } = useContext(UserInfoContext);
  const [isLoading, setIsLoading] = useState(true);
  const [guaranteeReports, setGuaranteeReports] = useState([]);
  const [loanReports, setLoanReports] = useState([]);
  const [creditRatings, setCreditRatings] = useState([]);
  const [b2bLoanReports, setB2BLoanReports] = useState([]);
  const [refreshTrigger, setRefreshTrigger] = useState(false);

  useEffect(() => {
    const reportPromises = [undefined, undefined, undefined, undefined];
    if (userInfo.features.guarantee) reportPromises[0] = getGuarantees(searchQuery);
    if (userInfo.features.loan) reportPromises[1] = getLoans(searchQuery);
    if (userInfo.features.creditRating) reportPromises[2] = getCreditRatings(searchQuery);
    if (userInfo.features.backToBackLoan) reportPromises[3] = getB2BLoans(searchQuery);

    const cancellablePromise = cancellable(Promise.all(reportPromises));
    cancellablePromise.promise
      .then(([guaranteeReports, loanReports, creditRatings, b2bLoanReports]) => {
        setGuaranteeReports(guaranteeReports);
        setLoanReports(loanReports);
        setCreditRatings(creditRatings);
        setB2BLoanReports(b2bLoanReports);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.cancelled) {
          cancellablePromise.cancel();
        }
      });

    return () => {
      cancellablePromise.cancel();
    };
    // eslint-disable-next-line
  }, [refreshTrigger]);

  return [isLoading, guaranteeReports, loanReports, creditRatings, b2bLoanReports, { setRefreshTrigger }];
}

export default useReports;
