import React, { useEffect, useState } from 'react';

import { getClients } from '~/api';
import { SingleSelect } from '~/ui';
import { getOptionsWithValueFromArray } from '~/utils/arrays';

function ClientSingleSelect({ label = 'Client', value, width = 'fullWidth', onChange }) {
  const [clients, setClients] = useState();

  useEffect(() => {
    getClients().then(setClients);
  }, []);

  return (
    <SingleSelect
      label={label}
      options={clients && getOptionsWithValueFromArray(clients, 'name', 'id')}
      value={value}
      width={width}
      onChange={onChange}
    />
  );
}

export default ClientSingleSelect;
