import React from 'react';
import injectSheet from 'react-jss';
import ReactPaginate from 'react-paginate';

import { FlexLayout, Icon, Text } from '~/ui';

import styles from './Pagination.styles.js';

function Pagination({ canNextPage, canPreviousPage, classes, pageCount, forcePage, onPageChange, isShowing = true }) {
  if (!isShowing) return null;

  const nextLabelColor = canNextPage ? 'deep-sapphire' : 'bali-hai';
  const previousLabelColor = canPreviousPage ? 'deep-sapphire' : 'bali-hai';

  return (
    <ReactPaginate
      previousLabel={
        <FlexLayout alignItems="center" mx={2} space={1}>
          <Icon color={previousLabelColor} icon="chevronLeft" size="s" />
          <Text color={previousLabelColor} variant="s-spaced-medium">
            Previous
          </Text>
        </FlexLayout>
      }
      breakLabel={
        <Text color="bali-hai" variant="m-spaced-bold">
          ...
        </Text>
      }
      nextLabel={
        <FlexLayout alignItems="center" mx={2} space={1}>
          <Text color={nextLabelColor} variant="s-spaced-medium">
            Next
          </Text>
          <Icon color={nextLabelColor} icon="chevronRight" size="s" />
        </FlexLayout>
      }
      marginPagesDisplayed={3}
      pageCount={pageCount}
      pageRangeDisplayed={2}
      forcePage={forcePage}
      onPageChange={onPageChange}
      // classes
      activeClassName={classes.pageActive}
      activeLinkClassName={classes.pageActiveLink}
      breakClassName={classes.brake}
      containerClassName={classes.container}
      disabledClassName={classes.disabled}
      nextLinkClassName={classes.link}
      pageClassName={classes.page}
      pageLinkClassName={classes.pageLink}
      previousLinkClassName={classes.link}
    />
  );
}

export default injectSheet(styles)(Pagination);
