import React from 'react';

import { SingleSelect } from '~/ui';
import { getOptionsFromArray } from '~/utils/arrays';

export const industries = [
  'Consumer Discretionary',
  'Consumer Staples',
  'Energy',
  'Financials',
  'Healthcare',
  'Industrials',
  'Materials',
  'Media & Communications',
  'Quasi Government',
  'Real Estate & REITs',
  'Sovereign',
  'Technology',
  'Utilities',
];

const options = getOptionsFromArray(industries);

function IndustrySingleSelect({ label = 'Sector', tooltip, value, width = 'm', onChange }) {
  return (
    <SingleSelect label={label} options={options} tooltip={tooltip} value={value} width={width} onChange={onChange} />
  );
}

export default IndustrySingleSelect;
