import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import _ from 'lodash';

import { Button, Modal, RadioGroup, Text } from 'ui';
import { UserInfoContext } from 'context/user';
import { LoanPricingApproachType, GuaranteePricingApproachType, LoanType, GuaranteeType } from 'types';
import { pricingApproachEnum } from 'enums';

// Pricing approaches can include 'non-standard'. That is set by the loan algorithm.
// The 'stand-alone' and 'stand-alone non-standard' are the same approach that's why we need to filter them here
function filterNonStandardPricingApproach(pricingApproach: string) {
  return pricingApproach.includes(pricingApproachEnum.STANDALONE)
    ? pricingApproachEnum.STANDALONE
    : pricingApproachEnum.IMPLICIT;
}

type PricingOptionsType = {
  label: string;
  tooltip: string;
  value: LoanPricingApproachType | GuaranteePricingApproachType;
};

type PricingApproachModalPropsType = {
  isLoan?: boolean;
  isGuarantee?: boolean;
  filterNonStandardValues: boolean;
  pricingOptions: Array<PricingOptionsType> | [];
  onHide: () => void;
  onSubmitClick: React.MouseEventHandler<HTMLButtonElement>;
  loading: boolean;
  isShowing: boolean;
  data: LoanType | GuaranteeType;
  updateField: ActionCreatorWithPayload<any, string>;
  checkCompaniesData: boolean;
};

function PricingApproachModal({
  filterNonStandardValues = false,
  pricingOptions,
  onHide,
  onSubmitClick,
  loading,
  isShowing,
  isLoan,
  isGuarantee,
  data,
  updateField,
  checkCompaniesData,
}: PricingApproachModalPropsType) {
  const { userInfo } = useContext(UserInfoContext);
  const dispatch = useDispatch();
  const [companyDataChangedWarning, setCompanyDataChangedWarning] = useState('');
  const [lenderPerspectiveChange, setLenderPerspectiveChange] = useState('');
  const loanData = data as LoanType;
  const guaranteeData = data as GuaranteeType;

  const checkIfCompanyDataChanged = useCallback(() => {
    const getCompanyChangedWarningMessage = (companyOrCompanies: string) =>
      `Data for ${companyOrCompanies} has been updated since the analysis was first performed. Create a new analysis to incorporate the most recent data.`;

    // do not do this check where prop is not set (Price and Benchmark)
    if (!checkCompaniesData) return;

    if (isLoan) {
      const { lender, embeddedLender, borrower, embeddedBorrower } = loanData;
      if (!lender || !borrower) return;

      const isLenderCompanySame = _.isEqual(lender, embeddedLender);
      const isBorrowerCompanySame = _.isEqual(borrower, embeddedBorrower);

      if (!isLenderCompanySame && !isBorrowerCompanySame)
        setCompanyDataChangedWarning(getCompanyChangedWarningMessage(`${lender.name} and ${borrower.name}`));
      else if (!isLenderCompanySame) setCompanyDataChangedWarning(getCompanyChangedWarningMessage(lender.name));
      else if (!isBorrowerCompanySame) setCompanyDataChangedWarning(getCompanyChangedWarningMessage(borrower.name));
    }
    if (isGuarantee) {
      const { guarantor, principal, embeddedGuarantor, embeddedPrincipal } = guaranteeData;
      if (!guarantor || !principal) return;

      const embeddedPrincipalWithoutCumulativeProbabilityOfDefault = {
        ...embeddedPrincipal,
        creditRating: _.omit(embeddedPrincipal.creditRating, [
          'cumulativeProbabilityOfDefault',
          'cumulativeProbabilityOfDefaultAdj',
        ]),
      };

      const isGuarantorCompanySame = _.isEqual(guarantor, embeddedGuarantor);
      const isPrincipalCompanySame = _.isEqual(principal, embeddedPrincipalWithoutCumulativeProbabilityOfDefault);

      if (!isGuarantorCompanySame && !isPrincipalCompanySame)
        setCompanyDataChangedWarning(getCompanyChangedWarningMessage(`${guarantor.name} and ${principal.name}`));
      else if (!isGuarantorCompanySame) setCompanyDataChangedWarning(getCompanyChangedWarningMessage(guarantor.name));
      else if (!isPrincipalCompanySame) setCompanyDataChangedWarning(getCompanyChangedWarningMessage(principal.name));
    }
  }, [checkCompaniesData, loanData, guaranteeData, isGuarantee, isLoan]);

  useEffect(() => {
    if (
      loanData.isApproachCalculated != null &&
      loanData.isApproachCalculated !== userInfo.client.isLoanApproachCalculated
    ) {
      setLenderPerspectiveChange(
        'Lender perspective setting has changed since this loan was priced. Create a new analysis to reflect the change in settings.'
      );
    }

    return () => {
      setLenderPerspectiveChange('');
    };
  }, [loanData.isApproachCalculated, userInfo.client.isLoanApproachCalculated]);

  useEffect(() => {
    checkIfCompanyDataChanged();

    return () => {
      setCompanyDataChangedWarning('');
    };
  }, [loanData.lender, loanData.borrower, guaranteeData.guarantor, guaranteeData.principal, checkIfCompanyDataChanged]);

  if (!isShowing) return null;

  return (
    <Modal
      actionButtons={
        <Button
          text="Submit"
          onClick={onSubmitClick}
          loading={loading}
          disabled={loading}
          dataTestId="submitPricingButton"
        />
      }
      title="Select the pricing approach you would like to apply"
      onHide={onHide}
    >
      <RadioGroup
        label="Pricing Approach"
        value={filterNonStandardValues ? filterNonStandardPricingApproach(data.pricingApproach) : data.pricingApproach}
        variant="column"
        options={pricingOptions}
        radioVariant="secondary"
        width="fullWidth"
        onChange={(value) => dispatch(updateField({ pricingApproach: value }))}
      />
      {companyDataChangedWarning && <Text color="blaze-orange">{companyDataChangedWarning}</Text>}
      {lenderPerspectiveChange && <Text color="blaze-orange">{lenderPerspectiveChange}</Text>}
    </Modal>
  );
}

export default PricingApproachModal;
