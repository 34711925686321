import _ from 'lodash';
import { useContext } from 'react';

import { UserInfoContext } from 'context/user';
import { featureNames } from 'enums';
import { SingleSelect } from 'ui';
import { SingleSelectWidthType, CurrencyVariantType, CurrencyRegions, CurrencyValueType } from 'types';

type AllCurrenciesType = {
  [key in CurrencyVariantType]: {
    label: CurrencyRegions;
    options: {
      label: CurrencyValueType | `${CurrencyValueType} - ${string}`;
      value: CurrencyValueType;
    }[];
  }[];
};

/** UPDATE currenciesArray if allCurrencies are updated*/
export const allCurrencies: AllCurrenciesType = {
  default: [
    {
      label: 'Global Core',
      options: [
        {
          label: 'USD - United States Dollar',
          value: 'USD',
        },
        {
          label: 'EUR - European Euro',
          value: 'EUR',
        },
        {
          label: 'GBP - Great Britain (UK) Pound',
          value: 'GBP',
        },
        {
          label: 'CHF - Swiss Franc',
          value: 'CHF',
        },
        {
          label: 'AUD - Australian Dollar',
          value: 'AUD',
        },
        {
          label: 'JPY - Japanese Yen',
          value: 'JPY',
        },
        {
          label: 'CAD - Canadian Dollar',
          value: 'CAD',
        },
      ],
    },
    {
      label: 'Asia',
      options: [
        {
          label: 'CNH - Chinese Renminbi',
          value: 'CNH',
        },
        {
          label: 'CNY - Chinese Renminbi (Yuan)',
          value: 'CNY',
        },
        {
          label: 'HKD - Hong Kong Dollar',
          value: 'HKD',
        },
        {
          label: 'INR - Indian Rupee',
          value: 'INR',
        },
        {
          label: 'IDR - Indonesian Rupiah',
          value: 'IDR',
        },
        {
          label: 'KRW - South Korean Won',
          value: 'KRW',
        },
        {
          label: 'MYR - Malaysian Ringgit',
          value: 'MYR',
        },
        {
          label: 'PHP - Philippine Peso',
          value: 'PHP',
        },
        {
          label: 'SGD - Singapore Dollar',
          value: 'SGD',
        },
        {
          label: 'TWD - New Taiwan Dollar',
          value: 'TWD',
        },
        {
          label: 'THB - Thai Baht',
          value: 'THB',
        },
      ],
    },
    {
      label: 'W. Europe',
      options: [
        {
          label: 'DKK - Danish Krone',
          value: 'DKK',
        },
        {
          label: 'NOK - Norwegian Kroner',
          value: 'NOK',
        },
        {
          label: 'SEK - Swedish Krona',
          value: 'SEK',
        },
      ],
    },
    {
      label: 'E. Europe',
      options: [
        {
          label: 'CZK - Czech Koruna',
          value: 'CZK',
        },
        {
          label: 'HUF - Hungarian Forint',
          value: 'HUF',
        },
        {
          label: 'RON - Romanian Leu',
          value: 'RON',
        },
        {
          label: 'PLN - Polish Zloty',
          value: 'PLN',
        },
        {
          label: 'RUB - Russian Ruble',
          value: 'RUB',
        },
      ],
    },
    {
      label: 'Oceania',
      options: [
        {
          label: 'NZD - New Zealand Dollar',
          value: 'NZD',
        },
      ],
    },
    {
      label: 'Latin America',
      options: [
        {
          label: 'MXN - Mexican Peso',
          value: 'MXN',
        },
        {
          label: 'PEN - Peruvian Sol',
          value: 'PEN',
        },
        {
          label: 'BRL - Brazilian Real',
          value: 'BRL',
        },
        {
          label: 'COP - Colombian Peso',
          value: 'COP',
        },
      ],
    },
    {
      label: 'Middle East & Africa',
      options: [
        {
          label: 'ILS - Israeli New Shekel',
          value: 'ILS',
        },
        {
          label: 'ZAR - South African Rand',
          value: 'ZAR',
        },
        {
          label: 'TRY - Turkish Lira',
          value: 'TRY',
        },
      ],
    },
  ],
  short: [
    {
      label: 'Global Core',
      options: [
        {
          label: 'USD',
          value: 'USD',
        },
        {
          label: 'EUR',
          value: 'EUR',
        },
        {
          label: 'GBP',
          value: 'GBP',
        },
        {
          label: 'CHF',
          value: 'CHF',
        },
        {
          label: 'AUD',
          value: 'AUD',
        },
        {
          label: 'JPY',
          value: 'JPY',
        },
        {
          label: 'CAD',
          value: 'CAD',
        },
      ],
    },
    {
      label: 'Asia',
      options: [
        {
          label: 'CNH',
          value: 'CNH',
        },
        {
          label: 'CNY',
          value: 'CNY',
        },
        {
          label: 'HKD',
          value: 'HKD',
        },
        {
          label: 'INR',
          value: 'INR',
        },
        {
          label: 'IDR',
          value: 'IDR',
        },
        {
          label: 'KRW',
          value: 'KRW',
        },
        {
          label: 'MYR',
          value: 'MYR',
        },
        {
          label: 'PHP',
          value: 'PHP',
        },
        {
          label: 'SGD',
          value: 'SGD',
        },
        {
          label: 'TWD',
          value: 'TWD',
        },
        {
          label: 'THB',
          value: 'THB',
        },
      ],
    },
    {
      label: 'W. Europe',
      options: [
        {
          label: 'DKK',
          value: 'DKK',
        },
        {
          label: 'NOK',
          value: 'NOK',
        },
        {
          label: 'SEK',
          value: 'SEK',
        },
      ],
    },
    {
      label: 'E. Europe',
      options: [
        {
          label: 'CZK',
          value: 'CZK',
        },
        {
          label: 'HUF',
          value: 'HUF',
        },
        {
          label: 'RON',
          value: 'RON',
        },
        {
          label: 'PLN',
          value: 'PLN',
        },
        {
          label: 'RUB',
          value: 'RUB',
        },
      ],
    },
    {
      label: 'Oceania',
      options: [
        {
          label: 'NZD',
          value: 'NZD',
        },
      ],
    },
    {
      label: 'Latin America',
      options: [
        {
          label: 'MXN',
          value: 'MXN',
        },
        {
          label: 'PEN',
          value: 'PEN',
        },
        {
          label: 'BRL',
          value: 'BRL',
        },
        {
          label: 'COP',
          value: 'COP',
        },
      ],
    },
    {
      label: 'Middle East & Africa',
      options: [
        {
          label: 'ILS',
          value: 'ILS',
        },
        {
          label: 'ZAR',
          value: 'ZAR',
        },
        {
          label: 'TRY',
          value: 'TRY',
        },
      ],
    },
  ],
  extended: [
    {
      label: 'Global Core',
      options: [
        {
          label: 'USD - United States Dollar',
          value: 'USD',
        },
        {
          label: 'EUR - European Euro',
          value: 'EUR',
        },
        {
          label: 'GBP - Great Britain (UK) Pound',
          value: 'GBP',
        },
        {
          label: 'CHF - Swiss Franc',
          value: 'CHF',
        },
        {
          label: 'AUD - Australian Dollar',
          value: 'AUD',
        },
        {
          label: 'JPY - Japanese Yen',
          value: 'JPY',
        },
        {
          label: 'CAD - Canadian Dollar',
          value: 'CAD',
        },
      ],
    },
    {
      label: 'World',
      options: [
        {
          label: 'AED - United Arab Emirates Dirham',
          value: 'AED',
        },
        {
          label: 'AFN - Afghan Afghani',
          value: 'AFN',
        },
        {
          label: 'ALL - Albanian Lek',
          value: 'ALL',
        },
        {
          label: 'AMD - Armenian Dram',
          value: 'AMD',
        },
        {
          label: 'ANG - Netherlands Antillean Guilder',
          value: 'ANG',
        },
        {
          label: 'AOA - Angolan Kwanza',
          value: 'AOA',
        },
        {
          label: 'ARS - Argentine Peso',
          value: 'ARS',
        },
        {
          label: 'AWG - Aruban Florin',
          value: 'AWG',
        },
        {
          label: 'AZN - Azerbaijani Manat',
          value: 'AZN',
        },
        {
          label: 'BAM - Bosnia and Herzegovina Convertible Mark',
          value: 'BAM',
        },
        {
          label: 'BBD - Barbadian Dollar',
          value: 'BBD',
        },
        {
          label: 'BDT -     Bangladeshi Taka',
          value: 'BDT',
        },
        {
          label: 'BGN - Bulgarian Lev',
          value: 'BGN',
        },
        {
          label: 'BHD - Bahraini Dinar',
          value: 'BHD',
        },
        {
          label: 'BIF - Burundian Franc',
          value: 'BIF',
        },
        {
          label: 'BMD - Bermudian Dollar',
          value: 'BMD',
        },
        {
          label: 'BND - Brazilian Real',
          value: 'BND',
        },
        {
          label: 'BOB - Bolivian Boliviano',
          value: 'BOB',
        },
        {
          label: 'BRL - Brazilian Real',
          value: 'BRL',
        },
        {
          label: 'BSD - Bahamian Dollar',
          value: 'BSD',
        },
        {
          label: 'BTN - Bhutanese Ngultrum',
          value: 'BTN',
        },
        {
          label: 'BWP - Botswana Pula',
          value: 'BWP',
        },
        {
          label: 'BYN - Belarusian Ruble',
          value: 'BYN',
        },
        {
          label: 'BZD - Belize Dollar',
          value: 'BZD',
        },
        {
          label: 'CDF - Congolese Franc',
          value: 'CDF',
        },
        {
          label: 'CLP - Chilean Peso',
          value: 'CLP',
        },
        {
          label: 'CNH - Chinese Renminbi',
          value: 'CNH',
        },
        {
          label: 'CNY - Chinese Renminbi (Yuan)',
          value: 'CNY',
        },
        {
          label: 'COP - Colombian Peso',
          value: 'COP',
        },
        {
          label: 'CRC - Costa Rican Colón',
          value: 'CRC',
        },
        {
          label: 'CUP - Cuban Peso',
          value: 'CUP',
        },
        {
          label: 'CVE - Cape Verdean Escudo',
          value: 'CVE',
        },
        {
          label: 'CZK - Czech Koruna',
          value: 'CZK',
        },
        {
          label: 'DJF - Djiboutian Franc',
          value: 'DJF',
        },
        {
          label: 'DKK - Danish Krone',
          value: 'DKK',
        },
        {
          label: 'DOP - Dominican Peso',
          value: 'DOP',
        },
        {
          label: 'DZD - Algerian Dinar',
          value: 'DZD',
        },
        {
          label: 'EGP - Egyptian Pound',
          value: 'EGP',
        },
        {
          label: 'ERN - Eritrean Nakfa',
          value: 'ERN',
        },
        {
          label: 'ETB - Ethiopian Birr',
          value: 'ETB',
        },
        {
          label: 'FJD - Fijian Dollar',
          value: 'FJD',
        },
        {
          label: 'FKP - Falkland Islands Pound',
          value: 'FKP',
        },
        {
          label: 'GEL - Georgian Lari',
          value: 'GEL',
        },
        {
          label: 'GHS - Ghanaian Cedi',
          value: 'GHS',
        },
        {
          label: 'GIP - Gibraltar Pound',
          value: 'GIP',
        },
        {
          label: 'GMD - Gambian Dalasi',
          value: 'GMD',
        },
        {
          label: 'GNF - Guinean Franc',
          value: 'GNF',
        },
        {
          label: 'GTQ - Guatemalan Quetzal',
          value: 'GTQ',
        },
        {
          label: 'GYD - Guyanese Dollar',
          value: 'GYD',
        },
        {
          label: 'HKD - Hong Kong Dollar',
          value: 'HKD',
        },
        {
          label: 'HNL - Honduran Lempira',
          value: 'HNL',
        },
        {
          label: 'HTG - Haitian Gourde',
          value: 'HTG',
        },
        {
          label: 'HUF - Hungarian Forint',
          value: 'HUF',
        },
        {
          label: 'IDR - Indonesian Rupiah',
          value: 'IDR',
        },
        {
          label: 'ILS - Israeli New Shekel',
          value: 'ILS',
        },
        {
          label: 'INR - Indian Rupee',
          value: 'INR',
        },
        {
          label: 'IQD - Iraqi Dinar',
          value: 'IQD',
        },
        {
          label: 'IRR - Iranian Rial',
          value: 'IRR',
        },
        {
          label: 'ISK - Icelandic Króna',
          value: 'ISK',
        },
        {
          label: 'JMD - Jamaican Dollar',
          value: 'JMD',
        },
        {
          label: 'JOD - Jordanian Dinar',
          value: 'JOD',
        },
        {
          label: 'KES - Kenyan Shilling',
          value: 'KES',
        },
        {
          label: 'KGS - Kyrgyz Som',
          value: 'KGS',
        },
        {
          label: 'KHR - Cambodian Riel',
          value: 'KHR',
        },
        {
          label: 'KMF - Comorian Franc',
          value: 'KMF',
        },
        {
          label: 'KPW - North Korean Won',
          value: 'KPW',
        },
        {
          label: 'KRW - South Korean Won',
          value: 'KRW',
        },
        {
          label: 'KWD - Kuwaiti Dinar',
          value: 'KWD',
        },
        {
          label: 'KYD - Cayman Islands Dollar',
          value: 'KYD',
        },
        {
          label: 'KZT - Kazakhstani Tenge',
          value: 'KZT',
        },
        {
          label: 'LAK - Lao Kip',
          value: 'LAK',
        },
        {
          label: 'LBP - Lebanese Pound',
          value: 'LBP',
        },
        {
          label: 'LKR - Sri Lankan Rupee',
          value: 'LKR',
        },
        {
          label: 'LRD - Liberian Dollar',
          value: 'LRD',
        },
        {
          label: 'LSL - Lesotho Loti',
          value: 'LSL',
        },
        {
          label: 'LYD - Libyan Dinar',
          value: 'LYD',
        },
        {
          label: 'MAD - Moroccan Dirham',
          value: 'MAD',
        },
        {
          label: 'MDL - Moldovan Leu',
          value: 'MDL',
        },
        {
          label: 'MGA - Malagasy Ariary',
          value: 'MGA',
        },
        {
          label: 'MKD - Macedonian Denar',
          value: 'MKD',
        },
        {
          label: 'MMK - Burmese Kyat',
          value: 'MMK',
        },
        {
          label: 'MNT - Mongolian Tögrög',
          value: 'MNT',
        },
        {
          label: 'MOP - Macanese Pataca',
          value: 'MOP',
        },
        {
          label: 'MRU - Mauritanian Ouguiya',
          value: 'MRU',
        },
        {
          label: 'MUR - Mauritian Rupee',
          value: 'MUR',
        },
        {
          label: 'MVR - Maldivian Rufiyaa',
          value: 'MVR',
        },
        {
          label: 'MWK - Malawian Kwacha',
          value: 'MWK',
        },
        {
          label: 'MXN - Mexican Peso',
          value: 'MXN',
        },
        {
          label: 'MYR - Malaysian Ringgit',
          value: 'MYR',
        },
        {
          label: 'MZN - Mozambican Metical',
          value: 'MZN',
        },
        {
          label: 'NAD - Namibian Dollar',
          value: 'NAD',
        },
        {
          label: 'NGN - Nigerian Naira',
          value: 'NGN',
        },
        {
          label: 'NIO - Nicaraguan Córdoba',
          value: 'NIO',
        },
        {
          label: 'NOK - Norwegian Kroner',
          value: 'NOK',
        },
        {
          label: 'NPR - Nepalese Rupee',
          value: 'NPR',
        },
        {
          label: 'NZD - New Zealand Dollar',
          value: 'NZD',
        },
        {
          label: 'OMR - Omani Rial',
          value: 'OMR',
        },
        {
          label: 'PAB - Panamanian Balboa',
          value: 'PAB',
        },
        {
          label: 'PEN - Peruvian Sol',
          value: 'PEN',
        },
        {
          label: 'PGK - Papua New Guinean Kina',
          value: 'PGK',
        },
        {
          label: 'PHP - Philippine Peso',
          value: 'PHP',
        },
        {
          label: 'PKR - Pakistani Rupee',
          value: 'PKR',
        },
        {
          label: 'PLN - Polish Zloty',
          value: 'PLN',
        },
        {
          label: 'PYG - Paraguayan Guaraní',
          value: 'PYG',
        },
        {
          label: 'QAR - Qatari Riyal',
          value: 'QAR',
        },
        {
          label: 'RON - Romanian Leu',
          value: 'RON',
        },
        {
          label: 'RSD - Serbian Dinar',
          value: 'RSD',
        },
        {
          label: 'RUB - Russian Ruble',
          value: 'RUB',
        },
        {
          label: 'RWF - Rwandan Franc',
          value: 'RWF',
        },
        {
          label: 'SAR - Saudi Riyal',
          value: 'SAR',
        },
        {
          label: 'SBD - Solomon Islands Dollar',
          value: 'SBD',
        },
        {
          label: 'SCR - Seychellois Rupee',
          value: 'SCR',
        },
        {
          label: 'SDG - Sudanese Pound',
          value: 'SDG',
        },
        {
          label: 'SEK - Swedish Krona',
          value: 'SEK',
        },
        {
          label: 'SGD - Singapore Dollar',
          value: 'SGD',
        },
        {
          label: 'SHP - Saint Helena Pound',
          value: 'SHP',
        },
        {
          label: 'SLE - Sierra Leonean Leone',
          value: 'SLE',
        },
        {
          label: 'SOS - Somali Shilling',
          value: 'SOS',
        },
        {
          label: 'SRD - Surinamese Dollar',
          value: 'SRD',
        },
        {
          label: 'SSP - South Sudanese Pound',
          value: 'SSP',
        },
        {
          label: 'STN - São Tomé and Príncipe Dobra',
          value: 'STN',
        },
        {
          label: 'SYP - Syrian Pound',
          value: 'SYP',
        },
        {
          label: 'SZL - Swazi Lilangeni',
          value: 'SZL',
        },
        {
          label: 'THB - Thai Baht',
          value: 'THB',
        },
        {
          label: 'TJS - Tajikistani Somoni',
          value: 'TJS',
        },
        {
          label: 'TMT - Turkmenistani Manat',
          value: 'TMT',
        },
        {
          label: 'TND - Tunisian Dinar',
          value: 'TND',
        },
        {
          label: 'TOP - Tongan Paʻanga',
          value: 'TOP',
        },
        {
          label: 'TRY - Turkish Lira',
          value: 'TRY',
        },
        {
          label: 'TTD - Trinidad and Tobago Dollar',
          value: 'TTD',
        },
        {
          label: 'TWD - New Taiwan Dollar',
          value: 'TWD',
        },
        {
          label: 'TZS - Tanzanian Shilling',
          value: 'TZS',
        },
        {
          label: 'UAH - Ukrainian Hryvnia',
          value: 'UAH',
        },
        {
          label: 'UGX - Ugandan Shilling',
          value: 'UGX',
        },
        {
          label: 'UYU - Uruguayan Peso',
          value: 'UYU',
        },
        {
          label: 'UZS - Uzbekistani Sum',
          value: 'UZS',
        },
        {
          label: 'VED - Venezuelan Digital Bolívar',
          value: 'VED',
        },
        {
          label: 'VES - Venezuelan Sovereign Bolívar',
          value: 'VES',
        },
        {
          label: 'VND - Vietnamese Đồng',
          value: 'VND',
        },
        {
          label: 'VUV - Vanuatu Vatu ',
          value: 'VUV',
        },
        {
          label: 'WST - Samoan Tālā',
          value: 'WST',
        },
        {
          label: 'XAF - Central African CFA Franc',
          value: 'XAF',
        },
        {
          label: 'XCD - Eastern Caribbean Dollar',
          value: 'XCD',
        },
        {
          label: 'XOF - West African CFA Franc',
          value: 'XOF',
        },
        {
          label: 'XPF - CFP Franc',
          value: 'XPF',
        },
        {
          label: 'YER - Yemeni Rial',
          value: 'YER',
        },
        {
          label: 'ZAR - South African Rand',
          value: 'ZAR',
        },
        {
          label: 'ZMW - Zambian Kwacha',
          value: 'ZMW',
        },
        {
          label: 'ZWL - Zimbabwe Dollar',
          value: 'ZWL',
        },
      ],
    },
  ],
  extendedShort: [
    {
      label: 'Global Core',
      options: [
        {
          label: 'USD',
          value: 'USD',
        },
        {
          label: 'EUR',
          value: 'EUR',
        },
        {
          label: 'GBP',
          value: 'GBP',
        },
        {
          label: 'CHF',
          value: 'CHF',
        },
        {
          label: 'AUD',
          value: 'AUD',
        },
        {
          label: 'JPY',
          value: 'JPY',
        },
        {
          label: 'CAD',
          value: 'CAD',
        },
      ],
    },
    {
      label: 'World',
      options: [
        {
          label: 'AED',
          value: 'AED',
        },
        {
          label: 'AFN',
          value: 'AFN',
        },
        {
          label: 'ALL',
          value: 'ALL',
        },
        {
          label: 'AMD',
          value: 'AMD',
        },
        {
          label: 'ANG',
          value: 'ANG',
        },
        {
          label: 'AOA',
          value: 'AOA',
        },
        {
          label: 'ARS',
          value: 'ARS',
        },
        {
          label: 'AWG',
          value: 'AWG',
        },
        {
          label: 'AZN',
          value: 'AZN',
        },
        {
          label: 'BAM',
          value: 'BAM',
        },
        {
          label: 'BBD',
          value: 'BBD',
        },
        {
          label: 'BDT',
          value: 'BDT',
        },
        {
          label: 'BGN',
          value: 'BGN',
        },
        {
          label: 'BHD',
          value: 'BHD',
        },
        {
          label: 'BIF',
          value: 'BIF',
        },
        {
          label: 'BMD',
          value: 'BMD',
        },
        {
          label: 'BND',
          value: 'BND',
        },
        {
          label: 'BOB',
          value: 'BOB',
        },
        {
          label: 'BRL',
          value: 'BRL',
        },
        {
          label: 'BSD',
          value: 'BSD',
        },
        {
          label: 'BTN',
          value: 'BTN',
        },
        {
          label: 'BWP',
          value: 'BWP',
        },
        {
          label: 'BYN',
          value: 'BYN',
        },
        {
          label: 'BZD',
          value: 'BZD',
        },
        {
          label: 'CDF',
          value: 'CDF',
        },
        {
          label: 'CLP',
          value: 'CLP',
        },
        {
          label: 'CNH',
          value: 'CNH',
        },
        {
          label: 'CNY',
          value: 'CNY',
        },
        {
          label: 'COP',
          value: 'COP',
        },
        {
          label: 'CRC',
          value: 'CRC',
        },
        {
          label: 'CUP',
          value: 'CUP',
        },
        {
          label: 'CVE',
          value: 'CVE',
        },
        {
          label: 'CZK',
          value: 'CZK',
        },
        {
          label: 'DJF',
          value: 'DJF',
        },
        {
          label: 'DKK',
          value: 'DKK',
        },
        {
          label: 'DOP',
          value: 'DOP',
        },
        {
          label: 'DZD',
          value: 'DZD',
        },
        {
          label: 'EGP',
          value: 'EGP',
        },
        {
          label: 'ERN',
          value: 'ERN',
        },
        {
          label: 'ETB',
          value: 'ETB',
        },
        {
          label: 'FJD',
          value: 'FJD',
        },
        {
          label: 'FKP',
          value: 'FKP',
        },
        {
          label: 'GEL',
          value: 'GEL',
        },
        {
          label: 'GHS',
          value: 'GHS',
        },
        {
          label: 'GIP',
          value: 'GIP',
        },
        {
          label: 'GMD',
          value: 'GMD',
        },
        {
          label: 'GNF',
          value: 'GNF',
        },
        {
          label: 'GTQ',
          value: 'GTQ',
        },
        {
          label: 'GYD',
          value: 'GYD',
        },
        {
          label: 'HKD',
          value: 'HKD',
        },
        {
          label: 'HNL',
          value: 'HNL',
        },
        {
          label: 'HTG',
          value: 'HTG',
        },
        {
          label: 'HUF',
          value: 'HUF',
        },
        {
          label: 'IDR',
          value: 'IDR',
        },
        {
          label: 'ILS',
          value: 'ILS',
        },
        {
          label: 'INR',
          value: 'INR',
        },
        {
          label: 'IQD',
          value: 'IQD',
        },
        {
          label: 'IRR',
          value: 'IRR',
        },
        {
          label: 'ISK',
          value: 'ISK',
        },
        {
          label: 'JMD',
          value: 'JMD',
        },
        {
          label: 'JOD',
          value: 'JOD',
        },
        {
          label: 'KES',
          value: 'KES',
        },
        {
          label: 'KGS',
          value: 'KGS',
        },
        {
          label: 'KHR',
          value: 'KHR',
        },
        {
          label: 'KMF',
          value: 'KMF',
        },
        {
          label: 'KPW',
          value: 'KPW',
        },
        {
          label: 'KRW',
          value: 'KRW',
        },
        {
          label: 'KWD',
          value: 'KWD',
        },
        {
          label: 'KYD',
          value: 'KYD',
        },
        {
          label: 'KZT',
          value: 'KZT',
        },
        {
          label: 'LAK',
          value: 'LAK',
        },
        {
          label: 'LBP',
          value: 'LBP',
        },
        {
          label: 'LKR',
          value: 'LKR',
        },
        {
          label: 'LRD',
          value: 'LRD',
        },
        {
          label: 'LSL',
          value: 'LSL',
        },
        {
          label: 'LYD',
          value: 'LYD',
        },
        {
          label: 'MAD',
          value: 'MAD',
        },
        {
          label: 'MDL',
          value: 'MDL',
        },
        {
          label: 'MGA',
          value: 'MGA',
        },
        {
          label: 'MKD',
          value: 'MKD',
        },
        {
          label: 'MMK',
          value: 'MMK',
        },
        {
          label: 'MNT',
          value: 'MNT',
        },
        {
          label: 'MOP',
          value: 'MOP',
        },
        {
          label: 'MRU',
          value: 'MRU',
        },
        {
          label: 'MUR',
          value: 'MUR',
        },
        {
          label: 'MVR',
          value: 'MVR',
        },
        {
          label: 'MWK',
          value: 'MWK',
        },
        {
          label: 'MXN',
          value: 'MXN',
        },
        {
          label: 'MYR',
          value: 'MYR',
        },
        {
          label: 'MZN',
          value: 'MZN',
        },
        {
          label: 'NAD',
          value: 'NAD',
        },
        {
          label: 'NGN',
          value: 'NGN',
        },
        {
          label: 'NIO',
          value: 'NIO',
        },
        {
          label: 'NOK',
          value: 'NOK',
        },
        {
          label: 'NPR',
          value: 'NPR',
        },
        {
          label: 'NZD',
          value: 'NZD',
        },
        {
          label: 'OMR',
          value: 'OMR',
        },
        {
          label: 'PAB',
          value: 'PAB',
        },
        {
          label: 'PEN',
          value: 'PEN',
        },
        {
          label: 'PGK',
          value: 'PGK',
        },
        {
          label: 'PHP',
          value: 'PHP',
        },
        {
          label: 'PKR',
          value: 'PKR',
        },
        {
          label: 'PLN',
          value: 'PLN',
        },
        {
          label: 'PYG',
          value: 'PYG',
        },
        {
          label: 'QAR',
          value: 'QAR',
        },
        {
          label: 'RON',
          value: 'RON',
        },
        {
          label: 'RSD',
          value: 'RSD',
        },
        {
          label: 'RUB',
          value: 'RUB',
        },
        {
          label: 'RWF',
          value: 'RWF',
        },
        {
          label: 'SAR',
          value: 'SAR',
        },
        {
          label: 'SBD',
          value: 'SBD',
        },
        {
          label: 'SCR',
          value: 'SCR',
        },
        {
          label: 'SDG',
          value: 'SDG',
        },
        {
          label: 'SEK',
          value: 'SEK',
        },
        {
          label: 'SGD',
          value: 'SGD',
        },
        {
          label: 'SHP',
          value: 'SHP',
        },
        {
          label: 'SLE',
          value: 'SLE',
        },
        {
          label: 'SOS',
          value: 'SOS',
        },
        {
          label: 'SRD',
          value: 'SRD',
        },
        {
          label: 'SSP',
          value: 'SSP',
        },
        {
          label: 'STN',
          value: 'STN',
        },
        {
          label: 'SYP',
          value: 'SYP',
        },
        {
          label: 'SZL',
          value: 'SZL',
        },
        {
          label: 'THB',
          value: 'THB',
        },
        {
          label: 'TJS',
          value: 'TJS',
        },
        {
          label: 'TMT',
          value: 'TMT',
        },
        {
          label: 'TND',
          value: 'TND',
        },
        {
          label: 'TOP',
          value: 'TOP',
        },
        {
          label: 'TRY',
          value: 'TRY',
        },
        {
          label: 'TTD',
          value: 'TTD',
        },
        {
          label: 'TWD',
          value: 'TWD',
        },
        {
          label: 'TZS',
          value: 'TZS',
        },
        {
          label: 'UAH',
          value: 'UAH',
        },
        {
          label: 'UGX',
          value: 'UGX',
        },
        {
          label: 'UYU',
          value: 'UYU',
        },
        {
          label: 'UZS',
          value: 'UZS',
        },
        {
          label: 'VED',
          value: 'VED',
        },
        {
          label: 'VES',
          value: 'VES',
        },
        {
          label: 'VND',
          value: 'VND',
        },
        {
          label: 'VUV',
          value: 'VUV',
        },
        {
          label: 'WST',
          value: 'WST',
        },
        {
          label: 'XAF',
          value: 'XAF',
        },
        {
          label: 'XCD',
          value: 'XCD',
        },
        {
          label: 'XOF',
          value: 'XOF',
        },
        {
          label: 'XPF',
          value: 'XPF',
        },
        {
          label: 'YER',
          value: 'YER',
        },
        {
          label: 'ZAR',
          value: 'ZAR',
        },
        {
          label: 'ZMW',
          value: 'ZMW',
        },
        {
          label: 'ZWL',
          value: 'ZWL',
        },
      ],
    },
  ],
};

/**
 * Current currencies can be extracted from `allCurrencies` with the following command
 * `_.flatten(allCurrencies.short.map(({ options }) => options)).map(({ value }) => value);`
 * It's not used programmatically to get the exact values as types
 */
export const currenciesArray = [
  'USD',
  'EUR',
  'GBP',
  'CHF',
  'AUD',
  'JPY',
  'CAD',
  'CNH',
  'CNY',
  'HKD',
  'INR',
  'IDR',
  'KRW',
  'MYR',
  'PHP',
  'SGD',
  'TWD',
  'THB',
  'DKK',
  'NOK',
  'SEK',
  'CZK',
  'HUF',
  'RON',
  'PLN',
  'RUB',
  'NZD',
  'MXN',
  'PEN',
  'BRL',
  'COP',
  'ILS',
  'ZAR',
  'TRY',
  'AFN',
  'ALL',
  'DZD',
  'AOA',
  'XCD',
  'ARS',
  'AMD',
  'AWG',
  'AZN',
  'BSD',
  'BHD',
  'BDT',
  'BBD',
  'BYN',
  'BZD',
  'XOF',
  'BMD',
  'BTN',
  'BOB',
  'BAM',
  'BWP',
  'BND',
  'BGN',
  'BIF',
  'CVE',
  'KHR',
  'XAF',
  'KYD',
  'CLP',
  'KMF',
  'CDF',
  'CRC',
  'CUP',
  'ANG',
  'DJF',
  'DOP',
  'EGP',
  'ERN',
  'SZL',
  'ETB',
  'FKP',
  'FJD',
  'XPF',
  'GMD',
  'GEL',
  'GHS',
  'GIP',
  'GTQ',
  'GNF',
  'GYD',
  'HTG',
  'HNL',
  'ISK',
  'IRR',
  'IQD',
  'JMD',
  'JOD',
  'KZT',
  'KES',
  'KPW',
  'KWD',
  'KGS',
  'LAK',
  'LBP',
  'LSL',
  'LRD',
  'LYD',
  'MOP',
  'MKD',
  'MGA',
  'MWK',
  'MVR',
  'MRU',
  'MUR',
  'MDL',
  'MNT',
  'MAD',
  'MZN',
  'MMK',
  'NAD',
  'NPR',
  'NIO',
  'NGN',
  'OMR',
  'PKR',
  'PAB',
  'PGK',
  'PYG',
  'QAR',
  'RWF',
  'SHP',
  'WST',
  'STN',
  'SAR',
  'RSD',
  'SCR',
  'SLE',
  'SBD',
  'SOS',
  'SSP',
  'LKR',
  'SDG',
  'SRD',
  'SYP',
  'TJS',
  'TZS',
  'TOP',
  'TTD',
  'TND',
  'TMT',
  'UGX',
  'UAH',
  'AED',
  'UYU',
  'UZS',
  'VUV',
  'VES',
  'VED',
  'VND',
  'YER',
  'ZMW',
  'ZWL',
] as const;

/**
 * Returns all currencies if there are no client currencies defined (Features table) or if the limitCurrencies prop wasn't passed.
 * If there are client currencies and limitCurrencies was passed (Price and Benchmark) we just show those in the SingleSelect.
 */
const getCurrencies = (clientCurrencies: string[], variant: CurrencyVariantType, limitCurrencies?: boolean) => {
  const allVariantCurrencies: AllCurrenciesType[CurrencyVariantType] = _.cloneDeep(allCurrencies[variant]);
  if (!limitCurrencies || !clientCurrencies) return allVariantCurrencies;

  for (const currencies of allVariantCurrencies) {
    currencies.options = _.values(_.pickBy(currencies.options, (option) => clientCurrencies.includes(option.value)));
  }

  return allVariantCurrencies.filter((currencies) => !_.isEmpty(currencies.options));
};

type CurrencySingleSelectProps = {
  label?: string;
  tooltip?: string;
  variant?: CurrencyVariantType;
  dataTestId?: string;
  limitCurrencies?: boolean;
  width?: SingleSelectWidthType;
  height?: string;
  disabled?: boolean;
  value: CurrencyValueType;
  onChange: Function;
};

function CurrencySingleSelect({
  label = 'Currency',
  tooltip,
  variant = 'default',
  dataTestId,
  limitCurrencies,
  width = 'm',
  height,
  disabled,
  value,
  onChange,
}: CurrencySingleSelectProps) {
  const { userInfo } = useContext(UserInfoContext);

  return (
    <SingleSelect
      hasGroupedOptions
      label={label}
      dataTestId={dataTestId}
      options={getCurrencies(userInfo.features[featureNames.CURRENCY]?.currencies, variant, limitCurrencies)}
      tooltip={tooltip}
      value={value}
      width={width}
      height={height}
      disabled={disabled}
      onChange={onChange}
    />
  );
}

export default CurrencySingleSelect;
