import PT from 'prop-types';
import React from 'react';
import InlineSvg from 'react-inlinesvg';

import { Box, FlexLayout, NumberInput } from '~/ui';
import {
  fourGroupHighlight,
  fourGroup,
  threeGroupHighlight,
  threeGroup,
  twoGroupHighlight,
  twoGroup,
} from '~/ui/assets/icons';

const variants = {
  twoGroup: {
    icon: twoGroup,
    linkedIcon: twoGroupHighlight,
  },
  threeGroup: {
    icon: threeGroup,
    linkedIcon: threeGroupHighlight,
  },
  fourGroup: {
    icon: fourGroup,
    linkedIcon: fourGroupHighlight,
  },
};

const widths = {
  0: 'l',
  1: 'm',
  2: 's',
};

function Connector({ icon }) {
  return (
    <Box sx={{ position: 'absolute', top: '50px', left: '-17px' }}>
      <InlineSvg src={icon} />
    </Box>
  );
}

function InputGroup({ inputs, variant, isLinked, isShowing = true, showTopInputOnly }) {
  const icon = isLinked ? variants[variant].linkedIcon : variants[variant].icon;

  const inputsToShow = showTopInputOnly ? [inputs[0]] : inputs;

  if (!isShowing) return null;

  return (
    <FlexLayout alignItems="center" sx={{ position: 'relative' }}>
      {!showTopInputOnly && <Connector icon={icon} />}
      <FlexLayout flexDirection="column" alignItems="flex-end" space={6}>
        {inputsToShow.map(({ label, level, renderInputGroup, ...rest }) => {
          if (renderInputGroup) return renderInputGroup();
          return <NumberInput key={label} label={label} inputType="float" width={widths[level]} {...rest} />;
        })}
      </FlexLayout>
    </FlexLayout>
  );
}

const inputPropType = PT.shape({
  allowNegatives: PT.bool,
  isPercentage: PT.bool,
  error: PT.string,
  label: PT.string,
  level: PT.oneOf([0, 1, 2]),
  renderInputGroup: PT.func,
  tooltip: PT.string,
  variant: PT.string,
  bg: PT.string,
  disabled: PT.bool,
  unit: PT.string,
  value: PT.oneOfType([PT.string, PT.number]),
  onChange: PT.func,
});

InputGroup.propTypes = {
  inputs: PT.arrayOf(inputPropType),
  variant: PT.string,
  isLinked: PT.bool,
};

export default InputGroup;
