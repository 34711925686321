import isAfter from 'date-fns/isAfter';

import { SingleSelect } from '~/ui';
import { getOptionsFromArray } from '~/utils/arrays';

const oldUsdGbpChfJpy = ['USD LIBOR', 'GBP LIBOR', 'CHF LIBOR', 'JPY LIBOR'];
const newUsdGbpChfJpy = ['USD SOFR', 'GBP SONIA', 'CHF SARON', 'JPY TONAR'];

const options = [
  'EURIBOR',
  'BBSW',
  'CDOR/BA',
  'CNH HIBOR',
  'CDI',
  'IBR',
  'SHIBOR',
  'HIBOR',
  'MIBOR',
  'JIBOR',
  'KORIBOR',
  'KLIBOR',
  'PHIREF',
  'SIBOR',
  'TAIBOR',
  'BIBOR',
  'CIBOR',
  'NIBOR',
  'STIBOR',
  'PRIBOR',
  'BUBOR',
  'ROBID-ROBOR',
  'WIBOR',
  'MOSPRIME',
  'BKBM',
  'TIIE',
  'LIMABOR',
  'TELBOR',
  'TRYIBOR',
  'JIBAR',
];

const getReferenceRateOptions = (issueDate) => {
  const usdGbpChfJpyEdgeDate = new Date('2021-12-04');

  const referenceRateOptions = [...options];

  if (isAfter(new Date(issueDate), usdGbpChfJpyEdgeDate)) {
    referenceRateOptions.unshift(...newUsdGbpChfJpy);
  } else {
    referenceRateOptions.unshift(...oldUsdGbpChfJpy);
  }

  return getOptionsFromArray(referenceRateOptions);
};

function ReferenceRateSingleSelect({
  isShowing = true,
  label = 'Reference Rate',
  value,
  width = 'm',
  onChange,
  issueDate,
}) {
  if (!isShowing) return null;
  return (
    <SingleSelect
      label={label}
      options={getReferenceRateOptions(issueDate)}
      value={value}
      width={width}
      onChange={onChange}
    />
  );
}

export default ReferenceRateSingleSelect;
