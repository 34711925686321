import { useContext } from 'react';

import { SingleSelect } from 'ui';
import { CountryContext } from 'context';
import { SingleSelectWidthType } from 'types';

type CountrySingleSelectProps = {
  isShowing?: boolean;
  label?: string;
  tooltip?: string;
  width?: SingleSelectWidthType;
  disabled?: boolean;
  dataTestId?: string;
  value: string | null;
  onChange: Function;
};

function CountrySingleSelect({
  isShowing = true,
  label = 'Country',
  tooltip,
  width = 'm',
  disabled,
  dataTestId,
  value,
  onChange,
}: CountrySingleSelectProps) {
  const { countryDropdownOptions } = useContext(CountryContext);

  if (!isShowing) return null;

  return (
    <SingleSelect
      label={label}
      options={countryDropdownOptions}
      tooltip={tooltip}
      width={width}
      disabled={disabled}
      dataTestId={dataTestId}
      value={value}
      onChange={onChange}
    />
  );
}

export default CountrySingleSelect;
