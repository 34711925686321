export function getAccessToken(): string | null {
  return localStorage.getItem('token');
}

export function getAuthMethod(): string | null {
  return localStorage.getItem('authMethod');
}

export function setAccessToken(token: string): void {
  localStorage.setItem('token', token);
}

export function removeAccessToken(): void {
  localStorage.removeItem('token');
}

export function setAuthMethod(authMethod: string): void {
  localStorage.setItem('authMethod', authMethod);
}

export function removeAuthMethod(): void {
  localStorage.removeItem('authMethod');
}

/* used to redirect user to certain page after logging in. see redirectAfterLogin.js */
export function getNextPath(): string | null {
  return localStorage.getItem('_next');
}

export function setNextPath(_next: string): void {
  return localStorage.setItem('_next', _next);
}

export function removeNextPath(): void {
  return localStorage.removeItem('_next');
}

export const isUserAuthenticated = !!getAccessToken();

export const azureLoginQueryString = 'azureLogin';

export { default as Login } from './Login';
export { default as ResetPasswordPage } from './ResetPasswordPage';
