import { useState } from 'react';
import { saveAs } from 'file-saver';

import { Button, FileInput, FlexLayout, Modal, Text, TextInput } from 'ui';
import { errorHandler } from 'utils/errors';
import { ClientType } from 'types';
import { Company } from 'types/database/Company.type';

type AddClientModalPropsType = {
  clients: Array<ClientType>;
  isShowing: boolean;
  onAddClient: (
    name: string,
    emailDomains: string,
    industry: string,
    companiesToCreate?: Array<Company>
  ) => Promise<void>;
  onHide: () => void;
};

const AddClientModal = ({ clients, isShowing, onAddClient, onHide }: AddClientModalPropsType) => {
  const [clientName, setClientName] = useState('');
  const [clientIndustry, setClientIndustry] = useState('');
  const [emailDomains, setEmailDomains] = useState('');
  const [companiesToCreate, setCompaniesToCreate] = useState<Array<Company>>();

  const resetFields = () => {
    setClientName('');
    setEmailDomains('');
    setClientIndustry('');
  };

  const onAddClientClick = () => {
    onAddClient(clientName, emailDomains, clientIndustry, companiesToCreate).then(resetFields);
  };

  const onJsonUpload = async (file: File) => {
    try {
      const buffer = await file.arrayBuffer();
      setCompaniesToCreate(JSON.parse(Buffer.from(buffer).toString()));
    } catch (err) {
      errorHandler(err);
    }
  };

  const onDownloadExample = () => {
    const exampleCompanies = [
      {
        name: 'Parent Company',
        industry: 'Consumer Staples',
        country: 'Norway',
        creditRating: { rating: 'AAA/Aaa', ratingAdj: null, probabilityOfDefault: 2, probabilityOfDefaultAdj: null },
      },
      {
        name: 'Other Company',
        industry: 'Consumer Discretionary',
        country: 'Croatia',
        creditRating: { rating: 'AA/Aa2', ratingAdj: 'A+/A1', probabilityOfDefault: 4.6, probabilityOfDefaultAdj: 2.2 },
      },
    ];

    saveAs(new Blob([JSON.stringify(exampleCompanies)]), 'example.json');
  };

  if (!isShowing || !clients) return null;

  return (
    <Modal
      actionButtons={<Button disabled={!clientName || !clientIndustry} text="Create" onClick={onAddClientClick} />}
      title="Create a new Client"
      width="s"
      onHide={() => {
        resetFields();
        onHide();
      }}
    >
      <TextInput
        label="Client's name"
        width="fullWidth"
        inputWidth="fullWidth"
        value={clientName}
        onChange={setClientName}
      />
      <TextInput
        label="Client's email domains (e.g. tpaccurate.com, arsfutura.com)"
        width="fullWidth"
        inputWidth="fullWidth"
        value={emailDomains}
        onChange={setEmailDomains}
      />
      <TextInput
        label="Client's industry"
        width="fullWidth"
        inputWidth="fullWidth"
        value={clientIndustry}
        onChange={setClientIndustry}
      />
      <Text color="deep-sapphire" sx={{ marginBottom: '12px !important' }}>
        Use to prefill client with companies (optional)
      </Text>
      <FlexLayout space={4}>
        <Button iconLeft="download" size="s" text="Download example" variant="secondary" onClick={onDownloadExample} />
        <FileInput accept=".json" onChange={onJsonUpload}>
          <Button iconLeft="upload" size="s" text="Upload companies" variant="secondary" onClick={() => {}} />
        </FileInput>
      </FlexLayout>
    </Modal>
  );
};

export default AddClientModal;
