import { backend } from '~/services';
import { toFile } from '~/utils/documents';

import { apiPaths } from './routes';

export function getCreditRatings({ limit, isPortfolio }) {
  const endpoint = `${apiPaths.creditRating}?limit=${limit}&isPortfolio=${isPortfolio}`;
  return backend.get(endpoint);
}

export function getDeletedCreditRatings() {
  return backend.get(`${apiPaths.creditRating}/deleted`);
}

export function getCreditRating(id) {
  return backend.get(`${apiPaths.creditRating}/${id}`);
}

export function postCreditRating(data) {
  return backend.post(apiPaths.creditRating, data);
}

export function postCreditRatings(data) {
  return backend.post(`${apiPaths.creditRating}/bulk`, data);
}

export function postImportedCreditRating(data) {
  return backend.post(`${apiPaths.creditRating}/import`, data);
}

export function postMassImportedCreditRatings(data) {
  return backend.post(`${apiPaths.creditRating}/import/mass`, data);
}

export function deleteCreditRating({ id, force = false }) {
  return backend.delete(`${apiPaths.creditRating}/${id}?force=${force}`);
}

export function restoreCreditRating(id) {
  return backend.patch(`${apiPaths.creditRating}/${id}`);
}

export function getTemplate() {
  return backend.get(`${apiPaths.creditRating}/template`, { responseType: 'blob' });
}

export function getCreditRatingMassImportTemplate() {
  return backend.get(`${apiPaths.creditRating}/template/import`, { responseType: 'blob' });
}

export function updateCreditRating({ id, data }) {
  return backend.put(`${apiPaths.creditRating}/${id}`, data);
}

export function updateImportedCreditRating({ id, data }) {
  return backend.put(`${apiPaths.creditRating}/imported/${id}`, data);
}

export function updateCreditRatingNote({ id, data }) {
  return backend.put(`${apiPaths.creditRating}/${id}/note`, data);
}

export function updateCreditRatingStatus({ id, data }) {
  return backend.put(`${apiPaths.creditRating}/${id}/status`, data);
}

export function updateCreditRatingIsPortfolio({ id, data }) {
  return backend.put(`${apiPaths.creditRating}/${id}/portfolio`, data);
}

export function createCreditRatingFile({ reportId, file, label, newName, status = 'Draft' }) {
  const formData = new FormData();
  formData.append('file', file);
  let endpoint = `${apiPaths.azureStorage}/creditRating/${reportId}?label=${label}&status=${status}`;

  if (newName) {
    endpoint = endpoint + `&newName=${newName}`;
  }

  return backend.post(endpoint, formData);
}

export async function getCreditRatingFile({ fileId }) {
  const response = await backend.get(`${apiPaths.azureStorage}/creditRating/file/${fileId}`, {
    fullResponse: true,
    responseType: 'blob',
  });
  return toFile(response);
}

export async function deleteCreditRatingFile({ fileId }) {
  return await backend.delete(`${apiPaths.azureStorage}/creditRating/file/${fileId}`);
}

export async function updateCreditRatingFileStatus({ fileId, data }) {
  return await backend.put(`${apiPaths.azureStorage}/creditRating/file/${fileId}`, data);
}

export function getCreditRatingDrafts() {
  return backend.get(`${apiPaths.creditRating}/draft`);
}

export function createCreditRatingDraft(name, body) {
  return backend.post(`${apiPaths.creditRating}/draft`, { name, ...body });
}

export function deleteCreditRatingDraft(id) {
  return backend.delete(`${apiPaths.creditRating}/draft/${id}`);
}
