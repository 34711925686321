import React from 'react';

import { Box, styles } from 'ui';

interface FlexLayoutProps {
  id?: string;
  alignItems?: string;
  children?: React.ReactNode;
  flex?: string;
  flexDirection?: 'row' | 'column';
  alignSelf?: string;
  flexGrow?: string;
  flexShrink?: string;
  flexWrap?: string;
  justifyContent?: string;
  space?: number;
  px?: number;
  py?: number;
  pl?: number;
  pr?: number;
  pt?: number;
  pb?: number;
  p?: number;
  mt?: number;
  mx?: number;
  m?: number | string;
  ref?: React.MutableRefObject<HTMLElement>;
  sx?: any;
  variant?: string;
  disabled?: boolean;
  bg?: string;
  className?: string;
  dataTestId?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
}

const FlexLayout = React.forwardRef<HTMLElement, FlexLayoutProps>(
  (
    {
      alignItems,
      children,
      flex,
      flexDirection,
      flexGrow,
      flexShrink,
      flexWrap,
      justifyContent,
      space = 0,
      sx = {},
      ...rest
    },
    ref
  ) => {
    const clickableStyle = rest.disabled || rest.onClick || rest.onMouseEnter ? styles.interactions.clickable : {};
    const marginDirection = flexDirection === 'column' ? 'mb' : 'mr';

    return (
      <Box
        ref={ref}
        sx={{
          ...sx,
          ...clickableStyle,
          alignItems,
          display: 'flex',
          flex,
          flexDirection,
          flexGrow,
          flexShrink,
          flexWrap,
          justifyContent,
          '> :not(:last-child)': {
            [marginDirection]: space,
          },
        }}
        {...rest}
      >
        {children}
      </Box>
    );
  }
);

export default FlexLayout;
