import { useContext, useEffect, useState } from 'react';

import { getDeletedCreditRatings, getDeletedGuarantees, getDeletedLoans, getDeletedB2BLoans } from 'api';
import { UserInfoContext } from 'context/user';
import { cancellable } from 'utils/promise';
import { LoanType, GuaranteeType, CreditRatingType, B2BLoanType } from 'types';

function useDeletedReports(): {
  isLoading: boolean;
  deletedGuarantees: GuaranteeType[];
  deletedLoans: LoanType[];
  deletedB2BLoans: B2BLoanType[];
  deletedCreditRatings: CreditRatingType[];
  setRefreshTrigger: React.Dispatch<React.SetStateAction<boolean>>;
} {
  const { userInfo } = useContext(UserInfoContext);
  const [isLoading, setIsLoading] = useState(true);
  const [deletedGuarantees, setGuaranteeReports] = useState<GuaranteeType[]>([]);
  const [deletedLoans, setLoanReports] = useState<LoanType[]>([]);
  const [deletedCreditRatings, setCreditRatings] = useState<CreditRatingType[]>([]);
  const [deletedB2BLoans, setB2BLoanReports] = useState<B2BLoanType[]>([]);
  const [refreshTrigger, setRefreshTrigger] = useState(false);

  useEffect(() => {
    const reportPromises: [GuaranteeType[], LoanType[], CreditRatingType[], B2BLoanType[]] = [[], [], [], []];
    if (userInfo.features.guarantee) reportPromises[0] = getDeletedGuarantees();
    if (userInfo.features.loan) reportPromises[1] = getDeletedLoans();
    if (userInfo.features.creditRating) reportPromises[2] = getDeletedCreditRatings();
    if (userInfo.features.backToBackLoan) reportPromises[3] = getDeletedB2BLoans() as any;

    const cancellablePromise = cancellable(Promise.all(reportPromises));
    cancellablePromise.promise
      .then((result) => {
        setGuaranteeReports(result[0]);
        setLoanReports(result[1]);
        setCreditRatings(result[2]);
        setB2BLoanReports(result[3]);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.cancelled) {
          cancellablePromise.cancel();
        }
      });

    return () => {
      cancellablePromise.cancel();
    };
    // eslint-disable-next-line
  }, [refreshTrigger]);

  return { isLoading, deletedGuarantees, deletedLoans, deletedB2BLoans, deletedCreditRatings, setRefreshTrigger };
}

export default useDeletedReports;
