const azureOauthUrl = `${process.env.REACT_APP_AZURE_LOGIN_URL}/organizations/oauth2/v2.0`;
export const AZURE_LOGOUT_URL = `${azureOauthUrl}/logout`;

export const getAzureLoginUrl = () => {
  const azureLoginUrl = `${azureOauthUrl}/authorize?`;

  const searchParams = new URLSearchParams({
    client_id: process.env.REACT_APP_AZURE_CLIENT_ID!,
    redirect_uri: process.env.REACT_APP_AZURE_REDIRECT_URI!,
    scope: process.env.REACT_APP_AZURE_API_SCOPE!,
    response_mode: 'query',
    response_type: 'code',
  });

  return `${azureLoginUrl}${searchParams}`;
};
