import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { cashPoolDataColumnsSelector, updateColumns } from '~/reducers/tableColumns/cashPoolDataColumns.slice';
import { Button, FlexLayout, Modal } from '~/ui';

function FilterCashPoolDataColumnsModal({ isShowing, onHide }) {
  const dispatch = useDispatch();
  const columns = useSelector(cashPoolDataColumnsSelector);
  const [columnsCopy, setColumnsCopy] = useState({ ...columns });

  function handleOnSelectClick() {
    dispatch(updateColumns(columnsCopy));
    onHide();
  }

  if (!isShowing) return null;

  return (
    <Modal
      actionButtons={<Button text="Select" onClick={handleOnSelectClick} />}
      title="Add or remove columns."
      width="s"
      onHide={onHide}
    >
      <FlexLayout alignItems="center" flexWrap="wrap" justifyContent="flex-start" space={4} sx={{ mt: -4 }}>
        {Object.keys(columnsCopy).map((column) => (
          <Button
            key={column}
            size="s"
            sx={{ mt: 4 }}
            text={column}
            variant={columnsCopy[column] ? 'primary' : 'gray'}
            onClick={() => setColumnsCopy((_state) => ({ ..._state, [column]: !_state[column] }))}
          />
        ))}
      </FlexLayout>
    </Modal>
  );
}

export default FilterCashPoolDataColumnsModal;
