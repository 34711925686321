import PT from 'prop-types';
import React from 'react';

import { FlexLayout, theme } from '~/ui';

const sizes = {
  s: '45px',
  m: '75px',
  l: '120px',
};

function LoadingSpinner({ color = 'shakespeare', size = 'm' }) {
  return (
    <FlexLayout alignItems="center" flexGrow="1" justifyContent="center">
      <svg width={sizes[size]} height={sizes[size]} viewBox="0 0 45 45" stroke={theme.colors[color]}>
        <g fill="none" fillRule="evenodd" transform="translate(1 1)" strokeWidth="2">
          <circle cx="22" cy="22" r="6" strokeOpacity="0">
            <animate attributeName="r" begin="1s" dur="2s" values="6;22" calcMode="linear" repeatCount="indefinite" />
            <animate
              attributeName="stroke-opacity"
              begin="1s"
              dur="2s"
              values="1;0"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-width"
              begin="1s"
              dur="2s"
              values="2;0"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx="22" cy="22" r="6" strokeOpacity="0">
            <animate attributeName="r" begin="2s" dur="2s" values="6;22" calcMode="linear" repeatCount="indefinite" />
            <animate
              attributeName="stroke-opacity"
              begin="2s"
              dur="2s"
              values="1;0"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-width"
              begin="2s"
              dur="2s"
              values="2;0"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx="22" cy="22" r="8">
            <animate
              attributeName="r"
              begin="0s"
              dur="1s"
              values="6;5;4;3;2;1;0;6"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
        </g>
      </svg>
    </FlexLayout>
  );
}

LoadingSpinner.propTypes = {
  size: PT.oneOf(Object.keys(sizes)),
  color: PT.string,
};

export default LoadingSpinner;
