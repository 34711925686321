import { backend } from '~/services';

import { apiPaths } from './routes';

export function getNotifications(offset, limit) {
  return backend.get(`${apiPaths.notification}?offset=${offset}&limit=${limit}`);
}

export function getUnhandledNotifications() {
  return backend.get(`${apiPaths.notification}/unhandled`);
}

export function createNotification(data) {
  return backend.post(apiPaths.notification, data);
}

export function deleteNotification(id) {
  return backend.delete(`${apiPaths.notification}/${id}`);
}

// Mark as read/unread
export function updateNotificationIsHandled(id, data) {
  return backend.patch(`${apiPaths.notification}/${id}/isHandled`, data);
}
