import 'tippy.js/dist/tippy.css';
import '~/styles/react-tippy-overrides.css';

import Tippy from '@tippyjs/react';
import PT from 'prop-types';
import React from 'react';

import { Box } from '~/ui';

function Tooltip({
  arrow = true,
  children,
  content,
  interactive = false,
  placement = 'bottom',
  visible,
  onClickOutside,
}) {
  if (!content) {
    return children;
  }

  return (
    <Box sx={{ flexShrink: '0' }}>
      <Tippy
        animation={false}
        arrow={arrow}
        content={content}
        interactive={interactive}
        placement={placement}
        visible={visible}
        onClickOutside={onClickOutside}
      >
        {children}
      </Tippy>
    </Box>
  );
}

Tooltip.propTypes = {
  arrow: PT.bool,
  children: PT.node.isRequired,
  content: PT.node,
  interactive: PT.bool,
  placement: PT.oneOf([
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
  ]),
};

export default Tooltip;
