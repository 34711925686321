import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  clientId: null,
  username: '',
  email: '',
  fullName: '',
  role: null,
  areNotificationsMuted: false,
  dateFormat: null,
  timezone: null,
  decimalPoint: null,
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
  client: {},
  errors: {},
  loginAsAnyoneEmail: '',
  loginAsAnyonePassword: '',
};

// Slice
export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateField: (state, action) => {
      return { ...state, ...action.payload };
    },
    setFieldError: (state, action) => {
      const { field, message } = action.payload;
      if (!state.errors) {
        state.errors = {};
      }
      state.errors[field] = message;
    },
    clearErrors: (state) => {
      state.errors = {};
    },
  },
});

export default settingsSlice.reducer;

// Actions
export const { setSettings, updateField, setFieldError, clearErrors } = settingsSlice.actions;

// Selectors
export const settingsSelector = (state) => state.settings;
