import { backend } from 'services';
import { toFile } from 'utils/documents';
import type { CountryValueType, BetaRegionsType, BetaType } from 'types';

import { apiPaths } from './routes';

export async function getEquityRiskPremium({
  countryName,
  issueDate,
}: {
  countryName: CountryValueType;
  issueDate: Date;
}): Promise<{ equityRiskPremium: number }> {
  return backend.post(`${apiPaths.b2bLoan}/equity-risk-premium`, { countryName, issueDate });
}

export async function getBeta({
  industry,
  region,
  type,
  issueDate,
}: {
  industry: string;
  region: BetaRegionsType;
  type: BetaType;
  issueDate: Date;
}): Promise<{ beta: number }> {
  return backend.post(`${apiPaths.b2bLoan}/beta`, { industry, region, type, issueDate });
}

export function getB2BLoans({ limit, isPortfolio }: { limit: number; isPortfolio: boolean }) {
  return backend.get(`${apiPaths.b2bLoan}?limit=${limit}&isPortfolio=${isPortfolio}`);
}

export function getB2BLoan(id: number) {
  return backend.get(`${apiPaths.b2bLoan}/${id}`);
}

export function createB2BLoan(data: any) {
  return backend.post(apiPaths.b2bLoan, data);
}

export function updateB2BLoanRates({ id, data }: { id: number; data: any }) {
  return backend.put(`${apiPaths.b2bLoan}/${id}/rates`, data);
}

export function updateB2BLoanLegRates({ id, data }: { id: number; data: any }) {
  return backend.put(`${apiPaths.b2bLoan}/${id}/leg-rates`, data);
}

export function getB2BLoanCalculatedPaymentsInterest({ id, data }: { id: number; data: any }) {
  return backend.post(`${apiPaths.b2bLoan}/${id}/payment/calculate-payments`, data);
}

export function deleteB2BLoan({ id, force = false }: { id: number; force?: boolean }) {
  return backend.delete(`${apiPaths.b2bLoan}/${id}?force=${force}`);
}

export function restoreB2BLoan(id: number) {
  return backend.patch(`${apiPaths.b2bLoan}/${id}`);
}

export function updateB2BLoanStatus({ id, data }: { id: number; data: { status: 'Draft' | 'Final' } }) {
  return backend.put(`${apiPaths.b2bLoan}/${id}/status`, data);
}

export function updateB2BLoanNote({ id, data }: { id: number; data: { note: string } }) {
  return backend.put(`${apiPaths.b2bLoan}/${id}/note`, data);
}

export function updateB2BLoanIsPortfolio({ id, data }: { id: number; data: { isPortfolio: boolean } }) {
  return backend.put(`${apiPaths.b2bLoan}/${id}/portfolio`, data);
}

export function getDeletedB2BLoans() {
  return backend.get(`${apiPaths.b2bLoan}/deleted`);
}

export function updateB2BLoan({ id, data }: { id: number; data: any }) {
  return backend.put(`${apiPaths.b2bLoan}/${id}`, data);
}

export function generateB2BLoanAgreements({ id }: { id: number }) {
  return backend.post(`${apiPaths.b2bLoan}/${id}/agreement`);
}

export function generateB2BLoanTpReport({ id }: { id: number }) {
  return backend.post(`${apiPaths.b2bLoan}/${id}/tp-report`);
}

export function createB2BLoanFile({
  reportId,
  file,
  label,
  newName,
}: {
  reportId: number;
  file: File;
  label: string;
  newName?: string;
}) {
  const formData = new FormData();
  formData.append('file', file);
  let endpoint = `${apiPaths.azureStorage}/b2bLoan/${reportId}?label=${label}`;

  if (newName) {
    endpoint = endpoint + `&newName=${newName}`;
  }

  return backend.post(endpoint, formData);
}

export async function getB2BLoanFile({ fileId, type }: { fileId: number; type: 'pdf' | undefined }) {
  let endpoint = `${apiPaths.azureStorage}/b2bLoan/file/${fileId}`;
  if (type) endpoint = `${endpoint}?type=${type}`;

  const response = await backend.get(endpoint, { fullResponse: true, responseType: 'blob' });
  return toFile(response);
}

export function deleteB2BLoanFile({ fileId, reportId }: { fileId: number; reportId: number }) {
  return backend.delete(`${apiPaths.azureStorage}/b2bLoan/${reportId}/file/${fileId}`);
}

export function updateB2BLoanFileStatus({ fileId, data }: { fileId: number; data: { status: 'Draft' | 'Final' } }) {
  return backend.put(`${apiPaths.azureStorage}/b2bLoan/file/${fileId}`, data);
}
