import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { showWarningToast } from 'ui/components/Toast';

import { reportEnum } from '~/enums';
import { createCompanyValue, getCompanyById } from '~/utils/companies';
import { findMissingValues } from '~/utils/form';
import { capitalize } from '~/utils/strings';

const requiredAttributes = [
  'naceSector',
  'months',
  'option',
  'operatingRevenueTurnover',
  'EBIT',
  'EBITDA',
  'PLBeforeTax',
  'PLForPeriod',
  'fixedAssets',
  'currentAssets',
  'totalAssets',
  'shareholdersFunds',
  'nonCurrentLiabilities',
  'currentLiabilities',
  'totalShareFundsAndLiabilities',
];

const getInFileErrorMessageFunction = (documentName) => (message) => `${message} in file: ${documentName}`;

export function isCreditRatingFormValid(data, { shouldThrowError, documentName } = { shouldThrowError: false }) {
  const getInFileErrorMessage = getInFileErrorMessageFunction(documentName);

  let missingRequiredValues = [];
  if (data?.closingDate == null) {
    missingRequiredValues.push('closingDate');
    if (shouldThrowError) throw new Error(getInFileErrorMessage('Closing date is required'));
  }
  if (!data?.company) showWarningToast('Company does not exist or is missing');
  missingRequiredValues = [...missingRequiredValues, ...findMissingValues(data?.attributes, requiredAttributes)];

  const errors = {};
  for (let i = 0, len = missingRequiredValues.length; i < len; i++) {
    if (shouldThrowError) {
      throw new Error(getInFileErrorMessage(`${capitalize(missingRequiredValues[i])} is required`));
    }
    errors[missingRequiredValues[i]] = 'This field is required!';
  }

  if (parseFloat(data?.attributes?.cashAndCashEquivalent) > parseFloat(data?.attributes?.otherCurrentAssets)) {
    const errorMessage = 'Cash & cash equivalents must be less than or equal to other current assets';
    errors.cashAndCashEquivalent = errorMessage;
    if (shouldThrowError) throw new Error(getInFileErrorMessage(errorMessage));
  }

  if (parseFloat(data?.attributes?.totalAssets) !== parseFloat(data?.attributes?.totalShareFundsAndLiabilities)) {
    const errorMessage = 'Total assets must be equal to total shareholder funds & liabilities';
    errors.totalAssets = errorMessage;
    if (shouldThrowError) throw new Error(getInFileErrorMessage(errorMessage));
  }

  if (
    parseFloat(data?.attributes?.treasuryShares) > parseFloat(data?.attributes?.otherShareholdersFunds) &&
    parseFloat(data?.attributes?.treasuryShares) !== 0
  ) {
    const errorMessage = 'Treasury shares must be less than or equal to other shareholder funds';
    errors.treasuryShares = errorMessage;
    if (shouldThrowError) throw new Error(getInFileErrorMessage(errorMessage));
  }

  if (parseFloat(data?.attributes?.provisions) > parseFloat(data?.attributes?.otherNonCurrentLiabilities)) {
    const errorMessage = 'Provisions must be less than or equal to other non-current liabilities';
    errors.provisions = errorMessage;
    if (shouldThrowError) throw new Error(getInFileErrorMessage(errorMessage));
  }

  return errors;
}

function _sumValues(values, oldValue, isOverridden) {
  if (isOverridden) {
    return oldValue;
  } else {
    let value;
    const allValuesNull = values.every((value) => value == null);
    if (allValuesNull) {
      value = null;
    } else {
      value = values.reduce((a, b) => (parseFloat(a) || null) + (parseFloat(b) || null), 0);
    }
    return value;
  }
}

function _subtractValues(values, oldValue, isOverridden) {
  if (isOverridden) {
    return oldValue;
  } else {
    let value;
    const allValueNull = values.every((value) => value == null);
    if (allValueNull) {
      value = null;
    } else {
      value = parseFloat(values[0]) || null;
      for (let i = 1, len = values.length; i < len; i++) {
        value -= parseFloat(values[i]) || null;
      }
    }

    return value;
  }
}

export function evaluateCreditRatingData(data) {
  const state = { ...data };
  const attributes = data?.attributes;
  state.attributes.fixedAssets = _sumValues(
    [attributes?.intangibleFixedAssets, attributes?.tangibleFixedAssets, attributes?.otherFixedAssets],
    attributes?.fixedAssets,
    attributes.overriddenStatus.fixedAssets
  );
  state.attributes.currentAssets = _sumValues(
    [attributes?.stocks, attributes?.debtors, attributes?.otherCurrentAssets],
    attributes?.currentAssets,
    attributes.overriddenStatus.currentAssets
  );
  state.attributes.totalAssets = _sumValues(
    [state.attributes?.fixedAssets, state.attributes?.currentAssets],
    attributes?.totalAssets,
    attributes.overriddenStatus.totalAssets
  );
  state.attributes.shareholdersFunds = _sumValues(
    [attributes?.capital, attributes?.otherShareholdersFunds],
    attributes?.shareholdersFunds,
    attributes.overriddenStatus.shareholdersFunds
  );
  state.attributes.nonCurrentLiabilities = _sumValues(
    [attributes?.longTermDebt, attributes?.otherNonCurrentLiabilities],
    attributes?.nonCurrentLiabilities,
    attributes.overriddenStatus.nonCurrentLiabilities
  );
  state.attributes.currentLiabilities = _sumValues(
    [attributes?.loans, attributes?.creditors, attributes?.otherCurrentLiabilities],
    attributes?.currentLiabilities,
    attributes.overriddenStatus.currentLiabilities
  );
  state.attributes.totalShareFundsAndLiabilities = _sumValues(
    [
      state.attributes?.nonCurrentLiabilities,
      state.attributes?.currentLiabilities,
      state.attributes?.shareholdersFunds,
    ],
    attributes?.totalShareFundsAndLiabilities,
    attributes.overriddenStatus.totalShareFundsAndLiabilities
  );
  state.attributes.grossProfit = _subtractValues(
    [attributes?.operatingRevenueTurnover, attributes?.costOfGoodSold],
    attributes?.grossProfit,
    attributes.overriddenStatus.grossProfit
  );
  state.attributes.EBIT = _subtractValues(
    [
      state.attributes?.grossProfit,
      attributes?.materialCosts,
      attributes?.costOfEmployees,
      attributes?.otherOperatingExpenses,
      attributes?.depreciation,
    ],
    attributes?.EBIT,
    attributes.overriddenStatus.EBIT
  );
  state.attributes.EBITDA = _sumValues(
    [state.attributes?.EBIT, state.attributes?.depreciation],
    attributes?.EBITDA,
    attributes.overriddenStatus.EBITDA
  );
  state.attributes.financialPL = _subtractValues(
    [attributes?.financialRevenue, attributes?.financialExpenses],
    attributes?.financialPL,
    attributes.overriddenStatus.financialPL
  );
  state.attributes.PLBeforeTax = _sumValues(
    [attributes?.EBIT, state.attributes?.financialPL],
    attributes?.PLBeforeTax,
    attributes.overriddenStatus.PLBeforeTax
  );
  state.attributes.PLAfterTax = _subtractValues(
    [state.attributes?.PLBeforeTax, attributes?.taxation],
    attributes?.PLAfterTax,
    attributes.overriddenStatus.PLAfterTax
  );
  state.attributes.extrAndOtherPL = _subtractValues(
    [attributes?.extrAndOtherRevenue, attributes?.extrAndOtherExpenses],
    attributes?.extrAndOtherPL,
    attributes.overriddenStatus.extrAndOtherPL
  );
  state.attributes.PLForPeriod = _sumValues(
    [state.attributes?.PLAfterTax, state.attributes?.extrAndOtherPL],
    attributes?.PLForPeriod,
    attributes.overriddenStatus.PLForPeriod
  );

  return state;
}

const initialState = {
  reportType: reportEnum.CREDIT_RATING,
  company: {},
  closingDate: null,
  isTemplateUploaded: false,
  creditRating: { rating: null },
  probabilityOfDefault: null,
  editable: true,
  status: 'Draft',
  note: '',
  isPortfolio: false,
  createdBy: null,
  updatedBy: null,
  finalizedBy: null,
  files: [],
  attributes: {
    naceSectorGroup: 'ALL',
    naceSector: null,
    option: 'Consolidated',
    months: null,
    currency: null,
    exchangeRate: null,
    fixedAssets: null,
    intangibleFixedAssets: null,
    tangibleFixedAssets: null,
    otherFixedAssets: null,
    currentAssets: null,
    stocks: null,
    debtors: null,
    otherCurrentAssets: null,
    cashAndCashEquivalent: null,
    totalAssets: null,
    shareholdersFunds: null,
    capital: null,
    otherShareholdersFunds: null,
    treasuryShares: null,
    nonCurrentLiabilities: null,
    longTermDebt: null,
    otherNonCurrentLiabilities: null,
    provisions: null,
    currentLiabilities: null,
    loans: null,
    creditors: null,
    otherCurrentLiabilities: null,
    totalShareFundsAndLiabilities: null,
    operatingRevenueTurnover: null,
    sales: null,
    costOfGoodSold: null,
    grossProfit: null,
    materialCosts: null,
    costOfEmployees: null,
    otherOperatingExpenses: null,
    depreciation: null,
    EBIT: null,
    EBITDA: null,
    financialPL: null,
    financialRevenue: null,
    financialExpenses: null,
    PLBeforeTax: null,
    taxation: null,
    PLAfterTax: null,
    extrAndOtherPL: null,
    extrAndOtherRevenue: null,
    extrAndOtherExpenses: null,
    PLForPeriod: null,
    overriddenStatus: {
      fixedAssets: false,
      currentAssets: false,
      totalAssets: false,
      shareholdersFunds: false,
      nonCurrentLiabilities: false,
      currentLiabilities: false,
      totalShareFundsAndLiabilities: false,
      capital: false,
      otherShareholdersFunds: false,
      treasuryShares: false,
      grossProfit: false,
      EBIT: false,
      EBITDA: false,
      financialPL: false,
      PLBeforeTax: false,
      PLAfterTax: false,
      extrAndOtherPL: false,
      PLForPeriod: false,
    },
  },
  errors: {},
  showErrors: false,
  isDirty: false,
};
const formKeys = ['company', 'closingDate', 'isTemplateUploaded', 'attributes'];

export function transform(creditRating) {
  return _.pick(creditRating, formKeys);
}

export const creditRatingSlice = createSlice({
  name: 'creditRating',
  initialState: {},
  reducers: {
    resetCreditRating: () => {
      return {};
    },
    clearCreditRating: () => {
      return initialState;
    },
    // For setting form with excel upload
    setCreditRatingAndEvaluate: (_state, action) => {
      const state = { ...initialState, ...action.payload, isDirty: true };
      return evaluateCreditRatingData(state);
    },
    // For credit rating create and view page
    setCreditRatingData: (state, action) => {
      return { ...initialState, ...action.payload, isDirty: false };
    },
    // For credit rating edit page ( set company with newest data )
    setCreditRating: (state, action) => {
      const { companies, company, ...rest } = action.payload;
      return {
        ...rest,
        company: createCompanyValue(getCompanyById(companies, company?.id), ['id', 'name', 'country']),
        isDirty: false,
      };
    },
    updateField: (_state, action) => {
      const { checkValid, ...rest } = action.payload;
      const state = { ..._state, ...rest, isDirty: true };
      if (state.showErrors && checkValid) {
        state.errors = isCreditRatingFormValid(state);
      }

      return state;
    },
    updateAttribute: (_state, action) => {
      const { checkValid, ...rest } = action.payload;
      const state = { ..._state, attributes: { ..._state.attributes, ...rest }, isDirty: true };
      if (state.showErrors && checkValid) {
        state.errors = isCreditRatingFormValid(state);
      }

      return state;
    },
    updateAttributeAndEvaluate: (_state, action) => {
      const state = { ..._state, attributes: { ..._state.attributes, ...action.payload }, isDirty: true };
      const evaluatedState = evaluateCreditRatingData(state);
      if (evaluatedState.showErrors) {
        evaluatedState.errors = isCreditRatingFormValid(evaluatedState);
      }

      return evaluatedState;
    },
    toggleAttributeOverriddenStatus: (_state, action) => {
      const { attribute } = action.payload;

      const state = {
        ..._state,
        attributes: {
          ..._state.attributes,
          overriddenStatus: {
            ..._state.attributes.overriddenStatus,
            [attribute]: !_state.attributes.overriddenStatus[attribute],
          },
        },
      };

      const evaluatedState = evaluateCreditRatingData(state);
      if (evaluatedState.showErrors) {
        evaluatedState.errors = isCreditRatingFormValid(evaluatedState);
      }

      return evaluatedState;
    },
    toggleCompanyPseudonym: (state, action) => {
      if (action.payload) {
        return { ...state, company: { ...state.company, pseudonym: `Company#${state.company.id}` } };
      }

      return { ...state, company: { ...state.company, pseudonym: undefined } };
    },
    setIsPristine: (state) => {
      state.isDirty = false;
    },
  },
});

export default creditRatingSlice.reducer;

export const {
  clearCreditRating,
  resetCreditRating,
  setCreditRating,
  updateField,
  updateAttribute,
  updateAttributeAndEvaluate,
  setIsPristine,
  setCreditRatingAndEvaluate,
  setCreditRatingData,
  toggleAttributeOverriddenStatus,
  toggleCompanyPseudonym,
} = creditRatingSlice.actions;

export const creditRating = (state) => state.creditRating;
