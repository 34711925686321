import { getReportUnit } from '~/utils/report';
import { displayNumber2 } from '~/utils/strings';
import { pricingApproachEnum } from '~/enums';

export function getBoundsReportData(data, userInfo) {
  const numberDisplayOptions = { decimalPoint: userInfo.decimalPoint, unit: getReportUnit(data.rateType) };

  return [
    { label: 'Upper Bound', value: displayNumber2(data.report.upperBound, numberDisplayOptions) },
    { label: 'Base', value: displayNumber2(data.report.midPoint, numberDisplayOptions) },
    { label: 'Lower Bound', value: displayNumber2(data.report.lowerBound, numberDisplayOptions) },
  ];
}

export function getLoanCalculationResultRenderValues(pricingApproach, lenderIsParent) {
  let subTitle, description, subject, nonStandardSentence;

  if (pricingApproach.includes('non-standard')) {
    subject = 'lender';
    nonStandardSentence = "The lender's rating was used since it gave a higher cost of debt than the borrower's.";
  } else {
    subject = 'borrower';
  }

  if (pricingApproach.includes(pricingApproachEnum.STANDALONE)) {
    subTitle = 'Standalone rating';
    description = `The arm's length price of the loan was calculated based on the
                   standalone rating of the
                  ${subject}. ${nonStandardSentence ?? ''} The results are presented below on a
                  per annum basis:`;
  } else {
    subTitle =
      pricingApproach.includes('non-standard') && lenderIsParent ? 'Rating' : 'Implicit support adjusted rating';
    description = `The arm's length price of the loan was calculated based on the
                   ${
                     pricingApproach.includes('non-standard') && lenderIsParent ? '' : 'implicit support adjusted'
                   } rating of the
                  ${subject}. ${nonStandardSentence ?? ''} The results are presented below on a
                  per annum basis:`;
  }

  return { subTitle, description };
}

export function getGuaranteeCalculationResultRenderValues(pricingApproach, report) {
  let subTitle, subTitleColor, description;

  if (report?.upperBound < 0) {
    subTitle = "Guarantee would not be agreed at arm's length";
    subTitleColor = 'blaze-orange';
    description =
      "The principal's cost of the liability to be guaranteed is less than that of the guarantor's cost, all else being equal. Thus, the principal would not be willing to pay for the guarantee since its credit quality would not be enhanced by it under the yield method.";
  } else if (report?.lowerBound > report?.upperBound) {
    subTitle = "Guarantee would not be agreed at arm's length";
    subTitleColor = 'blaze-orange';
    description =
      "The guarantor's risk in issuing the guarantee would require a guarantee fee that exceeds the benefit that it provides to the principal. Thus, the principal and guarantor would not be able to agree on a guarantee fee under the expected loss and yield methods.";
  } else {
    if (pricingApproach.includes(pricingApproachEnum.STANDALONE)) {
      subTitle = 'Standalone rating';
      subTitleColor = 'shakespeare';
      description = `The arm's length price of the guarantee was calculated based on the
                   standalone rating of the principal. The results are presented below on a
                  per annum basis:`;
    } else {
      subTitle = 'Implicit support adjusted rating';
      subTitleColor = 'shakespeare';
      description = `The arm's length price of the guarantee was calculated based on the implicit support adjusted rating of the principal. The results are presented below on a
                  per annum basis:`;
    }
  }

  return {
    subTitle,
    subTitleColor,
    description,
  };
}
