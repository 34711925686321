import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  'Primary Lender': true,
  'Ultimate Borrower': true,
  Currency: true,
  Amount: true,
  'Issue date': true,
  'Maturity date': true,
  'Tenor (yr.)': false,
  'Principal repayment': false,
  'Rate type': false,
  'Day count': false,
  'Interest compounding frequency': false,
  Seniority: false,
  'Ultimate Borrower rating standalone': false,
  'Ultimate Borrower rating implicit adj.': false,
  Created: false,
  Updated: false,
  Status: true,
  'Loan type': false,
  'Lower bound': false,
  Base: false,
  'Upper bound': false,
  Deleted: false,
  'Lender Type': false,
  Note: false,
};

export const backToBackLoanColumnsSlice = createSlice({
  name: 'backToBackLoanColumns',
  initialState,
  reducers: {
    resetForm: () => {
      return initialState;
    },
    setColumns: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateColumns: (state, action) => {
      return action.payload;
    },
  },
});

export default backToBackLoanColumnsSlice.reducer;

export const { resetForm, setColumns, updateColumns } = backToBackLoanColumnsSlice.actions;

export const backToBackLoanColumns = (state: { backToBackLoanColumns: any }) => state.backToBackLoanColumns;
