import { GroupBase, OptionContext, SingleValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { Box, Text } from 'ui';
import { withLabel } from 'ui/hocs';
import { SingleSelectWidthType, CreatableSelectOptionType } from 'types';

import components from '../SingleSelect/components';
import { getStyles } from '../SingleSelect/stylesFactory';

const widths = {
  xs: '120px',
  s: '227px',
  m: '252px',
  l: '277px',
  xl: '536px',
  fullWidth: '100%',
};

type CreatableSingleSelectProps = {
  error?: string;
  disabled?: boolean;
  hasGroupedOptions?: boolean;
  placeholder?: string;
  width?: SingleSelectWidthType;
  height?: string;
  dataTestId?: string;
  bg?: string;
  formatCreateLabel: (value: string) => string;
  formatOptionLabel: (option: CreatableSelectOptionType, { context }: { context: OptionContext }) => string;
  options: Array<CreatableSelectOptionType>;
  value: string;
  onChangeHandler: (newValue: SingleValue<CreatableSelectOptionType>) => void;
};

function CreatableSingleSelect({
  error,
  disabled = false,
  hasGroupedOptions = false,
  placeholder = '',
  width = 'm',
  height = 'input-height',
  bg,
  dataTestId,
  formatCreateLabel,
  formatOptionLabel,
  options = [],
  value,
  onChangeHandler,
}: CreatableSingleSelectProps) {
  const formatGroupLabel = (group: GroupBase<CreatableSelectOptionType>) => (
    <Text color="bali-hai" variant="s-spaced-medium-caps">
      {group.label}
    </Text>
  );

  function setValue() {
    if (hasGroupedOptions) {
      return (
        options
          .map((options) => options.options)
          .flat()
          .find((option) => option?.value === value) || null
      );
    } else {
      return options.find((option) => option.value === value) || null;
    }
  }

  return (
    <Box disabled={disabled} sx={{ flexShrink: '0', width: widths[width] }} dataTestId={dataTestId}>
      <CreatableSelect
        components={components}
        formatGroupLabel={formatGroupLabel}
        formatOptionLabel={formatOptionLabel}
        menuPlacement="auto"
        options={options}
        placeholder={placeholder}
        styles={getStyles({ error, height, bg })}
        formatCreateLabel={formatCreateLabel}
        value={setValue()}
        onChange={onChangeHandler}
      />
    </Box>
  );
}

export default withLabel(CreatableSingleSelect);
