import XLSX from 'xlsx';

export function toFile(response, options = {}) {
  const { defaultFilename = 'file', defaultMimeType = 'text/html' } = options;
  const { data, headers } = response;
  let type = defaultMimeType;
  let filename = defaultFilename;
  const contentType = headers['content-type'];
  const contentDisposition = headers['content-disposition'];
  if (contentType && contentDisposition) {
    const filenameMatch = contentDisposition.match(/filename=(.*)/);
    if (filenameMatch) {
      filename = filenameMatch[1].replace(/^"+|"+$/g, '');
    }
    type = contentType.split(';')[0];
  }

  return new File([data], filename, { type });
}

/** `range` is a number that determines how many rows should be skipped */
export async function sheetToJson(file, header = 1, range) {
  const workbook = XLSX.read(file, { cellDates: true, type: 'array' });
  return XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
    header,
    range,
    blankrows: false,
  });
}

export async function jsonToSheet(data, fileName) {
  const workbook = XLSX.utils.book_new();

  for (const { sheetData, sheetName } of data) {
    let worksheet;
    if (Array.isArray(sheetData) && Array.isArray(sheetData[0])) {
      worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    } else {
      worksheet = XLSX.utils.json_to_sheet(sheetData);
    }
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  }

  XLSX.writeFile(workbook, fileName);
}

/**
 * Numbers fixed to 3 decimal places because `sheetToJson` would sometimes,
 * for huge numbers, add something like .000005 to the end of the number
 */
export function transformToJSObject(data) {
  const object = {};
  for (let i = 0; i < data.length; i++) {
    const row = data[i];
    if (row.length !== 0) {
      if (!row[0]) continue;

      const rowName = row[0].trim();
      if (typeof row[1] === 'number') {
        object[rowName] = Number(row[1].toFixed(3));
      } else {
        object[rowName] = row[1];
      }
    }
  }

  return object;
}
