export const roundToXDecimals = (numberString: number | string, decimals = 2): string => {
  if (typeof numberString === 'number') {
    return numberString.toFixed(decimals);
  }
  return parseFloat(numberString).toFixed(decimals);
};

export const transformToPercentage = (numberString: string, decimals = 6): number => {
  if (numberString == null) return numberString;
  return Number((Number(numberString) / 100).toFixed(decimals));
};

export const isEqualWithPrecision = (num1: number, num2: number, precision = 5): boolean => {
  const multiplier = 10 ** precision;
  return Math.round(num1 * multiplier) === Math.round(num2 * multiplier);
};
