export enum TrancheAssetClassEnum {
  'Senior Secured' = 'Senior Secured',
  'Senior Unsecured' = 'Senior Unsecured',
  'Secured' = 'Secured',
  'Unsecured' = 'Unsecured',
  'Second Lien Secured' = 'Second Lien Secured',
  '1.5 Lien Secured' = '1.5 Lien Secured',
  'Junior Lien Secured' = 'Junior Lien Secured',
  'Subordinated' = 'Subordinated',
  'Convertible' = 'Convertible',
  'Mezzanine' = 'Mezzanine',
}

export enum TrancheTypeEnum {
  Term = 'Term',
  Revolver = 'Revolver',
}
