import { DateTime } from '~/components/Shared';
import { Box, Card, FlexLayout, Text } from '~/ui';
import { capitalize } from '~/utils/strings';
import { reportEnum } from '~/enums';

export function ReportCharacteristicsColumn({ data }) {
  return (
    <FlexLayout flexDirection="column">
      {data.map(({ label, value }, index) => (
        <FlexLayout alignItems="center" key={`report-item-${index}`} py={4} sx={{ borderTop: index !== 0 && 'border' }}>
          <Text color="bali-hai" variant="m-spaced-medium" sx={{ flexBasis: '50%' }}>
            {label}
          </Text>
          <Text color="deep-sapphire" variant="m-spaced-medium" sx={{ flexBasis: '50%' }}>
            {value || '\u2014'}
          </Text>
        </FlexLayout>
      ))}
    </FlexLayout>
  );
}

function ReportCharacteristics({ data, split, reportType, createdAt, updatedAt }) {
  const getReportName = (reportType) => {
    if (reportType === reportEnum.BACK_TO_BACK_LOAN) {
      return 'Back-to-back Loan';
    }

    return capitalize(reportType);
  };

  return (
    <Card
      flexGrow="1"
      title={
        <FlexLayout alignItems="center" justifyContent="space-between" space={6}>
          <Box>{`${getReportName(reportType)} Characteristics`}</Box>
          <Box>
            <Text
              bg="white"
              color="bali-hai"
              px={2}
              py={1}
              sx={{ border: 'border', borderRadius: 'm', lineHeight: 'inherit', height: 'fit-content' }}
              variant="xs-spaced-medium"
            >
              Created on: <DateTime withTime>{createdAt}</DateTime>
            </Text>
            <Text
              bg="white"
              color="bali-hai"
              px={2}
              py={1}
              ml={2}
              sx={{ border: 'border', borderRadius: 'm', lineHeight: 'inherit', height: 'fit-content' }}
              variant="xs-spaced-medium"
            >
              Updated on: <DateTime withTime>{updatedAt}</DateTime>
            </Text>
          </Box>
        </FlexLayout>
      }
      pt={6}
    >
      {split ? (
        <Box sx={{ display: 'grid', gridGap: 16, gridTemplateColumns: 'repeat(2, 1fr)' }}>
          <ReportCharacteristicsColumn data={data.slice(0, Math.ceil(data.length / 2))} />
          <ReportCharacteristicsColumn data={data.slice(Math.ceil(data.length / 2), data.length)} />
        </Box>
      ) : (
        <ReportCharacteristicsColumn data={data} />
      )}
    </Card>
  );
}

export default ReportCharacteristics;
