export const apiPaths = Object.freeze({
  azureStorage: '/azure-storage',
  cashPool: '/cash-pool',
  client: '/client',
  clientTemplate: '/client-template',
  company: '/company',
  creditRating: '/credit-rating',
  cuftData: '/cuft-data',
  feature: '/feature',
  guarantee: '/guarantee',
  loan: '/loan',
  b2bLoan: '/b2b-loan',
  notification: '/notification',
  oauth: '/oauth',
  user: '/user',
  wht: '/withholding-tax',
  setupData: '/setup-data',
  tableColumn: '/table-column',
});
