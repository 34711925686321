import { useState } from 'react';
import InlineSvg from 'react-inlinesvg';
import { useHistory } from 'react-router-dom';

import { resetPassword } from 'api';
import logoSrc from 'assets/images/logo-light-bg.svg';
import { isUserAuthenticated } from 'auth';
import { useQuery } from 'hooks';
import { routesEnum } from 'routes';
import { Box, Button, FlexLayout, TextInput } from 'ui';
import { showErrorToast, showToast } from 'ui/components/Toast';

type InputErrorsType = {
  newPassword?: string;
  confirmPassword?: string;
};

const ResetPasswordPage = () => {
  const history = useHistory();
  const query = useQuery();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [inputErrors, setInputErrors] = useState<InputErrorsType>({});

  const handleSaveNewPasswordClick = () => {
    setInputErrors({});
    if (newPassword.length < 12) {
      return setInputErrors({ newPassword: 'Password has to be 12 characters or longer' });
    }
    if (newPassword !== confirmPassword) {
      return setInputErrors({ confirmPassword: 'Password and confirm password do not match' });
    }

    const token = query.get('token');
    if (!token) {
      return showErrorToast('Token missing');
    }
    resetPassword({ newPassword, token })
      .then(() => {
        history.push(routesEnum.LOGIN);
        showToast('Password successfully reset');
      })
      .catch(() => showErrorToast());
  };

  if (isUserAuthenticated) {
    history.push(routesEnum.DASHBOARD);
    return null;
  }

  return (
    <FlexLayout
      alignItems="center"
      alignSelf="center"
      bg="white"
      flexDirection="column"
      m="0 auto"
      px={12}
      py={8}
      space={6}
      sx={{ alignSelf: 'center', borderRadius: 'm', boxShadow: 'depth-1', width: 'login-form-width' }}
    >
      <Box sx={{ flexShrink: '0' }}>
        <InlineSvg src={logoSrc} />
      </Box>
      <TextInput
        label="New Password"
        type="password"
        variant="password"
        value={newPassword}
        sx={{ width: '100%' }}
        width="fullWidth"
        onChange={setNewPassword}
        error={inputErrors?.newPassword}
      />
      <TextInput
        label="Confirm Password"
        type="password"
        variant="password"
        value={confirmPassword}
        sx={{ width: '100%' }}
        width="fullWidth"
        onChange={setConfirmPassword}
        error={inputErrors?.confirmPassword}
      />
      <Button
        disabled={!newPassword || !confirmPassword}
        text="Save Password"
        size="l"
        sx={{ mt: 4, width: '100%' }}
        onClick={handleSaveNewPasswordClick}
      />
    </FlexLayout>
  );
};

export default ResetPasswordPage;
