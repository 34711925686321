import React from 'react';

import { SingleSelect } from '~/ui';
import { getOptionsWithValueFromArray } from '~/utils/arrays';

function SimpleCompanySingleSelect({
  error,
  label = 'Data',
  tooltip,
  options,
  value,
  width = 'm',
  height = 'input-height-small',
  onChange,
}) {
  return (
    <SingleSelect
      error={error}
      label={label}
      options={options && getOptionsWithValueFromArray(options, 'name', 'id')}
      tooltip={tooltip}
      value={value}
      width={width}
      height={height}
      onChange={onChange}
    />
  );
}

export default SimpleCompanySingleSelect;
