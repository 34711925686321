import { rolesEnum } from '~/enums';
import { SingleSelect } from '~/ui';
import { capitalize } from '~/utils/strings';

export const rolesOptions = [
  { value: rolesEnum.USER, label: capitalize(rolesEnum.USER) },
  { value: rolesEnum.ADMIN, label: capitalize(rolesEnum.ADMIN) },
];

function RoleSingleSelect({ label = 'Role', value, width = 'm', onChange }) {
  return <SingleSelect label={label} options={rolesOptions} value={value} width={width} onChange={onChange} />;
}

export default RoleSingleSelect;
