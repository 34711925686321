import React from 'react';
import InlineSvg from 'react-inlinesvg';

import { Box, styles } from 'ui';
import * as icons from 'ui/assets/icons';

export type IconTypes = keyof typeof icons;

interface IconProps {
  color?: string;
  disabled?: boolean;
  icon: IconTypes;
  size?: string;
  sx?: any;
  isShowing?: boolean;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
}

const Icon = React.forwardRef<HTMLElement, IconProps>(
  ({ color, disabled, icon, size = 'm', sx, isShowing = true, onClick, ...rest }, ref) => {
    const iconSrc = icons[icon] as IconTypes; // eslint-disable-line import/namespace
    if (!iconSrc) {
      return null;
    }

    const clickableStyle = disabled || onClick || rest.onMouseEnter ? styles.interactions.clickable : {};

    if (!isShowing) return null;

    return (
      <Box
        disabled={disabled}
        ref={ref}
        sx={{
          height: (theme: any) => theme.sizes[`icon-${size}`],
          width: (theme: any) => theme.sizes[`icon-${size}`],
          ...clickableStyle,
          ...sx,
          alignItems: 'center',
          color,
          display: 'flex',
          flexGrow: '0',
          flexShrink: '0',
          justifyContent: 'center',
          svg: {
            height: '100%',
            width: '100%',
          },
        }}
        onClick={onClick}
        {...rest}
      >
        <InlineSvg src={iconSrc} />
      </Box>
    );
  }
);

export default Icon;
