import React from 'react';

import { rolesEnum } from 'enums';
import { ClientFeatureWithFeatureType } from 'types';

const Login = React.lazy(() => import('auth/Login'));
const AzureRedirect = React.lazy(() => import('auth/azure'));
const ResetPasswordPage = React.lazy(() => import('auth/ResetPasswordPage'));
const CashPoolsEditPage = React.lazy(() => import('components/CashPoolsEditPage'));
const CashPoolStatementDataPage = React.lazy(() => import('components/CashPoolStatementDataPage'));
const CashPoolsPage = React.lazy(() => import('components/CashPoolsPage'));
const CashPoolTopCurrencyAccountEditPage = React.lazy(() => import('components/CashPoolTopCurrencyAccountEditPage'));
const CashPoolViewPage = React.lazy(() => import('components/CashPoolViewPage'));
const CashPoolAuditTrailViewPage = React.lazy(() => import('components/CashPoolAuditTrailViewPage'));
const CashPoolAuditTrailsPage = React.lazy(() => import('components/CashPoolAuditTrailsPage'));
const AuditTrailTopCurrencyAccountPage = React.lazy(() => import('components/AuditTrailTopCurrencyAccountPage'));
const ClientsPage = React.lazy(() => import('components/ClientsPage'));
const ClientFeaturesPage = React.lazy(() => import('components/ClientFeaturesPage'));
const CompanyAuditTrailPage = React.lazy(() => import('components/CompanyAuditTrailPage'));
const CompanyAuditTrailViewPage = React.lazy(() => import('components/CompanyAuditTrailViewPage'));
const CompanyCreatePage = React.lazy(() => import('components/CompanyCreatePage'));
const CompanyViewPage = React.lazy(() => import('components/CompanyViewPage'));
const CompoundPeriodPaymentsPage = React.lazy(() => import('components/CompoundPeriodPaymentsPage'));
const CreditRatingPage = React.lazy(() => import('components/CreditRatingPage'));
const DashboardPage = React.lazy(() => import('components/DashboardPage'));
const GroupInformationPage = React.lazy(() => import('components/GroupInformationPage'));
const ImportedReportCreatePage = React.lazy(() => import('components/ImportedReportCreatePage'));
const NotificationsPage = React.lazy(() => import('components/NotificationsPage'));
const TrashPage = React.lazy(() => import('components/TrashPage'));
const PaymentsPage = React.lazy(() => import('components/PaymentsPage'));
const PortfolioPage = React.lazy(() => import('components/PortfolioPage'));
const PriceAndBenchmarkPage = React.lazy(() => import('components/PriceAndBenchmarkPage'));
const ReportEditPage = React.lazy(() => import('components/ReportEditPage'));
const ReportsPage = React.lazy(() => import('components/ReportsPage'));
const ReportViewPage = React.lazy(() => import('components/ReportViewPage'));
const SettingsPage = React.lazy(() => import('components/SettingsPage'));
const UsersPage = React.lazy(() => import('components/UsersPage'));
const CuftDataPage = React.lazy(() => import('components/CuftDataPage'));
const CuftDataUploadPage = React.lazy(() => import('components/CuftDataUploadPage'));

export const routesEnum = {
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  DASHBOARD: '/dashboard',
  CREDIT_RATING: '/credit-rating',
  GROUP_INFORMATION: '/group-information',
  GROUP_INFORMATION_NEW: '/group-information/new',
  GROUP_INFORMATION_COMPANY: '/group-information/:companyId',
  GROUP_INFORMATION_AUDIT_TRAIL: '/group-information/:companyId/audit-trail',
  GROUP_INFORMATION_AUDIT_TRAIL_SINGLE: '/group-information/:companyId/audit-trail/:auditTrailId',
  PRICE_AND_BENCHMARK: '/price-and-benchmark',
  ANALYSES_IMPORT: '/analyses/import',
  ANALYSIS_EDIT: '/analyses/:reportId/edit',
  ANALYSIS_VIEW: '/analyses/:reportId',
  ANALYSES: '/analyses',
  PORTFOLIO_VIEW: '/portfolio/:reportId',
  PORTFOLIO: '/portfolio',
  TRASH_VIEW: '/trash/:reportId',
  TRASH: '/trash',
  NOTIFICATIONS: '/notifications',
  USERS: '/users',
  CLIENTS: '/clients',
  CLIENTS_FEATURES: '/clients/:clientId/client-features',
  PAYMENTS: '/payments',
  CASH_POOLS: '/cash-pools',
  CASH_POOLS_NEW: '/cash-pools/new',
  CASH_POOL_VIEW: '/cash-pools/:cashPoolId',
  CASH_POOL_EDIT: '/cash-pools/:cashPoolId/edit',
  CASH_POOL_STATEMENT_DATA: '/cash-pools/:cashPoolId/statement-data',
  CASH_POOL_TOP_CURRENCY_ACCOUNT_NEW: '/cash-pools/top-currency-account/new',
  CASH_POOL_TOP_CURRENCY_ACCOUNT_EDIT: '/cash-pools/top-currency-account/:topCurrencyAccountId/edit',
  CASH_POOL_AUDIT_TRAIL_TOP_CURRENCY_ACCOUNT:
    '/cash-pools/:cashPoolId/audit-trail/:auditTrailId/top-currency-account/:auditTrailTopCurrencyAccountId',
  CASH_POOL_AUDIT_TRAIL: '/cash-pools/:cashPoolId/audit-trail/:auditTrailId',
  CASH_POOL_AUDIT_TRAIL_ALL: '/cash-pools/:cashPoolId/audit-trail',
  COMPOUND_PERIOD_PAYMENTS: '/payments/:loanId/compound-periods',
  SETTINGS: '/settings',
  CUFT_DATA: '/cuft',
  CUFT_DATA_UPLOAD: '/upload-cuft',
  AZURE_REDIRECT: '/azure/redirect',
};

const sharedPages = {
  [routesEnum.LOGIN]: {
    component: Login,
    documentTitle: 'TP Accurate',
    path: routesEnum.LOGIN,
  },
  [routesEnum.RESET_PASSWORD]: {
    component: ResetPasswordPage,
    documentTitle: 'Reset Password',
    path: routesEnum.RESET_PASSWORD,
  },
  [routesEnum.AZURE_REDIRECT]: {
    component: AzureRedirect,
    documentTitle: 'Azure Redirect',
    path: routesEnum.AZURE_REDIRECT,
  },
  [routesEnum.DASHBOARD]: {
    component: DashboardPage,
    documentTitle: 'Dashboard',
    navbarIcon: 'home',
    navbarTitle: 'Dashboard',
    path: routesEnum.DASHBOARD,
  },
  [routesEnum.GROUP_INFORMATION_NEW]: {
    component: CompanyCreatePage,
    documentTitle: 'Create Company',
    path: routesEnum.GROUP_INFORMATION_NEW,
  },
  [routesEnum.GROUP_INFORMATION_AUDIT_TRAIL_SINGLE]: {
    component: CompanyAuditTrailViewPage,
    documentTitle: 'Company Audit Trail',
    path: routesEnum.GROUP_INFORMATION_AUDIT_TRAIL_SINGLE,
  },
  [routesEnum.GROUP_INFORMATION_AUDIT_TRAIL]: {
    component: CompanyAuditTrailPage,
    documentTitle: 'Company Audit Trail',
    path: routesEnum.GROUP_INFORMATION_AUDIT_TRAIL,
  },
  [routesEnum.GROUP_INFORMATION_COMPANY]: {
    component: CompanyViewPage,
    documentTitle: 'Company',
    path: routesEnum.GROUP_INFORMATION_COMPANY,
  },
  [routesEnum.GROUP_INFORMATION]: {
    component: GroupInformationPage,
    documentTitle: 'Group Information',
    navbarIcon: 'grid',
    navbarTitle: 'Group Information',
    path: routesEnum.GROUP_INFORMATION,
  },
  [routesEnum.PRICE_AND_BENCHMARK]: {
    component: PriceAndBenchmarkPage,
    documentTitle: 'Price and Benchmark',
    navbarIcon: 'target',
    navbarTitle: 'Price and Benchmark',
    path: routesEnum.PRICE_AND_BENCHMARK,
  },
  [routesEnum.ANALYSIS_EDIT]: {
    component: ReportEditPage,
    documentTitle: 'Analysis',
    path: routesEnum.ANALYSIS_EDIT,
  },
  [routesEnum.ANALYSES_IMPORT]: {
    component: ImportedReportCreatePage,
    documentTitle: 'Import Report',
    path: routesEnum.ANALYSES_IMPORT,
  },
  [routesEnum.ANALYSIS_VIEW]: {
    component: ReportViewPage,
    documentTitle: 'Analysis',
    path: routesEnum.ANALYSIS_VIEW,
  },
  [routesEnum.ANALYSES]: {
    component: ReportsPage,
    documentTitle: 'Analyses',
    navbarIcon: 'metrics',
    navbarTitle: 'Analyses',
    path: routesEnum.ANALYSES,
  },
  [routesEnum.PORTFOLIO_VIEW]: {
    component: ReportViewPage,
    documentTitle: 'Portfolio Report',
    path: routesEnum.PORTFOLIO_VIEW,
  },
  [routesEnum.PORTFOLIO]: {
    component: PortfolioPage,
    documentTitle: 'Portfolio',
    navbarIcon: 'portfolio',
    navbarTitle: 'Portfolio',
    path: routesEnum.PORTFOLIO,
  },
  [routesEnum.TRASH_VIEW]: {
    component: ReportViewPage,
    documentTitle: 'Deleted Report',
    path: routesEnum.TRASH_VIEW,
  },
  [routesEnum.TRASH]: {
    component: TrashPage,
    documentTitle: 'Trash',
    path: routesEnum.TRASH,
  },
  [routesEnum.NOTIFICATIONS]: {
    component: NotificationsPage,
    documentTitle: 'Notifications',
    path: routesEnum.NOTIFICATIONS,
    roles: [rolesEnum.SUPERADMIN, rolesEnum.ADMIN],
  },
  [routesEnum.USERS]: {
    component: UsersPage,
    documentTitle: 'Users',
    path: routesEnum.USERS,
    roles: [rolesEnum.SUPERADMIN, rolesEnum.ADMIN],
  },
  [routesEnum.CLIENTS_FEATURES]: {
    component: ClientFeaturesPage,
    documentTitle: 'Client Features',
    path: routesEnum.CLIENTS_FEATURES,
  },
  [routesEnum.CLIENTS]: {
    component: ClientsPage,
    documentTitle: 'Clients',
    path: routesEnum.CLIENTS,
    roles: [rolesEnum.SUPERADMIN],
  },
  [routesEnum.SETTINGS]: {
    component: SettingsPage,
    documentTitle: 'Settings',
    path: routesEnum.SETTINGS,
  },
};

const creditRatingPages = {
  path: '/credit-rating',
  routes: {
    [routesEnum.CREDIT_RATING]: {
      component: CreditRatingPage,
      documentTitle: 'Credit Rating',
      navbarIcon: 'aPlus',
      navbarTitle: 'Credit Rating',
      path: routesEnum.CREDIT_RATING,
    },
  },
};

const paymentsPages = {
  path: '/payments',
  routes: {
    [routesEnum.COMPOUND_PERIOD_PAYMENTS]: {
      component: CompoundPeriodPaymentsPage,
      documentTitle: 'Payments',
      navbarIcon: 'payments',
      navbarTitle: 'Payments',
      path: routesEnum.COMPOUND_PERIOD_PAYMENTS,
    },
    [routesEnum.PAYMENTS]: {
      component: PaymentsPage,
      documentTitle: 'Payments',
      navbarIcon: 'payments',
      navbarTitle: 'Payments',
      path: routesEnum.PAYMENTS,
    },
  },
};

const cashPoolPages = {
  path: '/cash-pools',
  routes: {
    [routesEnum.CASH_POOL_TOP_CURRENCY_ACCOUNT_NEW]: {
      component: CashPoolTopCurrencyAccountEditPage,
      documentTitle: 'Top Currency Account',
      path: routesEnum.CASH_POOL_TOP_CURRENCY_ACCOUNT_NEW,
    },
    [routesEnum.CASH_POOL_TOP_CURRENCY_ACCOUNT_EDIT]: {
      component: CashPoolTopCurrencyAccountEditPage,
      documentTitle: 'Edit Top Currency Account',
      path: routesEnum.CASH_POOL_TOP_CURRENCY_ACCOUNT_EDIT,
    },
    [routesEnum.CASH_POOL_EDIT]: {
      component: CashPoolsEditPage,
      documentTitle: 'Edit Cash Pool',
      path: routesEnum.CASH_POOL_EDIT,
    },
    [routesEnum.CASH_POOL_STATEMENT_DATA]: {
      component: CashPoolStatementDataPage,
      documentTitle: 'Edit Cash Pool Statement Data',
      path: routesEnum.CASH_POOL_STATEMENT_DATA,
    },
    [routesEnum.CASH_POOL_AUDIT_TRAIL_TOP_CURRENCY_ACCOUNT]: {
      component: AuditTrailTopCurrencyAccountPage,
      documentTitle: 'Cash Pool Audit Trail Top Currency Account',
      path: routesEnum.CASH_POOL_AUDIT_TRAIL_TOP_CURRENCY_ACCOUNT,
    },
    [routesEnum.CASH_POOL_AUDIT_TRAIL]: {
      component: CashPoolAuditTrailViewPage,
      documentTitle: 'Cash Pool Audit Trail',
      path: routesEnum.CASH_POOL_AUDIT_TRAIL,
    },
    [routesEnum.CASH_POOL_AUDIT_TRAIL_ALL]: {
      component: CashPoolAuditTrailsPage,
      documentTitle: 'Cash Pool Audit Trail',
      path: routesEnum.CASH_POOL_AUDIT_TRAIL_ALL,
    },
    [routesEnum.CASH_POOLS_NEW]: {
      component: CashPoolsEditPage,
      documentTitle: 'New Cash Pool',
      path: routesEnum.CASH_POOLS_NEW,
    },
    [routesEnum.CASH_POOL_VIEW]: {
      component: CashPoolViewPage,
      documentTitle: 'Cash Pool',
      path: routesEnum.CASH_POOL_VIEW,
    },
    [routesEnum.CASH_POOLS]: {
      component: CashPoolsPage,
      documentTitle: 'Cash Pooling',
      navbarIcon: 'cashPools',
      navbarTitle: 'Cash Pooling',
      path: routesEnum.CASH_POOLS,
    },
  },
};

const cuftPages = {
  path: '/cuft',
  routes: {
    [routesEnum.CUFT_DATA]: {
      component: CuftDataPage,
      documentTitle: 'CUFT Agreements',
      navbarIcon: 'paper',
      navbarTitle: 'CUFT Agreements',
      path: routesEnum.CUFT_DATA,
    },
    [routesEnum.CUFT_DATA_UPLOAD]: {
      component: CuftDataUploadPage,
      documentTitle: 'CUFT Agreements Upload',
      navbarIcon: 'paper',
      navbarTitle: 'CUFT Agreements Upload',
      path: routesEnum.CUFT_DATA_UPLOAD,
      roles: [rolesEnum.SUPERADMIN],
    },
  },
};

export const allPages = {
  ...sharedPages,
  ...creditRatingPages.routes,
  ...paymentsPages.routes,
  ...cashPoolPages.routes,
  ...cuftPages.routes,
};

const additionalPages = [creditRatingPages, paymentsPages, cashPoolPages, cuftPages];

/**
 * Path in module/pages objects (paymentsPages, cashPoolPages...) have to match
 * the path column in the Features table. Only enabled features are sent from the backend
 * and the clientFeatures array contains only features marked as modules (isModule = true).
 * Also, only module features have a non-null path column.
 * When clientFeatures are undefined allPages are returned. This happens for a moment before
 * userInfo data is fetched. Returning allPages allows the refresh on one of the module pages
 * without being redirected to the Dashboard.
 */
export const getRoutes = (clientFeatures: ClientFeatureWithFeatureType[]) => {
  if (!clientFeatures) return allPages;

  let availablePages = { ...sharedPages };
  for (const features of clientFeatures) {
    for (const pages of additionalPages) {
      if (features.feature.path === pages.path) {
        availablePages = { ...availablePages, ...pages.routes };
      }
    }
  }
  return availablePages;
};
