import { useEffect, useState } from 'react';

import { CollapsibleCard, EditableText, FlexLayout } from '~/ui';

function NotesCard({ description, editMode = true, note, onChange, onSave }) {
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    if (note) setCollapsed(false);
  }, [note]);

  return (
    <CollapsibleCard
      collapsed={collapsed}
      description={description}
      title="Notes"
      onCollapseChange={() => setCollapsed(!collapsed)}
    >
      <FlexLayout disabled={!editMode} flexDirection="column">
        <EditableText
          showButton={editMode}
          isFocused={!collapsed}
          value={note}
          placeholder="Write a note"
          onChange={onChange}
          onSave={onSave}
        />
      </FlexLayout>
    </CollapsibleCard>
  );
}

export default NotesCard;
