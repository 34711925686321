import React from 'react';

import { FlexLayout, Text } from 'ui';

interface CardProps {
  children: React.ReactNode;
  title?: string | React.ReactNode;
  space?: number;
  p?: number;
  pb?: number;
  pt?: number;
  sx?: any;
  bg?: string;
  px?: number;
}

function Card({ children, title, space = 6, sx = {}, ...rest }: CardProps) {
  return (
    <FlexLayout
      bg="white"
      flexDirection="column"
      flexShrink="0"
      px={6}
      space={space}
      {...rest}
      sx={{ borderRadius: 'm', boxShadow: 'depth-1', ...sx }}
    >
      {title && (
        <Text color="deep-sapphire" variant="xl-spaced-bold">
          {title}
        </Text>
      )}
      {children}
    </FlexLayout>
  );
}
export default Card;
