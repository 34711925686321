import React, { useEffect, useState } from 'react';

import { getCashPools } from '~/api';
import { SingleSelect } from '~/ui';
import { getOptionsWithValueFromArray } from '~/utils/arrays';

function CashPoolSingleSelect({ label = 'Cash Pool', tooltip, width = 'fullWidth', value, onChange }) {
  const [cashPools, setCashPools] = useState();

  useEffect(() => {
    getCashPools().then(setCashPools);
  }, []);

  return (
    <SingleSelect
      label={label}
      tooltip={tooltip}
      options={cashPools && getOptionsWithValueFromArray(cashPools, 'name', 'id')}
      value={value}
      width={width}
      onChange={onChange}
    />
  );
}

export default CashPoolSingleSelect;
