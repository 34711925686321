import { RightSideComponent, Icon } from 'ui';

type LockInputIconProps = {
  isUnlockable?: boolean;
  isLocked: boolean;
  isShowing: boolean;
  setIsLocked: React.Dispatch<React.SetStateAction<boolean>>;
};

const LockInputIcon = ({ isUnlockable = true, isLocked, setIsLocked, isShowing }: LockInputIconProps) => {
  if (!isShowing) return null;

  return (
    <RightSideComponent>
      <Icon
        icon={isLocked ? 'lock' : 'openedLock'}
        size="m"
        sx={{ cursor: isUnlockable ? 'pointer' : 'not-allowed' }}
        onClick={() => {
          if (isUnlockable) {
            setIsLocked(!isLocked);
          }
        }}
      />
    </RightSideComponent>
  );
};

export default LockInputIcon;
