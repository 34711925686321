import { FlexLayout, Text } from 'ui';

type ToastProps = {
  text: string;
  bg?: string;
  dataTestId?: string;
};

function Toast({ text, bg = 'deep-sapphire', dataTestId }: ToastProps) {
  return (
    <FlexLayout bg={bg} p={4} sx={{ borderRadius: 'm', width: 'toast-width' }} dataTestId={dataTestId}>
      <Text color="white" variant="m-spaced-medium">
        {text}
      </Text>
    </FlexLayout>
  );
}

export default Toast;
