import PT from 'prop-types';

import { FlexLayout, Text } from '~/ui';

function Tabs({
  isShowing = true,
  selectedTab,
  tabs = [],
  onTabSelect,
  minWidth = 'tab-width',
  height = 'tab-height',
  textVariant = 'm-spaced-medium-caps',
  colorSelected = 'deep-sapphire',
}) {
  if (!isShowing) return null;

  return (
    <FlexLayout alignItems="center" sx={{ border: 'border', borderRadius: 'm', width: 'fit-content' }}>
      {tabs.map((tab, index) => {
        const { label, value, dataTestId } = tab;
        const isSelected = value === selectedTab;
        const isFirst = index === 0;
        const isLast = index === tabs.length - 1;

        return (
          <FlexLayout
            alignItems="center"
            bg={isSelected ? colorSelected : 'white'}
            justifyContent="center"
            key={tab.value}
            px={4}
            sx={{
              height,
              minWidth,
              borderRight: isLast ? undefined : 'border',
              borderBottomLeftRadius: isFirst ? 'm' : undefined,
              borderBottomRightRadius: isLast ? 'm' : undefined,
              borderTopLeftRadius: isFirst ? 'm' : undefined,
              borderTopRightRadius: isLast ? 'm' : undefined,
            }}
            dataTestId={dataTestId}
            onClick={() => onTabSelect(value)}
          >
            <Text color={isSelected ? 'white' : 'bali-hai'} variant={textVariant}>
              {label}
            </Text>
          </FlexLayout>
        );
      })}
    </FlexLayout>
  );
}

const tabPropType = PT.shape({
  label: PT.string,
  value: PT.string,
});

Tabs.propTypes = {
  selectedTab: PT.string,
  tabs: PT.arrayOf(tabPropType),
  onTabSelect: PT.func,
};

export default Tabs;
