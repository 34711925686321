import { Text, PageLayout, FlexLayout, Card } from 'ui';
import { routesEnum } from 'routes';

const ErrorBoundary = () => {
  return (
    <FlexLayout
      sx={{ position: 'absolute', top: 0, right: 0, width: '100vw', height: '100vh' }}
      onClick={() => (window.location.href = routesEnum.DASHBOARD)}
    >
      <FlexLayout pl={250} flexGrow="1">
        <PageLayout title="Something went wrong">
          <Card p={4}>
            <Text>Our team has been notified and will start working on the fix immediately.</Text>
            <Text color="shakespeare">Go to the Dashboard.</Text>
          </Card>
        </PageLayout>
      </FlexLayout>
    </FlexLayout>
  );
};

export default ErrorBoundary;
