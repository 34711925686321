import React from 'react';
import { useHistory } from 'react-router-dom';

import { routesEnum } from '~/routes';
import { Box, Text } from '~/ui';

const NotificationBadge = ({ notificationNumber }) => {
  const history = useHistory();

  const getNotificationBadgeBackgroundColor = () =>
    history.location.pathname === routesEnum.NOTIFICATIONS ? 'deep-sapphire-06' : 'shakespeare';

  if (!notificationNumber) return null;

  return (
    <Box
      sx={{
        borderRadius: '14px',
        backgroundColor: getNotificationBadgeBackgroundColor(),
        padding: '2px 8px',
        marginLeft: '40px',
      }}
    >
      <Text variant="m-spaced-medium" color="white">
        {notificationNumber}
      </Text>
    </Box>
  );
};

export default NotificationBadge;
