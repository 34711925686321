import { backend } from 'services';
import type { ClientFeatureStateType, ClientFeatureWithFeatureType, ClientFeatureUsedNumbersType } from 'types';

import { apiPaths } from './routes';

export function getClientFeatures(): Promise<ClientFeatureWithFeatureType[]> {
  return backend.get(apiPaths.feature);
}

export function getClientFeaturesUsedNumbers(): Promise<ClientFeatureUsedNumbersType> {
  return backend.get(`${apiPaths.feature}/used-number`);
}

export function getSpecificClientFeatures(clientId: number): Promise<ClientFeatureWithFeatureType[]> {
  return backend.get(`${apiPaths.feature}/client/${clientId}`);
}

export function updateClientFeatures(clientId: number, data: ClientFeatureStateType): Promise<void> {
  return backend.patch(`${apiPaths.feature}/client/${clientId}`, data);
}
