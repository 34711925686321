import 'react-toastify/dist/ReactToastify.css';
import '~/styles/react-toastify-overrides.css';

import { Slide, toast, ToastContainer } from 'react-toastify';

import Toast from './Toast';

export function ToastMessageContainer() {
  return (
    <ToastContainer
      autoClose={3000}
      className="toast-container"
      closeButton={false}
      closeOnClick
      draggable={false}
      hideProgressBar={true}
      pauseOnFocusLoss={false}
      pauseOnHover={false}
      position="top-right"
      toastClassName="toast-message"
      transition={Slide}
    />
  );
}

export function showToast(text: string) {
  toast(<Toast text={text} dataTestId="successToast" />);
}

export function showWarningToast(text: string) {
  toast(<Toast text={text} bg="blaze-orange" dataTestId="warningToast" />, { autoClose: 5000 });
}

export function showErrorToast(text = 'Something went wrong. Please try again.') {
  toast(<Toast text={text} bg="pomegranate" dataTestId="errorToast" />, { autoClose: 5000 });
}
