import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { getAccessToken, setNextPath } from 'auth';
import { routesEnum } from 'routes';

type RedirectAfterLoginProps = {
  children: React.ReactNode;
};

/**
 * Used for redirecting user to certain page after logging in
 * User receives a link to for example /analyses/200?reportType=loan.
 * If the user isn't logged in he will be redirected to /login.
 * After logging in we want to redirect the user to /analyses/200?reportType=loan.
 */
const RedirectAfterLogin = ({ children }: RedirectAfterLoginProps) => {
  const history = useHistory();

  useEffect(() => {
    const pathname = history.location.pathname;
    const ignoredPaths = [routesEnum.LOGIN, routesEnum.AZURE_REDIRECT];

    if (!getAccessToken()) {
      if (ignoredPaths.every((ignoredPath) => ignoredPath !== pathname)) {
        setNextPath(`${pathname}${history.location.search}`);
      }
    }
  }, [history]);

  return <>{children}</>;
};

export default RedirectAfterLogin;
