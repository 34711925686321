import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

type InitialStateType = {
  dateRange: { startDate: Date | null; endDate: Date | null };
  statementDate: Date | null;
  companyId: number | null;
  source: string | null;
  isUsed: boolean | null;
  offset: number;
  limit: number;
  sort: string;
};

const initialState: InitialStateType = {
  dateRange: { startDate: null, endDate: null },
  statementDate: null,
  companyId: null,
  source: null,
  isUsed: null,
  offset: 0,
  limit: 10,
  sort: 'date',
};

export const statementDataFiltersSlice = createSlice({
  name: 'statementDataFilters',
  initialState,
  reducers: {
    resetStatementDataFilter: () => {
      return initialState;
    },
    updatePagination: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateField: (state, action) => {
      return { ...state, ...action.payload, offset: 0 };
    },
    updateDateRange: (state, action) => {
      const { startDate, endDate } = action.payload;
      state.dateRange.startDate = startDate ?? undefined;
      state.dateRange.endDate = endDate ?? undefined;
    },
  },
});

export default statementDataFiltersSlice.reducer;

export const { resetStatementDataFilter, updatePagination, updateField, updateDateRange } =
  statementDataFiltersSlice.actions;

export const statementDataFiltersSelector = (state: RootState) => state.statementDataFilters;
