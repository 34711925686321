import React, { useContext } from 'react';

import { DateTime } from '~/components/Shared';
import { UserInfoContext } from '~/context/user';
import { ORIGINAL, UPDATED } from '~/enums/reports';
import { Button, Card, FlexLayout, Modal, Text } from '~/ui';
import { getReportUnit } from '~/utils/report';
import { capitalize, displayNumber } from '~/utils/strings';

import BoundsGraph from '../ReportViewPage/CalculationResults/BoundsGraph';
import { getBoundsReportData } from '../ReportViewPage/CalculationResults/CalculationResults.utils';
import { ReportCharacteristicsColumn } from '../ReportViewPage/ReportCharacteristics';

const Title = ({ text, finalInterestRate, unit }) => (
  <FlexLayout alignItems="center" justifyContent="space-between" space={6}>
    <Text color="deep-sapphire" variant="2xl-spaced-bold">
      {text}
    </Text>
    <Text
      bg="white"
      color="bali-hai"
      px={2}
      sx={{ border: 'border', borderRadius: 'm', lineHeight: 'inherit', height: 'fit-content' }}
      variant="xs-spaced-medium"
    >
      <Text variant="xs-spaced-medium">INTEREST: </Text>
      <Text color="deep-sapphire" variant="s-spaced-medium">
        {finalInterestRate ? `${finalInterestRate}${unit}` : '-'}
      </Text>
    </Text>
  </FlexLayout>
);

const getSummaryReportData = (originalData, onOriginalClick, updatedData, onUpdatedClick) => {
  return [
    {
      title: capitalize(ORIGINAL),
      dataColumns: [
        { label: 'ISSUE DATE', value: <DateTime>{originalData.issueDate}</DateTime> },
        { label: 'MATURITY DATE', value: <DateTime>{originalData.maturityDate}</DateTime> },
      ],
      data: originalData,
      onSelectReport: onOriginalClick,
    },
    {
      title: capitalize(UPDATED),
      dataColumns: [
        { label: 'ISSUE DATE', value: <DateTime>{updatedData.issueDate}</DateTime> },
        { label: 'MATURITY DATE', value: <DateTime>{updatedData.maturityDate}</DateTime> },
      ],
      data: updatedData,
      onSelectReport: onUpdatedClick,
    },
  ];
};

const WarningText = ({ reportData, isUpdated, createdAt }) => {
  const { finalInterestRate, upperBound, lowerBound } = reportData;

  if ((finalInterestRate > upperBound || finalInterestRate < lowerBound) && isUpdated) {
    return (
      <Text color="blaze-orange">
        The originally chosen interest falls outside of the updated arm's length interest range
      </Text>
    );
  } else if (isUpdated) {
    return (
      <Text color="deep-sapphire">
        This arm's length range was generated on <DateTime>{new Date()}</DateTime>
      </Text>
    );
  }

  return (
    <Text color="deep-sapphire">
      This arm's length range was generated on <DateTime>{createdAt}</DateTime>
    </Text>
  );
};

const SideBySideCalculationModal = ({
  originalData,
  updatedData,
  isShowing,
  onHide,
  title,
  modalWidth = 'm',
  onOriginalClick,
  onUpdatedClick,
}) => {
  const { userInfo } = useContext(UserInfoContext);
  const reports = getSummaryReportData(originalData, onOriginalClick, updatedData, onUpdatedClick);
  if (!isShowing) return null;
  return (
    <Modal
      title={
        <Title
          text={title}
          finalInterestRate={displayNumber(originalData.report?.finalInterestRate, userInfo.decimalPoint)}
          unit={getReportUnit(originalData.rateType)}
        />
      }
      width={modalWidth}
      onHide={onHide}
      showCancelButton={false}
      showButtonsSection={false}
    >
      <FlexLayout alignItems="center" justifyContent="center" space={6}>
        {reports.map(({ title, dataColumns, data, onSelectReport }) => (
          <FlexLayout
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            space={6}
            sx={{ width: '50%' }}
            key={title}
          >
            <Card style={{ height: '693px', width: '100%', paddingBottom: '24px' }}>
              <Text color="deep-sapphire" variant="xl-spaced-bold" sx={{ marginTop: '20px' }}>
                {title}
              </Text>
              <WarningText
                reportData={data.report}
                isUpdated={title === capitalize(UPDATED)}
                createdAt={data.createdAt}
              />
              <ReportCharacteristicsColumn data={dataColumns} />
              <BoundsGraph data={getBoundsReportData(data, userInfo)} />
            </Card>
            <FlexLayout alignItems="center" justifyContent="center">
              <Button text="Select" iconRight="arrowRight" onClick={onSelectReport} />
            </FlexLayout>
          </FlexLayout>
        ))}
      </FlexLayout>
    </Modal>
  );
};

export default SideBySideCalculationModal;
