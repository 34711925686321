import { TextWithTooltip } from 'components/Shared';
import { FlexLayout, Radio } from 'ui';

import { variants as radioVariants, widths } from '../Radio';

type RadioGroupPropsType = {
  isShowing?: boolean;
  label?: string;
  radioVariant?: keyof typeof radioVariants;
  spaced?: boolean;
  tooltip?: string;
  disabled?: boolean;
  variant?: 'row' | 'column';
  width?: keyof typeof widths;
  bg?: string;
  options: Array<{ label: string; value: string | boolean; tooltip?: string }>;
  value?: string | boolean | null;
  onChange: (...args: any[]) => any;
};

const variants = Object.freeze({
  row: Object.freeze({
    flexDirection: 'row',
    labelColor: 'bali-hai',
    labelSpace: 2,
    labelVariant: 'm-spaced-medium',
    radioSpace: 6,
    spacedRadioMt: 6,
  }),
  column: Object.freeze({
    flexDirection: 'column',
    labelColor: 'shakespeare',
    labelSpace: 4,
    labelVariant: 'm-spaced',
    radioSpace: 4,
    spacedRadioMt: 8,
  }),
});

function RadioGroup({
  isShowing = true,
  label,
  options = [],
  radioVariant = 'primary',
  spaced = false,
  tooltip,
  disabled,
  value,
  variant = 'row',
  width = 'm',
  onChange,
  bg = 'transparent',
}: RadioGroupPropsType) {
  const { flexDirection, labelColor, labelSpace, labelVariant, radioSpace, spacedRadioMt } = variants[variant] || {};
  const [firstOption, ...rest] = options;

  if (!isShowing) return null;
  return spaced ? (
    <>
      <FlexLayout flexDirection="column" space={labelSpace}>
        {label && (
          <TextWithTooltip color={labelColor} label={label} tooltip={tooltip} variant={labelVariant} id={undefined} />
        )}
        <Radio
          key={`${firstOption.value}-${firstOption.label}`}
          option={firstOption}
          disabled={disabled}
          selected={firstOption.value === value}
          variant={radioVariant}
          width={width}
          onChange={onChange}
        />
      </FlexLayout>
      <FlexLayout flexDirection={flexDirection} space={radioSpace}>
        {rest.map((option) => (
          <Radio
            key={`${option.value}-${option.label}`}
            option={option}
            disabled={disabled}
            selected={option.value === value}
            sx={{ mt: spacedRadioMt }}
            variant={radioVariant}
            width={width}
            onChange={onChange}
            bg={bg}
          />
        ))}
      </FlexLayout>
    </>
  ) : (
    <FlexLayout flexDirection="column" space={labelSpace}>
      {label && (
        <TextWithTooltip color={labelColor} label={label} tooltip={tooltip} variant={labelVariant} id={undefined} />
      )}
      <FlexLayout flexDirection={flexDirection} space={radioSpace}>
        {options.map((option) => (
          <Radio
            key={`${option.value}-${option.label}`}
            disabled={disabled}
            option={option}
            selected={option.value === value}
            variant={radioVariant}
            width={width}
            onChange={onChange}
            bg={bg}
          />
        ))}
      </FlexLayout>
    </FlexLayout>
  );
}

export default RadioGroup;
