import { createSlice } from '@reduxjs/toolkit';

import { removeFileExtension } from '~/utils/strings';

// Slice
const initialState = {
  name: '',
  newName: '',
  label: '',
  nameExists: false,
};

export const reportFileSlice = createSlice({
  name: 'reportFile',
  initialState: {},
  reducers: {
    resetReportFile: () => {
      return {};
    },
    setReportFile: (_state, action) => {
      const { name, files } = action.payload;
      const fileName = removeFileExtension(name);
      return { ...initialState, name: fileName, nameExists: files.some((f) => f.name === fileName) };
    },
    updateFileField: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export default reportFileSlice.reducer;

// Actions
export const { resetReportFile, updateFileField, setReportFile } = reportFileSlice.actions;

// Selectors
export const reportFile = (state) => state.reportFile;
