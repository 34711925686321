import colors from './colors';

export default Object.freeze({
  border: `1px solid ${colors['link-water']}`,
  'border-dashed-link-water': `1px dashed ${colors['link-water']}`,
  'border-error': `1px solid ${colors['pomegranate']}`,
  'border-thick': `2px solid ${colors['link-water']}`,
  'border-dashed': `1px dashed ${colors['deep-sapphire-02']}`,
  'border-thick-sapphire': `2px solid ${colors['deep-sapphire']}`,
});
