import { useContext } from 'react';

import { MultiSelect } from 'ui';
import { CountryContext } from 'context';
import { getOptionsFromAvailableOptions } from 'utils/select';
import { SingleSelectWidthType, CountryValueType } from 'types';

type CountryMultiSelectPropsType = {
  isShowing?: boolean;
  label?: string;
  tooltip?: string;
  width?: SingleSelectWidthType;
  disabled?: boolean;
  includeSelectAll?: boolean;
  availableOptions?: Array<string>;
  value: CountryValueType[] | undefined;
  onChange: Function;
};

function CountryMultiSelect({
  isShowing = true,
  label = 'Country',
  tooltip,
  width = 'm',
  disabled,
  includeSelectAll = false,
  availableOptions,
  value,
  onChange,
}: CountryMultiSelectPropsType) {
  const { countryDropdownOptions } = useContext(CountryContext);

  if (!isShowing) return null;

  return (
    <MultiSelect
      label={label}
      options={getOptionsFromAvailableOptions<CountryValueType>(countryDropdownOptions, availableOptions)}
      tooltip={tooltip}
      width={width}
      disabled={disabled}
      includeSelectAll={includeSelectAll}
      nameSingular="Country"
      namePlural="Countries"
      value={value}
      onChange={onChange}
    />
  );
}

export default CountryMultiSelect;
