import { theme } from '~/ui';

const { borders, colors, radii, sizes, space, text } = theme;

function getItemIndex(state) {
  return state.options.findIndex((option) => option.value === state.data.value);
}

const _indentLevelToPaddingMapping = {
  1: space[7],
  2: space[10],
};

export function getStyles({ error, isMulti = false, height, bg = 'white' }) {
  return {
    control: (styles) => ({
      ...styles,
      ...text['m-spaced'],
      backgroundColor: colors[bg],
      border: borders[error ? 'border-error' : 'border'],
      borderColor: 'red',
      borderRadius: radii['m'],
      boxShadow: 'none',
      color: colors['deep-sapphire'],
      cursor: 'pointer',
      height: sizes[height],
      minHeight: '32px',
      outline: 'none',
      padding: `0 ${space[3]}`,
      '&:hover': {
        border: borders[error ? 'border-error' : 'border'],
      },
    }),
    menu: (styles) => ({
      ...styles,
      border: borders['border'],
      borderRadius: radii['m'],
      boxShadow: 'none',
      cursor: 'pointer',
      margin: `${space[1]} 0`,
    }),
    menuList: (styles) => ({
      ...styles,
      padding: 0,
    }),
    option: (_styles, state) => {
      const itemIndex = getItemIndex(state);
      const isFirst = itemIndex === 0;
      const isLast = itemIndex === state.options.length;

      let paddingLeft;
      const indentLevel = state.data.indentLevel;
      if (indentLevel) {
        paddingLeft = _indentLevelToPaddingMapping[indentLevel];
      } else {
        paddingLeft = space[4];
      }

      return {
        ...text['m-spaced'],
        backgroundColor:
          state.isSelected && !isMulti
            ? colors['deep-sapphire']
            : state.isFocused
            ? colors['link-water']
            : colors['white'],
        borderTopLeftRadius: isFirst ? radii['m'] : undefined,
        borderTopRightRadius: isFirst ? radii['m'] : undefined,
        borderBottomLeftRadius: isLast ? radii['m'] : undefined,
        borderBottomRightRadius: isLast ? radii['m'] : undefined,
        color: state.isSelected ? colors['white'] : colors['deep-sapphire'],
        padding: `${space[3]} ${space[4]} ${space[3]} ${paddingLeft}`,
      };
    },
    singleValue: (styles) => ({
      ...styles,
      color: colors['deep-sapphire'],
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: 0,
      paddingRight: space[3],
    }),
  };
}
