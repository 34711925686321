import { useContext, useEffect, useRef, useState } from 'react';

import { UserInfoContext } from 'context/user';
import { DOT } from 'enums';
import { Box, ButtonSpinner, FlexLayout, Text, LockInputIcon } from 'ui';
import { withLabel } from 'ui/hocs';
import {
  removeBlankSpaces,
  removeNonDigits,
  removeNonDigitsDecimal,
  removeNonDigitsDecimalNegative,
  removeNonDigitsNegative,
  separateThousands,
} from 'utils/strings';
import { isEqualWithPrecision } from 'utils/numbers';

type NumberInputPropsType = {
  dataTestId?: string;
  allowNegatives?: boolean;
  isPercentage?: boolean;
  bg?: string;
  error?: string;
  inputType?: 'int' | 'float';
  isLoading?: boolean;
  placeholder?: string;
  tooltip?: string;
  unit?: string;
  width?: keyof typeof widths;
  height?: string;
  withLock?: boolean;
  isLocked?: boolean;
  disabled?: boolean;
  rightSideComponent?: React.ReactNode | false;
  label?: string;
  value: number | null;
  onChange: (...args: any[]) => any;
};

const widths = Object.freeze({
  xs: '114px',
  s: '227px',
  m: '252px',
  l: '277px',
  xl: '536px',
  fullWidth: '100%',
});

function fromInputValueInteger(inputValue: string, allowNegatives: boolean): string {
  let string;
  if (allowNegatives) {
    string = removeNonDigitsNegative(removeBlankSpaces(inputValue));
  } else {
    string = removeNonDigits(removeBlankSpaces(inputValue));
  }

  return string.length ? string : '';
}

function fromInputValueFloat(inputValue: string, allowNegatives: boolean): string {
  let string;
  if (allowNegatives) {
    string = removeNonDigitsDecimalNegative(removeBlankSpaces(inputValue));
  } else {
    string = removeNonDigitsDecimal(removeBlankSpaces(inputValue));
  }

  return string.length ? string : '';
}

function NumberInput({
  allowNegatives = true,
  isPercentage = false,
  error,
  inputType = 'int',
  isLoading = false,
  placeholder = '',
  dataTestId,
  unit,
  width = 'm',
  height = 'input-height',
  withLock = false,
  isLocked = withLock, // use this prop to control locked state from outside
  disabled,
  rightSideComponent = false,
  bg = 'transparent',
  value,
  onChange,
}: NumberInputPropsType) {
  const inputElement = useRef<HTMLInputElement>(null);
  const [locked, setLocked] = useState(isLocked);
  const [stringValue, setStringValue] = useState<string>('');
  const { userInfo } = useContext(UserInfoContext);
  const divisor = isPercentage ? 100 : 1;

  function inputElementFocus() {
    if (!inputElement.current) return;
    inputElement.current.focus();
  }

  /**
   * onChange saves actual number (probably in Redux or parent component state).
   * setStringValue saves the string with either decimal dot or comma.
   * Since comma in a number is not allowed we have to separate string from number.
   */
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputWithOneDecimalPoint = e.target.value;
    // prevents more than one decimal point
    if (userInfo.decimalPoint === DOT) {
      if (inputWithOneDecimalPoint.match(/^.*\..*\..*/)) {
        inputWithOneDecimalPoint = inputWithOneDecimalPoint.replace(/\.$/, '');
      }
    } else {
      if (inputWithOneDecimalPoint.match(/^.*,.*,.*/)) {
        inputWithOneDecimalPoint = inputWithOneDecimalPoint.replace(/,$/, '');
      }
    }

    const input =
      inputType === 'int'
        ? fromInputValueInteger(inputWithOneDecimalPoint, allowNegatives)
        : fromInputValueFloat(inputWithOneDecimalPoint, allowNegatives);

    if (userInfo.decimalPoint === DOT) {
      const normalNumber = input.replace(/,/g, '');
      setStringValue(normalNumber);
      if (normalNumber === '-') onChange('-');
      else onChange(Number(normalNumber === '' ? NaN : normalNumber) / divisor);
    } else {
      const normalNumber = input.replace(/\./g, '').replace(',', '.');
      setStringValue(normalNumber);
      if (normalNumber === '-') onChange('-');
      else onChange(Number(normalNumber === '' ? NaN : normalNumber) / divisor);
    }
  };

  // initialize string value
  useEffect(() => {
    if (value == null) return setStringValue('');

    if (!isEqualWithPrecision(Number(stringValue), value * divisor) || stringValue === null) {
      if (Number.isNaN(value)) {
        onChange(null);
        return setStringValue('');
      }
      if (stringValue === '-') {
        return setStringValue('-');
      }
      setStringValue(String(value * divisor));
    }
  }, [value, stringValue, divisor, onChange]);

  useEffect(() => {
    setLocked(isLocked);
  }, [isLocked]);

  return (
    <FlexLayout
      alignItems="center"
      disabled={disabled}
      dataTestId={dataTestId}
      bg={locked ? 'alabaster' : bg}
      sx={{
        '&:hover': { cursor: locked ? 'auto' : 'text' },
        border: error ? 'border-error' : 'border',
        borderRadius: 'm',
        height,
        width: widths[width],
      }}
      onClick={inputElementFocus}
    >
      <FlexLayout alignItems="center" flexGrow="1" space={unit ? 2 : undefined} px={4}>
        {isLoading ? (
          <ButtonSpinner color="deep-sapphire" justifyContent="flex-start" />
        ) : (
          <Box
            as="input"
            bg="inherit"
            disabled={locked}
            placeholder={placeholder}
            py={0}
            ref={inputElement}
            maxLength={isPercentage ? 6 : undefined}
            sx={{
              border: 'none',
              borderRadius: 'm',
              color: 'deep-sapphire',
              fontSize: '16px',
              flexGrow: 1,
              height: '100%',
              outline: 'none',
            }}
            value={separateThousands(stringValue, userInfo.decimalPoint)}
            onChange={onInputChange}
          />
        )}
        {unit && (
          <Text color="deep-sapphire" sx={{ minWidth: 'fit-content' }}>
            {unit}
          </Text>
        )}
      </FlexLayout>
      <LockInputIcon isShowing={withLock} isLocked={locked} setIsLocked={setLocked} />
      {rightSideComponent}
    </FlexLayout>
  );
}

export default withLabel(NumberInput);
