import { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { deleteCreditRating, restoreCreditRating } from '~/api';
import { DeleteModal, RestoreModal } from '~/components/Modals';
import { UserInfoContext } from '~/context/user';
import { REPORT_TYPE, reportEnum } from '~/enums';
import { FlexLayout, Table } from '~/ui';
import { showToast } from '~/ui/components/Toast';
import { errorHandler } from '~/utils/errors';
import { routesEnum } from '~/routes';
import { creditRatingColumns, setColumns, resetForm } from '~/reducers/tableColumns/creditRatingColumns.slice';

import { renderTableActionColumn } from '../ReportsTables.utils';
import { getCreditRatingsColumns, getCreditRatingsData } from './CreditRatingsTable.utils';

function CreditRatingsTable({ isEditable = false, data = [], setRefreshTrigger, isSearchable = false }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const visibleColumns = useSelector(creditRatingColumns);
  const [creditRatings, setCreditRatings] = useState(data);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { userInfo } = useContext(UserInfoContext);

  const isAnalyses = location.pathname === routesEnum.ANALYSES;
  const isPortfolio = location.pathname === routesEnum.PORTFOLIO;
  const isTrash = location.pathname === routesEnum.TRASH;

  useEffect(() => {
    if (isTrash) {
      dispatch(setColumns({ Deleted: true }));
    }

    return () => dispatch(resetForm());
  }, [dispatch, isTrash]);

  function onItemDelete() {
    deleteCreditRating({ id: selectedItem.id, force: isTrash })
      .then(() => {
        setCreditRatings(creditRatings.filter((creditRatingReport) => creditRatingReport.id !== selectedItem.id));
        setRefreshTrigger((prev) => !prev);
        setShowDeleteModal(false);
        setSelectedItem(null);
        showToast('Credit rating has been successfully deleted.');
      })
      .catch(errorHandler);
  }

  function onItemRestore() {
    restoreCreditRating(selectedItem.id)
      .then(() => {
        setCreditRatings(creditRatings.filter((creditRatingReport) => creditRatingReport.id !== selectedItem.id));
        setRefreshTrigger((prev) => !prev);
        setShowRestoreModal(false);
        setSelectedItem(null);
        showToast('Credit rating has been successfully restored.');
      })
      .catch(errorHandler);
  }

  return (
    <FlexLayout flexDirection="column">
      <Table
        actionColumn={
          isEditable &&
          ((item) =>
            renderTableActionColumn({
              item,
              reportType: reportEnum.CREDIT_RATING,
              setShowDeleteModal,
              setShowRestoreModal,
              setSelectedItem,
              history,
              role: userInfo.role,
              dispatch,
              isAnalyses,
              isPortfolio,
              isTrash,
            }))
        }
        columns={getCreditRatingsColumns(visibleColumns)}
        data={getCreditRatingsData(creditRatings, userInfo)}
        isSearchable={isSearchable}
        onItemClick={
          isSearchable
            ? ({ id }) => history.push(`${location.pathname}/${id}?${REPORT_TYPE}=${reportEnum.CREDIT_RATING}`)
            : null
        }
      />
      <DeleteModal
        item="credit rating"
        isShowing={showDeleteModal}
        handleOnDeleteClick={onItemDelete}
        handleOnHide={() => {
          setShowDeleteModal(false);
          setSelectedItem(null);
        }}
        isDeletePermanent={isTrash}
      />
      <RestoreModal
        item="credit rating"
        isShowing={showRestoreModal}
        handleOnRestoreClick={onItemRestore}
        handleOnHide={() => {
          setShowRestoreModal(false);
          setSelectedItem(null);
        }}
      />
    </FlexLayout>
  );
}

export default CreditRatingsTable;
