import React from 'react';

import { FlexLayout } from 'ui';

type RightSideComponentProps = {
  children?: React.ReactNode;
};

const RightSideComponent = ({ children }: RightSideComponentProps) => {
  return (
    <FlexLayout
      alignItems="center"
      justifyContent="center"
      bg="link-water"
      sx={{
        border: 'border',
        borderRadius: 'm',
        height: '100%',
        outline: 'none',
        width: '56px',
      }}
    >
      {children}
    </FlexLayout>
  );
};

export default RightSideComponent;
