import { createSlice } from '@reduxjs/toolkit';

// Slice
export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {},
  reducers: {
    resetNotification: () => {
      return {};
    },
    setNotification: (state, action) => {
      return { ...state, ...action.payload };
    },
    setIsSubmitting: (state, action) => {
      return { ...state, isSubmitting: action.payload };
    },
    updateField: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export default notificationsSlice.reducer;

// Actions
export const { resetNotification, setNotification, setIsSubmitting, updateField } = notificationsSlice.actions;

// Selectors
export const notificationSelector = (state) => state.notifications;
