import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { payPrincipal } from '~/api';
import { paymentSelector, updateField } from '~/reducers/payment.slice';
import { Box, Button, DateInput, Modal, NumberInput } from '~/ui';
import { showToast } from '~/ui/components/Toast';
import { errorHandler } from '~/utils/errors';

const RepayPrincipalModal = ({
  buttonText = 'Repay',
  loanId,
  onHide,
  payment,
  title = 'Repay principal amount',
  modalWidth = 's',
}) => {
  const dispatch = useDispatch();
  const { principalPaid } = useSelector(paymentSelector);
  const [paymentAmount, setPaymentAmount] = useState();
  const [paymentDate, setPaymentDate] = useState();

  const onRepayClick = () => {
    const data = { paymentAmount, paymentDate };
    payPrincipal({ loanId, data })
      .then(() => {
        showToast('Principal paid successfully');
        // used to trigger useEffect in PaymentsPage and refetch payments
        dispatch(updateField({ principalPaid: !principalPaid }));
        onHide();
        setPaymentAmount();
        setPaymentDate();
      })
      .catch(errorHandler);
  };

  if (!loanId) return null;

  return (
    <Modal
      actionButtons={<Button disabled={!paymentAmount || !paymentDate} text={buttonText} onClick={onRepayClick} />}
      title={title}
      width={modalWidth}
      onHide={onHide}
    >
      <Box sx={{ display: 'grid', gridGap: 8, gridTemplateColumns: 'repeat(2, 1fr)' }}>
        <NumberInput
          allowNegatives={false}
          label="Principal amount"
          inputType="float"
          width="fullWidth"
          value={paymentAmount}
          unit={payment.loan.currency}
          onChange={setPaymentAmount}
        />
        <DateInput label="Payment date" width="fullWidth" value={paymentDate} onChange={setPaymentDate} />
      </Box>
    </Modal>
  );
};

export default RepayPrincipalModal;
