import { backend } from '~/services';
import { toFile } from '~/utils/documents';

import { apiPaths } from './routes';

export function getCompanies() {
  return backend.get(apiPaths.company);
}

export function createCompany(data) {
  return backend.post(apiPaths.company, data);
}

export function createCompanies(data) {
  return backend.post(`${apiPaths.company}/bulk`, data);
}

export function getCompany(id) {
  return backend.get(`${apiPaths.company}/${id}`);
}

export function getCompanyTemplate() {
  return backend.get(`${apiPaths.company}/template`, { responseType: 'blob' });
}

export function updateCompany({ id, data }) {
  return backend.put(`${apiPaths.company}/${id}`, data);
}

export function deleteCompany(id) {
  return backend.delete(`${apiPaths.company}/${id}`);
}

export function updateImplicitSupport(data) {
  return backend.post(`${apiPaths.company}/implicit-support`, data);
}

export function updateImplicitRating(data) {
  return backend.post(`${apiPaths.company}/adjust-rating`, data);
}

export function updateImplicitProbabilityOfDefault(data, cancelToken) {
  return backend.post(`${apiPaths.company}/adjust-pod`, data, { cancelToken });
}

export function getCompanyAuditTrails(companyId) {
  return backend.get(`${apiPaths.company}/${companyId}/audit`);
}

export function getCompanyAuditTrail({ companyId, auditId }) {
  return backend.get(`${apiPaths.company}/${companyId}/audit/${auditId}`);
}

export function deleteCompanyAuditTrail({ companyId, auditId }) {
  return backend.delete(`${apiPaths.company}/${companyId}/audit/${auditId}`);
}

export async function exportCompaniesAsExcel() {
  const response = await backend.post(`${apiPaths.company}/export`, null, { responseType: 'blob', fullResponse: true });
  return toFile(response);
}
