import format from 'date-fns/format';
import { useContext } from 'react';

import { UserInfoContext } from '~/context/user';
import { dateFnsFormatMapper } from '~/utils/dates';

const DateTime = ({ children, overrideFormat, withTime }) => {
  const { userInfo } = useContext(UserInfoContext);

  if (!children) return null;

  const formatString =
    dateFnsFormatMapper(overrideFormat, withTime) || dateFnsFormatMapper(userInfo.dateFormat, withTime);
  return format(new Date(children), formatString);
};

export default DateTime;
