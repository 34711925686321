import { Box, FlexLayout, Text } from 'ui';
import { ColorType } from 'types';

type SwitchPropsType = {
  isShowing?: boolean;
  disabled?: boolean;
  label?: string;
  switchBg?: string;
  labelColor?: ColorType;
  isActive: boolean;
  onChange: (isActive: boolean) => void;
};

function Switch({
  isShowing = true,
  disabled = false,
  label,
  switchBg,
  labelColor = 'deep-sapphire',
  isActive,
  onChange,
}: SwitchPropsType) {
  if (!isShowing) return null;

  return (
    <FlexLayout
      disabled={disabled}
      alignItems="center"
      space={4}
      sx={{ width: 'fit-content' }}
      onClick={() => onChange(!isActive)}
    >
      <FlexLayout
        alignItems="center"
        flexShrink="0"
        bg={switchBg}
        justifyContent={isActive ? 'flex-end' : 'flex-start'}
        px={1}
        sx={{ border: 'border', borderRadius: 'pill', height: '24px', width: '40px' }}
      >
        <Box
          bg={isActive ? 'shakespeare' : 'link-water'}
          sx={{ borderRadius: 'round', height: '16px', width: '16px' }}
        />
      </FlexLayout>
      {label && (
        <Text color={labelColor} variant="s-spaced">
          {label}
        </Text>
      )}
    </FlexLayout>
  );
}

export default Switch;
