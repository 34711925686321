import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { NOTIONAL } from '~/enums';

const initialState = {
  name: '',
  currency: null,
  creditInterestRate: null,
  debitInterestRate: null,
  interestType: 'fixed',
  overnightRate: null,
  accounts: [],
};

/**
 * If isTopCurrencyAccount is true it's checking accounts of the top currency account, which means
 * comparison is made with this slices' CIR and DIR. If it's false then it's the Physical select participants (accounts)
 * and the comparison is made with the CIR and DIR of cashPool.slice.
 */
export const isTopCurrencyAccountValid = ({ topCurrencyAccount, cashPool, isTopCurrencyAccount }) => {
  const {
    creditInterestRate: topAccountCir,
    debitInterestRate: topAccountDir,
    accounts,
    interestType: topCurrencyInterestType,
  } = topCurrencyAccount;
  const {
    creditInterestRate: cashPoolCir,
    debitInterestRate: cashPoolDir,
    interestType: cashPoolInterestType,
    type,
  } = cashPool;

  const [creditInterestRate, debitInterestRate, interestType] = isTopCurrencyAccount
    ? [topAccountCir, topAccountDir, topCurrencyInterestType]
    : [cashPoolCir, cashPoolDir, cashPoolInterestType];
  const isFixed = interestType === 'fixed';
  const word = isFixed ? 'rate' : 'spread';
  const word2 = isTopCurrencyAccount ? "top currency account's" : "cash pool's";
  const errors = {};

  if (topAccountCir >= topAccountDir && isFixed) {
    errors.creditInterestRate = `The credit interest ${word} must be lower than the debit interest ${word}.`;
  }

  errors.accounts = [];
  for (const account of accounts) {
    const accountErrors = {};
    if (account.creditInterestRate == null) {
      accountErrors.creditInterestRate = `A standalone credit interest ${word} is required.`;
    }
    if (account.debitInterestRate == null) {
      accountErrors.debitInterestRate = `A standalone debit interest ${word} is required.`;
    }
    if (account.creditInterestRate >= creditInterestRate) {
      accountErrors.creditInterestRate = `The company's standalone credit interest ${word}s must be lower than the ${word2} credit interest ${word}.`;
    }
    if (account.debitInterestRate <= debitInterestRate) {
      accountErrors.debitInterestRate = `The company's standalone debit interest ${word}s must be higher than the ${word2} debit interest ${word}.`;
    }
    if (!account.currency && type === NOTIONAL) {
      accountErrors.currency = "The company's currency is required.";
    }
    if (!_.isEmpty(accountErrors)) {
      errors.accounts.push({ ...account, value: true, error: accountErrors });
    }
  }

  if (_.isEmpty(errors.accounts)) delete errors.accounts;

  return errors;
};

// Slice
export const cashPoolTopCurrencyAccountSlice = createSlice({
  name: 'cashPoolTopCurrencyAccount',
  initialState,
  reducers: {
    resetCashPoolTopCurrencyAccount: () => {
      return initialState;
    },
    setCashPoolTopCurrencyAccount: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateField: (state, action) => {
      return {
        ...state,
        ...action.payload,
        errors:
          (state.showErrors || action.payload.showErrors) &&
          isTopCurrencyAccountValid({
            topCurrencyAccount: { ...state, ...action.payload },
            cashPool: {},
            isTopCurrencyAccount: true,
          }),
      };
    },
    resetAccounts: (state) => {
      return { ...state, accounts: [] };
    },
    updateAccount: (state, { payload }) => {
      state.accounts = state.accounts.map((account) => {
        if (account.companyId === payload.companyId) {
          return { ...account, ...payload };
        }
        return account;
      });
      state.isDirty = false;
    },
    updateAccounts: (state, { payload }) => {
      const { companyId, value, error, ...rest } = payload;
      if (value) {
        return {
          ...state,
          accounts: [
            ...state.accounts.filter((account) => account.companyId !== companyId),
            { companyId, error, ...rest },
          ],
        };
      }
      return {
        ...state,
        accounts: state.accounts.filter((account) => account.companyId !== companyId),
      };
    },
  },
});

export default cashPoolTopCurrencyAccountSlice.reducer;

// Actions
export const {
  resetCashPoolTopCurrencyAccount,
  setCashPoolTopCurrencyAccount,
  updateField,
  resetAccounts,
  updateAccount,
  updateAccounts,
} = cashPoolTopCurrencyAccountSlice.actions;

// Selectors
export const cashPoolTopCurrencyAccountSelector = (state) => state.cashPoolTopCurrencyAccount;
