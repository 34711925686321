import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { deleteB2BLoan, restoreB2BLoan } from 'api';
import { DeleteModal, RestoreModal } from 'components/Modals';
import { UserInfoContext } from 'context/user';
import { REPORT_TYPE, reportEnum } from 'enums';
import { backToBackLoanColumns, setColumns, resetForm } from 'reducers/tableColumns/backToBackLoanColumns.slice';
import { routesEnum } from 'routes';
import { FlexLayout, Table } from 'ui';
import { showToast } from 'ui/components/Toast';
import { errorHandler } from 'utils/errors';

import { getB2BLoansColumns, getB2BLoansData } from './BackToBackLoansTable.utils';
import { renderTableActionColumn } from '../ReportsTables.utils';

type BackToBackLoansTablePropsType = {
  isEditable?: boolean;
  isSearchable?: boolean;
  data?: Array<any>;
  setRefreshTrigger: React.Dispatch<React.SetStateAction<boolean>>;
};

function BackToBackLoansTable({
  data = [],
  setRefreshTrigger,
  isEditable = false,
  isSearchable = false,
}: BackToBackLoansTablePropsType) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const visibleColumns = useSelector(backToBackLoanColumns);
  const [loanReports, setLoanReports] = useState(data);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const { userInfo } = useContext(UserInfoContext);

  const isAnalyses = location.pathname === routesEnum.ANALYSES;
  const isPortfolio = location.pathname === routesEnum.PORTFOLIO;
  const isTrash = location.pathname === routesEnum.TRASH;

  useEffect(() => {
    if (isPortfolio) {
      dispatch(setColumns({ Rate: true }));
    }
    if (isTrash) {
      dispatch(setColumns({ Deleted: true }));
    }

    return () => {
      dispatch(resetForm());
    };
  }, [dispatch, isPortfolio, isTrash]);

  function onItemDelete() {
    deleteB2BLoan({ id: selectedItem.id, force: isTrash })
      .then(() => {
        setLoanReports(loanReports.filter((loanReport) => loanReport.id !== selectedItem.id));
        setRefreshTrigger((prev) => !prev);
        setShowDeleteModal(false);
        setSelectedItem(null);
        showToast('Back-to-back loan has been successfully deleted.');
      })
      .catch(errorHandler);
  }

  function onItemRestore() {
    restoreB2BLoan(selectedItem.id)
      .then(() => {
        setLoanReports(loanReports.filter((loanReport) => loanReport.id !== selectedItem.id));
        setRefreshTrigger((prev) => !prev);
        setShowRestoreModal(false);
        setSelectedItem(null);
        showToast('Loan has been successfully restored.');
      })
      .catch(errorHandler);
  }

  return (
    <FlexLayout flexDirection="column">
      <Table
        actionColumn={
          isEditable &&
          ((item: any) =>
            renderTableActionColumn({
              item,
              reportType: reportEnum.BACK_TO_BACK_LOAN,
              setShowDeleteModal,
              setShowRestoreModal,
              setSelectedItem,
              history,
              role: userInfo.role,
              dispatch,
              isAnalyses,
              isPortfolio,
              isTrash,
            }))
        }
        columns={getB2BLoansColumns(visibleColumns)}
        data={getB2BLoansData(loanReports, userInfo)}
        isSearchable={isSearchable}
        onItemClick={
          isSearchable
            ? ({ id }: any) => history.push(`${location.pathname}/${id}?${REPORT_TYPE}=${reportEnum.BACK_TO_BACK_LOAN}`)
            : undefined
        }
      />
      <DeleteModal
        item="loan"
        isShowing={showDeleteModal}
        handleOnDeleteClick={onItemDelete}
        handleOnHide={() => {
          setShowDeleteModal(false);
          setSelectedItem(null);
        }}
        isDeletePermanent={isTrash}
      />
      <RestoreModal
        item="loan"
        isShowing={showRestoreModal}
        handleOnRestoreClick={onItemRestore}
        handleOnHide={() => {
          setShowRestoreModal(false);
          setSelectedItem(null);
        }}
        additionalInfo={''}
      />
    </FlexLayout>
  );
}

export default BackToBackLoansTable;
