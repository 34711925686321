import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  Lender: true,
  Borrower: true,
  'Payment amount': true,
  'Payment due': true,
  Status: true, // paid / unpaid
  'Payment number': true,
  'Compounding period end date': true,
  'Compounded interest': true,

  'Loan type': false,
  Currency: false,
  'Rate type': false,
  'Interest payment frequency': false,
  'Issue date': false,
  'Maturity date': false,
  Rate: false,
  'Principal amount': false,
  'Interest payment': false,
  'Reference rate': false,
};

export const floatBalloonPaymentColumnsSlice = createSlice({
  name: 'floatBalloonPaymentColumns',
  initialState,
  reducers: {
    resetForm: () => {
      return initialState;
    },
    setColumns: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateColumns: (_state, action) => {
      return action.payload;
    },
  },
});

export default floatBalloonPaymentColumnsSlice.reducer;

export const { resetForm, setColumns, updateColumns } = floatBalloonPaymentColumnsSlice.actions;

export const floatBalloonPaymentColumns = (state) => state.floatBalloonPaymentColumns;
