import { Suspense } from 'react';
import { Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { isUserAuthenticated } from 'auth';
import ContentWindow from 'components/ContentWindow';
import Sidebar from 'components/Sidebar';
import { FlexLayout, LoadingSpinner } from 'ui';
import { ToastMessageContainer } from 'ui/components/Toast';

import ErrorBoundaryComponent from './ErrorBoundary';

function MainLayout() {
  return (
    <>
      <FlexLayout sx={{ bottom: 0, left: 0, position: 'fixed', right: 0, top: 0 }}>
        {isUserAuthenticated && <Route path="*" component={Sidebar} />}
        <ErrorBoundary fallback={<ErrorBoundaryComponent />}>
          <Suspense fallback={<LoadingSpinner />}>
            <ContentWindow />
          </Suspense>
        </ErrorBoundary>
      </FlexLayout>
      <ToastMessageContainer />
    </>
  );
}

export default MainLayout;
