import { useCompanies } from 'hooks';
import { SingleSelect } from 'ui';
import { sortByStringValue } from 'utils/arrays';
import { createCompanyValue } from 'utils/companies';
import { SingleSelectWidthType } from 'types';

type CompanySingleSelectPropsType = {
  isShowing?: boolean;
  error?: string;
  label?: string;
  rightSideLabelComponent?: React.ReactNode | string;
  checkCreditRating?: boolean;
  checkCreditRatingAndProbabilityOfDefault?: boolean;
  isLoanApproachCalculated?: boolean;
  excludedValues?: Array<number>;
  properties?: Array<string>;
  sx?: any;
  tooltip?: string;
  width?: SingleSelectWidthType;
  disabled?: boolean;
  dataTestId?: string;
  value: number;
  onChange: Function;
};

/**
 * `checkCreditRating` filters out the companies that don't have the standalone credit rating, that is
 * under Company Information they don't have a rating under Issuer Rating.
 * `checkCreditRatingAndProbabilityOfDefault` filters out the companies that don't have both PoD and PoD adjusted,
 * that is under Company Information they don't have PoD under Issuer Rating and PoD adjusted under Implicit Support.
 *
 * "creditRating":{"rating":"A+/A1","ratingAdj":"A+/A1","probabilityOfDefault":7,"probabilityOfDefaultAdj":7}
 *
 * In Company Information `Issuer Rating` is the standalone credit rating and PoD is the probability of default (`rating` and `probabilityOfDefault`).
 * In Company Information `Implicit Support Adjusted Rating` is the implicit support adjusted rating and PoD adjusted
 * is the probability of default adjusted (`ratingAdj` and `probabilityOfDefaultAdj`).
 */
function CompanySingleSelect({
  isShowing = true,
  error,
  label,
  rightSideLabelComponent,
  checkCreditRating = false,
  checkCreditRatingAndProbabilityOfDefault = false,
  isLoanApproachCalculated,
  excludedValues = [],
  properties = ['id', 'parentCompanyId', 'creditRating', 'name', 'industry', 'country'],
  sx,
  tooltip,
  value,
  width = 'm',
  disabled,
  dataTestId,
  onChange,
}: CompanySingleSelectPropsType) {
  const companies = useCompanies(false);

  const getFilteredCompanies = () => {
    if (checkCreditRatingAndProbabilityOfDefault) {
      return companies.filter(
        (company) =>
          !excludedValues.includes(company.id) &&
          company?.creditRating?.rating &&
          (company?.creditRating?.probabilityOfDefault != null ||
            company?.creditRating?.probabilityOfDefaultAdj != null)
      );
    }
    if (isLoanApproachCalculated !== undefined && !isLoanApproachCalculated) {
      return companies.filter((company) => !excludedValues.includes(company.id));
    }
    if (checkCreditRating) {
      return companies.filter((company) => !excludedValues.includes(company.id) && company?.creditRating?.rating);
    }

    return companies.filter((company) => !excludedValues.includes(company.id));
  };

  const options = getFilteredCompanies()
    .map((company) => ({ label: company.name, value: createCompanyValue(company, properties) }))
    .sort(sortByStringValue('label'));

  if (!isShowing) return null;

  return (
    <SingleSelect
      error={error}
      label={label}
      rightSideLabelComponent={rightSideLabelComponent}
      options={options}
      sx={sx}
      tooltip={tooltip}
      disabled={disabled}
      dataTestId={dataTestId}
      value={value}
      width={width}
      onChange={onChange}
    />
  );
}

export default CompanySingleSelect;
