import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { hasAllValuesPresent } from '~/utils/form';

// Slice
const initialState = {
  parentCompanyId: null,
  name: '',
  industry: null,
  country: null,
  note: '',
  creditRating: {
    rating: null,
    ratingAdj: null,
    probabilityOfDefault: null,
    probabilityOfDefaultAdj: {
      value: null,
      loading: false,
      overriden: false,
    },
  },
  assessment: null,
  createdBy: null,
  updatedBy: null,
  availableCreditRatings: [],
  isParent: false,
  isDirty: false,
  isRatingDirty: false,
};
const formKeys = ['parentCompanyId', 'name', 'industry', 'country', 'note', 'creditRating', 'assessment', 'isParent'];

export function transform(company) {
  const data = _.pick(company, formKeys);
  if (data?.assessment && !data?.assessment?.name) {
    data.assessment = null;
  }

  return {
    ...data,
    creditRating: {
      ...data.creditRating,
      probabilityOfDefaultAdj: data?.creditRating?.probabilityOfDefaultAdj?.value,
    },
  };
}

function isCreditRatingValid(creditRating) {
  let valid = true;
  if (creditRating?.probabilityOfDefault != null) {
    valid &= creditRating?.probabilityOfDefault >= 0 && creditRating?.probabilityOfDefault <= 100;
  }

  if (creditRating?.probabilityOfDefaultAdj?.value != null) {
    valid &= creditRating?.probabilityOfDefaultAdj?.value >= 0 && creditRating?.probabilityOfDefaultAdj?.value <= 100;
  }

  return valid;
}

export function isCompanyFormValid(company) {
  const { parentCompanyId, name, creditRating, note, assessment, ...rest } = _.pick(company, formKeys);
  return !!name.length && isCreditRatingValid(creditRating) && hasAllValuesPresent(rest);
}

export const companySlice = createSlice({
  name: 'company',
  initialState: {},
  reducers: {
    resetCompany: () => {
      return {};
    },
    setCompany: (_state, action) => {
      if (action.payload) {
        const state = action.payload;
        const probabilityOfDefaultAdj = action.payload?.creditRating?.probabilityOfDefaultAdj ?? null;
        state.creditRating.probabilityOfDefaultAdj = {
          value: probabilityOfDefaultAdj,
          loading: false,
          overriden: false,
        };
        return state;
      }

      return initialState;
    },
    updateField: (state, action) => {
      return { ...state, ...action.payload, isDirty: true };
    },
    updateRating: (state, action) => {
      state.creditRating.rating = action.payload;
      state.isRatingDirty = true;
      state.isDirty = true;
    },
    updateRatingAdj: (state, action) => {
      state.creditRating.ratingAdj = action.payload;
      state.isDirty = true;
    },
    updateProbabilityOfDefault: (state, action) => {
      state.creditRating.probabilityOfDefault = action.payload;
      if (!state.creditRating.probabilityOfDefaultAdj.overriden) {
        state.creditRating.probabilityOfDefaultAdj.loading = true;
      }
      state.isDirty = true;
    },
    updateProbabilityOfDefaultAdj: (state, action) => {
      const { value, overriden } = action.payload;
      state.creditRating.probabilityOfDefaultAdj.value = value;
      if (overriden != null) {
        state.creditRating.probabilityOfDefaultAdj.overriden = overriden;
      }
      state.creditRating.probabilityOfDefaultAdj.loading = false;
      state.isDirty = true;
    },
    updateIsProbabilityOfDefaultAdjLoading: (state, action) => {
      state.creditRating.probabilityOfDefaultAdj.loading = action.payload;
    },
    updateAssessmentAnswers: (state, action) => {
      if (!state.assessment) {
        state.assessment = {};
      }
      state.assessment.answers = action.payload;
      state.isDirty = true;
    },
    updateAssessmentName: (state, action) => {
      state.assessment.name = action.payload;
      state.isDirty = true;
    },
    setIsPristine: (state) => {
      state.isRatingDirty = false;
      state.isDirty = false;
    },
  },
});

export default companySlice.reducer;

// Actions
export const {
  resetCompany,
  setCompany,
  updateField,
  updateRating,
  updateRatingAdj,
  updateProbabilityOfDefault,
  updateProbabilityOfDefaultAdj,
  updateIsProbabilityOfDefaultAdjLoading,
  updateAssessmentAnswers,
  updateAssessmentName,
  setIsPristine,
} = companySlice.actions;

// Selectors
export const company = (state) => state.company;
