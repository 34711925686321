import React from 'react';

import { MultiSelect } from '~/ui';

function CompanyMultiSelect({
  error,
  label,
  sx,
  tooltip,
  value,
  options = [],
  width = 'm',
  height,
  onChange,
  includeSelectAll,
}) {
  return (
    <MultiSelect
      error={error}
      label={label}
      options={options.map(({ name, id }) => ({ label: name, value: id }))}
      sx={sx}
      tooltip={tooltip}
      value={value}
      width={width}
      height={height}
      nameSingular="company"
      namePlural="companies"
      includeSelectAll={includeSelectAll}
      onChange={onChange}
    />
  );
}

export default CompanyMultiSelect;
