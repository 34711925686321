import { Button, Modal, Text } from 'ui';

type DeleteModalPropsType = {
  isShowing?: boolean;
  isDeletePermanent?: boolean;
  additionalInfo?: string;
  dataTestId?: string;
  handleOnDeleteClick: () => void;
  handleOnHide: () => void;
  item: string;
};

function DeleteModal({
  isShowing = true,
  item = 'item',
  isDeletePermanent = true,
  additionalInfo = '',
  dataTestId = '',
  handleOnDeleteClick,
  handleOnHide,
}: DeleteModalPropsType) {
  if (!isShowing) return null;

  return (
    <Modal
      actionButtons={<Button text="Delete" onClick={handleOnDeleteClick} dataTestId={dataTestId} />}
      title={`Delete ${item}`}
      width="s"
      onHide={handleOnHide}
    >
      <Text color="deep-sapphire" variant="m-spaced" sx={{ lineHeight: '21px' }}>
        Are you sure you want to delete this {item}?{' '}
        {isDeletePermanent ? "You won't be able to undo this action. " : 'Item will be moved to trash. '}
        {additionalInfo}
      </Text>
    </Modal>
  );
}

export default DeleteModal;
