import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  Company: true,
  'Fiscal Year': true,
  Rating: true,
  'Probability of default (1 yr.)': true,
  Created: true,
  Updated: true,
  Status: true,
  Deleted: false,
};

export const creditRatingColumnsSlice = createSlice({
  name: 'creditRatingColumns',
  initialState: initialState,
  reducers: {
    resetForm: () => {
      return initialState;
    },
    setColumns: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateColumns: (state, action) => {
      return action.payload;
    },
  },
});

export default creditRatingColumnsSlice.reducer;

export const { resetForm, setColumns, updateColumns } = creditRatingColumnsSlice.actions;

export const creditRatingColumns = (state: any) => state.creditRatingColumns;
