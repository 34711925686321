import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { useContext, useEffect } from 'react';

import { UserInfoContext } from '~/context/user';

export const SentryConfig = () => {
  const { userInfo } = useContext(UserInfoContext);

  useEffect(() => {
    if (userInfo) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        environment: process.env.REACT_APP_ENVIRONMENT,
        tracesSampleRate: 0.0,
      });

      Sentry.configureScope((scope) => scope.setUser({ ...userInfo }));
    }
  }, [userInfo]);

  return null;
};
