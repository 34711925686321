import { useState } from 'react';

import { ActionMenu, Box, Icon } from 'ui';

type ThreeDotActionMenuProps = {
  options: { label: string; onClick: Function | null }[];
  dataTestId?: string;
};

function ThreeDotActionMenu({ options, dataTestId }: ThreeDotActionMenuProps) {
  const [visible, setVisible] = useState(false);

  return (
    <ActionMenu options={options} visible={visible} setVisible={setVisible}>
      <Box
        dataTestId={dataTestId}
        bg={visible ? 'link-water' : 'transparent'}
        px={2}
        py={1}
        sx={{ borderRadius: 's' }}
        onClick={() => setVisible(!visible)}
      >
        <Icon color="deep-sapphire" icon="settings" size="m" />
      </Box>
    </ActionMenu>
  );
}

export default ThreeDotActionMenu;
