import _ from 'lodash';
import React from 'react';
import { Dispatch } from 'redux';

import { TextWithTooltip, ThreeDotActionMenu } from 'components/Shared';
import { creditRatings } from 'components/Shared/CreditRatingSingleSelect';
import { allCurrencies } from 'components/Shared/CurrencySingleSelect';
import { updateAccounts } from 'reducers/cashPoolTopCurrencyAccount.slice';
import { updateField } from 'reducers/cashPool.slice';
import { Checkbox, FlexLayout, Text } from 'ui';
import { getCompanyRating, getCompanyRatingAdj } from 'utils/companies';
import { displayNumber } from 'utils/strings';
import { UserType } from 'types/user.types';
import { CompanyDataType, AccountType, InTableParticipantType } from 'types/cashPool.types';
import { TableColumnType } from 'types/various.types';

type SelectParticipantsTableColumnValueType =
  | 'name'
  | 'country'
  | 'rating'
  | 'ratingAdj'
  | 'industry'
  | 'cirString'
  | 'dirString'
  | 'currencyString'
  | 'generateInterestStatementDataString';

export const renderActionColumn = ({
  item,
  accounts,
  setIsCirDirModalShowing,
  setIsCurrencyModalShowing,
  setIsBalanceModalShowing,
  setIsExternalIdsShowing,
  isNotional,
  isFixed,
  isEdit,
  dispatch,
}: {
  item: any;
  accounts: any;
  setIsCirDirModalShowing: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCurrencyModalShowing: React.Dispatch<React.SetStateAction<boolean>>;
  setIsBalanceModalShowing: React.Dispatch<React.SetStateAction<boolean>>;
  setIsExternalIdsShowing: React.Dispatch<React.SetStateAction<boolean>>;
  isNotional: boolean;
  isFixed: boolean;
  isEdit: boolean;
  dispatch: Dispatch<any>;
}) => {
  const type: string = isFixed ? 'rate' : 'spread';
  const options = [];

  options.push({ label: `Edit credit and debit ${type}s`, onClick: () => setIsCirDirModalShowing(item) });

  if (isEdit) {
    if (item.isSelected) {
      options.push({ label: 'Edit external IDs', onClick: () => setIsExternalIdsShowing(item) });

      const account = accounts?.find((account: any) => account?.companyId === item.id);
      options.push({
        label: `${item.generateInterestStatementData ? 'Remove' : 'Add'} interest to statement data`,
        onClick: () => {
          dispatch(
            updateAccounts({
              ...account,
              generateInterestStatementData: !item.generateInterestStatementData,
              value: true,
            })
          );
          dispatch(updateField({ isDirty: true }));
        },
      });
    }
    // New account that is being added
    if (item.isSelected && item.accountId == null) {
      options.push({ label: 'Set initial balance', onClick: () => setIsBalanceModalShowing(item) });
    }
  }
  if (isNotional) {
    options.push({ label: 'Assign currency', onClick: () => setIsCurrencyModalShowing(item) });
  }

  return <ThreeDotActionMenu options={options} dataTestId="participantsActionMenu" />;
};

/**
 * !WARNING
 * Changing this will change order of columns which will affect the onUploadTemplateClick since it depends on indices of columns
 */
const getPhysicalCompaniesColumns = ({
  dispatch,
  accounts,
  isFixed,
}: {
  dispatch?: Dispatch;
  accounts?: AccountType[];
  isFixed: boolean;
}): TableColumnType<SelectParticipantsTableColumnValueType>[] => {
  return [
    {
      label: 'Company',
      sortBy: 'name',
      value: 'name',
      wrapText: true,
      width: 250,
      renderCustomCell: ({ id, name }: { id: number; name: string }) => {
        if (!dispatch || !accounts) return null;
        return (
          <FlexLayout alignItems="center" space={2}>
            <Checkbox
              isActive={!!accounts.find((account) => account.companyId === id)}
              onChange={(isSelected: boolean) => dispatch(updateAccounts({ companyId: id, value: isSelected }))}
            />
            <Text color="deep-sapphire" variant="m-spaced">
              {name}
            </Text>
          </FlexLayout>
        );
      },
    },
    { label: 'Country', sortBy: 'country', value: 'country' },
    { label: 'Rating', sortArray: creditRatings, sortBy: 'rating', sortType: 'array', value: 'rating' },
    { label: 'Sector', sortBy: 'industry', value: 'industry' },
    { label: 'Rating (ADJ.)', sortBy: 'ratingAdj', value: 'ratingAdj' },
    {
      label: isFixed ? 'Credit Rate' : 'Credit Spread',
      sortBy: 'cirString',
      value: 'cirString',
      renderCustomCell: ({ id, cirString }: { id: number; cirString: string }) => {
        if (!accounts) return null;
        const account: AccountType | undefined = accounts.find((p) => p.companyId === id);
        const errorMessage: string | undefined = account?.error?.creditInterestRate;
        return (
          <TextWithTooltip
            id="cirString"
            color={errorMessage ? 'pomegranate' : 'deep-sapphire'}
            label={cirString?.toString()}
            tooltip={errorMessage}
            variant="m-spaced"
          />
        );
      },
    },
    {
      label: isFixed ? 'Debit Rate' : 'Debit Spread',
      sortBy: 'dirString',
      value: 'dirString',
      renderCustomCell: ({ id, dirString }: { id: number; dirString: string }) => {
        if (!accounts) return null;
        const account: AccountType | undefined = accounts.find((p) => p.companyId === id);
        const errorMessage: string = account?.error?.debitInterestRate;
        return (
          <TextWithTooltip
            id="dirString"
            color={errorMessage ? 'pomegranate' : 'deep-sapphire'}
            label={dirString?.toString()}
            tooltip={errorMessage}
            variant="m-spaced"
          />
        );
      },
    },
    {
      label: 'Interest added',
      sortBy: 'interestAdded',
      value: 'generateInterestStatementDataString',
      renderCustomCell: ({ generateInterestStatementData }: { generateInterestStatementData: boolean }) => {
        return <Text>{generateInterestStatementData ? 'Yes' : 'No'}</Text>;
      },
    },
  ];
};

export const getCompaniesColumns = ({
  dispatch,
  accounts,
  isFixed,
  isNotional,
}: {
  dispatch?: Dispatch;
  accounts?: AccountType[];
  isNotional?: boolean;
  isFixed: boolean;
}): TableColumnType<SelectParticipantsTableColumnValueType>[] => {
  if (isNotional) {
    return [
      ...getPhysicalCompaniesColumns({ dispatch, accounts, isFixed }),
      {
        label: 'Currency',
        sortBy: 'currencyString',
        value: 'currencyString',
        renderCustomCell: ({ id, currencyString }: { id: number; currencyString: string }) => {
          if (!accounts) return null;
          const account: AccountType | undefined = accounts.find((p) => p.companyId === id);
          const errorMessage: string | undefined = account?.error?.currency;
          return (
            <TextWithTooltip
              id="currencyString"
              color={errorMessage ? 'pomegranate' : 'deep-sapphire'}
              label={currencyString}
              tooltip={errorMessage}
              variant="m-spaced"
            />
          );
        },
      },
    ];
  }

  return getPhysicalCompaniesColumns({ dispatch, accounts, isFixed });
};

function getCompanyData({
  company,
  isFixed,
  userInfo,
}: {
  company: InTableParticipantType;
  isFixed: boolean;
  userInfo: UserType;
}): CompanyDataType {
  const {
    country,
    id,
    accountId,
    industry,
    name,
    creditInterestRate,
    debitInterestRate,
    currency,
    value,
    generateInterestStatementData,
  } = company;
  const unit = isFixed ? '%' : ' bps';

  return {
    id,
    accountId,
    country,
    industry,
    name,
    generateInterestStatementData,
    rating: getCompanyRating(company),
    ratingAdj: getCompanyRatingAdj(company),
    creditInterestRate,
    cirString:
      creditInterestRate != null ? `${displayNumber(creditInterestRate, userInfo.decimalPoint, '-')}${unit}` : '-',
    debitInterestRate,
    dirString:
      debitInterestRate != null ? `${displayNumber(debitInterestRate, userInfo.decimalPoint, '-')}${unit}` : '-',
    currency,
    currencyString: currency || '-',
    isSelected: value || false,
  };
}

export function getCompaniesData(
  data: InTableParticipantType[] = [],
  isFixed: boolean,
  userInfo: UserType
): CompanyDataType[] {
  const sortedData: CompanyDataType[] = data.map((company) => getCompanyData({ company, isFixed, userInfo }));
  // Put selected participants on top of the participants table
  sortedData.sort((a, b) => Number(b.isSelected) - Number(a.isSelected));
  return sortedData;
}

function getCompaniesSheetData(
  inTableParticipants: InTableParticipantType[] = [],
  isNotional: boolean,
  isFixed: boolean,
  userInfo: UserType
) {
  const unit = isFixed ? '%' : ' bps';
  return inTableParticipants.map((company) => {
    const sheetData: any = {};
    const companyData: CompanyDataType = getCompanyData({ company, isFixed, userInfo });
    const columns: TableColumnType<SelectParticipantsTableColumnValueType>[] = getCompaniesColumns({ isFixed });
    sheetData['Cash Pool Participant'] = company.value ? 'Yes' : 'No';
    for (let i = 0; i < columns.length; i++) {
      const column: TableColumnType<SelectParticipantsTableColumnValueType> = columns[i];
      if (column.value.toLowerCase().includes('string')) continue; // skips numbers (and currency and flag) because of comma they won't be valid, they are added after loop
      sheetData[column.label] = companyData[
        column.value as 'name' | 'country' | 'rating' | 'ratingAdj' | 'industry'
      ].replace(unit, '');
    }
    sheetData[isFixed ? 'Credit Rate' : 'Credit Spread'] = companyData.creditInterestRate;
    sheetData[isFixed ? 'Debit Rate' : 'Debit Spread'] = companyData.debitInterestRate;
    sheetData['Interest Added'] = companyData.generateInterestStatementData ? 'Yes' : 'No';
    if (isNotional) sheetData['Currency'] = companyData.currency;
    return sheetData;
  });
}

export const getSheetData = ({
  inTableParticipants,
  isNotional,
  isFixed,
  userInfo,
}: {
  inTableParticipants: InTableParticipantType[];
  isNotional: boolean;
  isFixed: boolean;
  userInfo: UserType;
}) => {
  if (isNotional) {
    const allShortCurrencies = _.flatten(allCurrencies.short.map(({ options }) => options)).map(({ value }) => value);
    return [
      {
        sheetData: getCompaniesSheetData(inTableParticipants, isNotional, isFixed, userInfo),
        sheetName: 'Cash Pools',
      },
      {
        sheetData: allShortCurrencies.map((currency) => ({ 'Allowed Currencies': currency })),
        sheetName: 'Currencies',
      },
    ];
  }
  return [
    {
      sheetData: getCompaniesSheetData(inTableParticipants, isNotional, isFixed, userInfo),
      sheetName: 'Cash Pools',
    },
  ];
};
