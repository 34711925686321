import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  Date: true,
  Balance: true,
  'Credit Rate': true,
  'Debit Rate': true,
  'Credit Rate (adj.)': true,
  'Debit Rate (adj.)': true,
  'Interest Receivable': true,
  'Interest Payable': true,
  Benefit: true,
  'Leader Benefit': true,
};

export const cashPoolDataColumnsSlice = createSlice({
  name: 'cashPoolDataColumns',
  initialState: initialState,
  reducers: {
    resetForm: () => {
      return initialState;
    },
    setColumns: (_state, action) => {
      return { ...initialState, ...action.payload };
    },
    updateColumns: (_state, action) => {
      return action.payload;
    },
  },
});

export default cashPoolDataColumnsSlice.reducer;

export const { resetForm, setColumns, updateColumns } = cashPoolDataColumnsSlice.actions;

export const cashPoolDataColumnsSelector = (state) => state.cashPoolDataColumns;
