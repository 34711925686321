import PT from 'prop-types';
import React from 'react';
import Select from 'react-select';

import { Box, Text } from '~/ui';
import { withLabel } from '~/ui/hocs';

import { getStyles } from '../SingleSelect/stylesFactory';
import components from './components';

const widths = {
  s: '114px',
  m: '252px',
  l: '536px',
  fullWidth: '100%',
};
const SELECT_ALL = 'SELECT_ALL';
const selectAllOption = { label: 'Select All', value: SELECT_ALL };

function MultiSelect({
  error,
  disabled = false,
  hasGroupedOptions = false,
  options = [],
  includeSelectAll,
  placeholder = '',
  value,
  width = 'm',
  height = 'input-height',
  nameSingular = '',
  namePlural = '',
  onChange,
}) {
  function formatGroupLabel({ label }) {
    return (
      <Text color="bali-hai" variant="s-spaced-medium-caps">
        {label}
      </Text>
    );
  }

  const setValue = () => {
    if (!value) return [];
    // if value is `USD, EUR, CHF` this transforms it to an array
    if (!Array.isArray(value)) {
      value = value.replace(/\s/g, '').split(',');
    }

    if (hasGroupedOptions) {
      options = options.map((options) => options.options).flat();
    }

    if (includeSelectAll) {
      options = [selectAllOption, ...options];
    }
    const result = [];
    for (const option of options) {
      for (const v of value) {
        if (option === v || option?.value === v) result.push(option);
      }
    }
    return result;
  };

  const onChosenChange = (chosenOptions) => {
    const isSelectAllChosen = chosenOptions[chosenOptions.length - 1]?.value === SELECT_ALL;
    if (isSelectAllChosen && chosenOptions.length !== options.length) {
      return onChange(options.filter(({ value }) => value !== SELECT_ALL).map(({ value }) => value));
    }
    if (isSelectAllChosen) {
      return onChange([]);
    }
    onChange(chosenOptions?.map(({ value }) => value));
  };

  return (
    <Box disabled={disabled} sx={{ flexShrink: '0', width: widths[width] }}>
      <Select
        components={components}
        formatGroupLabel={formatGroupLabel}
        menuPlacement="auto"
        options={includeSelectAll ? [selectAllOption, ...options] : options}
        placeholder={placeholder}
        value={setValue()}
        styles={getStyles({ error, isMulti: true, height })}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        customProps={{ nameSingular, namePlural }}
        onChange={onChosenChange}
        isMulti
        isClearable={false}
      />
    </Box>
  );
}

const optionPropType = PT.shape({
  label: PT.oneOfType([PT.string, PT.number]),
  value: PT.oneOfType([PT.string, PT.number, PT.object]),
});

MultiSelect.propTypes = {
  error: PT.string,
  label: PT.oneOfType([PT.string, PT.number]),
  options: PT.arrayOf(optionPropType),
  placeholder: PT.string,
  tooltip: PT.string,
  value: PT.oneOfType([PT.string, PT.array]),
  width: PT.oneOf(Object.keys(widths)),
  onChange: PT.func,
};

export default withLabel(MultiSelect);
