export const DATE_FNS_FORMATS = {
  'YYYY-MM-DD': 'yyyy/MM/dd',
  'DD-MM-YYYY': 'dd/MM/yyyy',
  'MM-DD-YYYY': 'MM/dd/yyyy',
  ISO: 'yyyy-MM-dd',
};

export const DATE_FNS_FORMATS_WITH_TIME = {
  'YYYY-MM-DD': 'yyyy/MM/dd HH:mm',
  'DD-MM-YYYY': 'dd/MM/yyyy HH:mm',
  'MM-DD-YYYY': 'MM/dd/yyyy HH:mm',
  ISO: 'yyyy-MM-dd HH:mm',
};
