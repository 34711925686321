import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import type { ClientFeatureStateTypeWithErrors } from 'types';

export const defaultFeatureAvailability = Object.freeze({
  cashPool: false,
  cashPoolNumber: false,
  creditRating: false,
  creditRatingNumber: false,
  currency: false,
  financingAdvisory: false,
  geographyData: false,
  guarantee: false,
  guaranteeNumber: false,
  loan: false,
  backToBackLoan: false,
  loanNumber: false,
  backToBackLoanNumber: false,
  loanGuaranteeNumber: false,
  payment: false,
  userNumber: false,
  cuftData: false,
  isTemplateCashPoolBatchUpload: false,
});

const initialState: ClientFeatureStateTypeWithErrors = {
  featureAvailability: { ...defaultFeatureAvailability },
  cashPoolNumber: 0,
  creditRatingNumber: 0,
  currency: { currencies: [] },
  geographyData: 'full',
  guaranteeNumber: 0,
  loanNumber: 0,
  backToBackLoanNumber: 0,
  loanGuaranteeNumber: 0,
  userNumber: 0,

  errors: {},
};

export const clientFeatureSlice = createSlice({
  name: 'clientFeature',
  initialState,
  reducers: {
    setClientFeature: (state, action) => {
      return {
        ...state,
        featureAvailability: action.payload.dbFeatureAvailability,
        ...action.payload.dbFeaturesWithValues,
      };
    },
    updateField: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateFeatureAnswers: (state, action) => {
      return { ...state, featureAvailability: action.payload };
    },
    updateError: (state, action) => {
      return { ...state, errors: { ...state.errors, ...action.payload } };
    },
    resetErrors: (state) => {
      return { ...state, errors: {} };
    },
  },
});

export default clientFeatureSlice.reducer;

export const { setClientFeature, updateField, updateFeatureAnswers, updateError, resetErrors } =
  clientFeatureSlice.actions;

export const clientFeatureSelector = (state: RootState) => state.clientFeature;
