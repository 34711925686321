import { MultiSelect } from 'ui';
import { getOptionsFromArray } from 'utils/arrays';
import { CreditRatingValueEnum } from 'enums/creditRating';
import { SingleSelectWidthType } from 'types';

type CreditRatingMultiSelectPropsType = {
  label?: string;
  tooltip?: string;
  width?: SingleSelectWidthType;
  disabled?: boolean;
  includeSelectAll?: boolean;
  value: string[] | undefined;
  onChange: Function;
};

const options = getOptionsFromArray(Object.values(CreditRatingValueEnum));

function CreditRatingMultiSelect({
  label = 'Credit Rating',
  tooltip,
  width = 'm',
  disabled = false,
  includeSelectAll = false,
  value,
  onChange,
}: CreditRatingMultiSelectPropsType) {
  return (
    <MultiSelect
      label={label}
      options={options}
      tooltip={tooltip}
      value={value}
      width={width}
      disabled={disabled}
      includeSelectAll={includeSelectAll}
      nameSingular="credit rating"
      namePlural="credit ratings"
      onChange={onChange}
    />
  );
}

export default CreditRatingMultiSelect;
